import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { Box, Button, Grid, OpswatCheckbox, TableAdvanced, TextGrid, Typography } from '@opswat/react-ui'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementOrganizationListPageURL,
  editUserRolePath,
  opswatRoleListPageURL,
  organizationRolesPath
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
  roleData: any
  creatorData: any
  contentTypePermissions: any
  permissionCodenames: any
  rolePermissions: string[]
}

const RoleDetailView = ({
  roleData,
  creatorData,
  contentTypePermissions,
  permissionCodenames,
  rolePermissions
}: IProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const navigate = useNavigate()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const isEditRoleDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_role')
    }
    return false
  }, [profileData])

  const isPermissionExisted = (mappingName: string, action: string) => {
    const permission = `${action.toLowerCase()}_${mappingName}`
    return permissionCodenames && permissionCodenames.includes(permission)
  }

  const columns = useMemo(() => {
    return [
      {
        header: '',
        body: (data: any) => <Typography variant="body2">{data.displayName || '--'}</Typography>,
        style: { minWidth: 370, width: 370 }
      },
      {
        header: 'View',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'view') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              disabled
              checked={rolePermissions.includes(`view_${data.mappingName}`)}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Add',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'add') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              disabled
              checked={rolePermissions.includes(`add_${data.mappingName}`)}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Change',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'change') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              disabled
              checked={rolePermissions.includes(`change_${data.mappingName}`)}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Delete',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'delete') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              disabled
              checked={rolePermissions.includes(`delete_${data.mappingName}`)}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'More',
        body: (data: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 1 }}>
            {data.otherPermissions.map((item: any, idx: number) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap' }} key={idx.toString()}>
                <OpswatCheckbox
                  labelStyle={{ mx: 0, gap: 1 }}
                  sx={{ margin: 'auto' }}
                  label=""
                  disabled
                  checked={rolePermissions.includes(item.codename)}
                />
                <Typography sx={{ mr: 2, my: 0.5 }}>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        ),
        style: { textAlign: 'center' }
      }
    ]
  }, [rolePermissions])

  const roleEditPageURL = isOpswatIncRoute
    ? `${opswatRoleListPageURL}/${roleData.id}/${editUserRolePath}`
    : `${customerManagementOrganizationListPageURL}/${roleData.organization.id}/${organizationRolesPath}/${roleData.id}/${editUserRolePath}`

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <TextGrid label={'Name*'} value={roleData.name} />
          <TextGrid label={'Description'} value={roleData.description} />
          <TextGrid
            label={'Permissions'}
            containerProps={{ alignItems: 'top' }}
            value={
              <Box sx={{ maxHeight: '800px', overflowY: 'scroll' }}>
                <TableAdvanced boxMinHeight="60vh" columns={columns} isLoading={false} data={contentTypePermissions} />
              </Box>
            }
          />
          <TextGrid
            label={'Updated at'}
            value={formatDatetime(roleData.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          />
          <TextGrid
            label={'Created at'}
            value={formatDatetime(roleData.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          />
          <TextGrid label={'Creator'} value={creatorData?.fullName} />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => {
                navigate(roleEditPageURL)
              }}
              color="primary"
              disabled={isEditRoleDisabled}
            >
              Edit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default RoleDetailView
