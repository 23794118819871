import { customerManagementPageURL } from './customerManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const customerRoleObjectsPath = 'roles-objects'
export const customerRoleObjectsPathNewPath = 'new'
export const customerRoleObjectsDetailPath = 'detail'
export const customerRoleObjectsIdPath = ':roleObjectId'
export const customerRoleObjectsTabPath = ':tabPath'
export const customerRoleObjectsEditPath = 'edit'
export const customerRoleObjectsEventLogsPath = 'event-logs'

/** ----------------- ROUTE -------------------- */
export const customerManagementRoleObjectsListPageURL = `${customerManagementPageURL}/${customerRoleObjectsPath}`
export const customerManagementRoleObjectsNewPageURL = `${customerManagementRoleObjectsListPageURL}/${customerRoleObjectsPathNewPath}`
export const customerManagementRoleObjectsDetailFlexPageURL = `${customerManagementRoleObjectsListPageURL}/${customerRoleObjectsIdPath}/${customerRoleObjectsTabPath}`
export const customerManagementRoleObjectsEventLogsListPageURL = `${customerManagementRoleObjectsListPageURL}/${customerRoleObjectsEventLogsPath}`
