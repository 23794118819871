import { TabAdvanced, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import FalseDetectionSubmissionListPage from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage'
import FalseSubmissionAntivirusEngine from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage/FalseSubmissionAntivirusEngine'
import FalseSubmissionEventLogs from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage/FalseSubmissionEventLogs'
import {
  dashboardFalseDetectionSubmissionAntivirusEngineListURL,
  dashboardFalseDetectionSubmissionEventLogsURL,
  dashboardFalseDetectionSubmissionListURL
} from 'myopswat-admin/src/routes'

const FalseDetectionSubmissionListContainer = () => {
  const tabArray = [
    {
      label: 'Submissions',
      content: <FalseDetectionSubmissionListPage />,
      tabPath: dashboardFalseDetectionSubmissionListURL
    },
    {
      label: 'Antivirus Engines',
      content: <FalseSubmissionAntivirusEngine />,
      tabPath: dashboardFalseDetectionSubmissionAntivirusEngineListURL
    },
    {
      label: 'Event Logs',
      content: <FalseSubmissionEventLogs />,
      tabPath: dashboardFalseDetectionSubmissionEventLogsURL
    }
  ]

  return (
    <TemplateSection>
      <TypographyDivider label="False Detection Submissions" isDivider={false} />
      <TabAdvanced tabs={tabArray} />
    </TemplateSection>
  )
}

export default FalseDetectionSubmissionListContainer
