import { Controller } from 'react-hook-form'

import { Grid, TextFieldSearch } from '@opswat/react-ui'

interface IProps {
  control: any
  handleQuery: (name: string, value: any) => void
}

const MassNotificationSearchFilter = ({ control, handleQuery }: IProps) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 3 }}>
        <Controller
          name={`filters.q`}
          control={control}
          render={({ field: { value } }) => (
            <TextFieldSearch
              placeholder={'Search for title...'}
              value={value}
              onChange={e => handleQuery('q', e.target.value)}
              onClearText={() => handleQuery('q', '')}
              sx={{
                minHeight: 'auto',
                '& .MuiInput-underline:before': { borderBottomColor: '#B7C3DC' }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default MassNotificationSearchFilter
