import { DIALOGS } from '@myopswat/common'
import { copyText } from '@opswat/react-core'
import { CopySimpleIcon } from '@opswat/react-icon'
import { Alert, Box, ButtonLoading, DialogAdvanced, Grid, TextField, Tooltip, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { useLazyAdminFalseSubmissionQuery } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { enqueueSnackbar } from 'notistack'
import { FC, useEffect, useMemo, useRef, useState } from 'react'

const DialogFalseDetectionDownloadInfo: FC<unknown> = () => {
  const [copyStatus, setCopyStatus] = useState('copy')
  const encryptionKeyRef: any = useRef(null)

  const dispatch = useAppDispatch()

  const dialogType = useTypedSelector(selectDialogs)
  const [getFalseSubmission, { data: falseSubmissionData }] = useLazyAdminFalseSubmissionQuery()

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO]: false,
        [`${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`]: ''
      })
    )
    setCopyStatus('copy')
  }

  useEffect(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`)) {
      const data = _get(dialogType, `${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`)
      const falseDetectionId = _get(data, 'id', '')
      getFalseSubmission(falseDetectionId)
    }
  }, [dialogType])

  const encryptionKey = useMemo(() => {
    if (falseSubmissionData) {
      return falseSubmissionData.password
    }
    return ''
  }, [falseSubmissionData])

  const handleCopy = () => {
    copyText(encryptionKey).then(() => {
      enqueueSnackbar('Password was copied successfully', { variant: 'success' })
      setCopyStatus('copied')
    })
  }

  return (
    <DialogAdvanced
      title={'Download Information'}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO, false)}
      onClose={() => handleClose()}
      content={
        <>
          <Grid display="flex" sx={{ mb: 2 }} alignItems="center">
            <Typography variant="subtitle2" sx={{ py: 0.5, minWidth: '80px' }}>
              Password for extraction
            </Typography>
            <TextField
              InputProps={{ readOnly: true }}
              size="small"
              sx={{ minHeight: '37px' }}
              value={encryptionKey}
              fullWidth
              inputRef={encryptionKeyRef}
            />
            <Tooltip title="Copy" arrow>
              <Box>
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    sx: { minHeight: '37px', background: copyStatus !== 'copied' ? '#1D6BFC' : '#194CA7' },
                    onClick: () => handleCopy()
                  }}
                  propsLoading={{ color: 'inherit' }}
                >
                  <CopySimpleIcon color="white" />
                </ButtonLoading>
              </Box>
            </Tooltip>
          </Grid>
          <Alert severity="warning" sx={{ mt: 3, mb: 1 }}>
            Please use the password above to extraction the compressed file.
          </Alert>
        </>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogFalseDetectionDownloadInfo
