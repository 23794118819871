export interface IProductFilterInput {
  q?: string
  tags?: string[]
  solutionIds?: number[]
  excludedTags?: string[]
}

export interface ProductInputType {
  id?: string
  parentIds: string[]
  licensedProductsDisplay: string[]
  avatar: string
  slug: string
  name: string
  shortName: string
  codeName: string
  tags: string[]
  blogTags: string[]
  description: string
  documentation: string
  note: string
  solutionIds: number[]
  asIds: number[]
  licenseTypes: string[]
  downloadable: boolean
  resources: any[]
  hide: boolean
  avatarFile: File
  thumbnailFile: File
  releaseMetadata: any
  rmId: number | null
}

export interface Solution {
  id: number
  name: string
}

export interface ProductReleaseFiltersInput {
  productId?: string
}

export interface ILatestReleasesInput {
  productId?: string
}

export interface IReleaseInput {
  productId: string
  q?: string
}

export interface IAdminReleaseInput {
  id?: string
  platformIds: number[]
  assets: string
  note?: string
  tags: string[]
  semVer?: string
  version: string
  versionSuffix: string
  releaseDate: string
  date: string
  epochTime: number
  updatedReason?: string
}

export enum RELEASE_INFORMATION_LEVEL {
  CRITICAL = 'critical',
  TIER_1 = 'all-new',
  TIER_2 = 'new-feature',
  TIER_3 = 'minor'
}

export enum RELEASE_INFORMATION_STATUS {
  PENDING = 'pending',
  PARTIALLY_DELIVERED = 'partially-delivered',
  DELIVERED = 'delivered',
  SKIPPED = 'skipped'
}

export const RELEASE_INFORMATION_LEVEL_LABELS: any = {
  [RELEASE_INFORMATION_LEVEL.CRITICAL]: 'Critical',
  [RELEASE_INFORMATION_LEVEL.TIER_1]: 'All New',
  [RELEASE_INFORMATION_LEVEL.TIER_2]: 'New Features',
  [RELEASE_INFORMATION_LEVEL.TIER_3]: 'Minor'
}

export const RELEASE_INFORMATION_STATUS_LABELS: any = {
  [RELEASE_INFORMATION_STATUS.PENDING]: 'Pending',
  [RELEASE_INFORMATION_STATUS.PARTIALLY_DELIVERED]: 'Partially Delivered',
  [RELEASE_INFORMATION_STATUS.DELIVERED]: 'Delivered',
  [RELEASE_INFORMATION_STATUS.SKIPPED]: 'Skipped'
}

export interface ReleaseInformationType {
  id: string
  productId: string
  productName: string
  platformIds: number[]
  version: string
  releaseLevel: RELEASE_INFORMATION_LEVEL
  sendReleaseEmail?: boolean
  featureHeader?: string
  featureDescription?: string
  platformStatuses?: any[]
  status?: RELEASE_INFORMATION_STATUS
  createdAt?: string
  updatedAt?: string
  executedAt?: string
  creatorEmail?: string
}

export interface IAdminReleaseInformationInput {
  id?: string
  productId: string
  platformIds: any[]
  version: string
  releaseLevel: string
  sendReleaseEmail?: boolean
  featureHeader?: string
  featureDescription?: string
  updatedReason?: string

  // transient
  platformStatuses?: any[]
  status?: RELEASE_INFORMATION_STATUS
}
