import { useEffect, useState } from 'react'

import _map from 'lodash/map'
import _range from 'lodash/range'

import { Grid, Skeleton, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import { useAdminOrganizationQuery } from 'myopswat-admin/src/api/organizationManagement'
import { useSearchParams } from 'react-router-dom'

import { DETAIL_MODE } from '../../constants'
import OrganizationEdit from './EditSection'
import OrganizationView from './ViewSection'

interface IProps {
  orgId: string
}

const OrganizationBasicInformation = ({ orgId }: IProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [searchParams] = useSearchParams()

  const {
    data: organization,
    isFetching,
    refetch
  } = useAdminOrganizationQuery(orgId, {
    refetchOnMountOrArgChange: true
  })

  const onEdit = () => {
    setIsEdit(true)
  }

  const handleCancel = () => {
    setIsEdit(false)
  }

  const handleAfterSave = async () => {
    await refetch()
    setIsEdit(false)
  }

  useEffect(() => {
    const mode = searchParams.get('mode')
    if (mode === DETAIL_MODE.EDIT) {
      setIsEdit(true)
    }
  }, [searchParams])

  const handleRender = () => {
    if (isFetching)
      return (
        <Grid container spacing={2}>
          {_map(_range(8), (_, idx) => (
            <Grid item xs={12} key={idx.toString()}>
              <Skeleton animation="wave" variant="text" width="30%" />
            </Grid>
          ))}
        </Grid>
      )

    if (isEdit) return <OrganizationEdit org={organization} onCancel={handleCancel} afterSave={handleAfterSave} />

    return <OrganizationView org={organization} onEdit={onEdit} />
  }

  return (
    <TemplateSection>
      <TypographyDivider label="User Management - Organization" />
      {handleRender()}
    </TemplateSection>
  )
}

export default OrganizationBasicInformation
