import { useEffect, useMemo, useState } from 'react'

import _debounce from 'lodash/debounce'
import _map from 'lodash/map'

import { Autocomplete, AutocompleteMultiple, Box, TextField, TextGrid } from '@opswat/react-ui'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { Controller, RefCallBack, UseFormReturn } from 'react-hook-form'

import { fullName } from '@opswat/react-core'
import { useLazyAdminOrganizationUsersQuery } from 'myopswat-admin/src/api/organizationManagement'
import { PAGE_DEFAULT } from 'myopswat-admin/src/constants'
import { IGroupForm } from './GroupSchema'

interface IProps {
  formRef: UseFormReturn<IGroupForm>
  groupData: any
  userData: any
  roleData: any
}

const GroupForm: any = ({ formRef, groupData, roleData }: IProps) => {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors }
  } = formRef

  const [inputValue, setInputValue] = useState('')

  const [getAdminOrganization, { data: usersData, isFetching: isFetchingUserData }] =
    useLazyAdminOrganizationUsersQuery()

  const convertUserDataToAutoCompleteOptions = (data: any[]) =>
    _map(data, item => {
      const _fullName = item.fullName ?? fullName(item.firstName, item.lastName)
      return {
        ...item,
        value: item.id,
        label: `${_fullName} (${item.email})`
      }
    })

  const autocompleteValues: any = useMemo(() => {
    return convertUserDataToAutoCompleteOptions(watch('users'))
  }, [watch('users')])

  const autocompleteOptions: any = useMemo(() => {
    return convertUserDataToAutoCompleteOptions(
      usersData?.adminOrganizationUsers.results.filter((item: any) => item.user?.id).map((item: any) => item.user)
    )
  }, [usersData, inputValue, autocompleteValues])

  const performSearchUserData = (q: string) => {
    _debounce(
      () =>
        getAdminOrganization({
          id: groupData.organization.id,
          filters: { q },
          pageInfo: {
            page: PAGE_DEFAULT,
            pageSize: 100
          }
        }),
      300
    )()
  }

  useEffect(() => {
    getAdminOrganization({
      id: groupData.organization.id,
      filters: { q: '' },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: 50
      }
    })
  }, [])

  return (
    <Box>
      <Box>
        <GridView
          label={'Name*'}
          value={
            <TextField
              size="small"
              fullWidth
              error={'name' in errors}
              {...register('name')}
              helperText={errors.name?.message}
            />
          }
        />
        <GridView
          label={'Description'}
          value={
            <TextField
              size="small"
              fullWidth
              rows={6}
              multiline
              error={'description' in errors}
              {...register('description')}
              helperText={errors.description?.message}
            />
          }
        />
        <TextGrid label={'Organization'} labelColProps={{ md: 2 }} value={groupData.organization.name} />
        <GridView
          label={'Roles'}
          value={
            <Controller
              name="roles"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={roleData}
                  getOptionLabel={option => option.name || ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={field.value}
                  onChange={(_event: any, newValue: any) => {
                    field.onChange(newValue)
                  }}
                  renderInput={params => <TextField {...params} variant="outlined" />}
                />
              )}
            />
          }
        />
        <GridView
          label={'Users'}
          value={
            <AutocompleteMultiple
              options={autocompleteOptions}
              filterOptions={x => x}
              loading={!!inputValue && isFetchingUserData}
              limitTags={20}
              value={autocompleteValues}
              placeholder="Type to search users"
              onChange={value => {
                setValue(
                  'users',
                  value.map((item: any) => ({ ...item, label: undefined })),
                  { shouldDirty: true }
                )
              }}
              inputValue={inputValue}
              onInputChange={(event, value) => {
                // prevent unexpected auto set value to ""
                if (!event) {
                  return
                }

                // perform search in the backend
                setInputValue(value)
                performSearchUserData(value)
              }}
              name={''}
              ref={{} as RefCallBack}
              onBlur={() => {
                return
              }}
            />
          }
        />
      </Box>
    </Box>
  )
}
export default GroupForm
