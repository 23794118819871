import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-admin/src/api/base-query'
import {
  OrganizationAcceptInvitationInput,
  OrganizationActiveUsersInput,
  OrganizationChangeUsersRolesInput,
  OrganizationCustomerFiltersInput,
  OrganizationDeactivateUsersInput,
  OrganizationInviteUserInput,
  OrganizationRemoveUsersInput,
  OrganizationUpdateInput,
  OrganizationUserFilterInput,
  SubOrganizationsInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    usingOrganization: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            usingOrganization {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              billingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress1
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
            }
          }
        `
      })
    }),
    organization: builder.query<any, string>({
      query: organizationId => ({
        document: gql`
          query {
            organization(id: "${organizationId}") {
              id
              name
              ssoId
              ssoMeta
              creator {
                firstName
                lastName
              }
              website
              region
              subRegion
              channelPartner
              currency
              customerSince
              supportLevel
              industries
              note
              owner
              billingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              shippingAddress {
                streetAddress1
                streetAddress2
                city
                country
                state
                phone
                postalCode
              }
              requiredMfa
              roles
            }
          }
        `
      })
    }),
    organizationUsers: builder.query<any, OrganizationUserFilterInput>({
      query: organizationFilters => ({
        document: gql`
          query ($organizationFilters: OrganizationUserFiltersInput, $roleFilters: RoleFiltersInput) {
            organizationUsers(filters: $organizationFilters) {
              user {
                id
                firstName
                lastName
                email
                ssoId
              }
              invitationId
              isActive
              roleIds
              lastLogin
            }
            roles(filters: $roleFilters) {
              id
              name
              ssoId
            }
          }
        `,
        variables: {
          organizationFilters: organizationFilters,
          roleFilters: {}
        }
      })
    }),
    organizationInviteUsers: builder.mutation<any, OrganizationInviteUserInput>({
      query: input => ({
        document: gql`
          mutation organizationInviteUsers($input: InviteUserType!) {
            organizationInviteUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationAcceptInvitation: builder.mutation<any, OrganizationAcceptInvitationInput>({
      query: input => ({
        document: gql`
          mutation organizationAcceptInvitation($input: AcceptInvitationType!) {
            organizationAcceptInvitation(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationRemoveUsers: builder.mutation<any, OrganizationRemoveUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationRemoveUsers($input: RemoveUserInput!) {
            organizationRemoveUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationDeactivateUsers: builder.mutation<any, OrganizationDeactivateUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationDeactivateUsers($input: DeactivateUserType!) {
            organizationDeactivateUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationActiveUsers: builder.mutation<any, OrganizationActiveUsersInput>({
      query: input => ({
        document: gql`
          mutation organizationActiveUsers($input: ActiveUserType!) {
            organizationActiveUsers(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationChangeUsersRoles: builder.mutation<any, OrganizationChangeUsersRolesInput>({
      query: input => ({
        document: gql`
          mutation organizationChangeUsersRoles($input: ChangeUserRoleInput!) {
            organizationChangeUsersRoles(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizationUpdate: builder.mutation<any, OrganizationUpdateInput>({
      query: input => ({
        document: gql`
          mutation organizationUpdate($input: OrganizationUpdateInput!) {
            organizationUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    organizations: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            organizations {
              id
              name
            }
          }
        `
      })
    }),
    organizationUpdateUsing: builder.mutation<any, string>({
      query: organizationId => ({
        document: gql`
          mutation {
            organizationUpdateUsing(id: "${organizationId}") {
                success
                errors
            }
          }
        `
      })
    }),
    adminOrganizationCustomers: builder.query<any, OrganizationCustomerFiltersInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query adminOrganizationCustomers($filters: AdminOrganizationCustomerFiltersInput!, $pageInfo: PageInfoType) {
            adminOrganizationCustomers(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                name
                ssoId
                accountId
                opportunityId
                customerTypes
                updatedAt
                latestEventLogAt
                latestEventLogBy
              }
            }
          }
        `,
        variables: {
          filters,
          pageInfo
        }
      })
    }),
    adminSubOrganizations: builder.query<any, SubOrganizationsInput>({
      query: ({ filters, pageInfo, orgId }) => ({
        document: gql`
          query adminSubOrganizations($filters: AdminOrganizationFiltersInput!, $pageInfo: PageInfoType) {
            adminSubOrganizations(filters: $filters, pageInfo: $pageInfo, id: "${orgId}") {
              totalCount
              results {
                id
                name
                ssoId
                opportunityId
                updatedAt
                latestEventLogAt
                latestEventLogBy
              }
            }
          }
        `,
        variables: {
          filters,
          pageInfo
        }
      })
    })
  })
})

export const {
  useUsingOrganizationQuery,
  useLazyUsingOrganizationQuery,
  useOrganizationUsersQuery,
  useLazyOrganizationUsersQuery,
  useOrganizationInviteUsersMutation,
  useOrganizationAcceptInvitationMutation,
  useOrganizationRemoveUsersMutation,
  useOrganizationDeactivateUsersMutation,
  useOrganizationActiveUsersMutation,
  useOrganizationChangeUsersRolesMutation,
  useOrganizationUpdateMutation,
  useOrganizationQuery,
  useOrganizationsQuery,
  useLazyOrganizationsQuery,
  useOrganizationUpdateUsingMutation,
  useLazyAdminOrganizationCustomersQuery,
  useLazyAdminSubOrganizationsQuery
} = api
