import _get from 'lodash/get'
import React, { FC, useState } from 'react'

import { WarningIcon } from '@opswat/react-icon'
import {
  Autocomplete,
  Box,
  Button,
  DropFile,
  FormHelperText,
  OpswatCheckbox,
  styled,
  TextField,
  Tooltip,
  Typography
} from '@opswat/react-ui'
import StandardTextFieldProps from '@opswat/react-ui/StandardTextFieldProps'
import { useAddProductTypeMutation, useProductTypeQuery } from 'myopswat-admin/src/api/product'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { enqueueSnackbar } from 'notistack'
import { Controller, UseFormReturn } from 'react-hook-form'
import {
  DESCRIPTION_SAMPLE,
  DOCUMENT_SAMPLE,
  PRODUCT_FORM_LABEL_MAP,
  PRODUCT_LICENSE_TYPE_OPTIONS,
  PRODUCT_TAG_OPTIONS,
  RELEASE_METADATA,
  RESOURCE_SAMPLE
} from '../../constants'
import { IProductForm } from './ProductSchema'

interface IProps {
  formRef: UseFormReturn<IProductForm>
  productMap: any
  asIdsProductMap: any
  avatar?: string | File
  thumbnail?: string | File
  solutionMap: { [key: number]: string }
  isEdit?: boolean
  icon?: string | File
  supportCasePlatforms: string[]
}

interface ICustomAutocompleteTextFieldProps extends StandardTextFieldProps {
  onProductTypeAdded: () => void
}

const CustomAutocompleteTextField: FC<ICustomAutocompleteTextFieldProps> = ({ onProductTypeAdded, ...props }) => {
  const [addProductType] = useAddProductTypeMutation()
  const [inputValue, setInputValue] = useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      try {
        const res = await addProductType({ typeName: inputValue }).unwrap()
        if (!res.success) {
          enqueueSnackbar(res.errors[0].message, { variant: 'error' })
        }
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: 'error' })
      }
      if (onProductTypeAdded) {
        onProductTypeAdded()
      }
    }
  }

  return <TextField size="small" variant="outlined" onChange={handleChange} onKeyDown={handleKeyDown} {...props} />
}

const GenerateButtonStyled = styled(Button)(() => ({
  marginTop: 1,
  maxWith: '150px'
}))

const ProductForm = ({
  formRef,
  solutionMap,
  productMap,
  asIdsProductMap,
  thumbnail,
  avatar,
  isEdit,
  icon,
  supportCasePlatforms
}: IProps) => {
  const {
    control,
    register,
    setValue,
    formState: { errors }
  } = formRef

  const handleChangeThumbnail = (data: any) => {
    setValue('thumbnailFile', _get(data, '0') || null, { shouldDirty: true })
  }

  const handleChangeImage = (data: any) => {
    setValue('avatarFile', _get(data, '0') || null, { shouldDirty: true })
  }

  const handleChangeIcon = (data: any) => {
    setValue('iconFile', _get(data, '0') || null, { shouldDirty: true })
  }

  const handlePasteSampleResources = () => {
    setValue('resources', JSON.stringify(RESOURCE_SAMPLE), { shouldDirty: true })
  }

  const handlePasteSampleReleaseMetadata = () => {
    setValue('releaseMetadata', JSON.stringify(RELEASE_METADATA), { shouldDirty: true })
  }

  const handlePasteSampleDocumentation = () => {
    setValue('documentation', DOCUMENT_SAMPLE, { shouldDirty: true })
  }

  const handlePasteSampleDescription = () => {
    setValue('description', DESCRIPTION_SAMPLE, { shouldDirty: true })
  }

  const { data: allProductTypes, refetch } = useProductTypeQuery()

  return (
    <Box>
      <GridView
        label={`${PRODUCT_FORM_LABEL_MAP.name}*`}
        value={
          <TextField
            size="small"
            fullWidth
            error={'name' in errors}
            {...register('name')}
            helperText={errors.name?.message}
          />
        }
      />
      <GridView
        label={`${PRODUCT_FORM_LABEL_MAP.slug}*`}
        value={
          <TextField
            size="small"
            fullWidth
            error={'slug' in errors}
            {...register('slug')}
            helperText={errors.slug?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.shortName}
        value={
          <TextField
            size="small"
            fullWidth
            error={'shortName' in errors}
            {...register('shortName')}
            helperText={errors.shortName?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.codeName}
        value={
          <TextField
            size="small"
            fullWidth
            error={'codeName' in errors}
            {...register('codeName')}
            helperText={errors.codeName?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.asIds}
        value={
          <TextField
            size="small"
            fullWidth
            placeholder="List numbers with comma seperator"
            error={'asIds' in errors}
            {...register('asIds')}
            helperText={errors.asIds?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.releaseManagementId}
        value={
          <TextField
            size="small"
            fullWidth
            error={'rmId' in errors}
            {...register('rmId')}
            helperText={errors.rmId?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.blogTags}
        value={
          <TextField
            size="small"
            fullWidth
            placeholder="List strings with comma seperator"
            error={'blogTags' in errors}
            {...register('blogTags')}
            helperText={errors.blogTags?.message}
          />
        }
      />
      <GridView
        label={`${PRODUCT_FORM_LABEL_MAP.thumbnail}*`}
        value={<DropFile file={thumbnail} getFiles={handleChangeThumbnail} />}
      />
      <GridView
        label={`${PRODUCT_FORM_LABEL_MAP.image}*`}
        value={<DropFile file={avatar} getFiles={handleChangeImage} />}
      />
      <GridView
        label={`${PRODUCT_FORM_LABEL_MAP.icon}`}
        value={<DropFile file={icon} getFiles={handleChangeIcon} />}
      />
      <GridView
        label={
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="label">
              {PRODUCT_FORM_LABEL_MAP.description}
            </Typography>
            <GenerateButtonStyled
              sx={{ mt: 1, maxWidth: '150px' }}
              color="primary"
              variant="outlined"
              onClick={handlePasteSampleDescription}
            >
              Generate Sample
            </GenerateButtonStyled>
          </Box>
        }
        value={
          <TextField
            size="small"
            fullWidth
            rows={6}
            multiline
            error={'description' in errors}
            {...register('description')}
            helperText={errors.description?.message}
          />
        }
      />
      <GridView
        label={
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="label">
              {PRODUCT_FORM_LABEL_MAP.resources}
            </Typography>
            <GenerateButtonStyled
              sx={{ mt: 1, maxWidth: '150px' }}
              color="primary"
              variant="outlined"
              onClick={handlePasteSampleResources}
            >
              Generate Sample
            </GenerateButtonStyled>
          </Box>
        }
        value={
          <Box>
            <TextField
              size="small"
              fullWidth
              rows={3}
              multiline
              error={'resources' in errors}
              {...register('resources')}
              helperText={errors.resources?.message}
            />
          </Box>
        }
      />
      <GridView
        label={
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="label">
              {PRODUCT_FORM_LABEL_MAP.releaseMetadata}
            </Typography>
            <GenerateButtonStyled
              sx={{ mt: 1, maxWidth: '150px' }}
              color="primary"
              variant="outlined"
              onClick={handlePasteSampleReleaseMetadata}
            >
              Generate Sample
            </GenerateButtonStyled>
          </Box>
        }
        value={
          <Box>
            <TextField
              size="small"
              fullWidth
              rows={3}
              multiline
              error={'releaseMetadata' in errors}
              {...register('releaseMetadata')}
              helperText={errors.releaseMetadata?.message}
            />
          </Box>
        }
      />
      <GridView
        label={
          <Box display={'flex'} flexDirection={'column'}>
            <Typography variant="subtitle2" component="label">
              {PRODUCT_FORM_LABEL_MAP.documentation}
            </Typography>
            <GenerateButtonStyled
              sx={{ mt: 1, maxWidth: '150px' }}
              color="primary"
              variant="outlined"
              onClick={handlePasteSampleDocumentation}
            >
              Generate Sample
            </GenerateButtonStyled>
          </Box>
        }
        value={
          <TextField
            size="small"
            fullWidth
            rows={6}
            multiline
            error={'documentation' in errors}
            {...register('documentation')}
            helperText={errors.documentation?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.type}
        value={
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                freeSolo
                options={allProductTypes || []}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                renderInput={(params: any) => (
                  <CustomAutocompleteTextField onProductTypeAdded={() => refetch()} {...params} />
                )}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.tags}
        value={
          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={Object.keys(PRODUCT_TAG_OPTIONS)}
                getOptionLabel={(option: any) => {
                  return PRODUCT_TAG_OPTIONS[option]
                }}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.licenseType}
        value={
          <Controller
            name={'licenseTypes'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={Object.keys(PRODUCT_LICENSE_TYPE_OPTIONS)}
                getOptionLabel={(option: any) => PRODUCT_LICENSE_TYPE_OPTIONS[option]}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.solutions}
        value={
          <Controller
            name={'solutionIds'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={Object.keys(solutionMap).map(k => Number(k))}
                getOptionLabel={(option: any) => solutionMap[option]}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option: any, value: any) => option == value}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={
          <Typography variant="subtitle2" fontSize={13}>
            {PRODUCT_FORM_LABEL_MAP.supportCasePlatforms}
            <span>
                <Tooltip
                  arrow
                  placement="top"
                  title={<Typography variant="body2"> If this field contains at least one selected value, the product
                    will
                    appear on the
                    <strong> Submit Support Case</strong> page and the Platform question will load these
                    selected items</Typography>}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                >
                <span style={{ height: '20px' }}>
                      <WarningIcon />
                </span>
              </Tooltip>
            </span>
          </Typography>
        }
        value={
          <Controller
            name={'supportCasePlatforms'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={supportCasePlatforms}
                getOptionLabel={(option: any) => option}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option: any, value: any) => option == value}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.note}
        value={
          <TextField
            size="small"
            fullWidth
            rows={3}
            multiline
            error={'Note' in errors}
            {...register('note')}
            helperText={errors.note?.message}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.parentProducts}
        value={
          <Controller
            name={'parentIds'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={Object.keys(productMap)}
                getOptionLabel={(option: any) => productMap[option]}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option: any, value: any) => option == value}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.licensedProductsDisplay}
        value={
          <Controller
            name={'licensedProductsDisplay'}
            control={control}
            render={({ field }) => (
              <Autocomplete
                size="small"
                multiple
                options={Object.keys(asIdsProductMap)}
                getOptionLabel={(option: any) => asIdsProductMap[option]}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option: any, value: any) => option == value}
                renderInput={(params: any) => <TextField size="small" {...params} variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.downloadable}
        value={
          <Controller
            name={'downloadable'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={field.value}
                labelStyle={{ marginLeft: '-8px' }}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.hide}
        value={
          <Controller
            name={'hide'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                labelStyle={{ marginLeft: '-8px' }}
                checked={field.value}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={PRODUCT_FORM_LABEL_MAP.showLatestVersion}
        value={
          <>
            <Controller
              name={'showLatestVersion'}
              control={control}
              render={({ field }) => (
                <OpswatCheckbox
                  label=""
                  checked={field.value}
                  onChange={(event: any) => {
                    field.onChange(event.target.checked)
                  }}
                  labelStyle={{ marginLeft: '-8px' }}
                />
              )}
            />

            <Box sx={{ marginTop: '2px' }}>
              <FormHelperText component="label" sx={{ color: '#fdb00d' }}>
                This setting should not apply to products that are released on multiple platforms and do not have a
                simultaneous release date, as well as products that have a hotfix version.
              </FormHelperText>
            </Box>
          </>
        }
      />

      {isEdit && (
        <GridView
          label={`${PRODUCT_FORM_LABEL_MAP.updatedReason}*`}
          value={
            <TextField
              size="small"
              fullWidth
              rows={3}
              multiline
              error={'updatedReason' in errors}
              {...register('updatedReason')}
              helperText={errors.updatedReason?.message}
            />
          }
        />
      )}
    </Box>
  )
}

export default ProductForm
