import _get from 'lodash/get'

import { Control, Controller, UseFormSetValue } from 'react-hook-form'

import { Grid, TextFieldSearch, Typography, TypographyLinkClamp } from '@opswat/react-ui'
import { customerManagementOrganizationListPageURL, detailCustomerUserPath } from 'myopswat-admin/src/routes'

interface IProps {
  control: Control<any>
  setValue: UseFormSetValue<any>
  handleQuery: (name?: string, value?: any) => void
  orgData: any
}

const SubOrganizationsHeader = ({ control, handleQuery, orgData }: IProps) => {
  const renderTextFieldSearch = () => {
    return (
      <Controller
        name={'q'}
        control={control}
        render={({ field: { value } }) => (
          <TextFieldSearch
            placeholder={'Search for name, opportunity ID, SSO ID...'}
            value={value}
            onChange={e => handleQuery('q', e.target.value)}
            onClearText={() => handleQuery('q', '')}
            sx={{
              minHeight: 'auto',
              '& .MuiInput-underline:before': { borderBottomColor: '#B7C3DC' }
            }}
          />
        )}
      />
    )
  }

  const renderParentOrgLink = () => {
    const parentIds = _get(orgData, 'parentIds', []) ?? []
    if (!parentIds.length) return null

    const parentId = parentIds[0]

    return (
      <>
        <Typography sx={{ whiteSpace: 'nowrap' }}>Parent Organization:</Typography>

        <TypographyLinkClamp
          line={1}
          href={`${customerManagementOrganizationListPageURL}/${parentId}/${detailCustomerUserPath}`}
          sx={{ fontWeight: 500 }}
          typographyProps={{
            tooltipValue: _get(orgData, 'parentOrgInfo') || '--',
            tooltipPlacement: 'top',
            tooltipProps: {
              tooltip: {
                sx: {
                  color: '#1B273C',
                  backgroundColor: '#E9EAEB',
                  whiteSpace: 'pre-line'
                }
              },
              arrow: {
                sx: {
                  color: '#E9EAEB'
                }
              }
            }
          }}
        >
          {_get(orgData, 'parentOrgInfo') || '--'}
        </TypographyLinkClamp>
      </>
    )
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      gap={2}
      sx={{
        marginBottom: 3
      }}
    >
      <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {renderTextFieldSearch()}
      </Grid>
      <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        {renderParentOrgLink()}
      </Grid>
    </Grid>
  )
}

export default SubOrganizationsHeader
