import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import { useAdminCustomerOrganizationAddUserMutation } from 'myopswat-admin/src/api/organizationManagement'
import { AdminOrganizationAddUserForm } from 'myopswat-admin/src/api/organizationManagement/types'
import useApiErrorHook from 'myopswat-admin/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ADD_USER_ERROR_MESSAGES } from '../../../constants'
import { addUserSchema } from '../schema'
import AddUserForm from './AddUserForm'
import _isEmpty from 'lodash/isEmpty'

interface AddUserDialogProps {
  orgId: string
  show: boolean
  roleMap: any
  onClose: () => void
  onSuccess: () => void
}

const AddUserCustomerDialog = ({ orgId, show, roleMap, onClose, onSuccess }: AddUserDialogProps) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: ADD_USER_ERROR_MESSAGES })
  const [addUser, { isLoading }] = useAdminCustomerOrganizationAddUserMutation()

  const defaultValues = useMemo(() => {
    return {
      email: '',
      role: null
    }
  }, [])

  const formRef = useForm<AdminOrganizationAddUserForm>({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(addUserSchema)
  })
  const { reset, handleSubmit, formState } = formRef
  const { isDirty } = formState

  const handleResetForm = () => {
    reset(defaultValues)
  }

  const handleCancel = () => {
    handleResetForm()
    onClose()
  }

  const onSubmit: SubmitHandler<AdminOrganizationAddUserForm> = async (formData: AdminOrganizationAddUserForm) => {
    try {
      const roleId = formData.role!
      const data = {
        orgId: orgId,
        email: formData.email,
        role: roleId,
      }
      const res = await addUser(data).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('orgAddUserSuccess'), { variant: 'success' })
        // Reset form data
        handleResetForm()
        // Callback
        onSuccess()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isDirty}
      title="Add User"
      open={show}
      onClose={onClose}
      onExited={onClose}
      onCancel={handleCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmBtnLabel="Submit"
      minHeight={300}
      maxWidth={'sm'}
      content={<AddUserForm roleMap={roleMap} formRef={formRef} />}
    />
  )
}

export default AddUserCustomerDialog
