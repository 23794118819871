import yup from 'myopswat-admin/src/utils/yup'

export interface AdminOrganizationCreateForm {
  id: string
  name: string
  rank: string
  ssoId: string
  mdcLicenseType: string
  accountId: string
  opportunityId: string
  accountType: string
  preferredPartner: any
  distributors: any
  resellers: any
  isOemCustomer: boolean
  showKioskSecureImage: boolean
  showEnginePackage: boolean
  isManageSubOrganization: boolean
  note: string
  updatedAt: string
  createdAt: string
}

export interface AdminOrganizationEditForm extends AdminOrganizationCreateForm {
  updatedReason: string
}

export const organizationCreateSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Organization name cannot be blank')
    .max(500, 'Please enter maximum 500 characters.')
    .test('validate-org-name', 'Organization name is invalid', value => {
      if (!value) return true

      // Remove all html tags based on regex of IDaaS and check if the value is not empty
      return value.replace(/(<([^>]+)>)/gi, '').length > 0
    }),
  accountId: yup.string().nullable().trim().max(128, 'Please enter maximum 128 characters.'),
  showKioskSecureImage: yup.boolean(),
  isOemCustomer: yup.boolean(),
  showEnginePackage: yup.boolean(),
  note: yup.string().nullable().max(1000, 'Please enter maximum 1000 characters.'),
  isManageSubOrganization: yup.boolean()
})

export const organizationEditSchema = organizationCreateSchema.shape({
  updatedReason: yup.string().trim().required('Update Reason cannot be blank'),
  opportunityId: yup
    .string()
    .nullable()
    .trim()
    .max(32, 'Please enter maximum 32 characters.')
    .min(10, 'Please enter minimum 10 characters.')
})
