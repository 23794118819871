import { FC, useEffect, useMemo } from 'react'

import _get from 'lodash/get'

import { fullName } from '@opswat/react-core'
import { Box, TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { useParams } from 'react-router-dom'

import { useLazySysItemCountriesQuery } from 'myopswat-admin/src/api/system'
import { useLazyAdminUserQuery } from 'myopswat-admin/src/api/userManagement'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementUserListPageURL,
  detailCustomerUserPath,
  editCustomerUserPath,
  opswatUserListPageURL,
  tabCustomerUserGroupsPath,
  tabCustomerUserHistoryPath,
  tabCustomerUserOrganizationsPath,
  tabCustomerUserPreferencesPath
} from 'myopswat-admin/src/routes'
import OrganizationList from '../OrganizationListPage'

import OrganizationGroupListContainer from 'myopswat-admin/src/containers/UserManagementContainer/organizationGroupList'
import BoxUserDetailEdit from './BoxUserDetailEdit'
import BoxUserDetailLoading from './BoxUserDetailLoading'
import BoxUserDetailView from './BoxUserDetailView'
import UserHistoryListPage from './UserHistoryList'
import { Helmet } from 'react-helmet'
import UserPreferencesPage from './UserPreferences'

const UserDetailPage: FC<unknown> = () => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()
  const userListPageURL = isOpswatIncRoute ? opswatUserListPageURL : customerManagementUserListPageURL

  const { tabPath = '', userId = '' } = useParams()

  const [getSysItemCountries, { data: sysItemCountriesData, isFetching: isFetchingSysItemCountries }] =
    useLazySysItemCountriesQuery()

  const [getAdminUser, { data: adminUserData, isFetching: isFetchingAdminUser }] = useLazyAdminUserQuery()

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'User List',
        path: userListPageURL
      },
      {
        label: fullName(_get(adminUserData, 'firstName'), _get(adminUserData, 'lastName')) || '--'
      }
    ]
  }, [adminUserData])

  const tabArray = useMemo(() => {
    return [
      {
        label: 'User Detail',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'User Detail'}
              </title>
            </Helmet>
            {tabPath === editCustomerUserPath ? (
              <BoxUserDetailEdit profileData={adminUserData} sysItemCountriesData={sysItemCountriesData} />
            ) : (
              <BoxUserDetailView profileData={adminUserData} sysItemCountriesData={sysItemCountriesData} />
            )}
          </>
        ),
        tabPath:
          tabPath === editCustomerUserPath
            ? `${userListPageURL}/${userId}/${editCustomerUserPath}`
            : `${userListPageURL}/${userId}/${detailCustomerUserPath}`
      },
      {
        label: 'Organizations',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'User Organization'}
              </title>
            </Helmet>
            <OrganizationList profileData={adminUserData} />
          </>
        ),
        tabPath: `${userListPageURL}/${userId}/${tabCustomerUserOrganizationsPath}`
      },
      {
        label: 'Groups',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                User Groups
              </title>
            </Helmet>
            <OrganizationGroupListContainer isNotTitle />
          </>
        ),
        tabPath: `${userListPageURL}/${userId}/${tabCustomerUserGroupsPath}`
      },
      {
        label: 'User History',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'User History'}
              </title>
            </Helmet>
            <UserHistoryListPage profileData={adminUserData} />
          </>
        ),
        tabPath: `${userListPageURL}/${userId}/${tabCustomerUserHistoryPath}`
      },
      {
        label: 'Preferences',
        content: <UserPreferencesPage profileData={adminUserData} />,
        tabPath: `${userListPageURL}/${userId}/${tabCustomerUserPreferencesPath}`
      }
    ]
  }, [adminUserData, tabPath])

  const isRender = useMemo(() => {
    return !isFetchingSysItemCountries && !isFetchingAdminUser && sysItemCountriesData && adminUserData
  }, [isFetchingSysItemCountries, isFetchingAdminUser, sysItemCountriesData, adminUserData])

  useEffect(() => {
    if (userId) {
      getAdminUser(userId)
      getSysItemCountries()
    }
  }, [userId])

  return (
    <>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && (
        <Box mt={6}>
          <BoxUserDetailLoading />
        </Box>
      )}

      {isRender && <TabAdvanced tabs={tabArray} />}
    </>
  )
}

export default UserDetailPage
