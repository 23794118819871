import React, { FC } from 'react'

import _get from 'lodash/get'

import { DialogAdvanced } from '@opswat/react-ui'

import SessionTimeoutForm from '../Form/SessionTimeoutForm'
import { selectDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { DIALOGS } from '@myopswat/common'

const DialogSessionTimeout: FC<unknown> = () => {
  const dialogType = useTypedSelector(selectDialogs)

  return (
    <DialogAdvanced
      open={_get(dialogType, DIALOGS.SESSION_TIMEOUT, false)}
      disableBackdropClick={false}
      content={<SessionTimeoutForm />}
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogSessionTimeout
