import React, { FC, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { KEYS } from '@myopswat/common'
import { getTimeZoneLabel } from '@opswat/react-core'
import { Box, Button, Grid, TemplateSection, TextField, TextGrid, Typography } from '@opswat/react-ui'
import { useAppDispatch } from 'myopswat-admin/src/store'
import { saveEditMode } from 'myopswat-admin/src/containers/MyAccountContainer/myAccountContainerSlice'

interface IProps {
  profileData: any
  sysItemCountriesData: any
}

const GeneralInfoView: FC<IProps> = ({ profileData, sysItemCountriesData }) => {
  const dispatch = useAppDispatch()

  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return result
  }, [profileData, sysItemCountriesData])

  const leftInfoArray = useMemo(() => {
    return [
      {
        label: 'First Name*',
        value: _get(profileData, 'firstName') === KEYS.FIRST_NAME_DEFAULT ? '' : _get(profileData, 'firstName')
      },
      {
        label: 'Last Name*',
        value: _get(profileData, 'lastName') === KEYS.LAST_NAME_DEFAULT ? '' : _get(profileData, 'lastName')
      },
      {
        label: 'Title*',
        value: _get(profileData, 'title') || ''
      },
      {
        label: 'Phone Number*',
        value: _get(profileData, 'address.phone') || ''
      },
      {
        label: 'Time Zone*',
        value: getTimeZoneLabel(_get(profileData, 'timezone', ''))
      }
    ]
  }, [profileData])

  const rightInfoArray = useMemo(() => {
    return [
      {
        label: 'Address1*',
        value: _get(profileData, 'address.streetAddress1') || ''
      },
      {
        label: 'Address2',
        value: _get(profileData, 'address.streetAddress2') || ''
      },
      {
        label: 'City*',
        value: _get(profileData, 'address.city') || ''
      },
      {
        label: 'Country*',
        value: _get(country, 'name') || ''
      },
      ...((_get(country, 'code', '') === 'US' && [
        {
          label: 'State',
          value: _get(profileData, 'address.state', '')
        }
      ]) ||
        []),
      {
        label: 'Postal Code*',
        value: _get(profileData, 'address.postalCode') || ''
      }
    ]
  }, [profileData, country])

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Email</Typography>}
              value={<Typography>{_get(profileData, 'email', '')}</Typography>}
            />

            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TextGrid
            label={<Typography variant="subtitle2">Note</Typography>}
            value={
              <TextField
                disabled
                minRows={6}
                multiline
                fullWidth
                size="small"
                value={_get(profileData, 'note') || ''}
              />
            }
            containerProps={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(saveEditMode('info'))
              }}
            >
              Edit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default GeneralInfoView
