import { DATE_FORMATS, DIALOGS } from '@myopswat/common'
import { formatDatetime, fullName } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider
} from '@opswat/react-ui'
import { useLazyAdminEvaluationRequestsQuery } from 'myopswat-admin/src/api/evaluation-request'
import {
  IAdminEvaluationRequest,
  IAdminEvaluationRequestFilter,
  IAdminEvaluationRequestFilterInput
} from 'myopswat-admin/src/api/evaluation-request/types'
import DialogEvaluation from 'myopswat-admin/src/components/Dialog/DialogEvaluation'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import BoxEvaluationRequestListFilter from './BoxEvaluationRequestListFilter'

const BoxProductDetailEvaluationRequest: FC<unknown> = () => {
  const dispatch = useAppDispatch()

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [getEvaluationRequests, { data: evaluationRequestData, isFetching: isFetchingEvaluationRequests }] =
    useLazyAdminEvaluationRequestsQuery()

  const [query, setQuery] = useState<IAdminEvaluationRequestFilterInput>({
    filters: {
      q: ''
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  useEffect(() => {
    getEvaluationRequests(query)
  }, [query])

  const approveRequest = (evaluationRequest: IAdminEvaluationRequest) => {
    dispatch(
      toggleDialogs({
        [DIALOGS.EVALUATION]: true,
        [`${DIALOGS.EVALUATION}_DATA`]: evaluationRequest
      })
    )
  }

  const refetchEvaluationRequestHandler = () => getEvaluationRequests(query)

  const handleSearch = (searchData: IAdminEvaluationRequestFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const handlePaginationOnChange = useCallback((page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }, [])

  const columns = useMemo(() => {
    return [
      {
        header: 'Product Name',
        body: (data: any) => <Typography variant="body2">{data.product.name || '--'}</Typography>,
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'User',
        body: (data: any) => (
          <Typography variant="body2">{fullName(data.user.firstName, data.user.lastName) || '--'}</Typography>
        ),
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'Status',
        body: (data: any) => <Typography variant="body2">{data.status || '--'}</Typography>,
        style: { minWidth: 150, width: 150 }
      },
      {
        header: 'Approved By',
        body: (data: any) => (
          <Typography variant="body2">
            {(data.approvedBy && fullName(data.approvedBy.firstName, data.approvedBy.lastName)) || '--'}
          </Typography>
        ),
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'Requested At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data.requestedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone) || '--'}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Approved At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data.approvedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone) || '--'}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: IAdminEvaluationRequest) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    disabled={data.status !== 'WAIT_APPROVAL'}
                    onClick={useCallback(() => approveRequest(data), [data])}
                  >
                    Approve
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <TypographyDivider label="Evaluation Requests" />
      <BoxEvaluationRequestListFilter filters={query.filters} onSearch={handleSearch} />
      <TableAdvanced
        columns={columns}
        isLoading={isFetchingEvaluationRequests}
        data={evaluationRequestData?.results}
        isPagination
        total={evaluationRequestData?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePaginationOnChange}
      />
      <DialogEvaluation refetchEvaluationRequests={refetchEvaluationRequestHandler} />
    </TemplateSection>
  )
}

export default BoxProductDetailEvaluationRequest
