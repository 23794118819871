import { useMemo } from 'react'

import _get from 'lodash/get'
import { enqueueSnackbar } from 'notistack'

import { TypographyDivider } from '@opswat/react-ui'

import useCheckPermissions from 'myopswat-admin/src/hooks/useCheckPermissions'
import CreateReleaseInfoProvider from 'myopswat-admin/src/pages/SystemManagementPage/ReleaseInformationPage/CreateReleaseInformationPage/context'
import { systemManagementReleaseInformationPageURL } from 'myopswat-admin/src/routes'
import { useParams } from 'react-router-dom'

const CreateReleaseInformationContainer = () => {
  const params = useParams()
  const { permission } = useCheckPermissions({
    targetPerrmissions: [
      'view_release_description',
      'add_release_description',
      'change_release_description',
      'delete_release_description'
    ]
  })

  const isCreatePage = useMemo(() => {
    return params.infoId === 'create'
  }, [params])

  const hasPagePermission = useMemo(() => {
    if (isCreatePage) {
      return _get(permission, 'add_release_description', false)
    }

    return _get(permission, 'change_release_description', false)
  }, [permission, isCreatePage])

  if (!hasPagePermission) {
    enqueueSnackbar('You do not have permission to perform this action.', {
      variant: 'error',
      autoHideDuration: 5000
    })

    return <></>
  }

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Release Information',
        path: systemManagementReleaseInformationPageURL
      },
      {
        label: `${isCreatePage ? 'Create' : 'Edit'} Release Information`
      }
    ]
  }, [])

  return (
    <>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      <CreateReleaseInfoProvider />
    </>
  )
}

export default CreateReleaseInformationContainer
