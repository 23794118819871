import {
  Autocomplete,
  Box,
  CheckboxWithLabel,
  FormHelperText,
  Grid,
  OpswatCheckbox,
  TextField,
  Typography
} from '@opswat/react-ui'
import GridView from '../../../components/Grid/GridView'
import { Controller, UseFormReturn } from 'react-hook-form'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { IRoleObjectForm } from './RoleObjectsSchema'
import React, { useState } from 'react'

interface IProps {
  formRef: UseFormReturn<IRoleObjectForm>
  listPermission: any
  roleObjectComponents: any
  edit: boolean
  globalRoles: any
  assignedPermissions: any
}

const RoleObjectForm = ({
  formRef,
  listPermission,
  roleObjectComponents,
  edit,
  globalRoles,
  assignedPermissions
}: IProps) => {
  const permissionsInitial = formRef?.getValues()?.permissions || []
  const [selectedPermissions, setSelectedPermissions] = useState(
    listPermission.filter((i: any) => permissionsInitial.includes(i['id']))
  )
  const renderPermissionsColumn = (data: any) => {
    const permissions = listPermission.filter((i: any) => data.includes(i['id']))
    return (permissions || []).map((permission: any) => (
      <Grid container spacing={0}>
        <OpswatCheckbox
          label={<Typography variant="body2"> {permission.name} </Typography>}
          disabled
          checked={data.includes(permission.id)}
        />
      </Grid>
    ))
  }

  const {
    control,
    register,
    formState: { errors }
  } = formRef

  return (
    <Box>
      <GridView
        label={
          <Typography paragraph>
            Code
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        }
        value={
          <TextField
            size="small"
            fullWidth
            disabled={edit}
            error={'code' in errors}
            {...register('code')}
            helperText={errors.code?.message}
          />
        }
      />
      <GridView
        label={
          <Typography paragraph>
            Name
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        }
        value={
          <TextField
            size="small"
            fullWidth
            error={'name' in errors}
            {...register('name')}
            helperText={errors.name?.message}
          />
        }
      />
      <GridView
        label={
          <Typography paragraph>
            Permissions
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        }
        value={(listPermission || []).map((source: any) => (
          <Grid container spacing={0}>
            <Controller
              name="permissions"
              control={control}
              render={({ field }: any) => {
                if (!edit) {
                  return (
                    <CheckboxWithLabel
                      label={<Typography variant="subtitle2">{source.name}</Typography>}
                      checkboxProps={{
                        disabled: edit,
                        checked: (formRef?.getValues()?.permissions || []).includes(source.id),
                        onChange: (e: any) => {
                          const data = e.target.checked
                            ? [...field.value, source.id]
                            : field.value.filter((i: any) => i !== source.id)
                          field.onChange(data)
                          const selectedPerms = listPermission.filter((i: any) => data.includes(i['id']))
                          setSelectedPermissions(selectedPerms)
                        }
                      }}
                      restProps={{
                        sx: {
                          marginLeft: '-6px'
                        }
                      }}
                    />
                  )
                } else {
                  return (
                    <Grid container spacing={0}>
                      <OpswatCheckbox
                        label={<Typography variant="body2"> {source.name} </Typography>}
                        disabled
                        checked={(formRef?.getValues()?.permissions || []).includes(source.id)}
                      />
                    </Grid>
                  )
                }
              }}
            />
            {_get(errors, ['permissions', 'message']) && (
              <FormHelperText
                error
                component="label"
                style={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {_get(errors, ['permissions', 'message'])}
              </FormHelperText>
            )}
          </Grid>
        ))}
      />
      {edit ? (
        assignedPermissions.map((item: any) => (
          <GridView
            key={item.roleName}
            label={
              <Typography paragraph>
                Default permission for {item.roleName}
                <Typography display="inline" color="red" component="span">
                  *
                </Typography>
              </Typography>
            }
            value={renderPermissionsColumn(item.selectedPermissions)}
          />
        ))
      ) : (
        <></>
      )}
      {!edit ? (
        globalRoles.map((item:any) => (
        <GridView
          key={item.id}
          label={
            <Typography paragraph>
              Default permission for {item.name}
              <Typography display="inline" color="red" component="span">
                *
              </Typography>
            </Typography>
          }
          value={
            <Controller
              name={item?.name.toLowerCase()}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  options={_map(selectedPermissions, item => _get(item, 'id')) || []}
                  value={value}
                  getOptionLabel={(option: any) =>
                    _get(
                      _find(selectedPermissions, item => _get(item, 'id') === option),
                      'name'
                    ) || ''
                  }
                  onChange={(event: any, newValue: any) => {
                    onChange(newValue)
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      error={!!_get(errors, item?.name.toLowerCase(), '')}
                      helperText={_get(errors, `${item?.name.toLowerCase()}.message`, '')}
                    />
                  )}
                />
              )}
            />
          }
        />
        ))
      ) : <></>}
      <GridView
        label={
          <Typography paragraph>
            My OPSWAT Component
            <Typography display="inline" color="red" component="span">
              *
            </Typography>
          </Typography>
        }
        value={
          <Controller
            name="componentId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                options={_map(roleObjectComponents, item => _get(item, 'id')) || []}
                value={value}
                getOptionLabel={(option: any) =>
                  _get(
                    _find(roleObjectComponents, item => _get(item, 'id') === option),
                    'name'
                  ) || ''
                }
                onChange={(event: any, newValue: any) => {
                  onChange(newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    error={!!_get(errors, 'componentId', '')}
                    helperText={_get(errors, 'componentId.message', '')}
                  />
                )}
              />
            )}
          />
        }
      />
      {edit ? (
        <GridView
          label={
            <Typography paragraph>
              Reason
              <Typography display="inline" color="red" component="span">
                *
              </Typography>
            </Typography>
          }
          value={
            <TextField
              size="small"
              fullWidth
              rows={3}
              multiline
              error={'reason' in errors}
              {...register('reason')}
              helperText={errors.name?.message}
            />
          }
        />
      ) : (
        <></>
      )}
    </Box>
  )
}

export default RoleObjectForm
