import { IAddress } from '../account/types'
import { QueryPagination } from '../types'

export interface OrganizationInviteUserForm {
  email: string
  groups: string[]
  role: string | null
}

export interface OrganizationInviteUsersForm {
  users: OrganizationInviteUserForm[]
}

export interface OrganizationEditForm {
  id: string
  owner: string
  website: string
  industries: string[]
  note: string
  billingAddress: IAddress
  shippingAddress: IAddress
}

export interface OrganizationEditNoteForm {
  id: string
  note?: string
}

export interface OrganizationUpdateInput {
  id: string
  owner?: string
  website?: string
  industries?: string[]
  note?: string
  requiredMfa?: boolean
  billingAddress?: IAddress
  shippingAddress?: IAddress
}

export interface OrganizationUserFilterInput {
  q: string
  status: any[]
  roles: string[]
  excludeUserIds: string[]
}

export interface OrganizationInviteUserInput {
  organizationId: string
  ssoId: string
  users: any
}

export interface OrganizationAcceptInvitationInput {
  invitationId: string
}
export interface OrganizationRemoveUsersInput {
  organizationId: string
  ssoId: string
  users: any
}
export interface OrganizationDeactivateUsersInput {
  organizationId: string
  users: any
}

export interface OrganizationActiveUsersInput {
  organizationId: string
  users: any
}

export interface OrganizationChangeUsersRolesInput {
  organizationId: string
  ssoId: string
  users: any
}

export interface OrganizationCustomerFiltersInput {
  filters: {
    orgId: string
    q?: string
    relationship?: ORG_CUSTOMER_RELATIONSHIP[]
  }
  pageInfo: QueryPagination
}

export interface SubOrganizationsInput {
  orgId: string
  filters: {
    q?: string
  }
  pageInfo: QueryPagination
}
export interface OrganizationCustomerFiltersInput {
  filters: {
    orgId: string
    q?: string
    relationship?: ORG_CUSTOMER_RELATIONSHIP[]
  }
  pageInfo: QueryPagination
}

export enum ORG_CUSTOMER_RELATIONSHIP {
  RESELLER = 'reseller',
  DISTRIBUTOR = 'distributor',
  PREFERRED_PARTNER = 'preferred_partner'
}

export const ORG_CUSTOMER_RELATIONSHIP_LABELS: any = {
  [ORG_CUSTOMER_RELATIONSHIP.RESELLER]: 'Reseller',
  [ORG_CUSTOMER_RELATIONSHIP.DISTRIBUTOR]: 'Distributor',
  [ORG_CUSTOMER_RELATIONSHIP.PREFERRED_PARTNER]: 'Preferred Partner'
}
