import { FC, useRef } from 'react'

import { ControllerRenderProps } from 'react-hook-form'
import { read, utils, writeFile } from 'xlsx'

import { AttachPaperclip } from '@opswat/react-icon'
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography, useTheme } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  disabled: boolean
  placeholder?: string
  tooltip?: string
}

const ComposeTextUpload: FC<IProps> = props => {
  const theme = useTheme()
  const inputFile: any = useRef(null)

  const onFilesChange = async (event: any) => {
    const fileList: FileList = event.target.files
    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onloadend = (e: any) => {
        const workbook = read(e.target.result, { type: 'binary' })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const values: any[] = utils.sheet_to_json(worksheet, { header: 1 })
        const emailValues = values.flatMap(item => item)
        props.onChange({ target: { value: emailValues.join('; ') } })
        props.onBlur()
      }
    }
  }

  const downloadEmailTemplate = () => {
    const rows = ['sample1@opswat.com', 'sample2@opswat.com'].map((email: string) => ({ email }))

    const worksheet = utils.json_to_sheet(rows, { skipHeader: true })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet)

    const maxWidth = rows.reduce((width: number, row: any) => Math.max(width, row.email.length), 10)
    worksheet['!cols'] = [{ wch: maxWidth }]

    writeFile(workbook, 'Mass Email_template.xlsx', { compression: true })
  }

  return (
    <Tooltip
      arrow
      placement="right"
      title={props.tooltip}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line',
            minWidth: '200px'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <span>
        <TextField
          {...props}
          fullWidth
          size="small"
          placeholder={props.placeholder}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={props.disabled} onClick={() => inputFile?.current?.click()}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4px' }}>
                    <AttachPaperclip />
                  </Box>
                </IconButton>
              </InputAdornment>
            )
          }}
          helperText={
            <Typography component="label" variant="subtitle2" color="#A4A8AE">
              You can add multiple emails separated by semicolons or{' '}
              <Typography
                component="span"
                variant="subtitle2"
                sx={{
                  color: '#A4A8AE',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  ':hover': {
                    color: '#1D6BFC'
                  }
                }}
                onClick={downloadEmailTemplate}
              >
                import a file in the accepted format
              </Typography>
            </Typography>
          }
          sx={{
            '& .MuiOutlinedInput-root': { paddingRight: 0 },
            '& .MuiFormHelperText-root': { margin: 0, marginTop: '4px' },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.secondary
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.text.disabled
            }
          }}
        />

        <input
          type="file"
          id="recipient-file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onFilesChange}
          onClick={(e: any) => (e.target.value = null)}
        />
      </span>
    </Tooltip>
  )
}

export default ComposeTextUpload
