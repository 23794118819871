import { useCallback, useEffect, useMemo, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'

import { Autocomplete, TextField } from '@opswat/react-ui'

import { useLazyAdminOrganizationsWithAccountIdsQuery } from 'myopswat-admin/src/api/organizationManagement'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { PAGE_DEFAULT } from 'myopswat-admin/src/constants'

import { AdminOrganizationEditForm } from '../schema'

interface SfdcOrganizationSearchProps {
  label: string
  placeholder?: string
  fieldOf: keyof AdminOrganizationEditForm
  control: Control<AdminOrganizationEditForm>
}

const SfdcOrganizationSearch = ({ control, fieldOf, label, placeholder }: SfdcOrganizationSearchProps) => {
  const [getOrgsWithAccountId, { data: orgData, isFetching }] = useLazyAdminOrganizationsWithAccountIdsQuery()

  const orgId = useWatch({ control, name: 'id' })

  const [orgSearchValue, setOrgSearchValue] = useState('')

  const autocompleteOptions: any = useMemo(() => {
    const results = orgData?.results || []
    const filteredResults = results.filter((item: any) => item.id !== orgId)
    return filteredResults
  }, [orgData, orgSearchValue])

  const performSearchOrgs = useCallback(
    _debounce(
      (q: string) =>
        getOrgsWithAccountId({
          filters: { q },
          pageInfo: {
            page: PAGE_DEFAULT,
            pageSize: 100
          }
        }),
      300
    ),
    []
  )

  useEffect(() => {
    performSearchOrgs(orgSearchValue)
  }, [orgSearchValue])

  return (
    <GridView
      label={label}
      value={
        <Controller
          name={fieldOf}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              loading={isFetching}
              options={autocompleteOptions}
              getOptionLabel={(option: any) =>
                [_get(option, 'accountId', '--'), _get(option, 'name', '--'), _get(option, 'ssoId', '--')].join(' - ')
              }
              value={value}
              onChange={(_: any, newValue: any) => {
                onChange(newValue)
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  placeholder={placeholder ?? 'Type to search organizations'}
                  onChange={e => setOrgSearchValue(e.target.value)}
                  onBlur={() => setOrgSearchValue('')}
                />
              )}
            />
          )}
        />
      }
    />
  )
}

export default SfdcOrganizationSearch
