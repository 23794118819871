import React, { FC, useEffect, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import _pickBy from 'lodash/pickBy'
import _has from 'lodash/has'
import _merge from 'lodash/merge'

import { TimezoneList, getTimeZoneLabel } from '@opswat/react-core'
import {
  Autocomplete,
  Box,
  Button,
  ButtonLoading,
  Grid,
  TemplateSection,
  TextField,
  TextGrid,
  Typography
} from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useLazySysItemRegionsQuery } from 'myopswat-admin/src/api/system'
import { useLazyProfileQuery, useProfileUpdateMutation } from 'myopswat-admin/src/api/account'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import {
  saveEditMode,
  saveIsDirtyField,
  selectSubmitMode
} from 'myopswat-admin/src/containers/MyAccountContainer/myAccountContainerSlice'
import { SCHEMA_YUP } from '@myopswat/common'

interface IProps {
  profileData: any
  sysItemCountriesData: any
}

interface IForm {
  email: string
  firstName: string
  lastName: string
  title: string
  userTypes: string
  streetAddress1: string
  streetAddress2: string
  city: string
  country: any
  state: any
  phone: string
  postalCode: string
  timezone: any
  note: string
  usingOrganizationId: string
}

const GeneralInfoEdit: FC<IProps> = ({ profileData, sysItemCountriesData }) => {
  const { t: translate } = useTranslation()

  const dispatch = useAppDispatch()
  const submitMode = useTypedSelector(selectSubmitMode)

  const [profileUpdate, { isLoading }] = useProfileUpdateMutation()
  const [getSysItemRegions, { data: sysItemRegionsData }] = useLazySysItemRegionsQuery()
  const [getProfile] = useLazyProfileQuery()

  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return _get(result, 'code')
  }, [sysItemCountriesData])

  const defaultValues = {
    email: _get(profileData, 'email', ''),
    firstName: _get(profileData, 'firstName', ''),
    lastName: _get(profileData, 'lastName', ''),
    title: _get(profileData, 'title', ''),
    streetAddress1: _get(profileData, 'address.streetAddress1', ''),
    streetAddress2: _get(profileData, 'address.streetAddress2', ''),
    city: _get(profileData, 'address.city', ''),
    country: country,
    state: _get(profileData, 'address.state'),
    phone: _get(profileData, 'address.phone', ''),
    postalCode: _get(profileData, 'address.postalCode', ''),
    timezone: _get(profileData, 'timezone', ''),
    note: _isEmpty(_get(profileData, 'note')) ? '' : _get(profileData, 'note'),
    usingOrganizationId: _get(profileData, 'usingOrganizationId', '')
  }

  const schema = yup
    .object()
    .shape(
      _merge(
        {},
        ...[
          SCHEMA_YUP.firstName,
          SCHEMA_YUP.lastName,
          SCHEMA_YUP.streetAddress1,
          SCHEMA_YUP.streetAddress2,
          SCHEMA_YUP.citySchema,
          SCHEMA_YUP.title,
          SCHEMA_YUP.country,
          SCHEMA_YUP.postalCode,
          SCHEMA_YUP.phone,
          SCHEMA_YUP.timezone,
          SCHEMA_YUP.note
        ]
      )
    )

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, dirtyFields, isDirty }
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = async (data: any) => {
    const commonKeyValues = _pickBy(data, (value, key) => _has(dirtyFields, key))

    if (!_isEmpty(commonKeyValues)) {
      try {
        const response = await profileUpdate(commonKeyValues).unwrap()

        if (response?.success) {
          enqueueSnackbar(translate('updateProfileSuccess'), {
            variant: 'success'
          })
          getProfile()
          dispatch(saveEditMode(''))
        } else {
          enqueueSnackbar(translate('updateProfileFail'), {
            variant: 'error'
          })
        }
      } catch {
        enqueueSnackbar(translate('updateProfileFail'), {
          variant: 'error'
        })
      }
    }
  }

  const onFail = (err: any) => {
    console.error(err)
  }

  useEffect(() => {
    if (watch('country') === 'US') {
      getSysItemRegions(234)
    }
  }, [watch('country')])

  useEffect(() => {
    if (submitMode === 'info') {
      handleSubmit(onSuccess, onFail)()
    }
  }, [submitMode])

  useEffect(() => {
    dispatch(saveIsDirtyField(isDirty))
  }, [isDirty])

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Email</Typography>}
              value={<Typography>{_get(profileData, 'email', '')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">First Name*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'firstName', '')}
                  helperText={_get(errors, 'firstName.message', '')}
                  {...register('firstName')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Last Name*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'lastName', '')}
                  helperText={_get(errors, 'lastName.message', '')}
                  {...register('lastName')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Title*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'title', '')}
                  helperText={_get(errors, 'title.message', '')}
                  {...register('title')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Phone Number*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'phone', '')}
                  helperText={_get(errors, 'phone.message', '')}
                  {...register('phone')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Time Zone*</Typography>}
              value={
                <Controller
                  name="timezone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={TimezoneList || []}
                      value={value}
                      getOptionLabel={(option: any) => getTimeZoneLabel(option)}
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          error={!!_get(errors, 'timezone', '')}
                          helperText={_get(errors, 'timezone.message', '')}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </TemplateSection>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Address1*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'streetAddress1', '')}
                  helperText={_get(errors, 'streetAddress1.message', '')}
                  {...register('streetAddress1')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Address2</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'streetAddress2', '')}
                  helperText={_get(errors, 'streetAddress2.message', '')}
                  {...register('streetAddress2')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">City*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'city', '')}
                  helperText={_get(errors, 'city.message', '')}
                  {...register('city')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">Country*</Typography>}
              value={
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={_map(sysItemCountriesData, item => _get(item, 'code')) || []}
                      value={value}
                      getOptionLabel={(option: any) =>
                        _get(
                          _find(sysItemCountriesData, item => _get(item, 'code') === option),
                          'name'
                        ) || ''
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          error={!!_get(errors, 'country', '')}
                          helperText={_get(errors, 'country.message', '')}
                        />
                      )}
                    />
                  )}
                />
              }
            />

            {watch('country') === 'US' && (
              <TextGrid
                label={<Typography variant="subtitle2">State</Typography>}
                value={
                  <Controller
                    name="state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disableClearable
                        options={_map(sysItemRegionsData, item => _get(item, 'name')) || []}
                        value={value}
                        getOptionLabel={(option: any) =>
                          _get(
                            _find(sysItemRegionsData, item => _get(item, 'name') === option),
                            'name'
                          ) || ''
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue)
                        }}
                        renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
                      />
                    )}
                  />
                }
              />
            )}

            <TextGrid
              label={<Typography variant="subtitle2">Postal Code*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'postalCode', '')}
                  helperText={_get(errors, 'postalCode.message', '')}
                  {...register('postalCode')}
                />
              }
            />
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TextGrid
            label={<Typography variant="subtitle2">Note</Typography>}
            value={
              <TextField
                {...register('note')}
                rows={6}
                multiline
                fullWidth
                placeholder="Type your note here..."
                size="small"
                error={!!_get(errors, 'note.message', '')}
                helperText={_get(errors, 'note.message', '')}
                required
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13) {
                    handleSubmit(onSuccess, onFail)()
                  }
                }}
              />
            }
            containerProps={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="inherit"
              disabled={isLoading}
              onClick={() => {
                dispatch(saveEditMode(''))
              }}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => {
                  handleSubmit(onSuccess, onFail)()
                },
                disabled: isLoading || !isDirty
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Box>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default GeneralInfoEdit
