import _debounce from 'lodash/debounce'

import { debounce } from '@mui/material/utils'
import { DIALOGS } from '@myopswat/common'
import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import {
  Autocomplete,
  Button,
  CheckboxWithLabel,
  DropdownFilter,
  Grid,
  TemplateSection,
  TextField,
  TextFieldSearch,
  Typography
} from '@opswat/react-ui'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import {
  FalseSubmissionCategories,
  FalseSubmissionInputTypes,
  FalseSubmissionStatuses,
  IFalseSubmissionFilter
} from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import { useLazyAdminBasicUsersQuery } from 'myopswat-admin/src/api/userManagement'
import { IAdminBasicUserType } from 'myopswat-admin/src/api/userManagement/types'
import { PAGE_DEFAULT } from 'myopswat-admin/src/constants'
import { useEffect, useMemo, useState } from 'react'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { Controller, useForm, useWatch } from 'react-hook-form'
import DialogFalseDetectionExport from './DialogFalseDetectionExport'

interface FilterSectionProps {
  filters: IFalseSubmissionFilter
  onSearch: (searchData: IFalseSubmissionFilter) => void
}

const FalseSubmissionFilter = ({ filters, onSearch }: FilterSectionProps) => {
  const { control, getValues, setValue, reset, watch, handleSubmit } = useForm<IFalseSubmissionFilter>({
    mode: 'onChange',
    defaultValues: filters
  })

  const q = useWatch({
    control: control,
    name: 'q'
  })

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isExportFalseDetectionDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('export_falsesubmission')
    }
    return false
  }, [profileData])

  const dispatch = useAppDispatch()

  const [selectedUser, setSelectedUser] = useState<IAdminBasicUserType | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<any>([])
  const [defaultOptions, setDefaultOptions] = useState<any>([])
  const [getAdminBasicUser] = useLazyAdminBasicUsersQuery()

  const fetch = useMemo(
    () =>
      debounce((input: string, callback: (results?: readonly IAdminBasicUserType[]) => void) => {
        getAdminBasicUser({
          filters: { q: input },
          pageInfo: {
            page: PAGE_DEFAULT,
            pageSize: 10
          }
        })
          .unwrap()
          .then(response => callback(response.results))
          .catch(e => {
            console.error(e)
          })
      }, 300),
    []
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(selectedUser ? [selectedUser] : [])
      return undefined
    }

    fetch(inputValue, (results?: readonly IAdminBasicUserType[]) => {
      if (active) {
        let newOptions: any[] = []
        if (selectedUser) {
          newOptions = [selectedUser]
        }
        if (results) {
          const existedIds = newOptions.map((item: any) => item.id)
          results = results.filter((item: any) => !existedIds.includes(item.id))
          newOptions = [...newOptions, ...results]
        }
        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [selectedUser, inputValue, fetch])

  const onSuccess = () => {
    const searchData = _omit(getValues(), 'all')
    onSearch(searchData)
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    fetch(inputValue, (results?: readonly IAdminBasicUserType[]) => {
      setDefaultOptions(results)
      setOptions(results)
    })
  }, [])

  useEffect(() => {
    if (inputValue === '' && defaultOptions.length > 0) {
      setOptions(defaultOptions)
    }
  }, [inputValue])

  useEffect(() => {
    const handleSearch = _debounce(() => {
      const searchData = _omit(getValues(), 'all')
      onSearch(searchData)
    }, 300)

    handleSearch()
    return () => {
      handleSearch.cancel()
    }
  }, [q])

  useEffect(() => {
    if (selectedUser) {
      setValue('submittedBy', selectedUser.id)
    }
  }, [selectedUser])

  const handleSelectCheckbox = (current: string[], value: string, checked: boolean) => {
    let newValue
    if (checked) {
      newValue = [...current, value]
    } else {
      newValue = current.filter(s => s !== value)
    }
    return newValue
  }

  useEffect(() => {
    if (watch('all') === true) {
      reset()
    }
  }, [watch('all')])

  return (
    <TemplateSection>
      <Grid container justifyContent="space-between" sx={{ mt: '4px' }}>
        <Grid item xs={12} sm={4}>
          <Controller
            name="q"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextFieldSearch
                placeholder={'Search by file name or hash...'}
                value={value}
                onChange={e => onChange(e.target.value)}
                onClearText={() => onChange('')}
                sx={{
                  minHeight: 'auto'
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}
        >
          <DropdownFilter
            button={
              <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                Filter
              </Button>
            }
            content={
              <Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Type</Typography>
                        </Grid>
                        {_map(FalseSubmissionInputTypes, (item: any, idx: any) => (
                          <Grid item xs={12} key={idx.toString()}>
                            <Controller
                              name={'inputTypes'}
                              control={control}
                              render={({ field }) => (
                                <CheckboxWithLabel
                                  label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                  checkboxProps={{
                                    checked: field.value.includes(item.value),
                                    onChange: (e: any) => {
                                      field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                      setValue('all', false)
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                      </>
                      <>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Status</Typography>
                        </Grid>
                        {_map(FalseSubmissionStatuses, (item: any, idx: any) => (
                          <Grid item xs={12} key={idx.toString()}>
                            <Controller
                              name={'cxStatuses'}
                              control={control}
                              render={({ field }) => (
                                <CheckboxWithLabel
                                  label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                  checkboxProps={{
                                    checked: field.value.includes(item.value),
                                    onChange: (e: any) => {
                                      field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                      setValue('all', false)
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Category</Typography>
                        </Grid>
                        {_map(FalseSubmissionCategories, (item: any, idx: any) => (
                          <Grid item xs={12} key={idx.toString()}>
                            <Controller
                              name={'categories'}
                              control={control}
                              render={({ field }) => (
                                <CheckboxWithLabel
                                  label={<Typography variant="subtitle2">{_get(item, 'name', '--')}</Typography>}
                                  checkboxProps={{
                                    checked: field.value.includes(item.value),
                                    onChange: (e: any) => {
                                      field.onChange(handleSelectCheckbox(field.value, item.value, e.target.checked))
                                      setValue('all', false)
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        ))}
                      </>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={2} sx={{ mt: 0 }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          Submitted By
                        </Typography>{' '}
                        <Autocomplete
                          id="user-filter"
                          getOptionLabel={option => option.fullName}
                          filterOptions={x => x}
                          options={options}
                          autoComplete
                          includeInputInList
                          value={selectedUser}
                          noOptionsText="No user found"
                          onChange={(event: any, newValue: IAdminBasicUserType | null) => {
                            setOptions(newValue ? [newValue, ...options] : options)
                            setSelectedUser(newValue)
                          }}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue)
                          }}
                          renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                <Grid container alignItems="center">
                                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {option.fullName}
                                  </Grid>
                                </Grid>
                              </li>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            onResetFilter={() => {
              setValue('all', true)
            }}
            onResetChange={() => console.log()}
            onApply={() => {
              handleSubmit(onSuccess, onFail)()
            }}
            propsPaper={{ sx: { maxWidth: '400px' } }}
          />
          {!isExportFalseDetectionDisabled && (
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(
                  toggleDialogs({
                    [DIALOGS.FALSE_DETECTION_EXPORT]: true
                  })
                )
              }}
            >
              Export
            </Button>
          )}
        </Grid>
      </Grid>
      <DialogFalseDetectionExport />
    </TemplateSection>
  )
}

export default FalseSubmissionFilter
