import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import _get from 'lodash/get'

import { CheckDoneIcon, CloseIcon } from '@opswat/react-icon'
import { Autocomplete, Box, Chip, IconButton, MenuItem, TextField, Typography, useTheme } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  disabled: boolean
  errors: any
  placeholder?: string
  options: any[]
}

const ComposeAutocomplete: FC<IProps> = ({ errors, disabled, placeholder, options, ...rest }: IProps) => {
  const theme = useTheme()

  return disabled ? (
    <TextField
      fullWidth
      multiline
      size="small"
      disabled={disabled}
      value={rest.value.map((item: any) => item.label).join(', ')}
      sx={{
        minHeight: 'auto',
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.text.secondary
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.text.disabled
        }
      }}
    />
  ) : (
    <Autocomplete
      {...rest}
      multiple
      fullWidth
      size="small"
      disabled={disabled}
      options={options}
      clearIcon={<CloseIcon size={16} />}
      onChange={(_, newValue: string[]) => {
        rest.onChange(newValue)
      }}
      isOptionEqualToValue={option => {
        return rest.value.some((item: any) => item.value === option.value)
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          placeholder={!rest.value?.length && placeholder}
          error={!!_get(errors, 'products', '')}
          helperText={_get(errors, 'products.message', '')}
          sx={{
            minHeight: 'auto',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.secondary
            },
            '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.text.disabled
            }
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        const numTags = value.length
        const limitTags = 2
        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                deleteIcon={
                  <IconButton sx={{ padding: 0, margin: '0px !important', marginLeft: '8px !important' }}>
                    <CloseIcon size={16} />
                  </IconButton>
                }
                {...getTagProps({ index })}
                label={
                  <Typography variant="subtitle2" color="#616875">
                    {option.label}
                  </Typography>
                }
                sx={{
                  backgroundColor: '#E9EAEB',
                  borderRadius: 0,
                  padding: '4px 4px 4px 8px',
                  height: '24px',
                  opacity: `${disabled ? 0.8 : 1} !important`,
                  '& .MuiChip-label': { padding: 0 }
                }}
              />
            ))}
            {numTags > limitTags && (
              <Chip
                size="small"
                label={
                  <Typography variant="subtitle2" color="#616875">
                    {`+${numTags - limitTags} more item${numTags - limitTags > 1 ? 's' : ''}`}
                  </Typography>
                }
                sx={{
                  backgroundColor: '#E9EAEB',
                  borderRadius: 0,
                  padding: '4px 8px',
                  marginLeft: '4px',
                  height: '24px',
                  opacity: `${disabled ? 0.8 : 1} !important`,
                  '& .MuiChip-label': { padding: 0 }
                }}
              />
            )}
          </>
        )
      }}
      renderOption={(props, option, state) => (
        <MenuItem {...props} value={option.value} sx={{ whiteSpace: 'normal' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'space-between',
              gap: 1
            }}
          >
            {option.label}
            <Box sx={{ display: state.selected ? 'flex' : 'none' }}>
              <CheckDoneIcon color={theme.palette.primary.main} />
            </Box>
          </Box>
        </MenuItem>
      )}
    />
  )
}

export default ComposeAutocomplete
