import { Box, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import ProductMailerPage from 'myopswat-admin/src/pages/ProductMailersPage'

const ProductMailerContainer = () => {
  return (
    <>
      <Box>
        <TypographyDivider label="System Subscribers" />
      </Box>
      <TemplateSection spacing={2}>
        <ProductMailerPage />
      </TemplateSection>
    </>
  )
}

export default ProductMailerContainer
