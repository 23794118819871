import { yupResolver } from '@hookform/resolvers/yup'
import { DIALOGS } from '@myopswat/common'
import { Box, ButtonLoading, DialogAdvanced, Grid, TextFieldArea } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useAdminFalseSubmissionUpdateMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import yup from 'myopswat-admin/src/utils/yup'
import { useSnackbar } from 'notistack'
import { FC, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface IProps {
  reloadData: () => void
}

const DialogFalseDetectionNote: FC<IProps> = ({ reloadData }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const { enqueueSnackbar } = useSnackbar()
  const [submitFalseSubmissionUpdate, { isLoading }] = useAdminFalseSubmissionUpdateMutation()

  const defaultValues = {
    note: ''
  }

  const schema = yup.object().shape({
    note: yup.string().nullable().max(1000, 'Note is limited to 1000 characters')
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const handleClose = (isRefetch = false) => {
    if (isRefetch) {
      reloadData()
    }
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_NOTE]: false,
        [`${DIALOGS.FALSE_DETECTION_NOTE}_DATA`]: ''
      })
    )
  }

  useEffect(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_NOTE}_DATA`)) {
      const data = _get(dialogType, `${DIALOGS.FALSE_DETECTION_NOTE}_DATA`)
      const note = _get(data, 'analystNote', '')
      setValue('note', note)
    }
  }, [dialogType])

  const onSuccess = () => {
    try {
      if (watch('note')) {
        const falseSubmissionId = _get(_get(dialogType, `${DIALOGS.FALSE_DETECTION_NOTE}_DATA`), 'id', '')
        submitFalseSubmissionUpdate({
          id: falseSubmissionId,
          analystNote: watch('note')
        })
          .unwrap()
          .then(async (response: any) => {
            if (response.success) {
              enqueueSnackbar('Update analysis summary successfully', { variant: 'success' })
              handleClose(true)
            } else {
              if (_get(response, 'errors.0.message')) {
                enqueueSnackbar(_get(response, 'errors.0.message', 'Update analysis summary failed'), {
                  variant: 'error'
                })
              }
            }
          })
          .catch(() => {
            enqueueSnackbar('Update analysis summary failed', { variant: 'error' })
          })
      } else {
        enqueueSnackbar('Please input summary content', { variant: 'warning' })
      }
    } catch (error) {
      enqueueSnackbar('Update analysis summary failed', { variant: 'error' })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <DialogAdvanced
      title={'Update analysis summary'}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_NOTE, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextFieldArea
                  fullWidth
                  value={field.value}
                  onChange={(e: any) => field.onChange(e.target.value)}
                  error={!!_get(errors, 'note', '')}
                  helperText={_get(errors, 'note.message', '')}
                />
              )}
            />
          </Grid>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(onSuccess, onFail)(),
                disabled: isLoading || _isEmpty(dirtyFields),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
    />
  )
}
export default DialogFalseDetectionNote
