import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { AdminPermissionQueryInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminPermissions: builder.query<any, AdminPermissionQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminPermissionFiltersInput, $pageInfo: PageInfoType) {
            adminPermissions(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                name
                codename
                isVisible
                contentTypeDetail {
                  id
                  displayName
                  mappingName
                }
              }
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    })
  })
})

export const { useAdminPermissionsQuery } = api
