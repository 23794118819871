import { useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { formatDatetime } from '@opswat/react-core'
import { CollapsibleTableAdvanced, Typography } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { IHistoryDiffType } from 'myopswat-admin/src/api/history/types'
import { useLazyAdminReleaseInformationEventLogsQuery } from 'myopswat-admin/src/api/releaseInformation'
import { QueryPagination, QuerySortInfo } from 'myopswat-admin/src/api/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import {
  RELEASE_INFORMATION_DELIVERED_LOG_MESSAGE,
  RELEASE_INFORMATION_FORM_LABEL_MAP,
  RELEASE_INFORMATION_SKIPPED_LOG_MESSAGE
} from '../../constants'
import EventLogsFilter from './EventLogsFilter'

interface IQuery {
  pageInfo?: QueryPagination
  sortInfo?: QuerySortInfo
  filters?: any
}

const ReleaseInformationEventLogs = () => {
  const [query, setQuery] = useState<IQuery>({
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    },
    sortInfo: {
      order: 'desc',
      orderBy: 'historyDate'
    },
    filters: {}
  })
  const [getReleaseInformationHistories, { data, isFetching: isLoading }] =
    useLazyAdminReleaseInformationEventLogsQuery()

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo?.pageSize) page = PAGE_DEFAULT
    setQuery(prevState => ({ ...prevState, pageInfo: { page, pageSize } }))
  }

  const tableData = useMemo(() => {
    return data?.results.map((item: any) => {
      const historyType = _get(item, 'historyType', '--')
      const historyReason = _get(item, 'historyUpdatedReason', '--')
      const isSpecialEvents =
        historyType === 'Changed' &&
        [RELEASE_INFORMATION_DELIVERED_LOG_MESSAGE, RELEASE_INFORMATION_SKIPPED_LOG_MESSAGE].includes(historyReason)

      const reason = historyType === 'Changed' && !isSpecialEvents ? historyReason : '--'
      const event = historyType === 'Created' ? 'Added' : historyType

      return {
        ...item,
        event,
        reason: reason === '-' ? '--' : reason,
        isSpecialEvents
      }
    })
  }, [data])

  const collapseData = useMemo(
    () =>
      _map(tableData, (columnData: any) =>
        _map(columnData.changes, (columnCollapseData: any) => ({
          ...columnCollapseData,
          releasePlatforms: _get(columnData, 'releasePlatforms', [])
        }))
      ),
    [tableData]
  )

  const renderTypographyColumn = (data: any, field: string) => {
    return <Typography variant="body2">{_get(data, field, '--')}</Typography>
  }

  const renderColumnImpacted = (data: any) => {
    const productName = data.productName || '--'
    const historyReason = _get(data, 'historyUpdatedReason', '--')

    switch (historyReason) {
      case RELEASE_INFORMATION_DELIVERED_LOG_MESSAGE:
        return <Typography variant="body2">Delivered information of {productName}</Typography>
      case RELEASE_INFORMATION_SKIPPED_LOG_MESSAGE:
        return <Typography variant="body2">Skipped Release information of {productName}</Typography>
      default:
        return <Typography variant="body2">Release information of {productName}</Typography>
    }
  }

  const renderColumnDatetime = (data: any) => {
    return <Typography variant="body2">{formatDatetime(data.historyDate, DATE_FORMATS.DATE_TIME)}</Typography>
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: any) => renderTypographyColumn(data, 'event'),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Impacted',
        body: renderColumnImpacted,
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Reason',
        body: (data: any) => renderTypographyColumn(data, 'reason'),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Updated By',
        body: (data: any) => renderTypographyColumn(data, 'historyUpdatedBy.email'),
        style: { minWidth: 150, width: 150 }
      },
      {
        key: 'historyDate',
        header: 'Datetime',
        body: renderColumnDatetime,
        style: { minWidth: 200, width: 200 },
        isSortable: true
      }
    ]
  }, [])

  const handleDisplayValue = (value: any) => {
    if (value === null) {
      return null
    } else if (typeof value === 'boolean') {
      return value ? 'True' : 'False'
    } else {
      return value
    }
  }

  const handleRenderPlatformIds = (value: any, data: IHistoryDiffType) => {
    if (!value) return null

    const platformIds = value.split(', ').map((num: any) => parseInt(num.trim()))
    const platformMap = _get(data, 'releasePlatforms', []).reduce((acc: any, platform: any) => {
      acc[_get(platform, 'id')] = platform.displayName
      return acc
    }, {})

    return _map(platformIds, (platformId: string) => _get(platformMap, platformId, '--')).join(', ')
  }

  const renderCollapseColumnField = (data: IHistoryDiffType) => {
    let field = _get(data, 'field')

    if (field === 'platformIds') field = 'Platforms'

    const fieldLabel = _get(RELEASE_INFORMATION_FORM_LABEL_MAP, field) || field
    return <Typography variant="body2">{fieldLabel}</Typography>
  }

  const renderCollapseColumnChange = (data: IHistoryDiffType) => {
    const field = _get(data, 'field')
    let value: any = _get(data, 'new')

    if (field === 'platformIds') {
      value = handleRenderPlatformIds(value, data)
    } else if (field === 'featureDescription') {
      return <div dangerouslySetInnerHTML={{ __html: value ?? '' }} />
    }

    return <Typography variant="body2">{handleDisplayValue(value)}</Typography>
  }

  const renderCollapseColumnWas = (data: IHistoryDiffType) => {
    const field = _get(data, 'field')
    let value: any = _get(data, 'old')

    if (field === 'platformIds') {
      value = handleRenderPlatformIds(value, data)
    } else if (field === 'featureDescription') {
      return <div dangerouslySetInnerHTML={{ __html: value ?? '' }} />
    }

    return <Typography variant="body2">{handleDisplayValue(value)}</Typography>
  }

  const collapsedColumnArray = useMemo(() => {
    return [
      // Empty cell for the purpose of alignment
      {
        header: '',
        body: () => <p></p>,
        style: { width: 80 }
      },
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => renderCollapseColumnField(data),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => renderCollapseColumnChange(data),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => renderCollapseColumnWas(data),
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [])

  const handleFiltersChange = (filterData: any) => {
    setQuery(prevState => ({
      ...prevState,
      filters: filterData,
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  const handleSortChange = (orderBy?: any, order?: any) => {
    setQuery(prevState => ({
      ...prevState,
      sortInfo: { order, orderBy },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  useEffect(() => {
    getReleaseInformationHistories(query)
  }, [query])

  return (
    <>
      <EventLogsFilter handleFiltersChange={handleFiltersChange} queryData={query} />

      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={tableData}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo?.page}
        pageSize={query.pageInfo?.pageSize}
        onPageChange={handlePaginationOnChange}
        order={query.sortInfo?.order}
        orderBy={query.sortInfo?.orderBy}
        onOrderChange={handleSortChange}
      />
    </>
  )
}

export default ReleaseInformationEventLogs
