import { Controller, UseFormReturn } from 'react-hook-form'
import { IAntivirusEngineForm } from '../FalseSubmissionAntivirusEngineSchema'
import GridView from '../../../../../components/Grid/GridView'
import { Autocomplete, Box, OpswatCheckbox, TextField, Typography } from '@opswat/react-ui'
import yup from '../../../../../utils/yup'
import _map from 'lodash/map'
import _get from 'lodash/get'
import _find from 'lodash/find'

interface IProps {
  formRef: UseFormReturn<IAntivirusEngineForm>
  sysItemCountriesData: any
}

export const antivirusEngineSchema = yup.object().shape({
  engineName: yup
    .string()
    .required('This engine name cannot be blank')
    .max(256, 'Please enter maximum 255 characters.'),
  vendorName: yup.string().max(256, 'Please enter maximum 255 characters.'),
  country: yup.string(),
  detection: yup.string().max(256, 'Please enter maximum 255 characters.'),
  heuristicDetection: yup.string().max(256, 'Please enter maximum 255 characters.'),
  isActive: yup.boolean()
})

const FalseSubmissionAntivirusEngineForm = ({ formRef, sysItemCountriesData }: IProps) => {
  const {
    control,
    register,
    formState: { errors }
  } = formRef

  return (
    <Box>
      <GridView
        label={`Engine Name*`}
        value={
          <TextField
            size="small"
            fullWidth
            error={'engineName' in errors}
            {...register('engineName')}
            helperText={errors.engineName?.message}
          />
        }
      />
      <GridView
        label={`Vendor Name`}
        value={
          <TextField
            size="small"
            fullWidth
            error={'vendorName' in errors}
            {...register('vendorName')}
            helperText={errors.vendorName?.message}
          />
        }
      />
      <GridView
        label={<Typography variant="subtitle2">Country</Typography>}
        value={
          <Controller
            name="country"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                options={_map(sysItemCountriesData, item => _get(item, 'code')) || []}
                value={value}
                getOptionLabel={(option: any) =>
                  _get(
                    _find(sysItemCountriesData, item => _get(item, 'code') === option),
                    'name'
                  ) || ''
                }
                onChange={(event: any, newValue: any) => {
                  onChange(newValue)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    error={!!_get(errors, 'country', '')}
                    helperText={_get(errors, 'country.message', '')}
                  />
                )}
              />
            )}
          />
        }
      />
      <GridView
        label="Detection"
        value={
          <TextField
            size="small"
            fullWidth
            error={'detection' in errors}
            {...register('detection')}
            helperText={errors.detection?.message}
          />
        }
      />
      <GridView
        label="Heuristic Detection"
        value={
          <TextField
            size="small"
            fullWidth
            error={'heuristicDetection' in errors}
            {...register('heuristicDetection')}
            helperText={errors.heuristicDetection?.message}
          />
        }
      />
      <GridView
        label="Hide"
        value={
          <Controller
            name={'isActive'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={!field.value}
                onChange={(event: any) => {
                  field.onChange(!event.target.checked)
                }}
              />
            )}
          />
        }
      />
    </Box>
  )
}

export default FalseSubmissionAntivirusEngineForm
