/** ----------------- PATHNAME -------------------- */
export const systemSubscribersPath = 'system-subscribers'
export const massNotificationPath = 'mass-notification'
export const composeEmailPath = 'compose-email'
export const composeEmailOptionalIdPath = ':emailId?'

/** ----------------- ROUTE -------------------- */
export const notificationManagementPageURL = '/notification-management'
export const notificationManagementFlexPageURL = '/notification-management/:path'
export const notificationManagementSystemSubscribersPageURL = `${notificationManagementPageURL}/${systemSubscribersPath}`
export const notificationManagementMassNotificationPageURL = `${notificationManagementPageURL}/${massNotificationPath}`
export const notificationManagementComposeEmailRouterURL = `${notificationManagementPageURL}/${massNotificationPath}/${composeEmailPath}/${composeEmailOptionalIdPath}`
export const notificationManagementComposeEmailBaseURL = `${notificationManagementPageURL}/${massNotificationPath}/${composeEmailPath}`
