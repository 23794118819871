import { DATE_FORMATS, DIALOGS, handleDownloadFile } from '@myopswat/common'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import {
  Box, Button,
  Chip,
  Grid, OpswatCheckbox,
  TextField,
  TextFieldArea, TextGrid, Tooltip,
  Typography
} from '@opswat/react-ui'
import { enqueueSnackbar } from 'notistack'
import { useMemo } from 'react'
import {
  useAdminDownloadFalseSubmissionMutation,
} from '../../../../api/analyzer/falseSubmission'
import {
  FalseSubmissionFileOrigins,
  FalseSubmissionStatuses
} from '../../../../api/analyzer/falseSubmission/types'
import useDialog from '../../../../components/Dialog/DialogHook'
import { toggleDialogs } from '../../../../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from '../../../../store'
import DialogFalseDetectionDownloadInfo from '../../FalseDetectionSubmissionPage/DialogFalseDetectionDownloadInfo'

interface IProps {
  falseDetectionSubmission: any
  onEdit?: () => void
}

const FalseDetectionSubmissionDetail = ({ falseDetectionSubmission, onEdit }: IProps) => {

  const dialog = useDialog()
  const dispatch = useAppDispatch()

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isChangeFalseDetectionDisabled = useMemo(() => {
    if (profileData?.permissions) {
      return !profileData.permissions.includes('change_falsesubmission')
    }
    return false
  }, [profileData])

  const [adminDownloadFalseSubmission] = useAdminDownloadFalseSubmissionMutation()

  const handleDownload = async (data: any) => {
    const confirmed = await dialog.openConfirmation({
      content:
        'Please be cautious as the file you are downloading might contain malicious code. Do you still want to download this file?',
      title: `Download ${data.filename}`
    })
    if (!confirmed) return
    await adminDownloadFalseSubmission(data.id)
      .unwrap()
      .then(response => {
        handleDownloadFile(response.url)
        dispatch(
          toggleDialogs({
            [DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO]: true,
            [`${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`]: data
          })
        )
      })
      .catch(() => {
        enqueueSnackbar('Download file failed', { variant: 'error' })
      })
  }
  const status = FalseSubmissionStatuses.find(
              (status) => status.value === falseDetectionSubmission.cxStatus
            )
  const fileOrigin = FalseSubmissionFileOrigins.find(
    (item) => item.value === falseDetectionSubmission.fileOrigin
  ) || null

  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12} md={10} lg={10}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2.5 }}>
            <TextGrid
              label={'Id'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    { falseDetectionSubmission?.id ? `#${falseDetectionSubmission?.id.toString().padStart(8, '0')}` : ''}
                  </Typography>
                </Box>
              }
            />
            <TextGrid
              label={'File'}
              
              value={
                <>
                  <Typography
                    color="primary"
                    sx={{
                      overflowWrap: 'anywhere',
                      cursor: falseDetectionSubmission.inputType === 'file' ? 'pointer' : 'default',
                    }}
                    variant="body2"
                    onClick={() => {
                      if (falseDetectionSubmission.inputType === 'file') {
                        handleDownload(falseDetectionSubmission)
                      }
                    }}
                  >
                    {falseDetectionSubmission.inputType === 'file'
                      ? falseDetectionSubmission.filename
                      : falseDetectionSubmission?.hash}
                  </Typography>
                  {falseDetectionSubmission.inputType === 'file' && (
                    <>
                      <Chip label="File" variant="outlined" color="primary" size="small" sx={{ mt: 0.5 }} />
                      <Chip
                        label={humanFileSize(falseDetectionSubmission.size)}
                        variant="outlined"
                        color="default"
                        size="small"
                        sx={{ mt: 0.5, ml: 1 }}
                      />
                    </>
                  )}
                  {falseDetectionSubmission.inputType === 'hash' && (
                    <Chip label="Hash" variant="outlined" color="error" size="small" sx={{ mt: 0.5 }} />
                  )}
                </>
              }
            />

            <TextGrid
              label={'Status'}
              value={<TextField disabled size="small" fullWidth value={status?.name ?? ''}  />}
            />

            <TextGrid
              label={'Category'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.category} />}
            />

            <TextGrid
              label={'Hash MD5'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.md5} />}

            />

            <TextGrid
              label={'Hash SHA1'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.sha1} />}

            />

            <TextGrid
              label={'Hash SHA256'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.sha256} />}

            />

            <TextGrid
              label={'Products'}
              value={
                <Tooltip
                  title={falseDetectionSubmission?.productNames ? falseDetectionSubmission?.productNames.join(', \n') : ''}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                >
                  <TextField disabled size="small" fullWidth value={falseDetectionSubmission?.productNames ? falseDetectionSubmission?.productNames.join(', ') : ''} />
                </Tooltip>
              }
            />

            <TextGrid
              label={'Antivirus engines'}
              value={
                <Tooltip
                  title={falseDetectionSubmission?.antivirusEngine}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}

                >
                  <TextField disabled size="small" fullWidth value={falseDetectionSubmission?.antivirusEngine} />
                </Tooltip>
              }
            />

            <TextGrid
              label={'AV detection/Threat name'}
              value={<TextField disabled fullWidth size="small" value={falseDetectionSubmission?.detectionName}/>}

            />


            <TextGrid
              label={'Image of Detection/Report Alert'}

              value={
                <Box sx={{ display: 'flex', fontSize: '14px' }}>
                  {falseDetectionSubmission?.submissionScreenshotName ? (
                    <Typography >
                      <a target="_blank" href={falseDetectionSubmission?.submissionScreenshotUrl}>
                        {falseDetectionSubmission?.submissionScreenshotName}
                      </a>
                    </Typography>
                  ) : (
                    <Typography >
                      {'--'}
                    </Typography>
                  )}
                </Box>
              }
            />

            <TextGrid
              label={'The origin of the file'}
              value={<TextField disabled fullWidth size="small" value={fileOrigin?.name}/>}

            />

            <TextGrid
              label={'The purpose of the submitted file'}
              value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission?.purpose}/>}

            />

            <TextGrid
              label={'Why this is a false detection?'}
              value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission?.note}/>}

            />

            <TextGrid
              label={'Agree to share the files with AV vendors for further analysis'}

              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <OpswatCheckbox label="" disabled checked={falseDetectionSubmission.userConsent} />
                </Box>
              }
            />

            <TextGrid
              label={'Analysis Summary'}
              value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission?.analystNote}/>}
            />

            <TextGrid
              label={'Submitted At'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {formatDatetime(falseDetectionSubmission?.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
                  </Typography>
                </Box>
              }
            />

            <TextGrid
              label={'Submitted By'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {falseDetectionSubmission?.createdByEmail}
                  </Typography>
                </Box>
              }
            />

            <TextGrid
              label={'Last Updated'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {formatDatetime(falseDetectionSubmission?.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
                  </Typography>
                </Box>
              }
            />

          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={onEdit} color="primary" disabled={isChangeFalseDetectionDisabled}>
              Edit
            </Button>
          </Box>
        </Grid>
      </Grid>
      <DialogFalseDetectionDownloadInfo />
    </>
  )
}

export default FalseDetectionSubmissionDetail
