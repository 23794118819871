import { createApi } from '@reduxjs/toolkit/query/react'
import { enqueueSnackbar } from 'notistack'

import { DIALOGS, KEYS } from '@myopswat/common'
import { getCookie, getLocalStorage } from '@opswat/react-core'
import { BaseQueryApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { toggleDialogs } from '../../containers/LayoutContainer/layoutContainerSlice'
import { graphqlRequestBaseQuery } from './graphql'

/** GraphQL */
const graphqlService = graphqlRequestBaseQuery({
  url: process.env.REACT_APP_API_ENDPOINT || ''
})

export const apiGraphql = createApi({
  baseQuery: graphqlService,
  endpoints: () => ({})
})

/** REST API */
const baseRestQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST || '',
  prepareHeaders: async headers => {
    const accessToken =
      process.env.REACT_APP_TOKEN_STORAGE === KEYS.COOKIE ? getCookie(KEYS.TOKEN_KEY) : getLocalStorage(KEYS.TOKEN_KEY)
    headers.set('Authorization', `Bearer ${accessToken}`)

    return headers
  }
})

const customBaseQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
  try {
    const result = await baseRestQuery(args, api, extraOptions)
    if (result.error) {
      const { status } = result.error
      if (status === 401) {
        api.dispatch(
          toggleDialogs({
            [DIALOGS.SESSION_TIMEOUT]: true
          })
        )
      }

      if (result.error.status === 'FETCH_ERROR') {
        enqueueSnackbar(
          'The action could not be completed due to a connection issue. Please try again when the connection is stable.',
          {
            variant: 'warning',
            preventDuplicate: true
          }
        )
      }
    }

    return result
  } catch (error) {
    if (error instanceof TypeError) {
      if (error.message === 'Network request failed') {
        enqueueSnackbar(
          'The action could not be completed due to a connection issue. Please try again when the connection is stable.',
          {
            variant: 'warning',
            preventDuplicate: true
          }
        )
      }
    }

    throw error
  }
}

export const apiRestful = createApi({
  reducerPath: 'apiRestful',
  baseQuery: customBaseQuery,
  endpoints: () => ({})
})
