import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { INPSSurveyQueryInput, INPSSurveyResponse, INPSSurveyResponsesExcelReport } from './types'

export const surveyQueries = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    npsSurvey: builder.query<INPSSurveyResponse, INPSSurveyQueryInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminNpsSurveyFiltersInput) {
            adminNpsSurvey(filters: $filters) {
              totalResponses
              detail {
                answer
                count
                percent
              }
            }
          }
        `,
        variables: {
          filters
        }
      })
    }),
    npsSurveyProducts: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminNpsSurveyProducts(id: "${id}") {
              id
              name
            }
          }
        `
      })
    }),
    npsSurveyResponses: builder.query<any, any>({
      query: (filters: any) => ({
        document: gql`
          query (
            $filters: AdminNpsSurveyFiltersInput
            $pageInfo: PageInfoType
            $sortInfo: AdminNpsSurveyResponseSortInput
          ) {
            adminNpsSurveyResponses(filters: $filters, pageInfo: $pageInfo, sortInfo: $sortInfo) {
              headers
              totalCount
              results
              order
              sortableHeaders
            }
          }
        `,
        variables: {
          ...filters
        }
      })
    }),
    npsSurveyResponsesExcelReport: builder.query<INPSSurveyResponsesExcelReport, INPSSurveyQueryInput>({
      query: (filters: any) => ({
        document: gql`
          query ($filters: AdminNpsSurveyFiltersInput) {
            adminNpsSurveyResponsesExcelReport(filters: $filters) {
              filename
              content
            }
          }
        `,
        variables: {
          filters
        }
      })
    })
  })
})

export const {
  useNpsSurveyQuery,
  useLazyNpsSurveyQuery,
  useLazyNpsSurveyProductsQuery,
  useLazyNpsSurveyResponsesQuery,
  useLazyNpsSurveyResponsesExcelReportQuery
} = surveyQueries
