import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useAdminRoleObjectUpdateMutation } from '../../../../api/roleObjectsManagement'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import RoleObjectForm from '../RoleObjectsForm'
import { IRoleObjectForm, roleObjectsUpdateSchema } from '../RoleObjectsSchema'
import { ButtonLoading, Grid, TemplateSection } from '@opswat/react-ui'
import { customerManagementRoleObjectsListPageURL } from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'
import _isEmpty from 'lodash/isEmpty'

interface IProps {
  roleObject: any
  roleObjectComponents: any
  listPermission: any
}

const RoleObjectsDetailEdit = ({ roleObject, roleObjectComponents, listPermission }: IProps) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [updateRoleObject, { isLoading }] = useAdminRoleObjectUpdateMutation()

  const assignedPermissions = roleObject.assignedPermissions || []
  const initValues = useMemo(() => {
    return {
      code: roleObject.code,
      name: roleObject.name,
      permissions: roleObject.permissions,
      componentId: roleObject.componentId,
      reason: ''
    }
  }, [roleObject])

  const formRef = useForm<IRoleObjectForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(roleObjectsUpdateSchema)
  })

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef

  const onSubmit = async (data: IRoleObjectForm) => {
    try {
      const dataUpdated: any = {
        id: roleObject.id
      }
      Object.keys(dirtyFields).forEach(field => {
        dataUpdated[field] = data[field as keyof IRoleObjectForm]
      })
      const res = await updateRoleObject(dataUpdated).unwrap()
      if (res.success) {
        enqueueSnackbar('Update role object successfully.', { variant: 'success' })
        navigate(customerManagementRoleObjectsListPageURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const isAllowEnable = () => {
    return !_isEmpty(dirtyFields) && dirtyFields.reason
  }

  return (
    <TemplateSection spacing={4}>
      <Grid container spacing={2} py={5}>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <RoleObjectForm
            formRef={formRef}
            listPermission={listPermission}
            roleObjectComponents={roleObjectComponents}
            edit={true}
            globalRoles={[]}
            assignedPermissions={assignedPermissions}
          />
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center" py={2.5}>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'inherit',
                  onClick: () => {
                    navigate(`${customerManagementRoleObjectsListPageURL}`)
                  },
                  disabled: isLoading
                }}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSubmit(onSubmit, onError),
                  disabled: isLoading || !isAllowEnable()
                }}
                isLoading={isLoading}
              >
                Save
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default RoleObjectsDetailEdit
