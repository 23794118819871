import { useState, useEffect, useCallback } from 'react'
import _findIndex from 'lodash/findIndex'

export const useOptimisticMutateList = <T extends Record<string, any>>(data: T[]) => {
  const [dataCache, setDataCache] = useState<T[]>(data)

  useEffect(() => {
    setDataCache(data)
  }, [data])

  const optimisticCreating = useCallback((data: T) => setDataCache(dataCache => [data, ...dataCache]), [])

  const optimisticEditing = useCallback(
    (data: Partial<T>) => {
      if (!data.id) return

      const updatedDataCache = [...dataCache]
      const selectedDataIdx = _findIndex(updatedDataCache, e => e.id === data.id)

      if (selectedDataIdx < 0) return

      updatedDataCache[selectedDataIdx] = {
        ...updatedDataCache[selectedDataIdx],
        ...data
      }

      setDataCache(updatedDataCache)
    },
    [dataCache]
  )

  return { dataCache, setDataCache, optimisticCreating, optimisticEditing }
}
