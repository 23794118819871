import _cloneDeep from 'lodash/cloneDeep'
import _omit from 'lodash/omit'
import { escape as htmlEscape } from 'html-escaper'
import _size from 'lodash/size'

import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonLoading, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'

import { useProductCreateMutation, useProductsSolutionsQuery } from 'myopswat-admin/src/api/product'
import { ProductInputType } from 'myopswat-admin/src/api/product/types'
import { systemManagementProductListPageURL } from 'myopswat-admin/src/routes/systemManagementRoutes'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import BoxProductDetailLoading from '../BoxProductDetailLoading'
import ProductForm from '../Form/ProductForm'
import { IProductForm, productSchema } from '../Form/ProductSchema'
import { useLazySystemValueListQuery } from '../../../../api/system'
import { SYSTEM_VALUE_KEYS } from '../../../../constants'
import _get from 'lodash/get'

const BoxProductDetailCreate = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { data, isFetching } = useProductsSolutionsQuery(undefined, {
    refetchOnMountOrArgChange: true
  })
  const navigate = useNavigate()
  const [createProduct, { isLoading: isCreating }] = useProductCreateMutation()

  const [getSystemValueList, { data: systemValueInfo }] = useLazySystemValueListQuery()

  const initValues = useMemo(() => {
    return {
      name: '',
      slug: '',
      shortName: '',
      codeName: '',
      description: '',
      documentation: '',
      note: '',
      solutionIds: [],
      parentIds: [],
      licensedProductsDisplay: [],
      tags: [],
      licenseTypes: [],
      downloadable: false,
      asIds: '',
      blogTags: '',
      resources: '',
      releaseMetadata: '',
      supportCasePlatforms: []
    }
  }, [])

  const supportCasePlatforms = useMemo(() => {
    return Array.isArray(systemValueInfo)
      ? _get(
        systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.SUPPORT_CASE_PLATFORMS),
        'data',
        []
      )
      : []
  }, [systemValueInfo])

  const onSubmit = async (data: IProductForm) => {
    data['resources'] = data['resources'] ? JSON.parse(data['resources']) : []
    data['releaseMetadata'] = data['releaseMetadata'] ? JSON.parse(data['releaseMetadata']) : {}
    const requestInput = _cloneDeep(_omit(data, ['asIds', 'blogTags'])) as unknown as ProductInputType
    requestInput.asIds = data.asIds ? data.asIds.split(',').map(id => Number(id)) : []
    requestInput.blogTags = data.blogTags ? data.blogTags.split(',') : []
    requestInput.rmId = data.rmId ? Number(data.rmId) : null
    requestInput.description = htmlEscape(data.description ?? '')
    requestInput.documentation = htmlEscape(data.documentation ?? '')
    // Ensure unique & match Release Management Id with Release metadata "id"
    if (requestInput.rmId || _size(requestInput.releaseMetadata)) {
      if (requestInput.rmId !== requestInput.releaseMetadata?.id) {
        formRef.setError(
          'rmId',
          {
            message: 'Release Management Id must match Release metadata "id"'
          },
          { shouldFocus: true }
        )

        formRef.setError('releaseMetadata', {
          message: 'Release metadata "id" must match Release Management Id'
        })

        return
      }
    }

    try {
      const res = await createProduct(requestInput).unwrap()
      if (res.success) {
        enqueueSnackbar('Create product successfully.', { variant: 'success' })
        navigate(systemManagementProductListPageURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const formRef = useForm<IProductForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(productSchema)
  })

  const productMap = useMemo(() => {
    if (!data) return {}
    return data.adminProducts?.reduce((obj: any, product: any) => ((obj[product.id] = product.name), obj), {})
  }, [data])

  const asIdsProductMap = useMemo(() => {
    if (!data) return {}
    return data.adminProducts?.reduce((obj: any, product: any) => {
      if (product.asIds?.length) {
        obj[product.id] = product.name
      }
      return obj
    }, {})
  }, [data])

  const solutionMap = useMemo(() => {
    if (!data) return {}
    return data.solutions.reduce(
      (obj: any, item: { id: number; name: string }) => ((obj[item.id] = item.name), obj),
      {}
    )
  }, [data])

  const { handleSubmit } = formRef

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const isRender = useMemo(() => {
    return productMap && solutionMap
  }, [productMap, solutionMap])

  useEffect(() => {
    getSystemValueList([SYSTEM_VALUE_KEYS.SUPPORT_CASE_PLATFORMS])
  }, [])

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Product List',
        path: systemManagementProductListPageURL
      },
      {
        label: 'Create New Product'
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {isLoading && <BoxProductDetailLoading />}

      {!isLoading && isRender && (
        <>
          <Grid container>
            <Grid item xs={12} sm={12} md={10} lg={8}>
              <ProductForm
                formRef={formRef}
                productMap={productMap}
                asIdsProductMap={asIdsProductMap}
                solutionMap={solutionMap}
                supportCasePlatforms={supportCasePlatforms}
              />
              <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item xs="auto">
                  <ButtonLoading
                    propsButton={{
                      variant: 'contained',
                      color: 'primary',
                      onClick: handleSubmit(onSubmit, onError),
                      disabled: isCreating
                    }}
                    isLoading={isCreating}
                  >
                    Create
                  </ButtonLoading>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </TemplateSection>
  )
}
export default BoxProductDetailCreate
