import { useContext } from 'react'

import { Box, ButtonLoading, Grid, TabAdvanced } from '@opswat/react-ui'

import { systemManagementSettingListPageURL } from 'myopswat-admin/src/routes'

import { PortalSettingsContext } from './interface'
import SurveySettings from './SurveySettings'

const PortalSettingsPage = () => {
  const {
    activeTabIndex,
    isEditMode,
    isSubmitting,
    hasEditPermission,
    setIsEditMode,
    setActiveTabIndex,
    handleCheckValidForm,
    handleResetForm,
    handleSubmitForm
  } = useContext(PortalSettingsContext)

  const tabArray = [
    {
      label: 'Surveys',
      content: <SurveySettings />,
      tabPath: systemManagementSettingListPageURL
    }
  ]

  const handleRenderActions = () => {
    if (!hasEditPermission) {
      return <></>
    }
    if (isEditMode) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <ButtonLoading
            propsButton={{
              variant: 'text',
              color: 'inherit',
              disableElevation: true,
              disabled: isSubmitting,
              sx: { marginBottom: 1 },
              onClick: () => handleResetForm(activeTabIndex)
            }}
            propsLoading={{ color: 'inherit' }}
          >
            Cancel
          </ButtonLoading>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              sx: { marginBottom: 1 },
              disabled: !handleCheckValidForm(activeTabIndex) || isSubmitting,
              onClick: () => handleSubmitForm(activeTabIndex)
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isSubmitting}
          >
            Save
          </ButtonLoading>
        </Box>
      )
    } else {
      return (
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
            sx: { marginBottom: 1 },
            onClick: () => setIsEditMode(!isEditMode)
          }}
          propsLoading={{ color: 'inherit' }}
        >
          Edit
        </ButtonLoading>
      )
    }
  }

  return (
    <Grid container>
      <TabAdvanced
        borderBottom="1px solid #E9EAEB"
        tabs={tabArray}
        emitActiveTabValue={setActiveTabIndex}
        actions={handleRenderActions()}
      />
    </Grid>
  )
}

export default PortalSettingsPage
