import React from 'react'
import { Helmet } from 'react-helmet'

import DashboardNPSSurveyPage from 'myopswat-admin/src/pages/DashboardPage/DashboardNPSSurveyPage'

export default function DashboardNPSSurveyContainer() {
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'NPS Survey'}
        </title>
      </Helmet>

      <DashboardNPSSurveyPage />
    </>
  )
}
