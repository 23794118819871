import React, { FC, useEffect } from 'react'

import _isEmpty from 'lodash/isEmpty'

import { Box } from '@opswat/react-ui'

import { useTypedSelector } from 'myopswat-admin/src/store'

import GeneralInfoLoading from './GeneralInfoLoading'
import GeneralInfoEdit from './GeneralInfoEdit'
import GeneralInfoView from './GeneralInfoView'
import { useLazySysItemCountriesQuery } from 'myopswat-admin/src/api/system'
import { useLocation } from 'react-router-dom'
import { myAccountPersonalInformationPageURL } from 'myopswat-admin/src/routes'
import { selectEditMode } from 'myopswat-admin/src/containers/MyAccountContainer/myAccountContainerSlice'

const General: FC<unknown> = () => {
  const location = useLocation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data) || {}
  const editMode = useTypedSelector(selectEditMode)

  const [getSysItemCountries, { data: sysItemCountriesData }] = useLazySysItemCountriesQuery()

  useEffect(() => {
    if (location.pathname.includes(myAccountPersonalInformationPageURL)) {
      getSysItemCountries()
    }
  }, [])

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {_isEmpty(profileData) ? (
        <GeneralInfoLoading />
      ) : (
        <>
          {editMode === 'info' ? (
            <GeneralInfoEdit profileData={profileData} sysItemCountriesData={sysItemCountriesData} />
          ) : (
            <GeneralInfoView profileData={profileData} sysItemCountriesData={sysItemCountriesData} />
          )}
        </>
      )}
    </Box>
  )
}

export default General
