import { FC } from 'react'

import CookieSettingDrawer from 'myopswat-admin/src/components/Drawer/CookieSettingDrawer'
import DialogCookieSettings from 'myopswat-admin/src/components/Dialog/DialogCookieSettings'
import DialogSessionTimeout from 'myopswat-admin/src/components/Dialog/DialogSessionTimeout'
import ConfirmationDialog from 'myopswat-admin/src/components/Dialog/ConfirmationDialog'
import DialogConfirmWithReason from 'myopswat-admin/src/components/Dialog/DialogConfirmWithReason'

const AllForm: FC<unknown> = () => {
  return (
    <>
      <CookieSettingDrawer />

      <DialogCookieSettings />

      <DialogSessionTimeout />

      <ConfirmationDialog />

      <DialogConfirmWithReason />
    </>
  )
}

export default AllForm
