import {
  Dropdown,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton, OpswatCheckbox,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AdminRoleObjectFilters, AdminRoleObjectFiltersInput } from '../../../../api/roleObjectsManagement/types'
import {
  useAdminRoleObjectDeleteMutation,
  useAdminRoleObjectsQuery,
  useLazyAdminRoleObjectsComponentQuery
} from '../../../../api/roleObjectsManagement'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from '../../../../constants'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _find from 'lodash/find'
import { formatDatetime } from '@opswat/react-core'
import { DATE_FORMATS } from '@myopswat/common'
import RoleObjectsFiltersSection from './RoleObjectsFiltersSection'
import { OptionIcon } from '@opswat/react-icon'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import {
  customerManagementRoleObjectsListPageURL,
  customerRoleObjectsDetailPath
} from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'
import useDialog from '../../../../components/Dialog/DialogHook'

interface IProps {
  permissions: any
}

const RoleObjectsList = ({ permissions }: IProps) => {
  const navigate = useNavigate()
  const dialog = useDialog()

  const listPermission = [
    {
      id: 1,
      name: 'None'
    },
    {
      id: 2,
      name: 'Read Only'
    },
    {
      id: 3,
      name: 'Full Access'
    }
  ]

  const [query, setQuery] = useState<AdminRoleObjectFiltersInput>({
    filters: {
      q: '',
      componentId: [],
      permissions: []
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    },
    sortInfo: {
      order: 'desc',
      orderBy: 'updatedAt'
    }
  })

  const handleSearch = (searchData: AdminRoleObjectFilters) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const [getRoleObjectComponents, { data: roleObjectComponentsData }] = useLazyAdminRoleObjectsComponentQuery()

  const [deleteRoleObject] = useAdminRoleObjectDeleteMutation()

  useEffect(() => {
    getRoleObjectComponents()
  }, [getRoleObjectComponents])

  const {
    data: roleObjectsData,
    isFetching: isFetchingRoleObjectsData,
    refetch
  } = useAdminRoleObjectsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => ({ ...statePrev, ...{ pageInfo: { page: page, pageSize: pageSize } } }))
  }

  const handleSortChange = (orderBy?: any, order?: any) => {
    setQuery(prevState => ({
      ...prevState,
      sortInfo: { order, orderBy },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  const handleDeleteRoleObject = useCallback(async (id: string) => {
    const confirmed = await dialog.openConfirmation({
      content:
        'Are you sure you want to delete this data? This action cannot be undone, and the deleted data will be permanently removed from the system.',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
    if (confirmed) {
      roleObjectDelete(id)
    }
  }, [])

  const roleObjectDelete = useCallback(async (id: string) => {
    await deleteRoleObject({
      id: id,
    })
      .unwrap()
      .then(async response => {
        if (response?.success) {
          await enqueueSnackbar('Deleted role object successfully', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar(_get(response, ['errors', 0, 'message']), {
            variant: 'error'
          })
        }
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Deleting role object has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
      })
  }, [])

  const roleObjects = useMemo(() => {
    if (roleObjectsData) {
      return roleObjectsData.results
    }
    return []
  }, [roleObjectsData])

  const roleObjectComponents = useMemo(() => {
    if (roleObjectComponentsData) {
      return roleObjectComponentsData
    }
    return []
  }, [roleObjectComponentsData])

  const renderPermissionsColumn = (data: any) => {
    return (listPermission || []).map((source: any) => (
      <Grid container spacing={0}>
        <OpswatCheckbox
          label={<Typography variant="body2"> {source.name} </Typography>}
          disabled
          checked={ data.permissions.includes(source.id)}
        />
      </Grid>
    ))
  }

  const totalCount = useMemo(() => {
    if (_isEmpty(roleObjectsData)) {
      return 0
    }
    return roleObjectsData.totalCount
  }, [roleObjectsData])

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Code',
        body: (data: any) => (
          <TypographyLineClamp
            line={1}
            variant="body2"
            display="block"
            sx={{ py: 0.5 }}
            textAlign="left"
            tooltipValue={data.code}
          >
            {data.code || '--'}
          </TypographyLineClamp>
        ),
        style: { minWidth: 100, width: '5.5%' }
      },
      {
        isSortable: true,
        key: 'name',
        header: 'Name',
        body: (data: any) => (
          <TypographyLineClamp
            line={1}
            variant="body2"
            display="block"
            textAlign="left"
            sx={{ py: 0.5 }}
            tooltipValue={data.name}
          >
            {data.name ?? '--'}
          </TypographyLineClamp>
        ),
        style: { minWidth: 150, width: '5.5%' }
      },
      {
        header: 'Permissions',
        body: (data: any) => renderPermissionsColumn(data),
        style: { minWidth: 100, width: '5.5%' }
      },
      {
        header: 'My OPSWAT Component',
        body: (data: any) => {
          const component = _find(
            roleObjectComponents,
            (item: any) => _get(item, 'id') === _get(data, 'componentId', '')
          )
          return (
            <TypographyLineClamp line={1} variant="body2" tooltipValue={_get(component, 'name', '')}>
              {_get(component, 'name', '')}
            </TypographyLineClamp>
          )
        },
        style: { minWidth: 100, width: '5.5%' }
      },
      {
        header: 'Last Updated By',
        body: (data: any) => (
          <TypographyLineClamp
            line={1}
            variant="body2"
            textAlign="left"
            sx={{ py: 0.5 }}
            tooltipValue={data.updatedByUser ?? '--'}
          >
            {data.updatedByUser ?? '--'}
          </TypographyLineClamp>
        ),
        style: { minWidth: 100, width: '5.5%' }
      },
      {
        isSortable: true,
        key: 'updatedAt',
        header: 'Last Updated',
        body: (data: any) => (
          <TypographyLineClamp
            line={1}
            variant="body2"
            tooltipValue={formatDatetime(data.updatedAt, DATE_FORMATS.DATE_TIME)}
          >
            {formatDatetime(data.updatedAt, DATE_FORMATS.DATE_TIME)}
          </TypographyLineClamp>
        ),
        style: { minWidth: 100, width: '5.5%' }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(
                        `${customerManagementRoleObjectsListPageURL}/${data?.id}/${customerRoleObjectsDetailPath}`
                      )
                    }}
                    disabled={!_get(permissions, 'change_role_object', false)}
                  >
                    Edit detail
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleDeleteRoleObject(data.id)
                    }}
                    disabled={!_get(permissions, 'delete_role_object', false)}
                  >
                    Delete
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, width: '2%', textAlign: 'right' }
      }
    ]
  }, [roleObjectComponents])

  return (
    <TemplateSection>
      <RoleObjectsFiltersSection
        filters={query.filters}
        onSearch={handleSearch}
        listPermission={listPermission}
        roleObjectComponents={roleObjectComponents}
        userPermissions={permissions}
      />

      <TableAdvanced
        columns={columnArray}
        isLoading={isFetchingRoleObjectsData}
        data={roleObjects}
        isPagination
        total={totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
        order={query.sortInfo.order}
        orderBy={query.sortInfo.orderBy}
        onOrderChange={handleSortChange}
      />
    </TemplateSection>
  )
}

export default RoleObjectsList
