import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { formatDatetime } from '@opswat/react-core'
import { CollapsibleTableAdvanced, Typography, TypographyTooltipClamp } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { useLazyAdminFalseSubmissionEventLogsQuery } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { FalseSubmissionStatusEnum } from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import { IHistoryDiffType } from 'myopswat-admin/src/api/history/types'
import { QueryPagination, QuerySortInfo } from 'myopswat-admin/src/api/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { convertValueToString } from 'myopswat-admin/src/utils/json'
import FalseSubmissionEventLogsFilter from './EventLogsFilter'

interface IQuery {
  pageInfo?: QueryPagination
  sortInfo?: QuerySortInfo
  filters?: any
}

const FalseSubmissionEventLogs = () => {
  const [query, setQuery] = useState<IQuery>({
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    },
    sortInfo: {
      order: 'desc',
      orderBy: 'historyDate'
    },
    filters: {}
  })
  const [getFSEventLogs, { data, isFetching: isLoading }] = useLazyAdminFalseSubmissionEventLogsQuery()

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo?.pageSize) page = PAGE_DEFAULT
    setQuery(prevState => ({ ...prevState, pageInfo: { page, pageSize } }))
  }

  const tableData = useMemo(() => {
    return data?.results.map((item: any) => {
      const historyType = _get(item, 'historyType', '--')
      const historyReason = _get(item, 'historyUpdatedReason', '--')

      const event = historyType === 'Created' ? 'Added' : historyType

      return {
        ...item,
        event,
        reason: historyReason === '-' ? '--' : historyReason
      }
    })
  }, [data])

  const collapseData = useMemo(
    () =>
      _map(tableData, (columnData: any) =>
        _map(columnData.changes, (columnCollapseData: any) => ({
          ...columnCollapseData,
          releasePlatforms: _get(columnData, 'releasePlatforms', [])
        }))
      ),
    [tableData]
  )

  const renderTypographyColumn = (data: any, field: string) => {
    return <Typography variant="body2">{_get(data, field, '--') || '--'}</Typography>
  }

  const renderColumnDatetime = (data: any) => {
    return <Typography variant="body2">{formatDatetime(data.historyDate, DATE_FORMATS.DATE_TIME)}</Typography>
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: any) => renderTypographyColumn(data, 'event'),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Impacted',
        body: (data: any) => renderTypographyColumn(data, 'impacted'),
        style: { minWidth: 500, width: 500 }
      },
      {
        header: 'Reason',
        body: (data: any) => renderTypographyColumn(data, 'reason'),
        style: { minWidth: 500, width: 500 }
      },
      {
        header: 'Updated By',
        body: (data: any) => renderTypographyColumn(data, 'historyUpdatedBy.email'),
        style: { minWidth: 250, width: 250 }
      },
      {
        key: 'historyDate',
        header: 'Datetime',
        body: renderColumnDatetime,
        style: { minWidth: 'auto' },
        isSortable: true
      }
    ]
  }, [])

  const handleRenderStatus = (value: any) => {
    const statusValue = FalseSubmissionStatusEnum[value as keyof typeof FalseSubmissionStatusEnum]
    let backgroundColor = '',
      textColor = ''

    switch (value) {
      case 'IN_QUEUE':
        backgroundColor = '#FDBD0D'
        textColor = '#000000'
        break
      case 'IN_PROGRESS':
        backgroundColor = '#1D6BFC'
        textColor = '#FFFFFF'
        break
      case 'CONFIRMED_MALICIOUS':
        backgroundColor = '#D00300'
        textColor = '#FFFFFF'
        break
      case 'CONFIRMED_BENIGN':
        backgroundColor = '#008A00'
        textColor = '#FFFFFF'
        break
      case 'INCONCLUSIVE':
        backgroundColor = '#F59209'
        textColor = '#000000'
        break
      case 'REJECTED':
        backgroundColor = '#BCBFC3'
        textColor = '#1B273C'
        break
    }

    return (
      <TypographyTooltipClamp
        line={1}
        tooltipValue={statusValue}
        backgroundColor={backgroundColor}
        textColor={textColor}
        sx={{
          backgroundColor,
          color: textColor,
          padding: '0px 4px',
          width: 'fit-content',
          fontSize: '12px',
          fontWeight: 500,
          display: 'inline'
        }}
      >
        {statusValue}
      </TypographyTooltipClamp>
    )
  }

  const handleRenderUrl = (value: any) => {
    const url = _get(value, 'url')
    const filename = _get(value, 'name')

    return (
      <Typography>
        <a target="_blank" href={url}>
          {filename}
        </a>
      </Typography>
    )
  }

  const renderCollapseColumnField = (data: IHistoryDiffType) => {
    const field = _get(data, 'field') || '--'
    return <Typography variant="body2">{field}</Typography>
  }

  const renderDiffValue = (changedData: IHistoryDiffType, mode: 'new' | 'old') => {
    const field = changedData.field
    const value = _get(changedData, mode)

    if (!value) return '--'

    if (field === 'Status' && value) {
      return handleRenderStatus(value)
    }

    if (typeof value === 'object' && _get(value, 'url') && _get(value, 'name')) {
      return handleRenderUrl(value)
    }

    if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
      return <pre style={{ whiteSpace: 'pre-wrap' }}>{convertValueToString(value)}</pre>
    }

    return <TypographyTooltipClamp variant="body2">{value || '--'}</TypographyTooltipClamp>
  }

  const collapsedColumnArray = useMemo(() => {
    return [
      // Empty cell for the purpose of alignment
      {
        header: '',
        body: () => <p></p>,
        style: { width: 80 }
      },
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => renderCollapseColumnField(data),
        style: { minWidth: 300, width: 300, maxWidth: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => renderDiffValue(data, 'new'),
        style: { width: '30vw', maxWidth: '30vw' }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => renderDiffValue(data, 'old'),
        style: { width: '30vw', maxWidth: '30vw' }
      }
    ]
  }, [])

  const handleFiltersChange = (filterData: any) => {
    setQuery(prevState => ({
      ...prevState,
      filters: filterData,
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  const handleSortChange = (orderBy?: any, order?: any) => {
    setQuery(prevState => ({
      ...prevState,
      sortInfo: { order, orderBy },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  useEffect(() => {
    getFSEventLogs(query)
  }, [query])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'Event Logs'}
        </title>
      </Helmet>

      <FalseSubmissionEventLogsFilter handleFiltersChange={handleFiltersChange} queryData={query} />

      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={tableData}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo?.page}
        pageSize={query.pageInfo?.pageSize}
        onPageChange={handlePaginationOnChange}
        order={query.sortInfo?.order}
        orderBy={query.sortInfo?.orderBy}
        onOrderChange={handleSortChange}
      />
    </>
  )
}

export default FalseSubmissionEventLogs
