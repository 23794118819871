import { Link } from 'react-router-dom'

import {
  CardsLayoutIcon,
  EmailIcon,
  PatchesIcon,
  SettingsIcon,
  SolidRightIcon,
  SuperisorApprovalIcon,
  UpdateIcon,
  UserConnectionIcon,
  UserGroupIcon,
  UserIcon
} from '@opswat/react-icon'

import { isEnabledPOCFeatures, isFalseSubmissionDisabled } from 'myopswat-admin/src/constants'
import {
  approvalManagementEvaluationRequestsPageURL,
  approvalManagementPageURL,
  customerManagementOrganizationListPageURL,
  customerManagementPageURL,
  customerManagementUserListPageURL,
  dashboardFalseDetectionSubmissionBaseURL,
  dashboardFalseDetectionSubmissionListURL,
  dashboardNPSSurveyPageURL,
  dashboardOrganizationPageURL,
  dashboardPageURL,
  dashboardUserPageURL,
  integrationManagementInternalAppListPageURL,
  integrationManagementPageURL,
  myAccountPersonalInformationPageURL,
  notificationManagementMassNotificationPageURL,
  notificationManagementPageURL,
  notificationManagementSystemSubscribersPageURL,
  opswatGroupListPageURL,
  opswatOrganizationPageURL,
  opswatRoleListPageURL,
  opswatUserListPageURL,
  syncingManagementOpswatBlogPageURL,
  syncingManagementPageURL,
  systemManagementPageURL,
  systemManagementProductListPageURL,
  systemManagementReleaseInformationPageURL,
  systemManagementSettingListPageURL,
  userManagementPageURL,
  userManagementPermissionListPageURL
} from 'myopswat-admin/src/routes'

import { customerManagementRoleObjectsListPageURL } from '../../routes/customerManagementRoutes/roleObjectsRoutes'

// Dashboard region
const dashboardMenu = {
  label: 'Dashboards',
  icon: <CardsLayoutIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: dashboardPageURL,
  children: [
    {
      label: 'Home',
      path: dashboardPageURL,
      propListItem: {
        component: Link,
        to: dashboardPageURL
      },
      isHiding: true
    },
    {
      label: 'User Reports',
      path: dashboardUserPageURL,
      propListItem: {
        component: Link,
        to: dashboardUserPageURL
      }
    },
    {
      label: 'Organization',
      path: dashboardOrganizationPageURL,
      propListItem: {
        component: Link,
        to: dashboardOrganizationPageURL
      },
      isHiding: true
    },
    {
      label: 'NPS Survey',
      path: dashboardNPSSurveyPageURL,
      propListItem: {
        component: Link,
        to: dashboardNPSSurveyPageURL
      }
    },
    {
      isHiding: isFalseSubmissionDisabled,
      label: 'False Detection Submissions',
      path: dashboardFalseDetectionSubmissionListURL,
      basePath: dashboardFalseDetectionSubmissionBaseURL,
      propListItem: {
        component: Link,
        to: dashboardFalseDetectionSubmissionListURL
      }
    }
  ]
}

// My account region
const myAccountMenu = {
  label: 'My Account',
  icon: <UserIcon size={16} color="#1B273C" style={{ marginLeft: '3px' }} />,
  expandIcon: <SolidRightIcon />,
  path: myAccountPersonalInformationPageURL,
  propListItem: {
    component: Link,
    to: myAccountPersonalInformationPageURL
  }
}

// User region
const userMenu = {
  label: 'User Management',
  icon: <UserGroupIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: userManagementPageURL,
  children: [
    {
      label: 'Organization',
      path: opswatOrganizationPageURL,
      propListItem: {
        component: Link,
        to: opswatOrganizationPageURL
      }
    },
    {
      label: 'Users',
      path: opswatUserListPageURL,
      propListItem: {
        component: Link,
        to: opswatUserListPageURL
      }
    },
    {
      label: 'Groups',
      path: opswatGroupListPageURL,
      permissions: ['view_group'],
      propListItem: {
        component: Link,
        to: opswatGroupListPageURL
      }
    },
    {
      label: 'Roles',
      path: opswatRoleListPageURL,
      permissions: ['view_role'],
      propListItem: {
        component: Link,
        to: opswatRoleListPageURL
      }
    },
    {
      label: 'Permissions',
      path: userManagementPermissionListPageURL,
      propListItem: {
        component: Link,
        to: userManagementPermissionListPageURL
      },
      isHiding: true
    }
  ]
}

// Syncing management region
const syncingManagementMenu = {
  label: 'Content Syncing',
  icon: <UpdateIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: syncingManagementPageURL,
  children: [
    {
      label: 'OPSWAT Blog',
      path: syncingManagementOpswatBlogPageURL,
      propListItem: {
        component: Link,
        to: syncingManagementOpswatBlogPageURL
      }
    }
  ]
}

// Notification Management region
const notificationManagementMenu = {
  label: 'Email Notifications',
  icon: <EmailIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: notificationManagementPageURL,
  children: [
    {
      label: 'System Subscribers',
      path: notificationManagementSystemSubscribersPageURL,
      propListItem: {
        component: Link,
        to: notificationManagementSystemSubscribersPageURL
      }
    },
    {
      label: 'Email Communication',
      path: notificationManagementMassNotificationPageURL,
      propListItem: {
        component: Link,
        to: notificationManagementMassNotificationPageURL
      }
    }
  ]
}

// Integration management region
const integrationManagementMenu = {
  label: 'Integrations',
  isOther: true,
  icon: <PatchesIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: integrationManagementPageURL,
  children: [
    {
      label: 'Internal Applications',
      path: integrationManagementInternalAppListPageURL,
      propListItem: {
        component: Link,
        to: integrationManagementInternalAppListPageURL
      }
    }
  ]
}

// Approval management region
const approvalManagementRegion = {
  label: 'Approvals',
  icon: <SuperisorApprovalIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: approvalManagementPageURL,
  children: [
    {
      isHiding: !isEnabledPOCFeatures,
      label: 'Evaluation Requests',
      path: approvalManagementEvaluationRequestsPageURL,
      propListItem: {
        component: Link,
        to: approvalManagementEvaluationRequestsPageURL
      }
    }
  ]
}

// Customer management region
const customerManagementMenu = {
  label: 'Customer Management',
  isOther: true,
  icon: <UserConnectionIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: customerManagementPageURL,
  children: [
    {
      label: 'Users',
      path: customerManagementUserListPageURL,
      propListItem: {
        component: Link,
        to: customerManagementUserListPageURL
      }
    },
    {
      label: 'Organizations',
      path: customerManagementOrganizationListPageURL,
      propListItem: {
        component: Link,
        to: customerManagementOrganizationListPageURL
      }
    },
    {
      label: 'Role Objects',
      path: customerManagementRoleObjectsListPageURL,
      propListItem: {
        component: Link,
        to: customerManagementRoleObjectsListPageURL
      }
    }
  ]
}

// System management region
const systemManagementMenu = {
  label: 'System Preferences',
  isOther: true,
  icon: <SettingsIcon size={16} color="#1B273C" />,
  expandIcon: <SolidRightIcon />,
  path: systemManagementPageURL,
  children: [
    {
      label: 'Products',
      path: systemManagementProductListPageURL,
      propListItem: {
        component: Link,
        to: systemManagementProductListPageURL
      }
    },
    {
      label: 'Portal Settings',
      path: systemManagementSettingListPageURL,
      propListItem: {
        component: Link,
        to: systemManagementSettingListPageURL
      }
    },
    {
      label: 'Release Information',
      path: systemManagementReleaseInformationPageURL,
      propListItem: {
        component: Link,
        to: systemManagementReleaseInformationPageURL
      }
    }
  ]
}

export const menuItems = [
  dashboardMenu,
  myAccountMenu,
  userMenu,
  syncingManagementMenu,
  notificationManagementMenu,
  integrationManagementMenu,
  approvalManagementRegion,
  customerManagementMenu,
  systemManagementMenu
]
