import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { IMassNotificationList, IMassNotificationQuery, IMassNotificationUpdateInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminMassNotifyList: builder.query<IMassNotificationList, IMassNotificationQuery>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminEmailNotifyFiltersInput, $pageInfo: PageInfoType) {
            adminEmailNotifies(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                creator {
                  email
                }
                recipientEmails
                title
                products {
                  name
                }
                content
                sendingPurpose
                sendAt
                deliveryStatus
                isCriticalAlert
              }
            }
          }
        `,
        variables: {
          filters,
          pageInfo
        }
      })
    }),
    adminMassNotifyItem: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminEmailNotify(id: "${id}") {
              id
              creator {
                  email
              }
              recipientEmails
              title
              products {
                id
                name
              }
              productOptions
              content
              sendingPurpose
              sendAt
              deliveryStatus
              isImmediately
              isCriticalAlert
              relatedProductsRecipientEmails
            }
          }
        `
      })
    }),
    adminMassNotifyCreate: builder.mutation<any, IMassNotificationUpdateInput>({
      query: input => ({
        document: gql`
          mutation adminEmailNotifyCreate($input: AdminEmailNotifyCreateInput!) {
            adminEmailNotifyCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    adminMassNotifyUpdate: builder.mutation<any, IMassNotificationUpdateInput>({
      query: input => ({
        document: gql`
          mutation adminEmailNotifyUpdate($input: AdminEmailNotifyUpdateInput!) {
            adminEmailNotifyUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    adminMassNotifyDelete: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation adminEmailNotifyDelete {
            adminEmailNotifyDelete(id: "${id}") {
              success
              errors
            }
          }
        `
      })
    }),
    adminMassNotifyGetRecipients: builder.query<any, any>({
      query: (filters: any) => ({
        document: gql`
          query adminProductRecipientEmails($filters: AdminProductRecipientEmailsInput) {
            adminProductRecipientEmails(filters: $filters) {
              recipientEmails
            }
          }
        `,
        variables: {
          filters
        }
      })
    }),
    adminMassNotifyCheckEnableGmail: builder.query<any, any>({
      query: () => ({
        document: gql`
          query AdminCheckIfAllowedRecipientGmails {
            adminCheckIfAllowedRecipientGmails {
              isAllowed
            }
          }
        `
      })
    })
  })
})

export const {
  useLazyAdminMassNotifyItemQuery,
  useLazyAdminMassNotifyListQuery,
  useAdminMassNotifyCreateMutation,
  useAdminMassNotifyUpdateMutation,
  useAdminMassNotifyDeleteMutation,
  useLazyAdminMassNotifyGetRecipientsQuery,
  useLazyAdminMassNotifyCheckEnableGmailQuery
} = api
