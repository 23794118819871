import { gql } from 'graphql-request'

import { EntrySyncUpInput } from './types'
import { apiGraphql } from '../base-query'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    entrySyncUp: builder.mutation<any, EntrySyncUpInput>({
      query: ({ entryType, startDate }) => ({
        document: gql`
          mutation entrySyncUp($input: EntrySyncUpInput!) {
            entrySyncUp(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            entryType: entryType,
            startDate: startDate
          }
        }
      })
    })
  })
})

export const { useEntrySyncUpMutation } = api
