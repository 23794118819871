import { SCHEMA_YUP } from '@myopswat/common'
import _merge from 'lodash/merge'
import yup from 'myopswat-admin/src/utils/yup'

export const adminReleaseEditSchema = yup
  .object()
  .shape(
    _merge(
      {},
      ...[
        SCHEMA_YUP.assets,
        SCHEMA_YUP.note,
        SCHEMA_YUP.semVer,
        SCHEMA_YUP.version,
        SCHEMA_YUP.date,
        SCHEMA_YUP.releaseDate,
        SCHEMA_YUP.platformIds,
        SCHEMA_YUP.epochTime,
        SCHEMA_YUP.updatedReason
      ]
    )
  )

export const adminReleaseCreateSchema = yup.object().shape(
  _merge(
    {},
    ...[
      SCHEMA_YUP.assets,
      SCHEMA_YUP.note,
      SCHEMA_YUP.date,
      SCHEMA_YUP.releaseDate,
      SCHEMA_YUP.platformIds,
      SCHEMA_YUP.epochTime,
      {
        version: yup
          .string()
          .trim()
          .required('Version cannot be blank')
          .matches(/[0-9]+\.[0-9]+\.[0-9]+/, 'Version value should contain a semantic version X.Y.Z')
      }
    ]
  )
)
