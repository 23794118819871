import { useMemo, useState } from 'react'

import { TableAdvanced, TemplateSection, Typography, TypographyLinkClamp } from '@opswat/react-ui'

import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import { useAdminUserOrganizationsQuery } from 'myopswat-admin/src/api/organizationManagement'
import {
  AdminOrganizationFilter,
  AdminUserOrganizationQueryInput
} from 'myopswat-admin/src/api/organizationManagement/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { MDC_LICENSE_TYPE_OPTIONS } from 'myopswat-admin/src/constants/organizations'
import { customerManagementOrganizationListPageURL, detailCustomerOrganizationPath } from 'myopswat-admin/src/routes'
import { useNavigate } from 'react-router-dom'
import FilterSection from './FilterSection'
import AccountOpportunityTooltip from './AccountOpportunityTooltip'

interface OrganizationListProps {
  profileData: any
}

const OrganizationList = ({ profileData }: OrganizationListProps) => {
  const navigate = useNavigate()
  const [organizationFilter] = useState<AdminOrganizationFilter>({
    q: ''
  })

  const [query, setQuery] = useState<AdminUserOrganizationQueryInput>({
    id: profileData.id,
    filters: organizationFilter,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const { data, isFetching } = useAdminUserOrganizationsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const tableData = useMemo(() => {
    if (_isEmpty(data)) return []
    return _map(data.results, (org: any) => ({
      id: org.organization.id,
      name: org.organization.name,
      accountId: org.organization.accountId,
      mdcLicenseType: MDC_LICENSE_TYPE_OPTIONS[org.organization.mdcLicenseType],
      opportunityId: org.organization.opportunityId
    }))
  }, [data])

  const handleSearch = (searchData: AdminOrganizationFilter) => {
    setQuery({
      id: profileData.id,
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      },
      filters: searchData
    })
  }

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => (
          <TypographyLinkClamp
            onClick={() => {
              navigate(`${customerManagementOrganizationListPageURL}/${data.id}/${detailCustomerOrganizationPath}`)
            }}
          >
            {data.name || '--'}
          </TypographyLinkClamp>
        ),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'MD Cloud License Type',
        body: (data: any) => <Typography variant="body2">{data?.mdcLicenseType || 'No License'}</Typography>,
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Account ID/Opportunity ID',
        body: AccountOpportunityTooltip,
        style: { textAlign: 'left' }
      },
      {
        header: '',
        body: () => <></>,
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <FilterSection filters={query.filters} onSearch={handleSearch} />

      <TableAdvanced
        columns={columnArray}
        isLoading={isLoading}
        data={tableData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default OrganizationList
