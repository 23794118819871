import { useContext } from 'react'
import { useWatch } from 'react-hook-form'

import { Box, Grid } from '@opswat/react-ui'

import CreateReleaseInfoActions from './components/ReleaseInfoActions'
import ReleaseInfoForm from './components/ReleaseInfoForm'
import ReleaseInfoPreview from './components/ReleaseInfoPreview'
import ReleaseInfoSkeletonLoading from './components/ReleaseInfoSkeletonLoading'

import { RELEASE_INFORMATION_STATUS } from '../../../../api/product/types'
import { ReleaseInfoContext } from './interface'

const CreateReleaseInfoPage = () => {
  const { isFetchingProducts, control, productOptions } = useContext(ReleaseInfoContext)

  const overallStatus = useWatch({ control, name: 'status' })

  return (
    <Box
      sx={{
        marginTop: 3
      }}
    >
      {/* Loading */}
      {(isFetchingProducts || !productOptions) && <ReleaseInfoSkeletonLoading />}

      {/* Form */}
      {!isFetchingProducts && productOptions && (
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <ReleaseInfoForm />
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={5}>
            <ReleaseInfoPreview />
          </Grid>
        </Grid>
      )}

      {(!overallStatus ||
        ![RELEASE_INFORMATION_STATUS.DELIVERED, RELEASE_INFORMATION_STATUS.SKIPPED].includes(overallStatus)) && (
        <CreateReleaseInfoActions />
      )}
    </Box>
  )
}

export default CreateReleaseInfoPage
