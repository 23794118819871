import { Alert, AlertColor, AlertTitle, TypographyLink } from '@opswat/react-ui'
import _get from 'lodash/get'
import _has from 'lodash/has'
import { REDIRECT_MESSAGE_CODE } from 'myopswat-admin/src/constants'
import { USER_ACCEPT_INVITE_ERROR_MESSAGES } from 'myopswat-admin/src/constants/errors'
import { resendEmailPageURL } from 'myopswat-admin/src/routes'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
interface IProps {
  messageCode: string
}

const AlertMessage = ({ messageCode }: IProps) => {
  const navigate = useNavigate()
  const messageData = useMemo(() => {
    let data = null
    if (messageCode === REDIRECT_MESSAGE_CODE.ACTIVE_USER_SUCCESS) {
      data = {
        type: 'success',
        title: 'Success',
        message: 'Your OPSWAT Portal account has been activated. Please sign in with your username and password.'
      }
    } else if (messageCode === REDIRECT_MESSAGE_CODE.ACTIVE_USER_FAIL) {
      data = {
        type: 'error',
        title: 'Error',
        message: (
          <>
            There was an error activating your OPSWAT My OPSWAT account. Please try to &nbsp;
            <TypographyLink onClick={() => navigate(resendEmailPageURL)}>resend the confirmation email.</TypographyLink>
          </>
        )
      }
    } else if (messageCode === REDIRECT_MESSAGE_CODE.ACCEPT_INVITE_SUCCESS) {
      data = {
        type: 'success',
        title: 'Success',
        message: 'You have joined to organization successfully.'
      }
    } else if (_has(USER_ACCEPT_INVITE_ERROR_MESSAGES, messageCode)) {
      data = {
        type: 'error',
        title: 'Error',
        message: _get(USER_ACCEPT_INVITE_ERROR_MESSAGES, messageCode)
      }
    }

    return data
  }, [messageCode])

  return (
    <>
      {messageData ? (
        <Alert variant="filled" severity={messageData.type as AlertColor}>
          <AlertTitle>{messageData.title}</AlertTitle>
          {messageData.message}
        </Alert>
      ) : (
        <></>
      )}
    </>
  )
}

export default AlertMessage
