import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { IAdminSubscriberActionUpdateInput, IAdminSubscriberCreateInput, IAdminSubscriberRemoveInput } from './types'

export const mailerQueries = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    subscribers: builder.query<any, void>({
      query: () => ({
        document: gql`
          query adminSubscribers {
            adminSubscribers {
              actionName
              actionSlug
              isEnabled
              emails
            }
          }
        `
      })
    }),
    createSubscriber: builder.mutation<any, IAdminSubscriberCreateInput>({
      query: input => ({
        document: gql`
          mutation adminSubscriberCreate($input: AdminSubscriberCreateInput!) {
            adminSubscriberCreate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    removeSubscriber: builder.mutation<any, IAdminSubscriberRemoveInput>({
      query: input => ({
        document: gql`
          mutation adminSubscriberRemove($input: AdminSubscriberRemoveInput!) {
            adminSubscriberRemove(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    updateSubscriberAction: builder.mutation<any, IAdminSubscriberActionUpdateInput>({
      query: input => ({
        document: gql`
          mutation adminSubscriberActionUpdate($input: AdminSubscriberActionUpdateInput!) {
            adminSubscriberActionUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useSubscribersQuery,
  useLazySubscribersQuery,
  useCreateSubscriberMutation,
  useRemoveSubscriberMutation,
  useUpdateSubscriberActionMutation
} = mailerQueries
