import { customerManagementOrganizationListPageURL, customerOrganizationIdParam } from './organizationRoutes'

/** ----------------- PATHNAME -------------------- */
export const organizationGroupsPath = 'groups'
export const organizationGroupIdParam = ':groupId'
export const organizationGroupNewPath = 'new'
export const detailOrganizationGroupPath = 'detail'
export const editOrganizationGroupPath = 'edit'
export const modeOrganizationGroupPath = ':mode'

/** ----------------- ROUTE -------------------- */
export const customerManagementOrganizationGroupListPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${organizationGroupsPath}`
export const customerManagementOrganizationGroupNewPageURL = `${customerManagementOrganizationGroupListPageURL}/${organizationGroupNewPath}`
export const customerManagementOrganizationGroupDetailPageURL = `${customerManagementOrganizationGroupListPageURL}/${organizationGroupIdParam}/${modeOrganizationGroupPath}`
