import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, OpswatCheckbox, TextGrid } from '@opswat/react-ui'
import {
  dashboardFalseDetectionSubmissionAntivirusEngineListURL,
  FalseDetectionEditPath
} from 'myopswat-admin/src/routes'
import _find from 'lodash/find'
import _get from 'lodash/get'

interface IProps {
  engine: any
  sysItemCountriesData: any
}
const FalseSubmissionAntivirusEngineDetailView = ({ engine, sysItemCountriesData }: IProps) => {
  const navigate = useNavigate()
  const country = _find(sysItemCountriesData, (item: any) => _get(item, 'code') === _get(engine, 'country', ''))

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box>
          <TextGrid label={'Engine Name*'} value={engine.engineName} />
          <TextGrid label={'Country'} value={_get(country, 'name', '')} />
          <TextGrid label={'Detection'} value={engine.detection} />
          <TextGrid label={'Heuristic Detection'} value={engine.heuristicDetection} />
          <TextGrid label={'Hide'} value={<OpswatCheckbox label="" disabled checked={!engine.isActive} />} />
        </Box>
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item xs="auto">
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  `${dashboardFalseDetectionSubmissionAntivirusEngineListURL}/${engine.id}/${FalseDetectionEditPath}`
                )
              }}
              color="primary"
              disabled={false}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default FalseSubmissionAntivirusEngineDetailView
