export enum DETAIL_MODE {
  VIEW = 'view',
  EDIT = 'edit'
}

export const ADD_USER_ERROR_MESSAGES: Record<string | number, string> = {
  add_user_not_found:
    'The user <%= email%> has not registered for an OPSWAT account.\nOnly existing users can be added to organizations.',
  403000: 'The user <%= email%> is already invited to this organization.',
  400006:
    '<%= email%> already has a pending invitation. A new invitation can be sent after the pending invitation expires.'
}

export const CREATE_ORG_ERROR_MESSAGES: Record<string | number, string> = {
  400008: 'Organization already exists.'
}
