import React, { FC, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import _map from 'lodash/map'

import { Grid, HorizontalSelect } from '@opswat/react-ui'

interface IOption {
  label: string
  value: string
}

interface ISelectParams {
  type: 'VIEW_BY' | 'TIME_RANGE'
  value: string
}

export interface IChartWidgetHeaderProps {
  onSelectChange: (data: ISelectParams) => void
  viewByOptions: IOption[]
  defaultViewByValue: string
  timeRangeOptions: IOption[]
  defaultTimeRangeValue: string
}

const ChartWidgetHeader: FC<IChartWidgetHeaderProps> = ({
  onSelectChange,
  viewByOptions,
  defaultViewByValue,
  timeRangeOptions,
  defaultTimeRangeValue
}) => {
  const [selectedViewBy, setSelectedViewBy] = useState(defaultViewByValue)
  const [selectedTimeRange, setSelectedTimeRange] = useState(defaultTimeRangeValue)

  const handleViewByChange = (value: string) => {
    setSelectedViewBy(value)
    onSelectChange({ type: 'VIEW_BY', value })
  }

  const handleTimeRangeChange = (event: SelectChangeEvent) => {
    setSelectedTimeRange(event.target.value)
    onSelectChange({ type: 'TIME_RANGE', value: event.target.value })
  }

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2">Report for &ensp;</Typography>
        <HorizontalSelect options={viewByOptions} value={selectedViewBy} handleSelect={handleViewByChange} />
      </Grid>

      <Grid
        item
        xs={12}
        md={3}
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Typography variant="body2">TIME RANGE &ensp;</Typography>
        <Select
          labelId="time-range-label"
          id="time-range-id"
          size="small"
          value={selectedTimeRange}
          variant="standard"
          onChange={handleTimeRangeChange}
        >
          {_map(timeRangeOptions, option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default ChartWidgetHeader
