import { Box, Grid, Skeleton } from '@opswat/react-ui'

import GridView from 'myopswat-admin/src/components/Grid/GridView'

import { RELEASE_INFORMATION_FORM_LABEL_MAP } from '../../../constants'

const ReleaseInfoSkeletonLoading = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box>
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.productName}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.platforms}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.version}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.releaseLevel}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.featureHeader}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <GridView
            required
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.featureDescription}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="400px" />}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ReleaseInfoSkeletonLoading
