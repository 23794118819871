import { Grid, Typography } from '@opswat/react-ui'
import _sum from 'lodash/sum'

import ChartWidget from '../ChartWidget/ChartWidget'
import ChartWidgetHeader, { IChartWidgetHeaderProps } from '../ChartWidget/ChartWidgetHeader'

export interface IUsersActiveChartProps extends IChartWidgetHeaderProps {
  seriesData: []
  categoriesData: []
}

const UsersActiveChart = ({
  seriesData = [],
  categoriesData = [],
  viewByOptions,
  timeRangeOptions,
  defaultViewByValue,
  defaultTimeRangeValue,
  onSelectChange
}: IUsersActiveChartProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography gutterBottom sx={{ fontSize: '16px' }}>
          Active Users
          <Typography
            display="inline"
            variant="body2"
            sx={{ fontSize: '12px', fontStyle: 'italic' }}
            color="textSecondary"
          >
            &nbsp;(User has logged-on My OPSWAT at least once in the report period)
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ChartWidgetHeader
          viewByOptions={viewByOptions}
          timeRangeOptions={timeRangeOptions}
          defaultViewByValue={defaultViewByValue}
          defaultTimeRangeValue={defaultTimeRangeValue}
          onSelectChange={onSelectChange}
        />
      </Grid>

      <Grid item xs={12}>
        <ChartWidget
          type="bar"
          categories={categoriesData}
          series={[
            {
              name: `Active Users (${_sum(seriesData)})`,
              type: 'column',
              data: seriesData
            }
          ]}
          height={400}
        />
      </Grid>
    </Grid>
  )
}

export default UsersActiveChart
