import { useEffect, useMemo, useState } from 'react'

import { CollapsibleTableAdvanced, TemplateSection, Typography } from '@opswat/react-ui'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { formatDatetime, fullName } from '@opswat/react-core'
import { useLazyAdminDetailUserHistoriesQuery } from 'myopswat-admin/src/api/history'
import {
  IAdminDetailUserHistoryInput,
  IAdminDetailUserHistoryType,
  IHistoryDiffType
} from 'myopswat-admin/src/api/history/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { convertValueToString } from 'myopswat-admin/src/utils/json'
import { USER_FORM_LABEL_MAP } from '../../constants'

interface IProps {
  profileData: any
}

const UserHistoryListPage = ({ profileData }: IProps) => {
  const [query, setQuery] = useState<IAdminDetailUserHistoryInput>({
    userId: _get(profileData, 'id'),
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const [getDetailUserHistories, { data, isFetching: isLoading }] = useLazyAdminDetailUserHistoriesQuery()

  const collapseData = useMemo(
    () => _map(data?.results, (columnData: IAdminDetailUserHistoryType) => columnData.changes),
    [data]
  )

  useEffect(() => {
    getDetailUserHistories(query)
  }, [query])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: IAdminDetailUserHistoryType) => (
          <Typography variant="body2">{data.historyType || '--'}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Reason',
        body: (data: IAdminDetailUserHistoryType) => (
          <Typography variant="body2">{data.historyUpdatedReason || '--'}</Typography>
        ),
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Updated By',
        body: (data: IAdminDetailUserHistoryType) => (
          <Typography variant="body2">
            {fullName(data.historyUpdatedBy.firstName, data.historyUpdatedBy.lastName) || '--'}
          </Typography>
        ),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Datetime',
        body: (data: IAdminDetailUserHistoryType) => (
          <Typography variant="body2">
            {formatDatetime(data.historyDate, undefined, profileData?.timezone) || '--'}
          </Typography>
        ),
        style: { minWidth: 300, width: 300 }
      }
    ]
  }, [])

  const collapsedColumnArray = useMemo(() => {
    return [
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => (
          <Typography variant="body2">{_get(USER_FORM_LABEL_MAP, data.field, data.field) || '--'}</Typography>
        ),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => (
          <Typography variant="body2">{convertValueToString(data.new || '--')}</Typography>
        ),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => (
          <Typography variant="body2">{convertValueToString(data.old || '--')}</Typography>
        ),
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={data?.results}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default UserHistoryListPage
