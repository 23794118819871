import { Box, Grid, Skeleton, Typography } from '@opswat/react-ui'

const NpsSettingsLoading = () => {
  return (
    <Grid item container xs={12} rowSpacing={2} sx={{ px: 2 }}>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontSize: '15px' }}>
          NPS Survey Period<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" color="#485161">
            Customers are requested to participate in the NPS survey every
          </Typography>
          <Skeleton animation="wave" variant="rectangular" width="120px" height="37px" />
          <Typography variant="subtitle2" color="#485161">
            day(s)
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontSize: '15px' }}>
          Skip Survey<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" color="#485161">
            Upon declining the NPS survey, customer will not see it again for
          </Typography>
          <Skeleton animation="wave" variant="rectangular" width="120px" height="37px" />
          <Typography variant="subtitle2" color="#485161">
            day(s)
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default NpsSettingsLoading
