import _join from 'lodash/join'
import _map from 'lodash/map'

import { Box, BoxImageVideo, Button, Grid, OpswatCheckbox, TextGrid, Theme, useTheme } from '@opswat/react-ui'
import Parser from 'html-react-parser'

import { handleDownloadFile } from '@myopswat/common'
import { DownloadIcon } from '@opswat/react-icon'
import { editSystemProductPath, systemManagementProductListPageURL } from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  PRODUCT_FORM_LABEL_MAP,
  PRODUCT_LICENSE_TYPE_OPTIONS,
  PRODUCT_TAG_OPTIONS,
  RESOURCE_TYPE
} from '../../constants'

interface IProps {
  product: any
  solutionMap: any
  productMap: any
}

interface IResource {
  code: string
  link: string
  displayName: string
}

interface IResourceProps {
  resources: IResource[]
}

const BoxProductDetailView = ({ product, solutionMap, productMap }: IProps) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditProductDisabled = useMemo(() => {
    if (profileData?.permissions) {
      return !profileData.permissions.includes('change_product')
    }
    return false
  }, [profileData])

  const SampleCode = ({ resource, theme }: { resource: IResource; theme: Theme }) => {
    return (
      <TextGrid
        label={resource.displayName}
        value={
          <Button
            color="primary"
            variant="text"
            size="small"
            onClick={() => handleDownloadFile(resource?.link || '')}
            startIcon={<DownloadIcon color={theme.palette.primary.main} />}
            sx={{ ml: -1 }}
          >
            Download the Sample Code
          </Button>
        }
      />
    )
  }

  const VideoPlayer = ({ resource }: { resource: IResource }) => {
    return (
      <TextGrid
        label={resource.displayName}
        value={<BoxImageVideo src={resource.link} backgroundColor="black" isVideo />}
      />
    )
  }

  const ResourceView = ({ resources }: IResourceProps) => {
    return (
      <>
        {resources &&
          resources.map((resource, index) => {
            if (resource.code === RESOURCE_TYPE.SAMPLE_CODE) {
              return <SampleCode key={index} resource={resource} theme={theme} />
            } else if (resource.code === RESOURCE_TYPE.VIDEO) {
              return <VideoPlayer key={index} resource={resource} />
            }
          })}
      </>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box>
          <TextGrid label={'Name*'} value={product.name} />
          <TextGrid label={'Slug*'} value={product.slug} />
          <TextGrid label={'Short Name'} value={product.shortName} />
          <TextGrid label={'Code Name'} value={product.codeName} />
          <TextGrid label={'Release Management Id'} value={product.rmId} />
          <TextGrid label={'AS Ids'} value={_join(product.asIds, ', ')} />
          <TextGrid label={'Blog Tags'} value={_join(product.blogTags, ', ')} />
          <TextGrid
            label={'Thumbnail*'}
            value={<BoxImageVideo ratio={12 / 9} fitMedia src={product.thumbnail} alt="image" />}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid
            label={'Image*'}
            value={<BoxImageVideo ratio={12 / 9} fitMedia src={product.avatar} alt="image" />}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid
            label={'Product Icon'}
            value={product.icon ? <BoxImageVideo ratio={12 / 9} fitMedia src={product.icon} alt="image" />: null}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid label={'Description'} value={Parser(product.description || '')} />
          <TextGrid label={'Resources'} value={<ResourceView resources={product.resources} />} />
          <TextGrid label={'Release Metadata'} value={JSON.stringify(product.releaseMetadata) || ''} />
          <TextGrid label={'Documentation'} value={Parser(product.documentation || '')} />
          <TextGrid label={'Type'} value={_join(product.type, ', ')} />
          <TextGrid
            label={'Tags'}
            value={_join(
              _map(product.tags, (tag: string) => PRODUCT_TAG_OPTIONS[tag]),
              ', '
            )}
          />
          <TextGrid
            label={'License Types'}
            value={_join(
              _map(product.licenseTypes, (licenseType: string) => PRODUCT_LICENSE_TYPE_OPTIONS[licenseType]),
              ', '
            )}
          />
          <TextGrid
            label={'Solutions'}
            value={_join(
              _map(product.solutionIds, (solutionId: string) => solutionMap[solutionId]),
              ', '
            )}
          />
          <TextGrid
            label={'Platform show on Support Case'}
            value={_join(
              _map(product.supportCasePlatforms, (platform: string) => platform),
              ', '
            )}
          />
          <TextGrid label={'Note'} value={Parser(product.note || '')} />
          <TextGrid
            label={'Parents product'}
            value={_join(
              _map(product.parentIds, (parentId: string) => productMap[parentId]),
              ', '
            )}
          />
          <TextGrid
            label={'Display only with licensed Products'}
            value={_join(
              _map(product.licensedProductsDisplay, (parentId: string) => productMap[parentId]),
              ', '
            )}
          />
          <TextGrid
            label={'Downloadable'}
            value={<OpswatCheckbox label="" disabled checked={product.downloadable} />}
          />
          <TextGrid label={'Hide'} value={<OpswatCheckbox label="" disabled checked={product.hide} />} />
          <TextGrid
            label={PRODUCT_FORM_LABEL_MAP.showLatestVersion}
            value={<OpswatCheckbox label="" disabled checked={product.showLatestVersion} />}
          />
        </Box>

        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item xs="auto">
            <Button
              variant="contained"
              onClick={() => {
                navigate(`${systemManagementProductListPageURL}/${product.id}/${editSystemProductPath}`)
              }}
              color="primary"
              disabled={isEditProductDisabled}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxProductDetailView
