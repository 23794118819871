import { userManagementPageURL, opswatIncPath } from './userManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const opswatRoleListPath = `${opswatIncPath}/roles`
export const detailUserRolePath = 'detail'
export const editUserRolePath = 'edit'
export const userRoleIdPath = ':roleId'
export const modeUserRolePath = ':mode'

/** ----------------- ROUTE -------------------- */
export const opswatRoleListPageURL = `${userManagementPageURL}/${opswatRoleListPath}`
export const opswatRoleDetailPageURL = `${opswatRoleListPageURL}/${userRoleIdPath}/${modeUserRolePath}`
