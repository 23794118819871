import React from 'react'
import { Control, UseFormHandleSubmit, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

export type ComposeEmailContextProps = {
  viewMode: string | null
  control: Control<any>
  setValue: UseFormSetValue<any>
  productOptions: any[]
  trigger: UseFormTrigger<any>
  handleSubmit: UseFormHandleSubmit<any>
  handleToggleEdit: () => void
  handleSubmitDraft: (data: any) => void
  handleSubmitSend: (data: any) => void
  handleDelete: () => void
  handleSetIsCriticalAlert: (value: boolean) => void
  handleResetForm: () => void
  isLoadingEmail: boolean
  isLoadingProducts: boolean
  isLoadingRecipients: boolean
  isCreating: boolean
  isUpdating: boolean
  isDeleting: boolean
}

export const ComposeEmailContext = React.createContext<ComposeEmailContextProps>({
  viewMode: 'create',
  control: {} as Control<any>,
  setValue: {} as UseFormSetValue<any>,
  productOptions: [],
  trigger: {} as UseFormTrigger<any>,
  handleSubmit: {} as UseFormHandleSubmit<any>,
  handleToggleEdit: () => {
    return
  },
  handleSubmitDraft: () => {
    return
  },
  handleSubmitSend: () => {
    return
  },
  handleDelete: () => {
    return
  },
  handleSetIsCriticalAlert: () => {
    return
  },
  handleResetForm: () => {
    return
  },
  isLoadingEmail: false,
  isLoadingProducts: false,
  isLoadingRecipients: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false
})

export const EMAIL_TEMPLATE = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>{{ subject }}</title>
    </head>
    <style>
        .email-container-inline-padding {
            padding-left: 0;
            padding-right: 0;
        }

        .email-width {
            width: 100%;
        }

        .email-footer-inline-padding {
            padding-left: 5px;
            padding-right: 5px;
        }

        @media (min-width: 800px) {
            .email-width {
                width: 600px;
            }

            .email-container-inline-padding {
                padding-left: 60px;
                padding-right: 60px;
            }
        }
    </style>
    <body style="margin: 0; font-family: 'Roboto', sans-serif; max-width: 100%; padding: 0">
        <div style="background-color: inherit; display: flex; justify-content: center; align-items: center; min-height: 85vh; max-width: 100%;" class="email-container">
            <div style="padding-bottom: 30px; background-color: #F3F4F7;" class="email-container-inline-padding email-body">
                <div style="font-size: 13px; font-weight: 400; height: auto; background-color: #FFFFFF;" class="email-width">
                    <div><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/header.png" style="height: auto;" class="email-width" /></div>
                    <!-- START MAIL CONTENT AREA -->
                    <%= content %>
                    <!-- END MAIL CONTENT AREA -->
                    <div style="width: 100%; height: 1px; background-color: #E7E9EE; content: '';"></div>
                    <div style="padding: 30px;">
                        <div style="margin-bottom: 16px;"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/opswat-tagline.png" width="232.21px" height="44px" /></div>
                        <div style="display: flex; align-items: center; margin-bottom: 16px; gap: 10px;">
                            <a href="https://www.opswat.com/blog" target="_blank"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/blog.png" alt="blog" style=" width: 28px; height: 28px; border-radius: 2.24px;"></a>
                            <a href="https://www.linkedin.com/company/opswat/" target="_blank"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/linkedin.png" alt="linkedin" style=" width: 28px; height: 28px; border-radius: 2.24px;"></a>
                            <a href="https://twitter.com/OPSWAT" target="_blank"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/twitter.png" alt="twitter" style=" width: 28px; height: 28px; border-radius: 2.24px;"></a>
                            <a href="https://www.facebook.com/OPSWAT" target="_blank"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/facebook.png" alt="facebook" style=" width: 28px; height: 28px; border-radius: 2.24px;"></a>
                            <a href="https://www.youtube.com/@OpswatInc" target="_blank"><img src="https://myopswat-staging.s3.us-east-2.amazonaws.com/images/email/v1/youtube.png" alt="link" style=" width: 28px; height: 28px; border-radius: 2.24px;"></a>
                        </div>
                        <p style="font-size: 11px; font-weight: 400; line-height: 17px; letter-spacing: 0.01em;">©{{ copy_year }} OPSWAT Inc. All rights reserved.</p>
                        <p style="font-size: 11px; font-weight: 700; line-height: 17px; letter-spacing: 0.01em;">
                            <a style="color: #707682; text-decoration: none;" href="https://www.opswat.com" target="_blank">www.opswat.com</a>
                        </p>
                    </div>
                </div>
                <div style="padding-top: 30px; height: auto;" class="email-width">
                    <p style="font-size: 11px; font-weight: 400; line-height: 17px; letter-spacing: 0.01em; text-align: center;">OPSWAT Inc., 5411 Skycenter Dr., Ste 900, Tampa, FL 33607, United States</p>
                    <p style="font-size: 11px; font-weight: 400; line-height: 17px; letter-spacing: 0.01em; text-align: center; color: #737E93; word-wrap: break-word;" class="email-footer-inline-padding">
                        For more on our commitment to your privacy and data protection practices, please visit our <a style="color: #1D6BFC; text-decoration: none;" href="https://www.opswat.com/legal/terms-of-service" target="_blank">Terms of Service</a> and <a style="color: #1D6BFC; text-decoration: none;" href="https://www.opswat.com/legal/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                </div>
            </div>
        </div>
    </body>
  </html>
`

export const SPECIAL_PRODUCT_OPTIONS = [
  { label: 'All Licensed Products', value: 'ALL_LICENSED_PRODUCTS' },
  { label: 'All Active My OPSWAT Portal Users (12 months)', value: 'ALL_ACTIVE_USERS' },
  { label: 'OEM Customers', value: 'OEM_CUSTOMERS' }
]
