import { useContext, useMemo } from 'react'

import { Box, TableAdvanced, TemplateSection } from '@opswat/react-ui'

import { PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'

import { ReleaseInformationContext } from './interface'
import ReleaseInformationSearchFilter from './searchFilter'

const ReleaseInformationPage = () => {
  const {
    isFetching,
    control,
    columns,
    results,
    totalCount,
    pageInfo,
    sortInfo,
    setValue,
    handleSort,
    handleQuery,
    handlePagination
  } = useContext(ReleaseInformationContext)

  const FilterMemo = useMemo(
    () => <ReleaseInformationSearchFilter control={control} setValue={setValue} handleQuery={handleQuery} />,
    [control, setValue, handleQuery]
  )

  const TableMemo = useMemo(
    () => (
      <TableAdvanced
        isPagination
        isLoading={isFetching}
        columns={columns}
        data={results}
        total={totalCount}
        page={pageInfo.page}
        pageSize={pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePagination}
        order={sortInfo.order}
        orderBy={sortInfo.orderBy}
        onOrderChange={handleSort}
      />
    ),
    [columns, results, totalCount, isFetching, pageInfo, sortInfo]
  )

  return (
    <TemplateSection>
      <Box sx={{ my: 2.5 }}>
        {FilterMemo}
        {TableMemo}
      </Box>
    </TemplateSection>
  )
}

export default ReleaseInformationPage
