import { useMemo } from 'react'

import { TypographyDivider } from '@opswat/react-ui'

import ComposeEmailProvider from 'myopswat-admin/src/pages/MassNotificationPage/ComposeEmailPage/context'
import { notificationManagementMassNotificationPageURL } from 'myopswat-admin/src/routes'

const ComposeEmailContainer = () => {
  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Email Communication',
        path: notificationManagementMassNotificationPageURL
      },
      {
        label: 'Compose Email'
      }
    ]
  }, [])

  return (
    <>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />
      <ComposeEmailProvider />
    </>
  )
}

export default ComposeEmailContainer
