import { Grid, Typography } from '@opswat/react-ui'
import { ReactNode } from 'react'

interface GridViewProps {
  label: ReactNode
  labelSx?: object
  subLabel?: ReactNode
  value: ReactNode
  required?: boolean
  labelProps?: any
}

const GridView = ({ label, labelSx, subLabel, value, required, labelProps }: GridViewProps) => {
  return (
    <Grid container spacing={1} sx={{ marginBottom: '1rem' }}>
      <Grid item xs={2} md={2} lg={2} sx={labelSx}>
        {typeof label === 'string' ? (
          <Typography variant="subtitle2" component="label" {...labelProps}>
            {label}
            {required && (
              <Typography component="span" color="error">
                {'*'}
              </Typography>
            )}
          </Typography>
        ) : (
          label
        )}
        {subLabel}
      </Grid>
      <Grid item xs={10} md={10} lg={10}>
        {value}
      </Grid>
    </Grid>
  )
}

export default GridView
