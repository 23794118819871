import { customerManagementPageURL } from './customerManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const customerUsersPath = 'users'
export const customerUserIdPath = ':userId'
export const customerUserNewPath = 'new'
export const detailCustomerUserPath = 'detail'
export const editCustomerUserPath = 'edit'
export const tabCustomerUserPath = ':tabPath'
export const tabCustomerUserHistoryPath = 'history'
export const tabCustomerUserOrganizationsPath = 'organizations'
export const tabCustomerUserPreferencesPath = 'preferences'
export const tabCustomerUserGroupsPath = 'groups'

/** ----------------- ROUTE -------------------- */
export const customerManagementUserListPageURL = `${customerManagementPageURL}/${customerUsersPath}`
export const customerManagementNewUserPageURL = `${customerManagementUserListPageURL}/${customerUserNewPath}`
export const customerManagementUserDetailFlexPageURL = `${customerManagementUserListPageURL}/${customerUserIdPath}/${tabCustomerUserPath}`
export const customerManagementUserHistoryPageURL = `${customerManagementUserListPageURL}/${customerUserIdPath}/${tabCustomerUserHistoryPath}`
export const customerManagementUserOrganizationsPath = `${customerManagementUserListPageURL}/${customerUserIdPath}/${tabCustomerUserOrganizationsPath}`
export const customerManagementUserGroupsPageURL = `${customerManagementUserListPageURL}/${customerUserIdPath}/${tabCustomerUserGroupsPath}`
