import _map from 'lodash/map'

import { useCallback } from 'react'
import { Control, Controller, useForm, UseFormSetValue, useWatch } from 'react-hook-form'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

import { ORG_CUSTOMER_RELATIONSHIP, ORG_CUSTOMER_RELATIONSHIP_LABELS } from 'myopswat-admin/src/api/organization/types'

interface IProps {
  control: Control<any>
  setValue: UseFormSetValue<any>
  handleQuery: (name?: string, value?: any) => void
}

const OrganizationCustomersSearchFilter = ({ control, setValue, handleQuery }: IProps) => {
  const currentValues = useWatch({ control, name: 'filters' })

  const {
    control: controlFilter,
    watch: watchFilter,
    reset: resetFilter,
    setValue: setValueFilter
  } = useForm<any>({
    defaultValues: {
      relationship: []
    }
  })

  const handleApplyFilter = () => {
    const filters = {
      ...currentValues,
      relationship: watchFilter('relationship')
    }
    setValue('filters', filters)
    handleQuery()
  }

  const handleOnChangeCheckbox = useCallback((e: any, onChange: any, item: any, value: any) => {
    if (e.target.checked) {
      onChange([...value, item])
    } else {
      onChange(value.filter((status: any) => status !== item))
    }
  }, [])

  const renderTextFieldSearch = () => {
    return (
      <Controller
        name={`filters.q`}
        control={control}
        render={({ field: { value } }) => (
          <TextFieldSearch
            placeholder={'Search for name, account ID, opportunity ID, SSO ID...'}
            value={value}
            onChange={e => handleQuery('q', e.target.value)}
            onClearText={() => handleQuery('q', '')}
            sx={{
              minHeight: 'auto',
              '& .MuiInput-underline:before': { borderBottomColor: '#B7C3DC' }
            }}
          />
        )}
      />
    )
  }

  const renderCheckboxFilter = useCallback(
    (name: string, label: string, enumType: any, enumLabels: any) => {
      return (
        <Grid item container xs={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{label}</Typography>
          </Grid>
          {_map(Object.values(enumType), (item: any, idx: any) => {
            return (
              <Grid item xs={12} spacing={2} key={idx.toString()}>
                <Controller
                  name={name}
                  control={controlFilter}
                  render={({ field: { value, onChange } }: any) => (
                    <CheckboxWithLabel
                      label={
                        <Typography variant="subtitle2" textTransform="capitalize">
                          {enumLabels[item] || '--'}
                        </Typography>
                      }
                      checkboxProps={{
                        checked: watchFilter(name).includes(item),
                        onChange: (e: any) => handleOnChangeCheckbox(e, onChange, item, value),
                        sx: {
                          marginLeft: '12px'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            )
          })}
        </Grid>
      )
    },
    [controlFilter, watchFilter]
  )

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        marginBottom: 3
      }}
    >
      <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {renderTextFieldSearch()}
      </Grid>
      <Grid item xs={12} sm={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        <DropdownFilter
          resetOnSubmit={false}
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={1} alignItems="flex-start">
              {renderCheckboxFilter(
                'relationship',
                'Customer Relationship',
                ORG_CUSTOMER_RELATIONSHIP,
                ORG_CUSTOMER_RELATIONSHIP_LABELS
              )}
            </Grid>
          }
          onResetFilter={() => {
            resetFilter()
          }}
          onResetChange={() => {
            Object.entries(currentValues).forEach(([key, value]) => {
              setValueFilter(key, value)
            })
          }}
          onApply={handleApplyFilter}
        />
      </Grid>
    </Grid>
  )
}

export default OrganizationCustomersSearchFilter
