import React, { FC, useMemo, useState } from 'react'

import _get from 'lodash/get'

import { EyeHideIcon, EyeIcon } from '@opswat/react-icon'
import { Box, ButtonLoading, TextField, Typography, TypographyLink } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import ReCAPTCHA from 'react-google-recaptcha'

import { ILoginInput } from 'myopswat-admin/src/api/account/types'

interface IProps {
  children?: React.ReactNode
  handleLogin: (data: ILoginInput) => void
  isLoading?: boolean
  isShowCaptcha: boolean
}

const StepLogin: FC<IProps> = ({ handleLogin, isLoading, isShowCaptcha }) => {
  const [isAutoFilled, setIsAutoFilled] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [captcha, setCaptcha] = useState<string>('')

  const handleClickShowPassword = () => setShowPassword(show => !show)

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const handleChange = (value: any) => {
    setCaptcha(value)
  }

  const defaultValues = {
    email: '',
    password: ''
  }

  const schema = yup.object().shape({
    email: yup.string().trim().required('Email cannot be blank').email('Please enter the correct email structure'),
    password: yup.string().trim().required('Password cannot be blank')
  })

  const {
    control,
    trigger,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (data: ILoginInput) => {
    handleLogin({
      email: _get(data, 'email', ''),
      password: _get(data, 'password', ''),
      captchaToken: captcha
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const isSubmitDisabled = useMemo(() => {
    if (isShowCaptcha) return !captcha || !watch('email') || !watch('password')
    return !watch('email') || !watch('password')
  }, [isShowCaptcha, captcha, watch('email'), watch('password')])

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        minHeight: '500px',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Box>
          <Typography variant="subtitle1">Email</Typography>
          <Controller
            name="email"
            control={control}
            render={(cProps: any) => (
              <TextField
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value.toLowerCase())}
                size="small"
                fullWidth
                error={!!_get(errors, 'email', '')}
                helperText={_get(errors, 'email.message', '')}
                required
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13 && !isSubmitDisabled) {
                    handleSubmit(onSuccess, onFail)()
                  }
                  trigger('email')
                }}
                onBlur={() => {
                  trigger('email')
                }}
                onFocus={() => {
                  setIsAutoFilled(true)
                }}
                inputProps={{
                  readOnly: !isAutoFilled,
                  style: { textTransform: 'lowercase' }
                }}
              />
            )}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1">Password</Typography>

          <Controller
            name="password"
            control={control}
            render={(cProps: any) => (
              <TextField
                name="password"
                size="small"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  readOnly: !isAutoFilled,
                  endAdornment: (
                    <Box
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      sx={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    >
                      {showPassword ? <EyeIcon /> : <EyeHideIcon />}
                    </Box>
                  )
                }}
                error={!!_get(errors, 'password', '')}
                helperText={_get(errors, 'password.message', '')}
                required
                value={cProps.field.value}
                onFocus={() => {
                  setIsAutoFilled(true)
                }}
                onChange={(e: any) => cProps.field.onChange(e.target.value)}
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13 && !isSubmitDisabled) {
                    handleSubmit(onSuccess, onFail)()
                  }
                  trigger('password')
                }}
                onBlur={() => {
                  trigger('password')
                }}
              />
            )}
          />
          <TypographyLink
            variant="subtitle2"
            onClick={() => (window.location.href = `${process.env.REACT_APP_SSO_WEB_HOST}/resetPassword` || '/')}
          >
            Forgot Your Password?
          </TypographyLink>
        </Box>

        {isShowCaptcha && (
          <Box display="flex" justifyContent="center" mt={4}>
            <ReCAPTCHA
              style={{ display: 'inline-block' }}
              sitekey={process.env.REACT_APP_CAPTCHA_KEY || ''}
              onChange={value => handleChange(value)}
            />
          </Box>
        )}
      </Box>

      <Box>
        <Box>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              onClick: handleSubmit(onSuccess, onFail),
              disabled: isLoading || isSubmitDisabled,
              fullWidth: true
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoading}
          >
            Sign In
          </ButtonLoading>
        </Box>

        <Box my={2}>
          <Typography component="div" color="textSecondary" variant="subtitle2">
            Do not have an OPSWAT Account?&nbsp;
            <TypographyLink
              variant="subtitle2"
              onClick={() => (window.location.href = `${process.env.REACT_APP_SSO_WEB_HOST}/register` || '/')}
            >
              Register
            </TypographyLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default StepLogin
