import { TableLoading, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import { useAdminGroupQuery } from 'myopswat-admin/src/api/group'
import {
  customerManagementOrganizationListPageURL,
  organizationGroupsPath,
  opswatGroupListPageURL,
  editOrganizationGroupPath,
  detailOrganizationGroupPath
} from 'myopswat-admin/src/routes'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import GroupDetailView from './GroupDetailView'
import GroupEditView from './GroupEditView'
import {
  OPSWAT_ORGANIZATION_ID,
  OPSWAT_ORGANIZATION_NAME,
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT
} from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import { useLazyAdminBasicUsersQuery } from 'myopswat-admin/src/api/userManagement'

const OrganizationGroupDetail = () => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const { orgId: organizationId = '', groupId = '', mode = '' } = useParams()
  const orgId = isOpswatIncRoute ? OPSWAT_ORGANIZATION_ID : organizationId

  const { data: groupData, isFetching: isFetchingGroup } = useAdminGroupQuery(groupId, {
    refetchOnMountOrArgChange: true
  })
  const [getUsers, { data: userData }] = useLazyAdminBasicUsersQuery()

  const [group, setGroup] = useState()

  const navigate = useNavigate()

  const isRender = useMemo(() => {
    setGroup(groupData)
    return groupData && !isFetchingGroup
  }, [groupData, isFetchingGroup])

  const groupListPageURL = useMemo(
    () =>
      isOpswatIncRoute
        ? opswatGroupListPageURL
        : `${customerManagementOrganizationListPageURL}/${orgId}/${organizationGroupsPath}`,
    [isOpswatIncRoute, orgId]
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: isOpswatIncRoute ? OPSWAT_ORGANIZATION_NAME : 'System Management'
      },
      {
        label: 'Groups',
        path: groupListPageURL
      },
      {
        label: groupData?.name || '--'
      }
    ]
  }, [groupData, isOpswatIncRoute])

  const handleNavigate = (path: string) => {
    if (path !== window.location.pathname) {
      navigate(path)
    }
  }

  useEffect(() => {
    if (mode === editOrganizationGroupPath) {
      handleNavigate(`${groupListPageURL}/${groupId}/${editOrganizationGroupPath}`)
    } else {
      handleNavigate(`${groupListPageURL}/${groupId}/${detailOrganizationGroupPath}`)
    }
  }, [groupListPageURL])

  useEffect(() => {
    if (groupData && groupData.creatorId) {
      getUsers({
        filters: { ids: [groupData.creatorId] },
        pageInfo: {
          page: PAGE_DEFAULT,
          pageSize: PAGE_SIZE_DEFAULT
        }
      })
    }
  }, [groupData])

  const creatorData = useMemo(() => {
    if (userData && userData.totalCount > 0) {
      return userData.results[0]
    }
  }, [userData])

  const handleDataUpdate = (updatedData: any) => {
    setGroup(updatedData)
  }

  return (
    <TemplateSection>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && <TableLoading />}

      {isRender &&
        (mode === 'edit' ? (
          <GroupEditView groupData={group} handleDataUpdate={handleDataUpdate} />
        ) : (
          <GroupDetailView groupData={group} creatorData={creatorData} />
        ))}
    </TemplateSection>
  )
}

export default OrganizationGroupDetail
