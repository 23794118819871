import React, { useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { formatDatetime } from '@opswat/react-core'
import { CollapsibleTableAdvanced, Grid, TemplateSection, Typography } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { IHistoryDiffType } from 'myopswat-admin/src/api/history/types'
import { QueryPagination, QuerySortInfo } from 'myopswat-admin/src/api/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import EventLogsFiltersSection from './EventLogsFiltersSection'
import {
  useLazyAdminRoleObjectEventLogsQuery,
  useLazyAdminRoleObjectsComponentQuery
} from '../../../../api/roleObjectsManagement'
import _find from 'lodash/find'

interface IQuery {
  pageInfo?: QueryPagination
  sortInfo?: QuerySortInfo
  filters?: any
}

const RoleObjectsEvenLogsPage = () => {
  const listPermission = [
    {
      id: 1,
      name: 'None'
    },
    {
      id: 2,
      name: 'Read Only'
    },
    {
      id: 3,
      name: 'Full Access'
    }
  ]
  const [query, setQuery] = useState<IQuery>({
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    },
    sortInfo: {
      order: 'desc',
      orderBy: 'historyDate'
    },
    filters: {}
  })
  const [getRoleObjectComponents, { data: roleObjectComponentsData }] = useLazyAdminRoleObjectsComponentQuery()

  const [getRoleObjectEventLogsHistories, { data, isFetching: isLoading }] =
    useLazyAdminRoleObjectEventLogsQuery()

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    if (pageSize !== query.pageInfo?.pageSize) page = PAGE_DEFAULT
    setQuery(prevState => ({ ...prevState, pageInfo: { page, pageSize } }))
  }
  const roleObjectComponents = useMemo(() => {
    if (roleObjectComponentsData) {
      return roleObjectComponentsData
    }
    return []
  }, [roleObjectComponentsData])

  const tableData = useMemo(() => {
    return data?.results.map((item: any) => {
      const historyType = _get(item, 'historyType', '--')
      const historyReason = _get(item, 'historyUpdatedReason', '--')
      const reason = historyType === 'Changed' ? historyReason : '--'
      const event = historyType === 'Created' ? 'Added' : historyType
      let changes = _get(item, 'changes', '--')
      if (event == 'Added') {
        changes = changes.flatMap((item: any) => {
          if (item.field === "assignedPermissions") {
            const jsonString = item.new.replace(/'/g, '"');
            const parsedPermissions = jsonString.split(", ").map((item:any) => JSON.parse(item));
            return parsedPermissions.map((permission: any) => {
              const [role, value] = Object.entries(permission)[0];
              const perm = listPermission.find(item => item.id === value);
              return {
                field: `Default permission for ${role.replace(/\s/g, '')}`,
                old: null,
                new: _get(perm, 'name', '--')
              };
            });
          }
          return item;
        });
      }
      return {
        ...item,
        event,
        reason,
        changes
      }
    })
  }, [data, roleObjectComponents])

  const collapseData = useMemo(
    () =>
      _map(tableData, (columnData: any) =>
        _map(columnData.changes, (columnCollapseData: any) => ({
          ...columnCollapseData,
        }))
      ),
    [tableData, roleObjectComponents]
  )

  const renderTypographyColumn = (data: any, field: string) => {
    return <Typography variant="body2">{_get(data, field, '--')}</Typography>
  }

  const renderColumnImpacted = (data: any) => {
    const roleObject = _get(data, 'historyRoleObject', '--')
    return <Typography variant="body2">Role Object / {roleObject.name}</Typography>
  }

  const renderColumnDatetime = (data: any) => {
    return <Typography variant="body2">{formatDatetime(data.historyDate, DATE_FORMATS.DATE_TIME)}</Typography>
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: any) => renderTypographyColumn(data, 'event'),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Impacted',
        body: renderColumnImpacted,
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Reason',
        body: (data: any) => renderTypographyColumn(data, 'reason'),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Updated By',
        body: (data: any) => renderTypographyColumn(data, 'historyUpdatedBy.email'),
        style: { minWidth: 150, width: 150 }
      },
      {
        key: 'historyDate',
        header: 'Datetime',
        body: renderColumnDatetime,
        style: { minWidth: 200, width: 200 },
        isSortable: true
      }
    ]
  }, [])

  const handleDisplayValue = (value: any) => {
    if (value === null) {
      return null
    } else if (typeof value === 'boolean') {
      return value ? 'True' : 'False'
    } else {
      return value
    }
  }

  const handleRenderComponent = (value: any) => {
    if (!value) return null

    const component = _find(
              roleObjectComponents,
              (item: any) => _get(item, 'id') === value
            )
    return component.name
  }

  const renderCollapseColumnField = (data: IHistoryDiffType) => {
    let field = _get(data, 'field')
    if (field === 'code') field = 'Code'
    if (field === 'name') field = 'Name'
    if (field === 'permissions') field = 'Permissions'
    if (field === 'component') field = 'My OPSWAT Component'
    return <Typography variant="body2">{field}</Typography>
  }

  const renderCollapseColumnChange = (data: IHistoryDiffType) => {
    const field = _get(data, 'field')
    let value: any = _get(data, 'new')

    if (field === 'component') {
      value = handleRenderComponent(value)
    }
    if (field === 'permissions' && value) {
      value = value
        .split(',')
        .map((id:any) => {
          const trimmedId = id.trim();
          const permission = listPermission.find(item => item.id.toString() === trimmedId);
          return permission ? permission.name : null;
        })
        .filter(Boolean)
        .join(", ");
    }

    return <Typography variant="body2">{handleDisplayValue(value)}</Typography>
  }

  const renderCollapseColumnWas = (data: IHistoryDiffType) => {
    const field = _get(data, 'field')
    let value: any = _get(data, 'old')

    if (field === 'component') {
      value = handleRenderComponent(value)
    }

    if (field === 'permissions' && value) {
      value = value
        .split(',')
        .map((id:any) => {
          const trimmedId = id.trim();
          const permission = listPermission.find(item => item.id.toString() === trimmedId);
          return permission ? permission.name : null;
        })
        .filter(Boolean)
        .join(", ");
    }

    return <Typography variant="body2">{handleDisplayValue(value)}</Typography>
  }

  const collapsedColumnArray = useMemo(() => {
    return [
      // Empty cell for the purpose of alignment
      {
        header: '',
        body: () => <p></p>,
        style: { width: 80 }
      },
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => renderCollapseColumnField(data),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => renderCollapseColumnChange(data),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => renderCollapseColumnWas(data),
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [renderCollapseColumnChange])

  const handleFiltersChange = (filterData: any) => {
    setQuery(prevState => ({
      ...prevState,
      filters: filterData,
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  const handleSortChange = (orderBy?: any, order?: any) => {
    setQuery(prevState => ({
      ...prevState,
      sortInfo: { order, orderBy },
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  useEffect(() => {
    getRoleObjectEventLogsHistories(query)
  }, [query])

  useEffect(() => {
    getRoleObjectComponents()
  }, [getRoleObjectComponents])

  return (
    <TemplateSection>
      <EventLogsFiltersSection handleFiltersChange={handleFiltersChange} queryData={query} />

      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={tableData}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo?.page}
        pageSize={query.pageInfo?.pageSize}
        onPageChange={handlePaginationOnChange}
        order={query.sortInfo?.order}
        orderBy={query.sortInfo?.orderBy}
        onOrderChange={handleSortChange}
      />
    </TemplateSection>
  )
}

export default RoleObjectsEvenLogsPage
