import { customerManagementOrganizationListPageURL, customerOrganizationIdParam } from './organizationRoutes'

/** ----------------- PATHNAME -------------------- */
export const organizationRolesPath = 'roles'
export const organizationRoleIdParam = ':roleId'
export const organizationRoleNewPath = 'new'
export const detailOrganizationRolePath = 'detail'
export const editOrganizationRolePath = 'edit'
export const modeOrganizationRolePath = ':mode'

/** ----------------- ROUTE -------------------- */
export const customerManagementOrganizationRolesPageUrl = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${organizationRolesPath}`
export const customerManagementOrganizationRoleNewPageUrl = `${customerManagementOrganizationRolesPageUrl}/${organizationRoleNewPath}`
export const customerManagementOrganizationRoleDetailPageUrl = `${customerManagementOrganizationRolesPageUrl}/${organizationRoleIdParam}/${modeOrganizationRolePath}`
