import React from 'react'
import { Helmet } from 'react-helmet'

import DashboardUserPage from 'myopswat-admin/src/pages/DashboardPage/DashboardUserPage'

export default function DashboardUserContainer() {
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'User'}
        </title>
      </Helmet>
      <DashboardUserPage />
    </>
  )
}
