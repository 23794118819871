import React from 'react'

import _get from 'lodash/get'

import { TypographyDivider, TemplateSection, Alert, AlertTitle } from '@opswat/react-ui'

import SyncBlogPage from '../../../pages/ManageSyncPage/SyncBlogPage'
import { useEntrySyncUpMutation } from 'myopswat-admin/src/api/entry'
import { EntrySyncUpInput } from 'myopswat-admin/src/api/entry/types'

const ManageSyncBlogContainer = () => {
  const [entrySyncUp, { data: entrySyncUpData, isLoading: isLoadingEntrySyncUp, error: entrySyncUpError }] =
    useEntrySyncUpMutation()

  const handleEntrySyncUp = async (data: EntrySyncUpInput) => {
    try {
      await entrySyncUp(data).unwrap()
    } catch (error) {
      console.error(error)
    }
  }

  const isSuccess = _get(entrySyncUpData, 'success')
  const isError =
    _get(entrySyncUpData, 'errors.0.message') ||
    _get(entrySyncUpError, 'errors.0.message') ||
    _get(entrySyncUpError, 'message')

  return (
    <>
      <TemplateSection spacing={3}>
        <TypographyDivider label="OPSWAT Blog" />
        <SyncBlogPage handleEntrySyncUp={handleEntrySyncUp} isLoadingEntrySyncUp={isLoadingEntrySyncUp} />

        {(isSuccess || isError) && (
          <Alert variant="filled" severity={isSuccess ? 'success' : 'error'}>
            <AlertTitle>Notification</AlertTitle>
            {isSuccess ? 'Sync Blog successfully.' : 'Sync Blog has failed. Please try again later.'}
          </Alert>
        )}
      </TemplateSection>
    </>
  )
}

export default ManageSyncBlogContainer
