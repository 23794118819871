import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { dashboardFalseDetectionSubmissionAntivirusEngineNewPageURL } from 'myopswat-admin/src/routes'
import { useNavigate } from 'react-router-dom'
import {
  IFalseSubmissionAntivirusEngineFilter,
  IFalseSubmissionFilter
} from '../../../../api/analyzer/falseSubmission/types'
import _omit from 'lodash/omit'
import { useEffect } from 'react'
import _debounce from 'lodash/debounce'

interface FilterSectionProps {
  filters: IFalseSubmissionAntivirusEngineFilter
  onSearch: (searchData: IFalseSubmissionAntivirusEngineFilter) => void
}

const FalseSubmissionAntivirusEngineFilter = ({ filters, onSearch }: FilterSectionProps) => {
  const navigate = useNavigate()

  const { control, getValues, reset, watch } = useForm<IFalseSubmissionFilter>({
    mode: 'onChange',
    defaultValues: filters
  })

  const q = useWatch({
    control: control,
    name: 'q'
  })

  useEffect(() => {
    const handleSearch = _debounce(() => {
      const searchData = _omit(getValues(), 'all')
      onSearch(searchData)
    }, 300)

    handleSearch()
    return () => {
      handleSearch.cancel()
    }
  }, [q])

  useEffect(() => {
    if (watch('all') === true) {
      reset()
    }
  }, [watch('all')])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextFieldSearch
              placeholder={'Search by Engine Name, Vendor Name, Detection, Heuristic Detection...'}
              value={value}
              onChange={e => onChange(e.target.value)}
              onClearText={() => onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(dashboardFalseDetectionSubmissionAntivirusEngineNewPageURL)
          }}
          disabled={false}
        >
          New Antivirus Engine
        </Button>
      </Grid>
    </Grid>
  )
}

export default FalseSubmissionAntivirusEngineFilter
