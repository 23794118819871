import { DIALOGS } from '@myopswat/common'
import { Box, ButtonLoading, DialogAdvanced, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useAdminEvaluationRequestApproveMutation } from 'myopswat-admin/src/api/evaluation-request'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  refetchEvaluationRequests: () => void
}

const DialogEvaluation: FC<IProps> = ({ refetchEvaluationRequests }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [activationKey, setActivationKey] = useState('')
  const [adminEvaluationRequestApprove, { isLoading }] = useAdminEvaluationRequestApproveMutation()
  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.EVALUATION]: false,
        [`${DIALOGS.EVALUATION}_DATA`]: ''
      })
    )
  }
  const handleSubmit = async () => {
    try {
      if (activationKey) {
        const evaluationId = _get(_get(dialogType, `${DIALOGS.EVALUATION}_DATA`), 'id', '')
        adminEvaluationRequestApprove({
          id: evaluationId,
          activationKey
        })
          .unwrap()
          .then(async (response: any) => {
            if (response.success) {
              enqueueSnackbar(translate('approveEvaluationSuccessfully'), { variant: 'success' })
              handleClose()
              refetchEvaluationRequests()
            } else {
              if (_get(response, 'errors.0.message')) {
                enqueueSnackbar(_get(response, 'errors.0.message', translate('approveEvaluationFailed')), {
                  variant: 'error'
                })
              }
            }
          })
          .catch(() => {
            enqueueSnackbar(translate('approveEvaluationFailed'), { variant: 'error' })
          })
      }
    } catch (error) {
      enqueueSnackbar(translate('approveEvaluationFailed'), { variant: 'error' })
    }
  }
  return (
    <DialogAdvanced
      title={'Evaluate'}
      open={_get(dialogType, DIALOGS.EVALUATION, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ py: 0.5, minWidth: '80px' }}>
              Input activation key
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              sx={{ minHeight: '37px' }}
              onChange={e => {
                setActivationKey(e.target.value)
              }}
              fullWidth
            />
          </Grid>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                disabled: isLoading || _isEmpty(activationKey),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
    />
  )
}
export default DialogEvaluation
