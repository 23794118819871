import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import {
  IAdminReleaseInput,
  ILatestReleasesInput,
  IProductFilterInput,
  IReleaseInput,
  ProductInputType,
  ProductReleaseFiltersInput
} from './types'

export const productQueries = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    products: builder.query<any, IProductFilterInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminProductFiltersInput) {
            adminProducts(filters: $filters) {
              id
              parentIds
              avatar
              thumbnail
              slug
              name
              shortName
              codeName
              tags
              description
              documentation
              note
              platforms
              licenseTypes
              solutions {
                id
                name
              }
              downloadable
              updatedAt
              createdAt
              hide
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    basicProducts: builder.query<any, IProductFilterInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminProductFiltersInput) {
            adminProducts(filters: $filters) {
              id
              name
              hide
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    productDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query ($filters: AdminProductFiltersInput) {
            adminProduct(id: "${id}") {
              id
              avatar
              thumbnail
              slug
              name
              shortName
              codeName
              description
              documentation
              note
              licenseTypes
              solutionIds
              type
              tags
              blogTags
              asIds
              releaseDate
              updatedAt
              createdAt
              downloadable
              parentIds
              resources
              hide
              releaseMetadata
              rmId
              releaseDate
              licensedProductsDisplay
              showLatestVersion
              icon
              supportCasePlatforms
            }
            adminProducts(filters: $filters) {
              id
              name
              asIds
            }
            solutions {
              id
              name
            }
          }
        `,
        variables: {
          filters: {}
        }
      })
    }),
    productsSolutions: builder.query<any, void>({
      query: () => ({
        document: gql`
          query ($filters: AdminProductFiltersInput) {
            adminProducts(filters: $filters) {
              id
              name
              asIds
            }
            solutions {
              id
              name
            }
          }
        `,
        variables: {
          filters: {}
        }
      })
    }),
    solutions: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            solutions {
              id
              name
            }
          }
        `
      })
    }),
    productRelease: builder.query<any, ProductReleaseFiltersInput>({
      query: filters => ({
        document: gql`
          query latestReleases($filters: ProductReleaseFiltersInput) {
            latestReleases(filters: $filters) {
              id
              name
              product {
                id
                name
              }
              rmProductName
              rmProductId
              platformId
              platform
              version
              semVer
              date
              releaseDate
              md5
              sha1
              sha256
              link
              size
              packageName
              epochTime
              displayOnly
              show
              isDefault
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    productUpdate: builder.mutation<any, ProductInputType>({
      query: input => ({
        document: gql`
          mutation ($input: AdminProductUpdateInput!) {
            adminProductUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    productCreate: builder.mutation<any, ProductInputType>({
      query: input => ({
        document: gql`
          mutation ($input: AdminProductCreateInput!) {
            adminProductCreate(input: $input) {
              product {
                id
              }
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    latestReleases: builder.query<any, ILatestReleasesInput>({
      query: filters => ({
        document: gql`
          query latestReleases($filters: ReleaseFiltersInput) {
            latestReleases(filters: $filters) {
              id
              name
              slug
              release {
                id
                name
                version
                semVer
                releaseDate
              }
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    publicProductReleases: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            publicProductReleases {
              name
              releases {
                md5
                name
                id
                releaseDate
                sha1
                sha256
                link
                version
              }
            }
          }
        `
      })
    }),
    releases: builder.query<any, IReleaseInput>({
      query: ({ productId, q }) => ({
        document: gql`
          query releases($filters: ReleaseFiltersInput) {
            releases(productId: "${productId}", filters: $filters)  {
              id
              name
              tags
              assets
              semVer
              versionSuffix
              version
              epochTime
              platformIds
              releaseDate
              note
              date
            }
          }
        `,
        variables: {
          filters: {
            q
          }
        }
      })
    }),
    adminReleaseUpdate: builder.mutation<any, Partial<IAdminReleaseInput>>({
      query: payload => ({
        document: gql`
          mutation ($input: AdminReleaseUpdateInput!) {
            adminReleaseUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    adminReleaseCreate: builder.mutation<any, IAdminReleaseInput>({
      query: payload => ({
        document: gql`
          mutation ($input: AdminReleaseCreateInput!) {
            adminReleaseCreate(input: $input) {
              release {
                id
                semVer
              }
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    basicActivationServerProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            activationServerProducts {
              id
              name
              hide
            }
          }
        `
      })
    }),
    productType: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            allProductType
          }
        `
      })
    }),
    addProductType: builder.mutation<any, any>({
      query: payload => ({
        document: gql`
          mutation ($input: AddProductTypeInput!) {
            addProductType(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    emailSubscriptionProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            emailSubscriptionProducts {
              id
              name
              releaseMetadata
            }
          }
        `
      })
    }),
    emailSubscriptionProductsWithDetail: builder.query<any, void>({
      query: () => ({
        document: gql`
          query emailSubscriptionProducts($includeDetails: Boolean) {
            emailSubscriptionProducts(includeDetails: $includeDetails) {
              id
              name
              parents
              releaseNote
              releaseMetadata
              latestReleases {
                semVer
              }
            }
          }
        `,
        variables: {
          includeDetails: true
        }
      })
    }),
    productOptions: builder.query<any, IProductFilterInput>({
      query: ({ q, tags, solutionIds, excludedTags }) => ({
        document: gql`
          query products($filters: ProductFiltersInput, $displayMode: String) {
            products(filters: $filters, displayMode: $displayMode) {
              id
              name
            }
          }
        `,
        variables: {
          filters: {
            q,
            tags,
            solutionIds,
            excludedTags
          },
          displayMode: 'nested'
        }
      })
    })
  })
})

export const {
  useProductsQuery,
  useBasicProductsQuery,
  useLazyBasicProductsQuery,
  useLazyProductsQuery,
  useSolutionsQuery,
  useProductUpdateMutation,
  useProductDetailQuery,
  useLazyProductDetailQuery,
  useProductsSolutionsQuery,
  useProductReleaseQuery,
  useProductCreateMutation,
  useLazyLatestReleasesQuery,
  useLazyReleasesQuery,
  useAdminReleaseUpdateMutation,
  useAdminReleaseCreateMutation,
  useLazyProductOptionsQuery,
  useBasicActivationServerProductsQuery,
  useLazyBasicActivationServerProductsQuery,
  useEmailSubscriptionProductsQuery,
  useLazyEmailSubscriptionProductsQuery,
  useLazyEmailSubscriptionProductsWithDetailQuery,
  useProductTypeQuery,
  useAddProductTypeMutation
} = productQueries
