import React from 'react'

import _difference from 'lodash/difference'
import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'

import { useTypedSelector } from 'myopswat-admin/src/store'

import { PAGE_PERMISSIONS } from '../../routes/handleRoutes'

export function withPermission(Component: any, path: string) {
  function PageWithPermission() {
    const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

    const userPermissions = _get(profileData, 'permissions', [])

    const pagePermissions = PAGE_PERMISSIONS[`${path}`] ?? []

    const hasPermission = _difference(pagePermissions, userPermissions).length === 0

    const showWarning = () => {
      enqueueSnackbar('You do not have permission to perform this action.', {
        variant: 'error',
        autoHideDuration: 5000
      })
      return <></>
    }

    return hasPermission ? React.useMemo(() => Component, []) : showWarning()
  }

  return <PageWithPermission />
}
