import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import _map from 'lodash/map'
import { AdminOrganizationFilter } from 'myopswat-admin/src/api/organizationManagement/types'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ORG_MANAGEMENT_FILTER, CUSTOMER_MANAGEMENT_FILTER_LABELS } from '../../CustomerManagement/constants'

interface FilterSectionProps {
  filters: AdminOrganizationFilter
  onSearch: (searchData: AdminOrganizationFilter) => void
  onCreate?: () => void
}

const FilterSection = ({ filters, onSearch, onCreate }: FilterSectionProps) => {
  const { t: translate } = useTranslation()
  const defaultFilter = {
    q: '',
    usersOf: []
  }

  const {
    control,
    reset,
    watch,
    getValues,
    formState: { dirtyFields }
  } = useForm<AdminOrganizationFilter>({
    mode: 'onChange',
    defaultValues: defaultFilter,
    values: filters
  })
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isCreateOrganizationDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('add_organization')
    }
    return false
  }, [profileData])

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const q = watch('q')
  const usersOf = watch('usersOf')

  const handleSearch = useCallback(() => {
    const searchData = {
      ...getValues(),
      usersOf: usersOf,
      q: q
    }
    onSearch(searchData)
  }, [q, usersOf])

  useEffect(() => {
    if (isChangedTextSearch) {
      const _search = _debounce(handleSearch, 300)

      _search()

      return () => {
        _search.cancel()
      }
    }
  }, [isChangedTextSearch, handleSearch])

  const handleApplyFilter = useCallback(() => {
    handleSearch()
  }, [handleSearch])

  const renderCheckboxFilter = useCallback(
    (name: any, label: string, enumType: any, enumLabels: any) => {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{label}</Typography>
          </Grid>
          {_map(Object.values(enumType), (item: any, idx: any) => {
            return (
              <Grid item xs={12} spacing={2} key={idx.toString()}>
                <Controller
                  name={name}
                  control={control}
                  render={({ field: { value, onChange } }: any) => (
                    <CheckboxWithLabel
                      label={
                        <Typography variant="subtitle2" textTransform="capitalize">
                          {enumLabels[item] || '--'}
                        </Typography>
                      }
                      checkboxProps={{
                        checked: watch(name).includes(item),
                        onChange: (e: any) => {
                          if (e.target.checked) {
                            onChange([...value, item])
                          } else {
                            onChange(value.filter((status: any) => status !== item))
                          }
                        },
                        sx: {
                          marginLeft: '12px'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            )
          })}
        </>
      )
    },
    [control, watch]
  )

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterOrgName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs="auto">
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <DropdownFilter
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item container xs={6} spacing={2}>
                    {renderCheckboxFilter(
                      'usersOf',
                      'Organization(s) of',
                      ORG_MANAGEMENT_FILTER,
                      CUSTOMER_MANAGEMENT_FILTER_LABELS
                    )}
                  </Grid>
                </Grid>
              }
              onResetFilter={() => {
                reset({...defaultFilter, q})
              }}
              onResetChange={() => {
                reset({...filters, q})
              }}
              onApply={handleApplyFilter}
            />
          </Grid>
          <Grid item xs="auto">
            <Button onClick={onCreate} variant="contained" color="primary" disabled={isCreateOrganizationDisabled}>
              New Organization
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FilterSection
