import React, { FC, useState } from 'react'

import { TemplateFormLogin, TemplateSection, Box, Grid, Typography } from '@opswat/react-ui'

import { OpswatIcon } from '@opswat/react-icon'

import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'
import EmailSent from './EmailSent'
import RequestEmail from './RequestEmail'
import { IAccountResendInput } from 'myopswat-admin/src/api/account/types'

interface IProps {
  children?: React.ReactNode
  isSuccess: boolean | undefined
  isLoading: boolean
  handleAccountResend: (data: IAccountResendInput) => void
  errorMessage?: string
}

const ResendEmailPage: FC<IProps> = ({ isLoading, handleAccountResend, errorMessage, isSuccess }) => {
  const [step, setStep] = useState<string>('request')

  return (
    <TemplateFormLogin background={imageBg}>
      <TemplateSection spacing={4}>
        <Box>
          <OpswatIcon />
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Create your OPSWAT Account</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Manage multiple OPSWAT products remotely, improving productivity flows, providing better visibility
                    between team members and more.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              {step === 'request' && (
                <RequestEmail
                  setStep={setStep}
                  isSuccess={isSuccess}
                  isLoading={isLoading}
                  handleAccountResend={handleAccountResend}
                  errorMessage={errorMessage}
                />
              )}
              {step === 'sent' && <EmailSent />}
            </Grid>
          </Grid>
        </Box>
      </TemplateSection>
    </TemplateFormLogin>
  )
}

export default ResendEmailPage
