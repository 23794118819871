import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  Typography,
  TypographyLinkClamp
} from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { useAdminGroupsQuery } from 'myopswat-admin/src/api/group'
import { AdminGroupQueryInput } from 'myopswat-admin/src/api/group/types'
import {
  OPSWAT_ORGANIZATION_ID,
  PAGE_DEFAULT,
  PAGE_SIZES_DEFAULT,
  PAGE_SIZE_DEFAULT
} from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import { detailOrganizationGroupPath, detailUserGroupPath, opswatGroupListPageURL } from 'myopswat-admin/src/routes'
import { customerManagementOrganizationListPageURL, organizationGroupsPath } from 'myopswat-admin/src/routes'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface OrganizationGroupsProps {
  userId: string
}

const OrganizationGroups = ({ userId }: OrganizationGroupsProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const filters = useMemo(() => {
    if (userId)
      return {
        organizationId: OPSWAT_ORGANIZATION_ID,
        userId: userId
      }

    return {
      organizationId: OPSWAT_ORGANIZATION_ID
    }
  }, [userId, OPSWAT_ORGANIZATION_ID])

  const [query, setQuery] = useState<AdminGroupQueryInput>({
    filters: filters,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const { data, isFetching } = useAdminGroupsQuery(query, {
    refetchOnMountOrArgChange: true
  })
  const navigate = useNavigate()

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const orgGroups = useMemo(() => {
    if (_isEmpty(data)) {
      return {
        totalCount: 0,
        results: []
      }
    }
    return data
  }, [data])

  const onClickDetailHandler = useCallback(
    (data: any) => () => {
      const detailGroupPageURL = isOpswatIncRoute
        ? `${opswatGroupListPageURL}/${data.id}/${detailUserGroupPath}`
        : `${customerManagementOrganizationListPageURL}/${OPSWAT_ORGANIZATION_ID}/${organizationGroupsPath}/${data.id}/${detailOrganizationGroupPath}`
      navigate(detailGroupPageURL)
    },
    [location, OPSWAT_ORGANIZATION_ID]
  )

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => (
          <TypographyLinkClamp onClick={onClickDetailHandler(data)}>{data.name || '--'}</TypographyLinkClamp>
        ),
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Description',
        body: (data: any) => <Typography variant="body2">{data.description || '--'}</Typography>,
        style: { minWidth: 500, width: 500 }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={onClickDetailHandler(data)}>View Detail</ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  const tableData = useMemo(() => {
    return _map(orgGroups.results, (group: any) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      createdAt: group.createdAt,
      updatedAt: group.updatedAt,
      organizationName: group.organization.name
    }))
  }, [orgGroups])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery((statePrev: any) => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  return (
    <TableAdvanced
      columns={columns}
      isLoading={isLoading}
      data={tableData}
      isPagination
      total={orgGroups.totalCount}
      page={query.pageInfo.page}
      pageSize={query.pageInfo.pageSize}
      pageSizes={PAGE_SIZES_DEFAULT}
      onPageChange={handlePaginationOnChange}
    />
  )
}
export default OrganizationGroups
