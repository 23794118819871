import { FC, useEffect, useMemo } from 'react'

import _debounce from 'lodash/debounce'

import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'

import { IReleaseInput } from 'myopswat-admin/src/api/product/types'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { DIALOGS } from '@myopswat/common'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { Controller, useForm } from 'react-hook-form'

interface IProps {
  getReleases: (params: IReleaseInput) => void
}

const BoxReleaseListFilter: FC<IProps> = ({ getReleases }) => {
  const dispatch = useAppDispatch()
  const { productId = '' } = useParams()
  const { t: translate } = useTranslation()
  const searchParams = new URLSearchParams(location.search)
  const qParams = searchParams.get('q') || ''

  const defaultValues = {
    q: qParams
  }

  const { control, watch } = useForm<any>({
    defaultValues: defaultValues
  })

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isCreateReleaseDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('add_release')
    }
    return false
  }, [profileData])

  useEffect(() => {
    const handleSearch = _debounce(() => {
      getReleases({
        productId,
        q: watch('q')
      })
    }, 300)

    handleSearch()

    return () => {
      handleSearch.cancel()
    }
  }, [watch('q')])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterReleaseSemVersion') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          onClick={() =>
            dispatch(
              toggleDialogs({
                [DIALOGS.RELEASE_PRODUCT]: true,
                [`${DIALOGS.RELEASE_PRODUCT}_TITLE`]: 'New Release'
              })
            )
          }
          color="primary"
          disabled={isCreateReleaseDisabled}
        >
          New Release
        </Button>
      </Grid>
    </Grid>
  )
}

export default BoxReleaseListFilter
