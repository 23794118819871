import { RefreshIcon } from '@opswat/react-icon'
import { CircularLoading, Divider, Grid, IconButton, Typography } from '@opswat/react-ui'
import {
  useLazyAdminDashboardUserStatusQuery,
  useLazyAdminDashboardUsersActiveQuery,
  useLazyAdminDashboardUsersNewQuery
} from 'myopswat-admin/src/api/dashboard'
import { useCallback, useEffect, useState } from 'react'

import NewUsersChart, { INewUsersChartProps } from '../Charts/NewUsersChart'
import UsersActiveChart, { IUsersActiveChartProps } from '../Charts/UsersActiveChart'

interface IUserStatusQuery {
  dashboardType: string
  timeRange: string
  viewBy: string
}

const defaultActiveUsersQuery = {
  dashboardType: 'ACTIVE_USERS',
  timeRange: '6',
  viewBy: 'MONTH'
}

const defaultNewUsersQuery = {
  dashboardType: 'NEW_USERS',
  timeRange: '6',
  viewBy: 'MONTH'
}

const activeUserViewByOptions = [
  {
    label: 'DAU',
    value: 'DAY'
  },
  {
    label: 'WAU',
    value: 'WEEK'
  },
  {
    label: 'MAU',
    value: 'MONTH'
  }
]

const newUserViewByOptions = [
  {
    label: 'Daily',
    value: 'DAY'
  },
  {
    label: 'Weekly',
    value: 'WEEK'
  },
  {
    label: 'Monthly',
    value: 'MONTH'
  }
]

const timeRangeOptions = [
  {
    label: '1 MONTH',
    value: '1'
  },
  {
    label: '3 MONTHS',
    value: '3'
  },
  {
    label: '6 MONTHS',
    value: '6'
  },
  {
    label: '1 YEAR',
    value: '12'
  },
  {
    label: '3 YEARS',
    value: '36'
  },
  {
    label: 'ALL',
    value: 'ALL'
  }
]

const UserStatusDashboard = () => {
  const [activeUsersQuery, setActiveUsersQuery] = useState<IUserStatusQuery>(defaultActiveUsersQuery)
  const [newUsersQuery, setNewUsersQuery] = useState<IUserStatusQuery>(defaultNewUsersQuery)
  const [getUserStatus, { data: userStatus, isFetching: isUserStatusFetching }] = useLazyAdminDashboardUserStatusQuery()
  const [getUserActive, { data: activeUsers, isFetching: isUserActiveFetching }] =
    useLazyAdminDashboardUsersActiveQuery()
  const [getUserNew, { data: newUsers, isFetching: isUserNewFetching }] = useLazyAdminDashboardUsersNewQuery()

  const handleActiveUsersSelectChange: IUsersActiveChartProps['onSelectChange'] = data => {
    if (data.type == 'VIEW_BY') {
      setActiveUsersQuery(prev => ({ ...prev, viewBy: data.value }))
    } else if (data.type == 'TIME_RANGE') {
      setActiveUsersQuery(prev => ({ ...prev, timeRange: data.value }))
    }
  }

  const handleNewUsersSelectChange: INewUsersChartProps['onSelectChange'] = data => {
    if (data.type == 'VIEW_BY') {
      setNewUsersQuery(prev => ({ ...prev, viewBy: data.value }))
    } else if (data.type == 'TIME_RANGE') {
      setNewUsersQuery(prev => ({ ...prev, timeRange: data.value }))
    }
  }

  const refreshData = useCallback(() => {
    getUserStatus()
    getUserActive(activeUsersQuery)
    getUserNew(newUsersQuery)
  }, [activeUsersQuery, newUsersQuery])

  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    getUserActive(activeUsersQuery)
  }, [activeUsersQuery])

  useEffect(() => {
    getUserNew(newUsersQuery)
  }, [newUsersQuery])

  const isFetching = isUserStatusFetching && isUserActiveFetching && isUserNewFetching

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography>Current (Real-time) report</Typography>
            <IconButton onClick={refreshData}>
              {isFetching ? <CircularLoading /> : <RefreshIcon color="#32AAFF" />}
            </IconButton>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              textAlign="center"
              color="primary"
              sx={{
                fontSize: '32px'
              }}
              paragraph
            >
              {userStatus?.data?.totalUsers?.toLocaleString()}
            </Typography>
            <Typography textAlign="center">Total Users</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              textAlign="center"
              color="primary"
              sx={{
                fontSize: '32px'
              }}
              paragraph
            >
              {userStatus?.data?.currentActiveUsers?.toLocaleString()}
            </Typography>
            <Typography textAlign="center" gutterBottom>
              Active Users
            </Typography>
            <Typography
              textAlign="center"
              variant="body2"
              sx={{ fontSize: '12px', fontStyle: 'italic' }}
              color="textSecondary"
            >
              (User has logged-on My OPSWAT at least once in the last 180 days)
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}>
        <Typography>By Time Period Report</Typography>
      </Grid>

      <Grid item xs={12}>
        <UsersActiveChart
          seriesData={activeUsers?.data?.series}
          categoriesData={activeUsers?.data?.categories}
          viewByOptions={activeUserViewByOptions}
          timeRangeOptions={timeRangeOptions}
          defaultViewByValue={defaultActiveUsersQuery.viewBy}
          defaultTimeRangeValue={defaultActiveUsersQuery.timeRange}
          onSelectChange={handleActiveUsersSelectChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <NewUsersChart
          seriesData={newUsers?.data?.series}
          categoriesData={newUsers?.data?.categories}
          viewByOptions={newUserViewByOptions}
          timeRangeOptions={timeRangeOptions}
          defaultViewByValue={defaultNewUsersQuery.viewBy}
          defaultTimeRangeValue={defaultNewUsersQuery.timeRange}
          onSelectChange={handleNewUsersSelectChange}
        />
      </Grid>
    </Grid>
  )
}

export default UserStatusDashboard
