import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import _map from 'lodash/map'

import { FormControlLabel, Radio, RadioGroup, Typography } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  disabled: boolean
  options: any[]
}

const ComposeRadioGroup: FC<IProps> = ({ options, disabled, ...rest }: IProps) => {
  return (
    <RadioGroup {...rest}>
      {_map(options, option => (
        <FormControlLabel
          key={option.value}
          disabled={disabled}
          value={option.value}
          control={<Radio size="small" />}
          label={<Typography variant="subtitle2">{option.label}</Typography>}
        />
      ))}
    </RadioGroup>
  )
}

export default ComposeRadioGroup
