import {
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationGroupPageURL,
  myOrganizationPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL
} from './myOrganizationRoutes'

import {
  dashboardFalseDetectionSubmissionListURL,
  dashboardNPSSurveyPageURL,
  dashboardPageURL,
  dashboardUserPageURL
} from './dashboardRoutes'

import { approvalManagementEvaluationRequestsPageURL, approvalManagementPageURL } from './approvalManagementRoutes'
import {
  customerManagementOrganizationListPageURL,
  customerManagementOrganizationNewPageURL,
  customerManagementPageURL,
  customerManagementUserListPageURL
} from './customerManagementRoutes'
import { accessRestrictedPageUrl } from './errorRoutes'
import {
  integrationManagementInternalAppListPageURL,
  integrationManagementPageURL
} from './integrationManagementRoutes'
import { myAccountPageURL } from './myAccountRoutes'
import {
  notificationManagementComposeEmailBaseURL,
  notificationManagementComposeEmailRouterURL,
  notificationManagementMassNotificationPageURL,
  notificationManagementPageURL,
  notificationManagementSystemSubscribersPageURL
} from './notificationManagement'
import {
  activePageURL,
  changePasswordPageURL,
  confirmEmailPageURL,
  loginPageURL,
  organizationAcceptInvitationURL,
  registerPageURL,
  resendEmailPageURL,
  resetPasswordPageURL,
  verifyOTPPageURL
} from './ssoRoutes'
import { syncingManagementOpswatBlogPageURL, syncingManagementPageURL } from './syncingManagementRoutes'
import {
  systemManagementPageURL,
  systemManagementProductListPageURL,
  systemManagementReleaseInformationEventLogsPageURL,
  systemManagementReleaseInformationPageURL,
  systemManagementSettingListPageURL
} from './systemManagementRoutes'
import {
  opswatGroupDetailPageURL,
  opswatGroupListPageURL,
  opswatOrganizationPageURL,
  opswatRoleDetailPageURL,
  opswatRoleListPageURL,
  opswatUserDetailFlexPageURL,
  opswatUserListPageURL,
  userManagementPageURL,
  userManagementPermissionListPageURL
} from './userManagementRoutes'
import {
  customerManagementRoleObjectsEventLogsListPageURL,
  customerManagementRoleObjectsListPageURL
} from './customerManagementRoutes/roleObjectsRoutes'

/** ----------------- TITLE -------------------- */
export const PageTitle = {
  [loginPageURL]: 'Login',
  [registerPageURL]: 'Register',
  [resetPasswordPageURL]: 'Reset Password',
  [changePasswordPageURL]: 'Change Password',
  [confirmEmailPageURL]: 'Confirm Email',
  [resendEmailPageURL]: 'Resend Email',
  [verifyOTPPageURL]: 'Verify OTP',
  [activePageURL]: 'Active',
  [organizationAcceptInvitationURL]: 'Accepted Invite',
  [dashboardPageURL]: 'Dashboard',
  [dashboardNPSSurveyPageURL]: 'NPS Survey',
  [myAccountPageURL]: 'My Account',
  [userManagementPageURL]: 'User Management',
  [opswatOrganizationPageURL]: 'OPSWAT Inc Org',
  [opswatUserListPageURL]: 'OPSWAT Inc Users',
  [opswatGroupListPageURL]: 'OPSWAT Inc Groups',
  [opswatRoleListPageURL]: 'OPSWAT Inc Roles',
  [userManagementPermissionListPageURL]: 'Permissions',
  [syncingManagementPageURL]: 'Syncing Management',
  [syncingManagementOpswatBlogPageURL]: 'OPSWAT Blog',
  [notificationManagementPageURL]: 'Notification Management',
  [notificationManagementSystemSubscribersPageURL]: 'System Subscribers',
  [notificationManagementMassNotificationPageURL]: 'Email Communication',
  [notificationManagementComposeEmailBaseURL]: 'Compose Email',
  [integrationManagementPageURL]: 'Integration Management',
  [integrationManagementInternalAppListPageURL]: 'Internal Applications',
  [approvalManagementPageURL]: 'Approval Management',
  [approvalManagementEvaluationRequestsPageURL]: 'Evaluation Requests',
  [customerManagementPageURL]: 'Customer Management',
  [customerManagementUserListPageURL]: 'Users',
  [customerManagementOrganizationListPageURL]: 'Organizations',
  [dashboardFalseDetectionSubmissionListURL]: 'False Detection Submissions',
  [systemManagementPageURL]: 'System Management',
  [systemManagementProductListPageURL]: 'Products',
  [myOrganizationPageURL]: 'My Organization',
  [myOrganizationGeneralInfoPageURL]: 'General Information (My Organization)',
  [myOrganizationGroupPageURL]: 'Groups (My Organization)',
  [myOrganizationUsersPageURL]: 'Users (My Organization)',
  [myOrganizationRolesPageURL]: 'Application Roles (My Organization)',
  [myOrganizationSecurityPageURL]: 'Security (My Organization)',
  [myOrganizationEventHistoryPageURL]: 'Event History (My Organization)',
  [customerManagementOrganizationNewPageURL]: 'New Organization',
  [dashboardUserPageURL]: 'User',
  [systemManagementSettingListPageURL]: 'Settings',
  [accessRestrictedPageUrl]: 'Access Restricted',
  [systemManagementReleaseInformationPageURL]: 'Release Information'
}

export const PAGE_PERMISSIONS = {
  [opswatOrganizationPageURL]: ['view_organization'],
  [opswatUserListPageURL]: ['view_user'],
  [opswatGroupListPageURL]: ['view_group'],
  [opswatRoleListPageURL]: ['view_role'],
  [userManagementPermissionListPageURL]: ['view_permission'],
  [syncingManagementOpswatBlogPageURL]: ['view_entry'],
  [notificationManagementSystemSubscribersPageURL]: ['view_subscriber'],
  [integrationManagementInternalAppListPageURL]: ['view_integratedapp'],
  [approvalManagementEvaluationRequestsPageURL]: ['view_evaluationrequest'],
  [customerManagementUserListPageURL]: ['view_user'],
  [customerManagementOrganizationListPageURL]: ['view_organization'],
  [dashboardFalseDetectionSubmissionListURL]: ['view_falsesubmission'],
  [systemManagementProductListPageURL]: ['view_product'],
  [systemManagementSettingListPageURL]: ['view_generalsettings'],
  [opswatUserDetailFlexPageURL]: ['view_user'],
  [opswatGroupDetailPageURL]: ['view_group'],
  [opswatRoleDetailPageURL]: ['view_role'],
  [dashboardNPSSurveyPageURL]: ['dashboard_survey'],
  [notificationManagementMassNotificationPageURL]: ['view_massmail'],
  [notificationManagementComposeEmailRouterURL]: ['view_massmail'],
  [systemManagementReleaseInformationPageURL]: ['view_release_description'],
  [systemManagementReleaseInformationEventLogsPageURL]: ['view_release_description'],
  [customerManagementRoleObjectsListPageURL]: ['view_role_object'],
  [customerManagementRoleObjectsEventLogsListPageURL]: ['view_role_object']
}
