import _template from 'lodash/template'
import _unescape from 'lodash/unescape'

import { useWatch } from 'react-hook-form'

import { Box, Grid, Typography } from '@opswat/react-ui'

import { useContext } from 'react'
import { ComposeEmailContext, EMAIL_TEMPLATE } from '../interface'

const ComposeEmailPreview = () => {
  const { control } = useContext(ComposeEmailContext)

  const renderPreview = _template(EMAIL_TEMPLATE)
  const title = useWatch({ control, name: 'title' })
  const content = useWatch({ control, name: 'content' })

  return (
    <Grid container rowSpacing={0.5}>
      <Grid item xs={12}>
        <Typography component="label">{'Preview'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
            '& .email-container': {
              display: 'block !important',
              minHeight: 'auto !important'
            },
            '& .email-body': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }
          }}
        >
          <div
            dangerouslySetInnerHTML={{ __html: renderPreview({ title, content: _unescape(String(content)) }) }}
            style={{ display: 'grid', overflowX: 'auto' }}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default ComposeEmailPreview
