import _isBoolean from 'lodash/isBoolean'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'

export const validateAsIds = (value: string): boolean => {
  if (!value) return true
  const arrays = Array.from(value.split(','))
  return arrays.every(data => data.match(/^\d+$/))
}

export const validateBlogTags = (value: string): boolean => {
  if (!value) return true
  const arrays = Array.from(value.split(','))
  return arrays.every(data => data.match(/^[a-z-]+$/))
}

export const validateJSONString = (value: string): boolean => {
  if (!value) return true

  try {
    JSON.parse(value)
    return true
  } catch (error) {
    return false
  }
}

export const validateAssets = (value: string, platformIds: number[]): boolean => {
  const requiredProperties = [
    'name',
    'displayName',
    'platformId',
    'link',
    'md5',
    'sha1',
    'sha256',
    'size',
    'source',
    'version',
    'displayOnly'
  ]

  // Check if json
  if (!validateJSONString(value)) {
    throw new Error('Assets must be a valid json')
  }

  const assets = JSON.parse(value)

  if (_isEmpty(assets)) throw new Error(`{ ${requiredProperties.join(', ')} } is required`)

  const assetsValue = assets[0]

  for (const property of requiredProperties) {
    if (!_isNumber(assetsValue[property]) && !_isBoolean(assetsValue[property]) && _isEmpty(assetsValue[property])) {
      throw new Error(`${property} is required`)
    }
  }

  if (!platformIds.includes(assetsValue.platformId)) {
    throw new Error(
      `The platformId value "(${assetsValue.platformId})" is invalid. It should be in values [${platformIds.join(
        ', '
      )}]`
    )
  }

  return true
}
