import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'

import {
  IAdminDetailOrganizationHistoryInput,
  IAdminDetailOrganizationHistoryList,
  IAdminDetailProductHistoryInput,
  IAdminDetailProductHistoryList,
  IAdminDetailReleaseHistoryInput,
  IAdminDetailReleaseHistoryList,
  IAdminDetailUserHistoryInput,
  IAdminDetailUserHistoryList
} from './types'

export const api: any = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminDetailUserHistories: builder.query<IAdminDetailUserHistoryList, IAdminDetailUserHistoryInput>({
      query: ({ userId, pageInfo }) => ({
        document: gql`
          query ($userId: UUID, $pageInfo: PageInfoType) {
            adminDetailUserHistories(userId: $userId, pageInfo: $pageInfo) {
              totalCount
              results {
                historyDate
                historyType
                historyUpdatedBy {
                  id
                  email
                  firstName
                  lastName
                }
                firstName
                lastName
                email
                timezone
                historyUpdatedReason
                changes {
                  field
                  new
                  old
                }
              }
            }
          }
        `,
        variables: {
          userId,
          pageInfo
        }
      })
    }),
    adminDetailOrganizationHistories: builder.query<
      IAdminDetailOrganizationHistoryList,
      IAdminDetailOrganizationHistoryInput
    >({
      query: ({ organizationId, pageInfo }) => ({
        document: gql`
          query ($organizationId: UUID, $pageInfo: PageInfoType) {
            adminDetailOrganizationHistories(organizationId: $organizationId, pageInfo: $pageInfo) {
              totalCount
              results {
                historyDate
                historyType
                historyUpdatedBy {
                  id
                  email
                  firstName
                  lastName
                }
                historyUpdatedByApp {
                  id
                  name
                }
                historyUpdatedReason
                changes {
                  field
                  new
                  old
                }
                metadata
              }
            }
          }
        `,
        variables: {
          organizationId,
          pageInfo
        }
      })
    }),
    adminDetailProductHistories: builder.query<IAdminDetailProductHistoryList, IAdminDetailProductHistoryInput>({
      query: ({ productId, pageInfo }) => ({
        document: gql`
          query ($productId: UUID, $pageInfo: PageInfoType) {
            adminDetailProductHistories(productId: $productId, pageInfo: $pageInfo) {
              totalCount
              results {
                historyDate
                historyType
                historyUpdatedBy {
                  id
                  email
                  firstName
                  lastName
                }
                historyUpdatedReason
                changes {
                  field
                  new
                  old
                }
                hiddenRelease {
                  id
                  semVer
                  releaseDate
                }
              }
            }
          }
        `,
        variables: {
          productId,
          pageInfo
        }
      })
    }),
    adminDetailReleaseHistories: builder.query<IAdminDetailReleaseHistoryList, IAdminDetailReleaseHistoryInput>({
      query: ({ releaseId, pageInfo }) => ({
        document: gql`
          query ($releaseId: UUID, $pageInfo: PageInfoType) {
            adminDetailReleaseHistories(releaseId: $releaseId, pageInfo: $pageInfo) {
              totalCount
              results {
                historyDate
                historyType
                historyUpdatedBy {
                  id
                  email
                  firstName
                  lastName
                }
                historyUpdatedReason
                changes {
                  field
                  new
                  old
                }
              }
            }
          }
        `,
        variables: {
          releaseId,
          pageInfo
        }
      })
    })
  })
})

export const {
  useAdminDetailUserHistoriesQuery,
  useLazyAdminDetailUserHistoriesQuery,
  useAdminDetailOrganizationHistoriesQuery,
  useLazyAdminDetailOrganizationHistoriesQuery,
  useAdminDetailProductHistoriesQuery,
  useLazyAdminDetailProductHistoriesQuery,
  useAdminDetailReleaseHistoriesQuery,
  useLazyAdminDetailReleaseHistoriesQuery
} = api
