import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import _get from 'lodash/get'

import { AdminLeftMenu, AppMenu, TemplateDashboard, useTheme } from '@opswat/react-ui'
import { PAGE_PERMISSIONS } from 'myopswat-admin/src/routes/handleRoutes'

import AllForm from './AllForm'
import RightMenu from './RightMenu'
import { menuItems } from './navigations'

interface IProps {
  profileData: any
}

const LayoutPage: FC<IProps> = ({ profileData }) => {
  const theme = useTheme()

  const getPermissionMenus = (menuList: any[]): any[] => {
    if (!menuList) {
      return []
    }
    return menuList.map((item: any) => ({
      ...item,
      permissions: _get(PAGE_PERMISSIONS, item.path),
      children: getPermissionMenus(item.children)
    }))
  }

  return (
    <>
      <TemplateDashboard
        top={
          <AppMenu
            sx={{
              zIndex: 1201,
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
          >
            <RightMenu />
          </AppMenu>
        }
        left={<AdminLeftMenu menuList={getPermissionMenus(menuItems)} content={<Outlet />} profileData={profileData} />}
      />

      <AllForm />
    </>
  )
}

export default LayoutPage
