import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  useAdminRoleObjectCreateMutation, useLazyAdminGlobalRolesQuery,
  useLazyAdminRoleObjectsComponentQuery
} from '../../../../api/roleObjectsManagement'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateRoleObjectsCreateSchema, IRoleObjectForm } from '../RoleObjectsSchema'
import RoleObjectForm from '../RoleObjectsForm'
import { customerManagementRoleObjectsListPageURL } from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'
import { Helmet } from 'react-helmet'
import { ButtonLoading, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import _get from 'lodash/get'

const RoleObjectCreatePage = () => {
  const listPermission = [
    {
      id: 1,
      name: 'None'
    },
    {
      id: 2,
      name: 'Read Only'
    },
    {
      id: 3,
      name: 'Full Access'
    }
  ]
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [createRoleObject, { isLoading: isCreating }] = useAdminRoleObjectCreateMutation()
  const [getRoleObjectComponents, { data: roleObjectComponentsData }] = useLazyAdminRoleObjectsComponentQuery()
  const [getGlobalRoles, { data: globalRolesData }] = useLazyAdminGlobalRolesQuery()

  const initValues = useMemo(() => {
    return {
      code: '',
      name: '',
      permissions: [1, 2, 3],
      componentId: undefined
    }
  }, [])

  const globalRoles = useMemo(() => {
    if (globalRolesData) {
      return globalRolesData
    }
    return []
  }, [globalRolesData])

  const formRef = useForm<IRoleObjectForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(generateRoleObjectsCreateSchema(globalRoles))
  })

  const { handleSubmit } = formRef

  const onSubmit = async (data: IRoleObjectForm) => {
    try {
      const assignPermissionsGlobalRoles = globalRoles.map((item:any) => {
        const nameKey = item.name.toLowerCase();
        return {
          roleId: item.id,
          permission: _get(data, nameKey)
        };
      });

      const payLoad = {
        code: data.code,
        name: data.name,
        permissions: data.permissions,
        componentId: data.componentId,
        assignPermissionsGlobalRoles: assignPermissionsGlobalRoles
      }
      const res = await createRoleObject(payLoad).unwrap()
      if (res.success) {
        enqueueSnackbar('Create role object successfully.', { variant: 'success' })
        navigate(customerManagementRoleObjectsListPageURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Role Objects',
        path: customerManagementRoleObjectsListPageURL
      },
      {
        label: 'New Role object'
      }
    ]
  }, [])

  const roleObjectComponents = useMemo(() => {
    if (roleObjectComponentsData) {
      return roleObjectComponentsData
    }
    return []
  }, [roleObjectComponentsData])


  useEffect(() => {
    getRoleObjectComponents()
  }, [getRoleObjectComponents])

  useEffect(() => {
    getGlobalRoles()
  }, [getGlobalRoles])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          New Role Object
        </title>
      </Helmet>
      <TemplateSection>
        <TypographyDivider breadcrumbsArray={breadcrumbs} />
        <Grid container>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <RoleObjectForm
              formRef={formRef}
              listPermission={listPermission}
              roleObjectComponents={roleObjectComponents}
              edit={false}
              globalRoles={globalRoles}
              assignedPermissions={[]}
            />
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'inherit',
                    onClick: () => {
                      navigate(customerManagementRoleObjectsListPageURL)
                    },
                    disabled: isCreating
                  }}
                >
                  Cancel
                </ButtonLoading>
              </Grid>
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleSubmit(onSubmit, onError),
                    disabled: isCreating
                  }}
                  isLoading={isCreating}
                >
                  Create
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TemplateSection>
    </>
  )
}

export default RoleObjectCreatePage
