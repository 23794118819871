import { yupResolver } from '@hookform/resolvers/yup'
import { Box, ButtonLoading, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useAdminCreateOrganizationMutation } from 'myopswat-admin/src/api/organizationManagement'
import useApiErrorHook from 'myopswat-admin/src/hooks/useApiErrorHook'
import { customerManagementOrganizationListPageURL, detailCustomerOrganizationPath } from 'myopswat-admin/src/routes'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CREATE_ORG_ERROR_MESSAGES } from '../../constants'
import OrganizationCreateForm from './CreateForm'
import { AdminOrganizationCreateForm, organizationCreateSchema } from './schema'

const OrganizationCreate = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { showErrorMessage } = useApiErrorHook({ errorMap: CREATE_ORG_ERROR_MESSAGES })
  const [createOrg, { isLoading }] = useAdminCreateOrganizationMutation()

  const initValues = useMemo(() => {
    return {
      name: '',
      mdcLicenseType: 'non-license',
      accountId: '',
      opportunityId: '',
      isOemCustomer: false,
      showKioskSecureImage: false,
      showEnginePackage: false,
      isManageSubOrganization: false,
      note: ''
    }
  }, [])

  const formRef = useForm<AdminOrganizationCreateForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(organizationCreateSchema)
  })

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef

  const onSubmit = async (data: AdminOrganizationCreateForm) => {
    try {
      const res = await createOrg(data).unwrap()
      if (res.success) {
        enqueueSnackbar('Create organization successfully.', { variant: 'success' })
        const org = res.organization
        navigate(`${customerManagementOrganizationListPageURL}/${org.id}/${detailCustomerOrganizationPath}`)
      } else if (res.errors) {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  const handleCancel = () => {
    navigate(`${customerManagementOrganizationListPageURL}`)
  }

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Organization List',
        path: customerManagementOrganizationListPageURL
      },
      {
        label: 'New Organization'
      }
    ]
  }, [])

  return (
    <>
      <Box>
        <TypographyDivider breadcrumbsArray={breadcrumbs} />
      </Box>
      <TemplateSection spacing={2}>
        <Box display={'flex'} flexDirection={'column'} sx={{ mt: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: handleCancel,
                disabled: isLoading
              }}
            >
              Cancel
            </ButtonLoading>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
                disabled: isLoading || _isEmpty(dirtyFields)
              }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Box>
          <OrganizationCreateForm formRef={formRef} />
        </Box>
      </TemplateSection>
    </>
  )
}
export default OrganizationCreate
