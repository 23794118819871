import React, { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { PageLoading } from '@opswat/react-ui'
import { handleCheckLogin } from '@myopswat/common'

import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'
import { loginPageURL, dashboardPageURL } from 'myopswat-admin/src/routes'

export default function LoadingContainer() {
  const navigate = useNavigate()

  const isToken = handleCheckLogin()

  useEffect(() => {
    if (isToken) {
      navigate(dashboardPageURL)
    } else {
      navigate(loginPageURL)
    }
  }, [isToken, navigate])

  return <PageLoading background={imageBg} color="primary" />
}
