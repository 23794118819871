import { Box, Grid, Skeleton, TextGrid } from '@opswat/react-ui'
import { PRODUCT_FORM_LABEL_MAP } from '../../constants'

const BoxProductDetailLoading = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box>
          <TextGrid
            label={'Name*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Slug*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Short Name'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Code Name'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'AS Ids'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Thumbnail*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="300px" />}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid
            label={'Image*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="300px" />}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid
            label={'Product Icon'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="300px" />}
            valueColProps={{
              xs: 12,
              sm: 4,
              md: 4
            }}
          />
          <TextGrid
            label={'Description'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Resources'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Release Metadata'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Documentation'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Type'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Tags'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'License Types'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Solutions'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Note'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Parents product'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Display only with licensed Products'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Downloadable'}
            value={<Skeleton animation="wave" variant="rectangular" width="30%" height="35px" />}
          />
          <TextGrid
            label={'Hide'}
            value={<Skeleton animation="wave" variant="rectangular" width="30%" height="35px" />}
          />
          <TextGrid
            label={PRODUCT_FORM_LABEL_MAP.showLatestVersion}
            value={<Skeleton animation="wave" variant="rectangular" width="30%" height="35px" />}
          />
        </Box>
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item xs="auto">
            <Skeleton animation="wave" variant="rectangular" width="30%" height="35px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxProductDetailLoading
