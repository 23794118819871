import { userManagementPageURL, opswatIncPath } from './userManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const opswatUserListPath = `${opswatIncPath}/users`
export const detailOpswatUserPath = 'detail'
export const editOpswatUserPath = 'edit'
export const opswatUserIdPath = ':userId'
export const tabOpswatUserPath = ':tabPath'

/** ----------------- ROUTE -------------------- */
export const opswatUserListPageURL = `${userManagementPageURL}/${opswatUserListPath}`
export const opswatUserDetailFlexPageURL = `${opswatUserListPageURL}/${opswatUserIdPath}/${tabOpswatUserPath}`
