import React, { FC, useState, useEffect } from 'react'

import { useLocation } from 'react-router-dom'

import { OpswatIcon } from '@opswat/react-icon'
import { Alert, AlertTitle, Box, Grid, TemplateFormLogin, TemplateSection, Typography } from '@opswat/react-ui'

import { ILoginInput } from 'myopswat-admin/src/api/account/types'
import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'
import AlertMessage from './AlertMessage'
import StepLogin from './StepLogin'

interface IProps {
  children?: React.ReactNode
  handleLogin: (data: ILoginInput) => void
  errorMessage?: string
  isLoading?: boolean
}

const LoginPage: FC<IProps> = ({ handleLogin, errorMessage, isLoading }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const redirectMessageCode = searchParams.get('code')

  const [isShowCaptcha, setIsShowCaptcha] = useState<boolean>(false)

  useEffect(() => {
    if (errorMessage) {
      setIsShowCaptcha(true)
    }
  }, [errorMessage])

  return (
    <TemplateFormLogin background={imageBg}>
      <TemplateSection spacing={4}>
        <Box>
          <OpswatIcon />
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h1" paragraph component="h1">
                Sign in to My OPSWAT
              </Typography>
              <Typography variant="body2">
                Manage multiple OPSWAT products remotely, improving productivity flows, providing better visibility
                between team members and more.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {errorMessage ? (
                  <Grid item xs={12}>
                    <Alert variant="filled" severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {errorMessage}
                    </Alert>
                  </Grid>
                ) : (
                  <>
                    {redirectMessageCode && (
                      <Grid item xs={12}>
                        <AlertMessage messageCode={redirectMessageCode} />
                      </Grid>
                    )}
                  </>
                )}
                <Grid item xs={12}>
                  <StepLogin isLoading={isLoading} handleLogin={handleLogin} isShowCaptcha={isShowCaptcha} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TemplateSection>
    </TemplateFormLogin>
  )
}

export default LoginPage
