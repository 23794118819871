import React from 'react'
import { Control, FieldErrors, UseFormHandleSubmit, UseFormSetValue, UseFormTrigger } from 'react-hook-form'

import type { IAdminReleaseInformationInput } from 'myopswat-admin/src/api/product/types'

export type ReleaseInfoContextType = {
  // form controls
  control: Control<IAdminReleaseInformationInput, any>
  handleSubmit: UseFormHandleSubmit<IAdminReleaseInformationInput>
  errors: FieldErrors<IAdminReleaseInformationInput>
  setValue: UseFormSetValue<IAdminReleaseInformationInput>
  trigger: UseFormTrigger<IAdminReleaseInformationInput>
  isFormValid: boolean

  // data
  viewMode?: 'create' | 'edit'
  isFetchingProducts: boolean
  productOptions: any[]
  platformOptions: any[]
  releaseInformationLevels: any[]
  enableSendReleaseEmail: boolean
  isRequiredFeaturesInput: boolean
  handleSave: (values: IAdminReleaseInformationInput) => void
  handleReset: () => void
  isCreating: boolean
  isChecking: boolean
  warningMessage: string
}

export const ReleaseInfoContext = React.createContext<ReleaseInfoContextType>({
  control: {} as Control<any>,
  handleSubmit: {} as UseFormHandleSubmit<any>,
  errors: {} as FieldErrors<any>,
  setValue: {} as UseFormSetValue<any>,
  trigger: {} as UseFormTrigger<any>,
  isFormValid: false,

  viewMode: 'create',
  productOptions: [],
  isFetchingProducts: false,
  platformOptions: [],
  releaseInformationLevels: [],
  enableSendReleaseEmail: false,
  isRequiredFeaturesInput: false,
  handleSave: () => {
    return null
  },
  handleReset: () => {
    return null
  },
  isCreating: false,
  isChecking: false,
  warningMessage: ''
})
