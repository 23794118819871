import { FC } from 'react'
import { TemplateSection, TypographyDivider } from '@opswat/react-ui'

import UserStatusDashboard from './Dashboards/UserStatus'

const DashboardUserPage: FC<unknown> = () => {
  return (
    <TemplateSection>
      <TypographyDivider label="User Reports" />
      <UserStatusDashboard />
    </TemplateSection>
  )
}

export default DashboardUserPage
