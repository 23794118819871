import React, { useMemo, FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { Typography, Skeleton, Grid, TemplateSection, TextGrid, CheckboxWithLabel } from '@opswat/react-ui'
import { CheckDisabledIcon, CheckedDisabledIcon } from '@opswat/react-icon'
import { USER_FORM_LABEL_MAP } from '../../constants'

interface IProps {
  children?: React.ReactNode
}

const BoxUserDetailLoading: FC<IProps> = () => {
  const leftInfoArray = useMemo(() => {
    return [
      {
        label: USER_FORM_LABEL_MAP.email
      },
      {
        label: `${USER_FORM_LABEL_MAP.firstName}*`
      },
      {
        label: `${USER_FORM_LABEL_MAP.lastName}*`
      },
      {
        label: USER_FORM_LABEL_MAP.title
      },
      {
        label: USER_FORM_LABEL_MAP.companyName
      },
      {
        label: USER_FORM_LABEL_MAP.phoneNumber
      },
      {
        label: `${USER_FORM_LABEL_MAP.timezone}*`
      }
    ]
  }, [])

  const rightInfoArray = useMemo(() => {
    return [
      {
        label: USER_FORM_LABEL_MAP.streetAddress1
      },
      {
        label: USER_FORM_LABEL_MAP.streetAddress1
      },
      {
        label: USER_FORM_LABEL_MAP.city
      },
      {
        label: USER_FORM_LABEL_MAP.country
      },
      {
        label: USER_FORM_LABEL_MAP.state
      },
      {
        label: USER_FORM_LABEL_MAP.postalCode
      },
      {
        label: USER_FORM_LABEL_MAP.customerSupportId
      },
      {
        label: USER_FORM_LABEL_MAP.sfdcUserType
      },
      {
        label: USER_FORM_LABEL_MAP.sfdcCountry
      },
      {
        label: USER_FORM_LABEL_MAP.sfdcContactId
      },
      {
        label: USER_FORM_LABEL_MAP.sfdcAccountId
      }
    ]
  }, [])

  const checkboxArray = useMemo(() => {
    return [
      {
        label: 'IS Kiosk Secure Image',
        value: false
      },
      {
        label: 'Can Access V4 Definition Updates',
        value: false
      },
      {
        label: 'OEM Customer',
        value: false
      },
      {
        label: 'Channel Partner',
        value: false
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}

            <TextGrid
              label={<Typography variant="subtitle2">Note</Typography>}
              value={<Skeleton animation="wave" variant="rectangular" width="100%" height="100px" />}
              containerProps={{ alignItems: 'flex-start' }}
            />
          </TemplateSection>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {_map(checkboxArray, (item, idx) => (
          <Grid item xs={12} sm={6} md="auto" key={idx.toString()}>
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.label}</Typography>}
              checkboxProps={{
                disabled: true,
                checked: item.value,
                icon: <CheckDisabledIcon />,
                checkedIcon: <CheckedDisabledIcon />
              }}
            />
          </Grid>
        ))}
      </Grid>
    </TemplateSection>
  )
}

export default BoxUserDetailLoading
