import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-admin/src/api/base-query'

import { IDashboardFiltersInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminDashboardUser: builder.query<any, IDashboardFiltersInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminDashboardUserFiltersInput) {
            adminDashboardUser(filters: $filters) {
              active
              new
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    adminDashboardTotalUser: builder.query<any, IDashboardFiltersInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminDashboardUserFiltersInput) {
            adminDashboardTotalUser(filters: $filters) {
              total
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    adminDashboardUsersActive: builder.query<any, IDashboardFiltersInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminDashboardUserFiltersInput) {
            adminDashboardUserActive(filters: $filters) {
              data
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    adminDashboardUserStatus: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            adminDashboardUserStatus {
              data
            }
          }
        `
      })
    }),
    adminDashboardUsersNew: builder.query<any, IDashboardFiltersInput>({
      query: filters => ({
        document: gql`
          query ($filters: AdminDashboardUserFiltersInput) {
            adminDashboardUserNew(filters: $filters) {
              data
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    })
  })
})

export const {
  useLazyAdminDashboardUserQuery,
  useLazyAdminDashboardTotalUserQuery,
  useLazyAdminDashboardUsersActiveQuery,
  useLazyAdminDashboardUsersNewQuery,
  useLazyAdminDashboardUserStatusQuery
} = api
