import React, { FC, useMemo } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _join from 'lodash/join'
import _map from 'lodash/map'

import {
  Box,
  Chip,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Tooltip,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import { DATE_FORMATS, DIALOGS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import { useLazyReleasesQuery } from 'myopswat-admin/src/api/product'
import { IAdminReleaseInput } from 'myopswat-admin/src/api/product/types'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useOptimisticMutateList } from 'myopswat-admin/src/hooks/useOptimisticMutateList'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useParams } from 'react-router-dom'
import { RELEASE_TAGS } from '../../constants'
import BoxProductListFilter from './BoxReleaseListFilter'
import DialogRelease from './DialogRelease'
import DialogReleaseHistoryList from './DialogReleaseHistoryList'

const BoxProductDetailRelease: FC<unknown> = () => {
  const { productId = '' } = useParams()
  const dispatch = useAppDispatch()

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [getReleases, { data: releasesData, isFetching: isFetchingReleases }] = useLazyReleasesQuery()

  const {
    dataCache: releasesDataCache,
    optimisticCreating,
    optimisticEditing
  } = useOptimisticMutateList<IAdminReleaseInput>(releasesData)

  const productDetail = useTypedSelector(state => state?.api?.queries?.[`productDetail("${productId}")`]?.data)

  const platformMap: any = useMemo(() => {
    if (!productDetail) return {}

    const platformList = _get(_get(productDetail, 'adminProduct.releaseMetadata', ''), 'platforms', [])
    const result = platformList.reduce((m: any, obj: any) => ((m[obj.id] = obj.displayName), m), {})
    return result
  }, [])

  const handleRenderPlatform = (platformIds = []) => {
    const result = platformIds.map(platformId => platformMap[platformId] || '').filter(platform => platform)
    return result
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Sem Version',
        body: (data: any) => (
          <Typography variant="body2" component="div">
            {data?.semVer ? (data.versionSuffix ? data.semVer + data.versionSuffix : data.semVer) : '--'}
            {_includes(data?.tags, RELEASE_TAGS[0]) && (
              <>
                &nbsp;
                <Chip label="Hidden" color="error" variant="outlined" size="small" />
              </>
            )}
          </Typography>
        ),
        style: { minWidth: 150, width: 150 }
      },
      {
        header: 'Version',
        body: (data: any) => <Typography variant="body2">{data?.version || '--'}</Typography>,
        style: { minWidth: 150, width: 150 }
      },
      {
        header: 'Platforms',
        body: (data: any) => (
          <Tooltip
            arrow
            title={_map(handleRenderPlatform(data?.platformIds), (item: string, idx: number) => (
              <React.Fragment key={idx.toString()}>
                <Typography variant="caption" color="inherit" gutterBottom>
                  {item || '--'}
                </Typography>
                <br />
              </React.Fragment>
            ))}
          >
            <Box pr={2}>
              <TypographyLineClamp variant="body2" line={1}>
                {_join(handleRenderPlatform(data?.platformIds), ', ')}
              </TypographyLineClamp>
            </Box>
          </Tooltip>
        ),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Released At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data?.epochTime, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        toggleDialogs({
                          [DIALOGS.RELEASE_PRODUCT]: true,
                          [`${DIALOGS.RELEASE_PRODUCT}_DATA`]: data,
                          [`${DIALOGS.RELEASE_PRODUCT}_TITLE`]: 'Edit Release'
                        })
                      )
                    }}
                  >
                    Edit
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      dispatch(
                        toggleDialogs({
                          [DIALOGS.RELEASE_HISTORIES]: true,
                          [`${DIALOGS.RELEASE_HISTORIES}_DATA`]: data
                        })
                      )
                    }}
                  >
                    Histories
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <BoxProductListFilter getReleases={getReleases} />

      <TableAdvanced columns={columnArray} isLoading={isFetchingReleases} data={releasesDataCache} />

      <DialogRelease optimisticCreatingHandler={optimisticCreating} optimisticEditingHandler={optimisticEditing} />
      <DialogReleaseHistoryList />
    </TemplateSection>
  )
}

export default BoxProductDetailRelease
