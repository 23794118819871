import { Box, ColorLabel, Grid, Typography } from '@opswat/react-ui'
import GaugeComponent from 'react-gauge-component'
import { NPS_COLORS } from '../contants'

interface INpsGaugeChart {
  nps: number
  detractor: {
    percent: number
    count: number
  }
  passive: {
    percent: number
    count: number
  }
  promoter: {
    percent: number
    count: number
  }
}

export const NpsGaugeChart = ({ nps, detractor, passive, promoter }: INpsGaugeChart) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography>Net Promoter Score</Typography>
    </Grid>

    <Grid item xs={12}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          lg={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '90%',
            height: 'auto'
          }}
        >
          <GaugeComponent
            type="semicircle"
            arc={{
              width: 0.2,
              padding: 0.005,
              cornerRadius: 1,
              subArcs: [
                {
                  limit: 0,
                  color: NPS_COLORS.DETRACTORS,
                  showTick: true
                },
                {
                  limit: 40,
                  color: NPS_COLORS.PASSIVES
                },
                {
                  limit: 70,
                  color: NPS_COLORS.GOOD
                },
                {
                  limit: 100,
                  color: NPS_COLORS.PROMOTERS,
                  showTick: true
                }
              ]
            }}
            labels={{
              valueLabel: { formatTextValue: () => '' }
            }}
            pointer={{
              color: '#485161',
              length: 0.8,
              width: 15
            }}
            value={nps}
            minValue={-100}
            maxValue={100}
          />
        </Grid>

        <Grid paddingLeft={1} item xs={12} sm={4} md={4} lg={4} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid container>
            <Grid item xs={12}>
              <ColorLabel py={0.5} width={2} rectColor={NPS_COLORS.DETRACTORS}>
                Need Improvement
              </ColorLabel>
              <ColorLabel py={0.5} width={2} rectColor={NPS_COLORS.PASSIVES}>
                Good
              </ColorLabel>
              <ColorLabel py={0.5} width={2} rectColor={NPS_COLORS.GOOD}>
                Great
              </ColorLabel>
              <ColorLabel py={0.5} width={2} rectColor={NPS_COLORS.PROMOTERS}>
                Excellent
              </ColorLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>NPS Breakdown</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                textAlign="center"
                color="primary"
                sx={{
                  fontSize: '32px'
                }}
                paragraph
              >
                {nps}%
              </Typography>
              <Typography textAlign="center">NPS Score</Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: '32px'
                }}
                paragraph
              >
                =
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                textAlign="center"
                color={NPS_COLORS.GREEN}
                sx={{
                  fontSize: '32px'
                }}
                paragraph
              >
                {promoter?.percent}%
              </Typography>
              <Typography textAlign="center">Promoters</Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography
                textAlign="center"
                sx={{
                  fontSize: '32px'
                }}
                paragraph
              >
                -
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography
                textAlign="center"
                color={NPS_COLORS.RED}
                sx={{
                  fontSize: '32px'
                }}
                paragraph
              >
                {detractor?.percent}%
              </Typography>
              <Typography textAlign="center">Detractors</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container rowSpacing={1}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ width: '120px' }}>Detractors (0-6)</Typography>
            <Box sx={{ height: '16px', width: '200px', backgroundColor: NPS_COLORS.BACKGROUND, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  width: `${detractor?.percent}%`,
                  height: '100%',
                  backgroundColor: NPS_COLORS.RED
                }}
              />
            </Box>
            <Typography>
              {detractor?.percent}% ({detractor?.count})
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ width: '120px' }}>Passives (7-8)</Typography>
            <Box sx={{ height: '16px', width: '200px', backgroundColor: NPS_COLORS.BACKGROUND, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  width: `${passive?.percent}%`,
                  height: '100%',
                  backgroundColor: NPS_COLORS.GRAY
                }}
              />
            </Box>
            <Typography>
              {passive?.percent}% ({passive?.count})
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ width: '120px' }}>Promoters (9-10)</Typography>
            <Box sx={{ height: '16px', width: '200px', backgroundColor: NPS_COLORS.BACKGROUND, position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  width: `${promoter?.percent}%`,
                  height: '100%',
                  backgroundColor: NPS_COLORS.GREEN
                }}
              />
            </Box>
            <Typography>
              {promoter?.percent}% ({promoter?.count})
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
