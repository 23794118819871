import { DIALOGS } from '@myopswat/common'
import { copyText } from '@opswat/react-core'
import { CopySimpleIcon } from '@opswat/react-icon'
import { Alert, Box, ButtonLoading, DialogAdvanced, Grid, TextField, Tooltip, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { AppTokenInput } from 'myopswat-admin/src/api/integrated-app/types'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { enqueueSnackbar } from 'notistack'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

interface IProps {
  getAppTokens: (data: AppTokenInput) => any
  token: string
  appData: any
}

// eslint-disable-next-line
const DialogAppToken: FC<IProps> = ({ getAppTokens, token, appData }) => {
  const { t: translate } = useTranslation()
  const { appId = '' } = useParams()
  const [tokenCopyStatus, setTokenCopyStatus] = useState('copy')
  const tokenRef: any = useRef(null)

  const dispatch = useAppDispatch()

  const dialogType = useTypedSelector(selectDialogs)

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.APP_TOKEN]: false,
        [`${DIALOGS.APP_TOKEN}_DATA`]: ''
      })
    )
    getAppTokens({ appId: appId })
    setTokenCopyStatus('copy')
  }

  const handleCopy = () => {
    copyText(token).then(() => {
      enqueueSnackbar(translate('copyTokenSuccessfully'), { variant: 'success' })
      setTokenCopyStatus('copied')
    })
  }

  return (
    <DialogAdvanced
      title={'Generated Token'}
      open={_get(dialogType, DIALOGS.APP_TOKEN, false)}
      onClose={() => handleClose()}
      content={
        <>
          <Grid display="flex" sx={{ mb: 2 }} alignItems="center">
            <Typography variant="subtitle2" sx={{ py: 0.5, minWidth: '80px' }}>
              Token value
            </Typography>
            <TextField
              InputProps={{ readOnly: true }}
              size="small"
              sx={{ minHeight: '37px' }}
              value={token}
              fullWidth
              inputRef={tokenRef}
            />
            <Tooltip title="Copy" arrow>
              <Box>
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    sx: { minHeight: '37px', background: tokenCopyStatus !== 'copied' ? '#1D6BFC' : '#194CA7' },
                    onClick: () => handleCopy()
                  }}
                  propsLoading={{ color: 'inherit' }}
                >
                  <CopySimpleIcon color="white" />
                </ButtonLoading>
              </Box>
            </Tooltip>
          </Grid>
          <Alert severity="warning" sx={{ mt: 3, mb: 1 }}>
            System does not store the token value. After close this screen, the token will not be able to be retrieved
            again. Please store the token in your side for the next use.
          </Alert>
        </>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogAppToken
