import { useMemo } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _round from 'lodash/round'

import { Divider, Grid, Typography } from '@opswat/react-ui'

import { NpsGaugeChart } from '../../Charts/NpsGaugeChart'
import { NpsTrackerChart } from '../../Charts/NpsTrackerChart'

interface IProps {
  npsSurvey: any
}

const NPSSummaryTab = ({ npsSurvey }: IProps) => {
  const { detractor, passive, promoter, nps } = useMemo(() => {
    const result: any = {
      detractor: {
        percent: 0,
        count: 0
      },
      passive: {
        percent: 0,
        count: 0
      },
      promoter: {
        percent: 0,
        count: 0
      },
      nps: 0,
      colors: [] as string[]
    }

    if (!npsSurvey) return result

    // Calculate nps value
    // 0 -> 6: Detractor
    // 7 -> 8: Passive
    // 9 -> 10: Promoter
    // nps = Total Promoter - Total Detractor
    for (const { answer, count, percent } of _get(npsSurvey, 'detail', [])) {
      if (answer <= 6) {
        result.detractor.count += count
        result.detractor.percent += percent
      } else if (answer <= 8) {
        result.passive.count += count
        result.passive.percent += percent
      } else {
        result.promoter.count += count
        result.promoter.percent += percent
      }
    }
    // round the inital values
    result.promoter.percent = _round(result.promoter.percent, 2)
    result.passive.percent = _round(result.passive.percent, 2)
    result.detractor.percent = _round(result.detractor.percent, 2)

    // calculate adjustments
    const totalPercent = result.promoter.percent + result.detractor.percent + result.passive.percent
    if (totalPercent !== 100) {
      const diff = 100 - totalPercent
      const order = ['promoter', 'passive', 'detractor']
      if (diff < 0) {
        // over 100
        order.reverse()
      }

      for (const key of order) {
        if (_get(result, [key, 'percent'], 0) > 0) {
          result[key]['percent'] += diff
          result[key]['percent'] = _round(result[key]['percent'], 2)
          break
        }
      }
    }
    result.nps = _round(result.promoter.percent - result.detractor.percent, 2)

    return result
  }, [npsSurvey])

  const npsSurveyRounded = useMemo(() => {
    return _get(npsSurvey, 'detail', []).map((item: any) => ({ ...item, percent: _round(item.percent, 2) }))
  }, [npsSurvey])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="label" variant="body1">
          On a scale of 0-10, how likely are you to recommend OPSWAT products to a friend or colleague?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <NpsTrackerChart
          categoriesData={_map(_get(npsSurvey, 'detail', []), ({ answer }) => answer)}
          seriesData={_map(_get(npsSurvey, 'detail', []), ({ count }) => count)}
          percentData={_map(npsSurveyRounded, ({ percent }) => percent)}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <NpsGaugeChart nps={nps} detractor={detractor} passive={passive} promoter={promoter} />
      </Grid>
    </Grid>
  )
}

export default NPSSummaryTab
