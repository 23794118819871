import { FC } from 'react'

import _get from 'lodash/get'

import { Control, Controller, useFormState } from 'react-hook-form'

import { Grid, GridProps, Skeleton, Typography } from '@opswat/react-ui'

interface IProps {
  control: Control<any>
  title: string
  fieldName: string
  Component: FC<any>
  rules?: any
  isFetching?: boolean
  isLoading?: boolean
  gridProps?: GridProps
  hideRequiredMark?: boolean
  labelProps?: any
  titleIcon?: React.ReactNode
}

const ComposeEmailField: FC<IProps> = ({
  control,
  title,
  fieldName,
  Component,
  gridProps,
  rules,
  isFetching,
  isLoading,
  hideRequiredMark = false,
  labelProps,
  titleIcon
}) => {
  const { errors } = useFormState({ control })

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5
      }}
      {...gridProps}
    >
      {title && (
        <Typography component="label" {...labelProps}>
          {title}
          {rules?.required && !hideRequiredMark && (
            <Typography component="span" color="error">
              {'*'}
            </Typography>
          )}
          {titleIcon}
        </Typography>
      )}
      {isFetching || isLoading ? (
        <Skeleton variant="rectangular" height={37} width={'100%'} />
      ) : (
        <Controller
          name={fieldName}
          control={control}
          render={({ field }) => (
            <Component
              {...field}
              error={!!_get(errors, fieldName, '')}
              helperText={_get(errors, `${fieldName}.message`, '')}
            />
          )}
          rules={{
            ...rules
          }}
        />
      )}
    </Grid>
  )
}

export default ComposeEmailField
