import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider,
  TypographyLinkClamp
} from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { useAdminRolesQuery } from 'myopswat-admin/src/api/role'
import { AdminRoleFilter, AdminRolesQueryInput } from 'myopswat-admin/src/api/role/types'
import {
  OPSWAT_ORGANIZATION_ID,
  PAGE_DEFAULT,
  PAGE_SIZES_DEFAULT,
  PAGE_SIZE_DEFAULT
} from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  opswatRoleListPageURL,
  customerManagementOrganizationListPageURL,
  organizationRolesPath,
  detailUserRolePath,
  detailOrganizationRolePath
} from 'myopswat-admin/src/routes'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface OrganizationRolesProps {
  orgId: string
}

const OrganizationRoles = ({ orgId }: OrganizationRolesProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()
  orgId = isOpswatIncRoute ? OPSWAT_ORGANIZATION_ID : orgId

  const [filters] = useState<AdminRoleFilter>({
    q: '',
    organizationId: orgId
  })
  const [query, setQuery] = useState<AdminRolesQueryInput>({
    filters: filters,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const { data, isFetching } = useAdminRolesQuery(query, {
    refetchOnMountOrArgChange: true
  })
  const navigate = useNavigate()

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const orgRoles = useMemo(() => {
    if (_isEmpty(data)) {
      return {
        totalCount: 0,
        results: []
      }
    }
    return data
  }, [data])

  const onClickDetailHandler = useCallback(
    (data: any) => () => {
      const detailRolePageURL = isOpswatIncRoute
        ? `${opswatRoleListPageURL}/${data.id}/${detailUserRolePath}`
        : `${customerManagementOrganizationListPageURL}/${orgId}/${organizationRolesPath}/${data.id}/${detailOrganizationRolePath}`
      navigate(detailRolePageURL)
    },
    [isOpswatIncRoute, orgId]
  )

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => (
          <TypographyLinkClamp onClick={onClickDetailHandler(data)}>{data.name}</TypographyLinkClamp>
        ),
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Description',
        body: (data: any) => <Typography variant="body2">{data.description || '--'}</Typography>,
        style: { minWidth: 500, width: 500 }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={onClickDetailHandler(data)}>View Detail</ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  const tableData = useMemo(() => {
    return _map(orgRoles.results, (group: any) => ({
      id: group.id,
      name: group.name,
      description: group.description,
      createdAt: group.createdAt,
      updatedAt: group.updatedAt,
      organizationName: group.organization.name
    }))
  }, [orgRoles])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery((statePrev: any) => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  return (
    <TemplateSection>
      <TypographyDivider label="User Management - Roles" />

      <TableAdvanced
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        isPagination
        total={orgRoles.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default OrganizationRoles
