import { useMemo } from 'react'

import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'

import { useAdminReleaseUpdateMutation } from 'myopswat-admin/src/api/product'
import { IAdminReleaseInput } from 'myopswat-admin/src/api/product/types'

import { yupResolver } from '@hookform/resolvers/yup'
import { DIALOGS } from '@myopswat/common'

import { selectDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { convertDateToEpochTime } from 'myopswat-admin/src/utils/dateTime'
import { BeautifyJSON } from 'myopswat-admin/src/utils/json'
import { validateAssets } from 'myopswat-admin/src/utils/validator'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { adminReleaseEditSchema } from '../SchemaRelease'

interface IProps {
  optimisticEditingHandler: (data: Partial<IAdminReleaseInput>) => void
  handleClose: () => void
}

const useAdminReleaseEdit = ({ optimisticEditingHandler, handleClose }: IProps) => {
  // Logic: Dialog
  const { t: translate } = useTranslation()

  const dialogType = useTypedSelector(selectDialogs)

  // Logic: Form
  const [adminReleaseUpdate, { isLoading }] = useAdminReleaseUpdateMutation()

  const defaultValues = useMemo<IAdminReleaseInput>(() => {
    const releaseDetailData = _get(dialogType, `${DIALOGS.RELEASE_PRODUCT}_DATA`)
    return {
      id: _get(releaseDetailData, 'id') || '',
      platformIds: _get(releaseDetailData, 'platformIds') || [],
      assets: _get(releaseDetailData, 'assets') ? BeautifyJSON(_get(releaseDetailData, 'assets', {})) : '',
      tags: _get(releaseDetailData, 'tags') || [],
      semVer: _get(releaseDetailData, 'semVer') || '',
      version: _get(releaseDetailData, 'version') || '',
      versionSuffix: _get(releaseDetailData, 'versionSuffix') || '',
      date: _get(releaseDetailData, 'date') || '',
      releaseDate: _get(releaseDetailData, 'releaseDate') || '',
      note: _get(releaseDetailData, 'note') || '',
      epochTime: _get(releaseDetailData, 'epochTime') || convertDateToEpochTime(new Date()),
      updatedReason: ''
    }
  }, [_get(dialogType, `${DIALOGS.RELEASE_PRODUCT}_DATA`)])

  const formRef = useForm<IAdminReleaseInput>({
    resolver: yupResolver(adminReleaseEditSchema),
    defaultValues
  })

  const onSuccess = (data: IAdminReleaseInput) => {
    const {
      formState: { dirtyFields },
      setError
    } = formRef

    try {
      validateAssets(data.assets, data.platformIds)
    } catch (err) {
      setError('assets', { type: 'custom', message: (err as Error).message })
      return
    }

    if (!_isEmpty(dirtyFields)) {
      const commonKeyValues: any = {}
      console.log('dirtyFields: ', dirtyFields)
      _forEach(Object.keys(dirtyFields), (field: string) => {
        if (field === 'epochTime') {
          commonKeyValues['epochTime'] = Number(data['epochTime'])
        } else if (field === 'assets') {
          commonKeyValues['assets'] = JSON.parse(data.assets!)
        } else {
          commonKeyValues[field] = data[field as keyof IAdminReleaseInput]
        }
      })
      const payload = _merge(
        {
          id: data.id
        },
        commonKeyValues
      )
      adminReleaseUpdate(payload)
        .unwrap()
        .then((data: any) => {
          if (data?.success) {
            enqueueSnackbar(translate('updateReleaseProductSuccess'), {
              variant: 'success'
            })
            optimisticEditingHandler(payload)
            handleClose()
          } else {
            enqueueSnackbar(translate('updateReleaseProductFail'), {
              variant: 'error'
            })

            data?.errors.map((error: any) => {
              enqueueSnackbar(`${error?.message}`, { variant: 'warning' })
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('updateReleaseProductFail'), {
            variant: 'error'
          })
        })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return { formRef, onSuccess, onFail, isLoading, defaultValues }
}

export default useAdminReleaseEdit
