import { FC, useCallback, useEffect, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import { IAdminReleaseInput } from 'myopswat-admin/src/api/product/types'

import { DIALOGS } from '@myopswat/common'
import {
  Autocomplete,
  Box,
  Button,
  ButtonLoading,
  DialogAdvanced,
  Grid,
  TextField,
  TextGrid,
  Typography,
  styled
} from '@opswat/react-ui'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { ASSETS_SAMPLE, RELEASE_TAGS } from 'myopswat-admin/src/pages/SystemManagementPage/constants'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { convertDateToEpochTime } from 'myopswat-admin/src/utils/dateTime'
import { BeautifyJSON } from 'myopswat-admin/src/utils/json'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useAdminReleaseCreate from './hooks/useAdminReleaseCreate'
import useAdminReleaseEdit from './hooks/useAdminReleaseEdit'
import { RELEASE_FORM_LABEL_MAP } from '../../../constants'

interface IProps {
  optimisticCreatingHandler: (data: IAdminReleaseInput) => void
  optimisticEditingHandler: (data: Partial<IAdminReleaseInput>) => void
}

const GenerateButtonStyled = styled(Button)(() => ({
  marginTop: 1,
  maxWith: '150px'
}))

const DialogRelease: FC<IProps> = ({ optimisticCreatingHandler, optimisticEditingHandler }) => {
  // Logic: Dialog
  const { productId = '' } = useParams()

  const dispatch = useAppDispatch()
  const myDialog = useDialog()
  const productDetailData = useTypedSelector(state => state?.api?.queries?.[`productDetail("${productId}")`]?.data)
  const dialogType = useTypedSelector(selectDialogs)

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.RELEASE_PRODUCT]: false,
        [`${DIALOGS.RELEASE_PRODUCT}_TITLE`]: '',
        [`${DIALOGS.RELEASE_PRODUCT}_DATA`]: ''
      })
    )
  }

  // Logic: Form
  const isDialogOpen = _get(dialogType, DIALOGS.RELEASE_PRODUCT, false)
  const isAdminReleaseEdit = !!_get(dialogType, `${DIALOGS.RELEASE_PRODUCT}_DATA`)
  const formTitle = _get(dialogType, `${DIALOGS.RELEASE_PRODUCT}_TITLE`)
  const adminReleaseEditHook = useAdminReleaseEdit({ optimisticEditingHandler, handleClose })
  const adminReleaseCreateHook = useAdminReleaseCreate({ optimisticCreatingHandler, handleClose })

  const adminReleaseHook = isAdminReleaseEdit ? adminReleaseEditHook : adminReleaseCreateHook
  const { formRef, onSuccess, onFail, isLoading, defaultValues } = adminReleaseHook

  const platformsIdsArray = useMemo(() => {
    if (!_get(productDetailData, 'adminProduct.releaseMetadata')) return []

    return _get(_get(productDetailData, 'adminProduct.releaseMetadata', ''), 'platforms', [])
  }, [productDetailData])

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields, isValid }
  } = formRef

  const handleSave = async () => {
    if (isValid && !_isEmpty(watch('tags'))) {
      const confirmed = await myDialog.openConfirmation({
        content:
          'You are hiding the release/asset. After clicking "Yes" button will be hide release/asset on customer view?',
        title: 'Hidden Confirmation'
      })
      if (confirmed) {
        handleSubmit(onSuccess, onFail)()
      }
    } else {
      handleSubmit(onSuccess, onFail)()
    }
  }

  const setDefaultValue = useCallback(() => {
    if (!_isEmpty(defaultValues)) {
      formRef.reset(defaultValues)
    }
  }, [defaultValues])

  useEffect(() => {
    setDefaultValue()
  }, [setDefaultValue, isDialogOpen])

  const handlePasteSampleAssets = () => {
    setValue('assets', BeautifyJSON(ASSETS_SAMPLE), { shouldValidate: true, shouldDirty: true })
  }

  const releaseDialogRegister = useMemo(
    () => ({
      semVer: register('semVer', {
        disabled: !isAdminReleaseEdit
      }),
      version: register('version'),
      versionSuffix: register('versionSuffix'),
      date: register('date', {
        disabled: !isAdminReleaseEdit
      }),
      releaseDate: register('releaseDate', {
        onChange: event => {
          const value = event.target.value
          setValue('releaseDate', value, { shouldDirty: true })
          setValue('epochTime', convertDateToEpochTime(value), { shouldDirty: true })
          if (!isAdminReleaseEdit) {
            setValue('date', value, { shouldDirty: true })
          }
        }
      }),
      epochTime: register('epochTime'),
      assets: register('assets'),
      note: register('note'),
      updatedReason: register('updatedReason')
    }),
    [isAdminReleaseEdit]
  )

  return (
    <DialogAdvanced
      title={formTitle}
      open={isDialogOpen}
      onClose={() => handleClose()}
      content={
        <>
          {isAdminReleaseEdit && (
            <TextGrid
              label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.semVersion}*</Typography>}
              value={
                <TextField
                  {...releaseDialogRegister['semVer']}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'semVer', '')}
                  helperText={_get(errors, 'semVer.message', '')}
                />
              }
            />
          )}

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.version}*</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['version']}
                size="small"
                fullWidth
                error={!!_get(errors, 'version', '')}
                helperText={_get(errors, 'version.message', '')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.versionSuffix}</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['versionSuffix']}
                size="small"
                fullWidth
                error={!!_get(errors, 'versionSuffix', '')}
                helperText={_get(errors, 'versionSuffix.message', '')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.date}*</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['date']}
                size="small"
                fullWidth
                error={!!_get(errors, 'date', '')}
                helperText={_get(errors, 'date.message', '')}
                type="date"
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.releaseDate}*</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['releaseDate']}
                size="small"
                fullWidth
                error={!!_get(errors, 'releaseDate', '')}
                helperText={_get(errors, 'releaseDate.message', '')}
                type="date"
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.epochTime}*</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['epochTime']}
                size="small"
                fullWidth
                error={!!_get(errors, 'epochTime', '')}
                helperText={_get(errors, 'epochTime.message', '')}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.tags}</Typography>}
            value={
              <Controller
                name="tags"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    disableClearable
                    options={RELEASE_TAGS}
                    value={value}
                    getOptionLabel={(option: any) => _find(RELEASE_TAGS, item => item === option) || '--'}
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        error={!!_get(errors, 'tags', '')}
                        helperText={_get(errors, 'tags.message', '')}
                        {...params}
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              />
            }
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.platforms}*</Typography>}
            value={
              <Controller
                name="platformIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    disableClearable
                    options={_map(platformsIdsArray, item => _get(item, 'id')) || []}
                    value={value}
                    getOptionLabel={(option: any) =>
                      _get(
                        _find(platformsIdsArray, item => _get(item, 'id') === option),
                        'displayName'
                      ) || '--'
                    }
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue)
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        error={!!_get(errors, 'platformIds.message', '')}
                        helperText={_get(errors, 'platformIds.message', '')}
                      />
                    )}
                  />
                )}
              />
            }
          />

          <TextGrid
            label={
              <Box display={'flex'} flexDirection={'column'}>
                <Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.assets}*</Typography>
                <GenerateButtonStyled
                  sx={{ mt: 1, maxWidth: '150px' }}
                  color="primary"
                  variant="outlined"
                  onClick={handlePasteSampleAssets}
                >
                  Generate Sample
                </GenerateButtonStyled>
              </Box>
            }
            value={
              <TextField
                {...releaseDialogRegister['assets']}
                error={!!_get(errors, 'assets', '')}
                helperText={_get(errors, 'assets.message', '')}
                rows={6}
                multiline
                fullWidth
                placeholder="Type your assets here..."
                size="small"
                required
              />
            }
            containerProps={{ alignItems: 'flex-start' }}
          />

          <TextGrid
            label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.note}</Typography>}
            value={
              <TextField
                {...releaseDialogRegister['note']}
                rows={6}
                multiline
                fullWidth
                placeholder="Type your note here..."
                size="small"
                error={!!_get(errors, 'note.message', '')}
                helperText={_get(errors, 'note.message', '')}
                required
              />
            }
          />

          {isAdminReleaseEdit && (
            <TextGrid
              label={<Typography variant="subtitle2">{RELEASE_FORM_LABEL_MAP.updatedReason}*</Typography>}
              value={
                <TextField
                  {...releaseDialogRegister['updatedReason']}
                  rows={6}
                  multiline
                  fullWidth
                  placeholder="Type your updated reason here..."
                  size="small"
                  error={!!_get(errors, 'updatedReason.message', '')}
                  helperText={_get(errors, 'updatedReason.message', '')}
                  required
                />
              }
            />
          )}
        </>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSave(),
                disabled: isLoading || _isEmpty(dirtyFields),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'md'
      }}
    />
  )
}

export default DialogRelease
