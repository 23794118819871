import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { NpsSettingsUpdateInput } from './types'

export const portalSettingsQueries = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    portalSettings: builder.query<any, void>({
      query: () => ({
        document: gql`
          query adminPortalSettings {
            adminPortalSettings {
              npsSurvey {
                surveyPeriod
                skipPeriod
              }
            }
          }
        `
      })
    }),
    npsSettingsUpdate: builder.mutation<any, NpsSettingsUpdateInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminUpdateNpsSettingsInput!) {
            adminNpsSettingsUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const { useLazyPortalSettingsQuery, useNpsSettingsUpdateMutation } = portalSettingsQueries
