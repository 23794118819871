import { ConfigureStoreOptions, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import { apiGraphql, apiRestful } from 'myopswat-admin/src/api/base-query'
import layout from '../containers/LayoutContainer/layoutContainerSlice'
import myAccount from '../containers/MyAccountContainer/myAccountContainerSlice'

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: {
      layout,
      myAccount,
      [apiGraphql.reducerPath]: apiGraphql.reducer,
      [apiRestful.reducerPath]: apiRestful.reducer
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(apiRestful.middleware).concat(apiGraphql.middleware),
    ...options
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
