import { FC, useMemo } from 'react'

import { Chip } from '@opswat/react-ui'

import { RELEASE_INFORMATION_LEVEL, RELEASE_INFORMATION_LEVEL_LABELS } from 'myopswat-admin/src/api/product/types'

interface IProps {
  level: RELEASE_INFORMATION_LEVEL
}

const ReleaseInformationLevelChip: FC<IProps> = ({ level }) => {
  const stylesMap = useMemo(
    () => ({
      [RELEASE_INFORMATION_LEVEL.CRITICAL]: { backgroundColor: '#A6003C' },
      [RELEASE_INFORMATION_LEVEL.TIER_1]: { backgroundColor: '#006641' },
      [RELEASE_INFORMATION_LEVEL.TIER_2]: { backgroundColor: '#0D2654' },
      [RELEASE_INFORMATION_LEVEL.TIER_3]: { color: '#3D5176', backgroundColor: '#e7e9ee' }
    }),
    []
  )

  return (
    <Chip
      color="primary"
      sx={{
        ...stylesMap[level],
        fontSize: '12px',
        padding: '4px 16px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        '& .MuiChip-label': {
          padding: '0px',
          lineHeight: '12px'
        }
      }}
      label={RELEASE_INFORMATION_LEVEL_LABELS[level]}
      size="small"
    />
  )
}

export default ReleaseInformationLevelChip
