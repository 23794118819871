export const NPS_COLORS = {
  DETRACTORS: '#D00300',
  PASSIVES: '#FDBD0D',
  PROMOTERS: '#008A00',
  GOOD: '#92CD92',
  BACKGROUND: '#F8F8F8',
  GREEN: '#15A87C',
  RED: '#E46F6D',
  GRAY: '#707682'
}
