import { Box, Grid, Skeleton, TextGrid } from '@opswat/react-ui'
import React from 'react'

const RoleObjectsDetailLoading = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Box>
          <TextGrid
            label={'Code*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Name'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Permissions'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'My OPSWAT Component*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Reason*'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
        </Box>
      </Grid>
    </Grid>
  )
}
export default RoleObjectsDetailLoading
