import _get from 'lodash/get'

import { UseFormReturn } from 'react-hook-form'

import { InformationIcon } from '@opswat/react-icon'
import { Box, FormHelperText, Grid, InputAdornment, TextField, Tooltip, Typography } from '@opswat/react-ui'

import { NpsSettingsUpdateInput } from 'myopswat-admin/src/api/portalSettings/types'

interface IProps {
  isDisabled: boolean
  form: UseFormReturn<NpsSettingsUpdateInput>
}

const NpsSettings = ({ isDisabled, form }: IProps) => {
  const {
    register,
    formState: { errors }
  } = form

  return (
    <Grid item container xs={12} rowSpacing={2} sx={{ px: 2 }}>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontSize: '15px' }}>
          NPS Survey Period<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" color="#485161">
            Customers are requested to participate in the NPS survey every
          </Typography>
          <TextField
            size="small"
            type="number"
            disabled={isDisabled}
            InputProps={{
              sx: { width: '120px', maxWidth: '120px' },
              endAdornment: (
                <Tooltip
                  arrow
                  placement="top"
                  title="Set this field to 0 to disable the survey"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                >
                  <InputAdornment position="end">
                    <InformationIcon color="#707682" />
                  </InputAdornment>
                </Tooltip>
              )
            }}
            sx={{
              minHeight: 'auto',
              backgroundColor: isDisabled ? '#f4f4f5' : '#ffffff',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#a4a8ae'
              },
              '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: '#bdbdbd'
              }
            }}
            {...register('surveyPeriod')}
          />
          <Typography variant="subtitle2" color="#485161">
            day(s)
          </Typography>
        </Box>

        {!!_get(errors, 'surveyPeriod') && (
          <FormHelperText sx={{ color: '#D00300 !important', marginTop: 0.75 }}>
            {_get(errors, 'surveyPeriod.message', '')}
          </FormHelperText>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontSize: '15px' }}>
          Skip Survey<span style={{ color: '#D00300' }}>*</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle2" color="#485161">
            Upon declining the NPS survey, customer will not see it again for
          </Typography>
          <TextField
            size="small"
            type="number"
            disabled={isDisabled}
            InputProps={{
              sx: {
                width: '120px',
                maxWidth: '120px',
                backgroundColor: isDisabled ? '#f4f4f5' : '#ffffff',
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#a4a8ae'
                },
                '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#bdbdbd'
                }
              },
              endAdornment: (
                <Tooltip
                  arrow
                  placement="top"
                  title="Set this field to 0 to require a response and prevent skipping"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                >
                  <InputAdornment position="end">
                    <InformationIcon color="#707682" />
                  </InputAdornment>
                </Tooltip>
              )
            }}
            sx={{
              minHeight: 'auto'
            }}
            {...register('skipPeriod')}
          />
          <Typography variant="subtitle2" color="#485161">
            day(s)
          </Typography>
        </Box>

        {!!_get(errors, 'skipPeriod') && (
          <FormHelperText sx={{ color: '#D00300 !important', marginTop: 0.75 }}>
            {_get(errors, 'skipPeriod.message', '')}
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

export default NpsSettings
