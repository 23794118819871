import { gql } from 'graphql-request'
import { apiGraphql } from 'myopswat-admin/src/api/base-query'
import {
  AdminCustomerOrganizationAddUserInput,
  AdminCustomerOrganizationChangeUserRoleInput,
  AdminOrganizationAddUserInput,
  AdminOrganizationCreateInputType,
  AdminOrganizationQueryInput,
  AdminOrganizationRemoveUserInput,
  AdminOrganizationSetSuperAdminInput,
  AdminOrganizationUpdateInputType,
  AdminOrganizationUserQueryInput,
  AdminUserOrganizationQueryInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminOrganizations: builder.query<any, AdminOrganizationQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminOrganizationFiltersInput, $pageInfo: PageInfoType) {
            adminOrganizations(filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                name
                rank
                mdcLicenseType
                ssoId
                accountId
                opportunityId
              }
              totalCount
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    }),
    adminOrganizationsWithAccountIds: builder.query<any, AdminOrganizationQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminOrganizationFiltersInput, $pageInfo: PageInfoType) {
            adminOrganizationsWithAccountId(filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                name
                accountId
                ssoId
              }
              totalCount
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    }),
    adminUserOrganizations: builder.query<any, AdminUserOrganizationQueryInput>({
      query: ({ id, filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminUserOrganizationFiltersInput, $pageInfo: PageInfoType) {
            adminUserOrganizations(id: "${id}", filters: $filters, pageInfo: $pageInfo) {
              results {
                organization {
                  id
                  name
                  rank
                  accountId
                  mdcLicenseType
                  opportunityId
                }
              }
              totalCount
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    }),
    adminOrganization: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminOrganization(id: "${id}") {
              id
              name
              rank
              isOemCustomer
              showEnginePackage
              showKioskSecureImage
              isManageSubOrganization
              mdcLicenseType
              accountId
              opportunityId
              ssoId
              note
              accountType
              preferredPartner {
                id
                name
              }
              reseller {
                id
                name
                accountId
                ssoId
              }
              distributor {
                id
                name
                accountId
                ssoId
              }
              parentIds
              parentOrgInfo
              updatedAt
              createdAt
            }
        }
        `
      })
    }),
    adminUpdateOrganization: builder.mutation<any, AdminOrganizationUpdateInputType>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationUpdateInput!) {
            adminOrganizationUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminCreateOrganization: builder.mutation<any, AdminOrganizationCreateInputType>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationCreateInput!) {
            adminOrganizationCreate(input: $input) {
              errors
              success
              organization {
                id
              }
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminOrganizationUsers: builder.query<any, AdminOrganizationUserQueryInput>({
      query: ({ id, filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminOrganizationUserFiltersInput, $pageInfo: PageInfoType, $roleFilters: RoleFiltersInput) {
            adminOrganizationUsers(id: "${id}", filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                user {
                  id
                  firstName
                  lastName
                  email
                  ssoId
                  createdAt
                  metadata
                }
                email
                invitationId
                isActive
                roleIds
                lastLogin
                isSuperadmin
                portalRole
              }
              totalCount
            },
            roles(filters: $roleFilters) {
              id
              name
              ssoId
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo,
          roleFilters: {}
        }
      })
    }),
    adminOrganizationAddUser: builder.mutation<any, AdminOrganizationAddUserInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationAddUserInput!) {
            adminOrganizationAddUser(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminOrganizationRemoveUser: builder.mutation<any, AdminOrganizationRemoveUserInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationRemoveUserInput!) {
            adminOrganizationRemoveUser(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminOrganizationSetSuperAdmin: builder.mutation<any, AdminOrganizationSetSuperAdminInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationSetSuperAdminInput!) {
            adminOrganizationSetSuperAdmin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminPortalRoles: builder.query<any, any>({
      query: (roleFilters: any) => ({
        document: gql`
          query ($roleFilters: PortalRoleFiltersInput) {
            adminPortalRoles(filters: $roleFilters) {
              organizationPortalRoles {
                id
                name
                isDefault
              }
            }
          }
        `,
        variables: {
          roleFilters: roleFilters
        }
      })
    }),
    adminCustomerOrganizationAddUser: builder.mutation<any, AdminCustomerOrganizationAddUserInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminCustomerOrganizationAddUserInput!) {
            adminOrganizationAddUserCustomer(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminCustomerOrganizationChangeUserRole: builder.mutation<any, AdminCustomerOrganizationChangeUserRoleInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminOrganizationChangeUserRoleInput!) {
            adminOrganizationChangeUserRoleCustomer(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useAdminOrganizationsQuery,
  useAdminUserOrganizationsQuery,
  useAdminOrganizationQuery,
  useAdminUpdateOrganizationMutation,
  useAdminCreateOrganizationMutation,
  useAdminOrganizationUsersQuery,
  useLazyAdminOrganizationsWithAccountIdsQuery,
  useLazyAdminOrganizationUsersQuery,
  useAdminOrganizationAddUserMutation,
  useAdminOrganizationRemoveUserMutation,
  useAdminOrganizationSetSuperAdminMutation,
  useLazyAdminPortalRolesQuery,
  useAdminCustomerOrganizationAddUserMutation,
  useAdminCustomerOrganizationChangeUserRoleMutation
} = api
