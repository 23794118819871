import { handleConvertStringToBoolean } from '@myopswat/common'

export const ORGANIZATION_ADMIN_ROLE = '257d94a3-2aaf-4e89-8bf7-a118a7f6c0fd'
export const OPSWAT_ORGANIZATION_ID = 'bbf79160-8ec7-452f-b9f1-f692c8184e2c'
export const OPSWAT_ORGANIZATION_NAME = 'OPSWAT Inc'

export enum REDIRECT_MESSAGE_CODE {
  ACTIVE_USER_SUCCESS = 'S001',
  ACTIVE_USER_FAIL = 'E001',
  ACCEPT_INVITE_SUCCESS = 'S002'
}

export enum DIALOG_MESSAGES {
  organization_admin_permission = 'You must have admin permission of organization to perform this action.'
}

export enum AUTHENTICATION_CODES {
  jwt_signature_expired = 'jwt_signature_expired',
  account_not_authenticated = 'account_not_authenticated',
  invalid_token = 'invalid_token',
  session_error = 'session_error'
}

export enum SYSTEM_VALUE_KEYS {
  SUPPORT_CASE_PLATFORMS = 'support_case_platforms'
}

// Pagination
export const PAGE_DEFAULT = 0 // fetch data at page 1 but @MuiPagination has zero base index
export const PAGE_SIZE_DEFAULT = 20
export const PAGE_SIZES_DEFAULT = [10, 20, 50, 100]

export const EMPTY_STRING = '--'

export const isEnabledPOCFeatures = handleConvertStringToBoolean(process.env.REACT_APP_ENABLED_POC_FEATURES)
export const isFalseSubmissionDisabled = handleConvertStringToBoolean(process.env.REACT_APP_FALSE_SUBMISSION_DISABLED)

export enum EVENT_LOGS_TYPE {
  CREATED = 'Created',
  CHANGED = 'Changed',
  DELETED = 'Deleted'
}

export const EVENT_LOGS_TYPE_LABELS: any = {
  [EVENT_LOGS_TYPE.CREATED]: 'Added',
  [EVENT_LOGS_TYPE.CHANGED]: 'Changed',
  [EVENT_LOGS_TYPE.DELETED]: 'Deleted'
}

export enum EVENT_TIME_FRAME_FILTER {
  LAST_24H = 'last-24h',
  LAST_7D = 'last-7d',
  LAST_30D = 'last-30d',
  LAST_12M = 'last-12m',
  CUSTOM = 'custom'
}

export const EVENT_TIME_FRAME_FILTER_OPTIONS = [
  { label: `Last 24 hour`, value: EVENT_TIME_FRAME_FILTER.LAST_24H },
  { label: `Last 7 days`, value: EVENT_TIME_FRAME_FILTER.LAST_7D },
  { label: `Last 30 days`, value: EVENT_TIME_FRAME_FILTER.LAST_30D },
  { label: `Last 12 months`, value: EVENT_TIME_FRAME_FILTER.LAST_12M },
  { label: 'Custom', value: EVENT_TIME_FRAME_FILTER.CUSTOM }
]
