import { userManagementPageURL, opswatIncPath } from './userManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const opswatGroupListPath = `${opswatIncPath}/groups`
export const detailUserGroupPath = 'detail'
export const editUserGroupPath = 'edit'
export const userGroupIdPath = ':groupId'
export const modeUserGroupPath = ':mode'

/** ----------------- ROUTE -------------------- */
export const opswatGroupListPageURL = `${userManagementPageURL}/${opswatGroupListPath}`
export const opswatGroupDetailPageURL = `${opswatGroupListPageURL}/${userGroupIdPath}/${modeUserGroupPath}`
