import { useEffect, useMemo, useState } from 'react'
import {
  IFalseSubmissionAntivirusEngineFilter,
  IFalseSubmissionAntivirusEngineFilterInput
} from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT, PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'
import { useAdminFalseSubmissionAntivirusEnginesQuery } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import _isEmpty from 'lodash/isEmpty'
import { Chip, TableAdvanced, Typography, TypographyLink } from '@opswat/react-ui'
import FalseSubmissionAntivirusEngineFilter from './FalseSubmissionAntivirusEngineFilter'
import {
  dashboardFalseDetectionSubmissionAntivirusEngineListURL,
  falseDetectionDetailPath
} from 'myopswat-admin/src/routes'
import _get from 'lodash/get'
import { useNavigate } from 'react-router-dom'
import { useLazySysItemCountriesQuery } from '../../../../api/system'
import _find from 'lodash/find'
import { Helmet } from 'react-helmet'

const FalseSubmissionAntivirusEngine = () => {
  const navigate = useNavigate()
  const [query, setQuery] = useState<IFalseSubmissionAntivirusEngineFilterInput>({
    filters: {
      q: ''
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const [getSysItemCountries, { data: sysItemCountriesData }] = useLazySysItemCountriesQuery()

  const { data: falseSubmissionAntivirusEngineData, isFetching: isFetchingFalseSubmissionAntivirusEngines } =
    useAdminFalseSubmissionAntivirusEnginesQuery(query, {
      refetchOnMountOrArgChange: true
    })

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => ({ ...statePrev, ...{ pageInfo: { page: page, pageSize: pageSize } } }))
  }

  const handleSearch = (searchData: IFalseSubmissionAntivirusEngineFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const falseSubmissionAntivirusEngines = useMemo(() => {
    if (falseSubmissionAntivirusEngineData) {
      return falseSubmissionAntivirusEngineData.results
    }
    return []
  }, [falseSubmissionAntivirusEngineData])

  const totalCount = useMemo(() => {
    if (_isEmpty(falseSubmissionAntivirusEngineData)) {
      return 0
    }
    return falseSubmissionAntivirusEngineData.totalCount
  }, [falseSubmissionAntivirusEngineData])

  useEffect(() => {
    getSysItemCountries()
  }, [])

  const columns = useMemo(() => {
    return [
      {
        header: 'Engine Name',
        body: (data: any) => (
          <>
            <TypographyLink
              color="primary"
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                },
                overflowWrap: 'anywhere'
              }}
              variant="body2"
              onClick={() => {
                navigate(
                  `${dashboardFalseDetectionSubmissionAntivirusEngineListURL}/${data?.id}/${falseDetectionDetailPath}`
                )
              }}
            >
              {_get(data, 'engineName', '--')}
            </TypographyLink>
            {!data?.isActive && (
              <>
                &nbsp;
                <Chip label="Hidden" color="error" variant="outlined" size="small" />
              </>
            )}
          </>
        ),
        style: { minWidth: 85, width: '5%' }
      },
      {
        header: 'Vendor Name',
        body: (data: any) => (
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {data.vendorName}
          </Typography>
        ),
        style: { minWidth: 85, width: '5%' }
      },
      {
        header: 'Country',
        body: (data: any) => {
          const country = _find(sysItemCountriesData, (item: any) => _get(item, 'code') === _get(data, 'country', ''))
          return (
            <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
              {_get(country, 'name', '')}
            </Typography>
          )
        },
        style: { minWidth: 85, width: '5%' }
      },
      {
        header: 'Detection',
        body: (data: any) => (
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {data.detection}
          </Typography>
        ),
        style: { minWidth: 85, width: '5%' }
      },
      {
        header: 'Heuristic Detection',
        body: (data: any) => (
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            {data.heuristicDetection}
          </Typography>
        ),
        style: { minWidth: 85, width: '5%' }
      }
    ]
  }, [sysItemCountriesData])
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          Antivirus Engines
        </title>
      </Helmet>

      <FalseSubmissionAntivirusEngineFilter filters={query.filters} onSearch={handleSearch} />

      <TableAdvanced
        columns={columns}
        isLoading={isFetchingFalseSubmissionAntivirusEngines}
        data={falseSubmissionAntivirusEngines}
        isPagination
        total={totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePaginationOnChange}
      />
    </>
  )
}
export default FalseSubmissionAntivirusEngine
