import { DIALOGS } from '@myopswat/common'
import { Box, ButtonLoading, Chip, DialogAdvanced, FormControl, Grid, TextField } from '@opswat/react-ui'
import _get from 'lodash/get'
import { useCreateSubscriberMutation } from 'myopswat-admin/src/api/mailer'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  reloadData: () => void
}

const DialogNewSubscriber: FC<IProps> = ({ reloadData }) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [values, setValues] = useState<string[]>([])
  const [currentValue, setCurrentValue] = useState('')

  const [createSubscriber, { isLoading: isCreating }] = useCreateSubscriberMutation()

  const subscriberActionName = useMemo(() => {
    if (_get(dialogType, `${DIALOGS.NEW_SUBSCRIBER}_DATA`)) {
      const data: any = _get(dialogType, `${DIALOGS.NEW_SUBSCRIBER}_DATA`)
      return data?.actionName
    }
    return []
  }, [dialogType])

  const handleKeyUp = (e: any) => {
    // check whether pressed key is whitespace
    if (e.keyCode == 32) {
      setValues((oldState: string[]) => [...oldState, e.target.value])
      setCurrentValue('')
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }

  const handleDelete = (item: any, index: number) => {
    const temp = [...values]
    temp.splice(index, 1)
    setValues(temp)
  }

  const handleClose = (isRefetch = false) => {
    dispatch(
      toggleDialogs({
        [DIALOGS.NEW_SUBSCRIBER]: false,
        [`${DIALOGS.NEW_SUBSCRIBER}_DATA`]: ''
      })
    )
    setCurrentValue('')
    setValues([])
    if (isRefetch) {
      reloadData()
    }
  }

  const handleSubmit = async () => {
    const actionSlug = _get(_get(dialogType, `${DIALOGS.NEW_SUBSCRIBER}_DATA`), 'actionSlug', null)
    if (actionSlug) {
      const emails = currentValue ? values.concat([currentValue]) : values
      createSubscriber({
        actionSlug: actionSlug,
        emails: emails
      })
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            enqueueSnackbar(translate('addNewSubscriberSuccessfully'), { variant: 'success' })
            handleClose(true)
          } else if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('addNewSubscriberFailed')), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('addNewSubscriberFailed'), { variant: 'error' })
        })
    }
  }

  return (
    <DialogAdvanced
      title={`Add new subscriber for ${subscriberActionName}`}
      open={_get(dialogType, DIALOGS.NEW_SUBSCRIBER, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <FormControl sx={{ width: '100%' }}>
            <div className={'container'} style={{ marginBottom: 10 }}>
              {values.map((item, index) => (
                <Chip size="small" sx={{ mr: 1, my: 0.5 }} onDelete={() => handleDelete(item, index)} label={item} />
              ))}
            </div>
            <TextField value={currentValue} onChange={handleChange} onKeyDown={handleKeyUp} />
          </FormControl>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isCreating}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogNewSubscriber
