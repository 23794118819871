import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-admin/src/api/base-query'
import { AdminGroupInputType, AdminGroupQueryInput, IGroupFiltersInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    groups: builder.query<any, IGroupFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: GroupFiltersInput) {
            groups(filters: $filters) {
              id
              name
              description
              updatedAt
              createdAt
              users {
                id
                firstName
                lastName
              }
              roles {
                id
                name
                description
              }
              creatorId
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    groupCreate: builder.mutation<any, any>({
      query: ({ name, userIds, roleIds }) => ({
        document: gql`
          mutation ($input: GroupInput!) {
            groupCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            name: name,
            userIds: userIds || [],
            roleIds: roleIds || []
          }
        }
      })
    }),
    groupDelete: builder.mutation<any, string>({
      query: id => ({
        document: gql`
        mutation {
          groupDelete(id: "${id}") {
              success
              errors
          }
      }
        `
      })
    }),
    adminGroups: builder.query<any, AdminGroupQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminGroupFiltersInput, $pageInfo: PageInfoType) {
            adminGroups(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                name
                description
                createdType
                organization {
                  id
                  name
                }
                users {
                  id
                  firstName
                  lastName
                }
                roles {
                  id
                  name
                  description
                }
                updatedAt
                createdAt
                creatorId
              }
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    }),
    adminGroup: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminGroup(id: "${id}") {
              id
              name
              description
              createdType
              organization {
                id
                name
              }
              users {
                id
                email
                firstName
                lastName
              }
              roles {
                id
                name
                description
              }
              updatedAt
              createdAt
              creatorId
              ownerIds
            }
          }
        `
      })
    }),
    adminGroupUpdate: builder.mutation<any, AdminGroupInputType>({
      query: input => ({
        document: gql`
          mutation groupUpdate($input: GroupInput!) {
            groupUpdate(input: $input) {
              errors
              success
              group {
                id
                name
                description
                organization {
                  id
                  name
                }
                users {
                  id
                  firstName
                  lastName
                }
                roles {
                  id
                  name
                }
                updatedAt
                createdAt
                creatorId
              }
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useGroupsQuery,
  useAdminGroupUpdateMutation,
  useGroupCreateMutation,
  useGroupDeleteMutation,
  useAdminGroupsQuery,
  useAdminGroupQuery
} = api
