import { createSearchParams, useNavigate } from 'react-router-dom'

import { ButtonLoading, TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import useCheckPermissions from 'myopswat-admin/src/hooks/useCheckPermissions'
import MassNotificationProvider from 'myopswat-admin/src/pages/MassNotificationPage/context'
import {
  notificationManagementComposeEmailBaseURL,
  notificationManagementMassNotificationPageURL
} from 'myopswat-admin/src/routes'

const MassNotificationContainer = () => {
  const navigate = useNavigate()
  const { permission } = useCheckPermissions({
    targetPerrmissions: ['add_massmail', 'change_massmail', 'delete_massmail', 'view_massmail']
  })

  const tabArray = [
    {
      label: 'Emails',
      content: <MassNotificationProvider permission={permission} />,
      tabPath: notificationManagementMassNotificationPageURL
    }
  ]

  return (
    <>
      <TypographyDivider isDivider={false} label="Email Communication" />
      <TabAdvanced
        borderBottom="1px solid #E9EAEB"
        tabs={tabArray}
        actions={
          permission['add_massmail'] ? (
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
                onClick: () => {
                  navigate({
                    pathname: notificationManagementComposeEmailBaseURL,
                    search: createSearchParams({ mode: 'create' }).toString()
                  })
                },
                sx: { marginBottom: 1 }
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Compose Email
            </ButtonLoading>
          ) : (
            <></>
          )
        }
      />
    </>
  )
}

export default MassNotificationContainer
