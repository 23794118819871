import React, { FC, useState } from 'react'

import { TemplateFormLogin, TemplateSection, Box, Typography, Grid, TypographyLink } from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'

import { useNavigate } from 'react-router-dom'

import { loginPageURL } from 'myopswat-admin/src/routes'
import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'

import StepOTP from './StepOTP'
import StepRecoveryCode from './StepRecoveryCode'

interface IProps {
  isLoadingLogin: boolean
  handleVerifyOTP: (data: string) => void
  handleRecoveryCode: (data: string) => void
}

const VerifyOTPPage: FC<IProps> = ({ isLoadingLogin, handleVerifyOTP, handleRecoveryCode }) => {
  const navigate = useNavigate()

  const [step, setStep] = useState<'otp' | 'code'>('otp')

  return (
    <TemplateFormLogin background={imageBg}>
      <TemplateSection spacing={4}>
        <Box>
          <OpswatIcon />

          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <TypographyLink
              onClick={() => {
                if (step === 'code') {
                  setStep('otp')
                } else {
                  navigate(loginPageURL)
                }
              }}
            >
              Back
            </TypographyLink>
          </Box>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={step === 'otp' ? 6 : 5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Sign in to My OPSWAT</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Manage multiple OPSWAT products remotely, improving productivity flows, providing better visibility
                    between team members and more.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={step === 'otp' ? 6 : 7}>
              {step === 'otp' && (
                <StepOTP setStep={setStep} isLoadingLogin={isLoadingLogin} handleVerifyOTP={handleVerifyOTP} />
              )}

              {step === 'code' && (
                <StepRecoveryCode isLoadingLogin={isLoadingLogin} handleRecoveryCode={handleRecoveryCode} />
              )}
            </Grid>
          </Grid>
        </Box>
      </TemplateSection>
    </TemplateFormLogin>
  )
}

export default VerifyOTPPage
