import { DIALOGS } from '@myopswat/common'
import {
  Box,
  ButtonLoading,
  DialogAdvanced,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from '@opswat/react-ui'
import _get from 'lodash/get'
import { useAdminFalseSubmissionUpdateMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { FalseSubmissionValidityEnum } from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  reloadData: () => void
}

const DialogFalseDetectionSetValidity: FC<IProps> = ({ reloadData }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedItem, setSelectedItem] = useState<string>('')

  const falseSubmissionValidity = useMemo(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`)) {
      const data = _get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`)
      return _get(data, 'validity')
    }
    return ''
  }, [dialogType])

  useEffect(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`)) {
      const data = _get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`)
      const validity = _get(data, 'validity', '')
      setSelectedItem(validity)
    }
  }, [dialogType])

  const handleClose = (isRefetch = false) => {
    if (isRefetch) {
      reloadData()
    }
    setSelectedItem('')
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_SET_VALIDITY]: false,
        [`${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`]: ''
      })
    )
  }

  const [submitFalseSubmissionUpdate, { isLoading }] = useAdminFalseSubmissionUpdateMutation()

  const validityOptions: any[] = useMemo(() => {
    const results: any = []
    Object.keys(FalseSubmissionValidityEnum).forEach((key: string) => {
      results.push({
        value: key,
        label: (FalseSubmissionValidityEnum as any)[key],
        disabled: false
      })
    })
    return results
  }, [])

  const handleSubmit = async () => {
    if (selectedItem) {
      const false_submission_id = _get(_get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_VALIDITY}_DATA`), 'id', '')
      submitFalseSubmissionUpdate({
        id: false_submission_id,
        validity: selectedItem
      })
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            enqueueSnackbar(translate('updateValiditySuccessfully'), { variant: 'success' })
            handleClose(true)
          } else {
            if (_get(response, 'errors.0.message')) {
              enqueueSnackbar(_get(response, 'errors.0.message', translate('updateValidityFailed')), {
                variant: 'error'
              })
            }
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('updateValidityFailed'), { variant: 'error' })
        })
    } else {
      enqueueSnackbar('Please select an item', { variant: 'warning' })
    }
  }

  return (
    <DialogAdvanced
      title={'Set validity'}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_SET_VALIDITY, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <FormControl key="false-detection-validity-group">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={falseSubmissionValidity}
              onChange={(event: ChangeEvent<HTMLInputElement>) => setSelectedItem(event.target.value)}
            >
              {validityOptions.map((item: any, idx: number) => {
                return (
                  <FormControlLabel
                    key={idx.toString()}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                    disabled={item.disabled}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
    />
  )
}
export default DialogFalseDetectionSetValidity
