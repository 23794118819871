import React, { FC } from 'react'
import { OverflowTooltip } from '@opswat/react-ui'

interface AccountOpportunityTooltipProps {
  accountId?: string
  opportunityId?: string
}

const AccountOpportunityTooltip: FC<AccountOpportunityTooltipProps> = ({ accountId, opportunityId }) => {
  const tooltipValue = `${accountId || '--'} / ${opportunityId || '--'}`

  return (
    <OverflowTooltip tooltipValue={tooltipValue} sx={{ maxWidth: '250px' }}>
      {tooltipValue}
    </OverflowTooltip>
  )
}

export default AccountOpportunityTooltip
