import { useNavigate } from 'react-router-dom'
import _get from 'lodash/get'

import { ButtonLoading, TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import useCheckPermissions from 'myopswat-admin/src/hooks/useCheckPermissions'
import ReleaseInformationProvider from 'myopswat-admin/src/pages/SystemManagementPage/ReleaseInformationPage/context'
import ReleaseInformationEventLogs from 'myopswat-admin/src/pages/SystemManagementPage/ReleaseInformationPage/ReleaseInformationEventLogs'
import {
  systemManagementReleaseInformationEventLogsPageURL,
  systemManagementReleaseInformationPageURL
} from 'myopswat-admin/src/routes'

const ReleaseInformationContainer = () => {
  const navigate = useNavigate()

  const { permission } = useCheckPermissions({
    targetPerrmissions: [
      'view_release_description',
      'add_release_description',
      'change_release_description',
      'delete_release_description'
    ]
  })

  const tabArray = [
    {
      label: 'Release information',
      content: <ReleaseInformationProvider permission={permission} />,
      tabPath: systemManagementReleaseInformationPageURL
    },
    {
      label: 'Event Logs',
      content: <ReleaseInformationEventLogs />,
      tabPath: systemManagementReleaseInformationEventLogsPageURL
    }
  ]

  return (
    <>
      <TypographyDivider isDivider={false} label="Release Information" />
      <TabAdvanced
        borderBottom="1px solid #E9EAEB"
        tabs={tabArray}
        actions={
          _get(permission, 'add_release_description', false) ? (
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
                onClick: () => {
                  navigate(`${systemManagementReleaseInformationPageURL}/create`)
                },
                sx: { marginBottom: 1 }
              }}
              propsLoading={{ color: 'inherit' }}
            >
              New Release Information
            </ButtonLoading>
          ) : (
            <></>
          )
        }
      />
    </>
  )
}

export default ReleaseInformationContainer
