import { TemplateSection, TypographyDivider } from '@opswat/react-ui'
import OrganizationGroups from 'myopswat-admin/src/pages/UserManagementPage/Group'
import { useParams } from 'react-router-dom'

interface IProps {
  isNotTitle?: boolean
}

const OrganizationGroupListContainer = ({ isNotTitle }: IProps) => {
  const { userId = '' } = useParams()

  return (
    <TemplateSection>
      {!isNotTitle && <TypographyDivider label="User Management - Groups" />}

      <OrganizationGroups userId={userId} />
    </TemplateSection>
  )
}

export default OrganizationGroupListContainer
