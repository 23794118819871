import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BLOB_FILE_TYPES, handleCreateFile } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { RefreshIcon } from '@opswat/react-icon'
import {
  AutocompleteMultiple,
  Box,
  ButtonLoading,
  CheckboxWithLabel,
  CircularLoading,
  CustomTab,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
  TemplateSection,
  TextField,
  Typography
} from '@opswat/react-ui'

import {
  useLazyNpsSurveyProductsQuery,
  useLazyNpsSurveyQuery,
  useLazyNpsSurveyResponsesExcelReportQuery,
  useLazyNpsSurveyResponsesQuery
} from 'myopswat-admin/src/api/survey'
import { INPSSurveyQueryInput } from 'myopswat-admin/src/api/survey/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { useTypedSelector } from 'myopswat-admin/src/store'

import NPSResponsesTab from './NPSSurveyTabs/NPSResponsesTab'
import NPSSummaryTab from './NPSSurveyTabs/NPSSummaryTab'

type INPSSurveyQueryFormInput = Omit<INPSSurveyQueryInput, 'surveyId' | 'productIds'> & {
  productOptions: { label: string; value: string }[]
}

const DEFAULT_PRODUCT_OPTION_GENERAL = {
  id: 'general',
  name: 'General'
}

const TIME_FILTER_OPTIONS = [
  { label: `From April 17, 2024 to current`, value: 'UP_TO_CURRENT' },
  { label: 'Custom', value: 'CUSTOM' }
]

const NPSSurvey = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { surveyId } = useParams()
  const npsSurveyId = surveyId ?? '696ea1eb-3053-4f63-b146-9363e6035d5a'

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const userPermissions: string[] = _get(profileData, 'permissions', [])
  const hasExportPermission = userPermissions.includes('export_survey')

  const [updatedTime, setUpdatedTime] = useState('')
  const [hasNpsExcelReportRequest, setHasNpsExcelReportRequest] = useState(false)
  const [getProducts, { data: products, isFetching: isFetchingProducts }] = useLazyNpsSurveyProductsQuery()
  const [getNPSSurvey, { data: npsSurvey, isFetching: isFetchingNPSSurvey }] = useLazyNpsSurveyQuery()
  const [getNPSSurveyResponses, { data: responseList, isFetching: isFetchingResponseList }] =
    useLazyNpsSurveyResponsesQuery()
  const [getNpsExcelReportData, { data: npsExcelReportData, isFetching: isFetchingNpsExcelReportData, isError }] =
    useLazyNpsSurveyResponsesExcelReportQuery()

  const [pageInfo, setPageInfo] = useState<any>({
    page: PAGE_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT
  })

  const [sortInfo, setSortInfo] = useState<{ order: 'asc' | 'desc'; orderBy: string | null }>({
    order: 'asc',
    orderBy: null
  })

  const defaultQueryValues = useMemo<INPSSurveyQueryFormInput>(
    () => ({
      productOptions: [],
      startDate: '',
      endDate: '',
      excludeInternal: true
    }),
    []
  )

  const {
    control,
    register,
    watch,
    formState: { errors }
  } = useForm<INPSSurveyQueryFormInput>({
    defaultValues: defaultQueryValues
  })

  const productOptions = useMemo(() => {
    if (Array.isArray(products)) {
      return [DEFAULT_PRODUCT_OPTION_GENERAL, ...products]
    }
    return []
  }, [_isEmpty(products)])

  useEffect(() => {
    getProducts(npsSurveyId)
  }, [])

  useEffect(() => {
    const onSearch = _debounce(handleSearch, 300)
    onSearch()
    return () => onSearch.cancel()
  }, [watch('productOptions'), watch('timeRangeType'), watch('startDate'), watch('endDate'), watch('excludeInternal')])

  useEffect(() => {
    if (hasNpsExcelReportRequest && _get(npsExcelReportData, 'content') && !isFetchingNpsExcelReportData) {
      enqueueSnackbar(t('downloadNpsSurveyResponseExcelReportSuccess'), {
        variant: 'success'
      })

      handleCreateFile(
        _get(npsExcelReportData, 'filename', 'NPS_Report'),
        'xlsx',
        _get(npsExcelReportData, 'content', ''),
        {
          type: BLOB_FILE_TYPES.EXCEL
        }
      )
      setHasNpsExcelReportRequest(false)
    } else if (hasNpsExcelReportRequest && isError && !isFetchingNpsExcelReportData) {
      enqueueSnackbar(t('downloadNpsSurveyResponseExcelReportFail'), {
        variant: 'error'
      })
      setHasNpsExcelReportRequest(false)
    }
  }, [hasNpsExcelReportRequest, isError, npsExcelReportData, isFetchingNpsExcelReportData])

  const getNpsRequestFilters = useCallback(() => {
    const productOptions = watch('productOptions') || []
    const excludeInternal = watch('excludeInternal') || false
    let startDate = watch('startDate')
    let endDate = watch('endDate')
    if (watch('timeRangeType') === 'UP_TO_CURRENT') {
      startDate = ''
      endDate = ''
    }

    const productIds = productOptions.map(item => item.value)

    const request: INPSSurveyQueryInput = {
      productIds,
      startDate,
      endDate,
      excludeInternal,
      surveyId: npsSurveyId
    }

    return request
  }, [])

  const handleSearch = useCallback(
    (paging?: any, sortData?: any) => {
      const requestFilters = getNpsRequestFilters()
      const requestPageInfo = paging ?? { ...pageInfo, page: PAGE_DEFAULT }
      let _sortData = sortData ?? sortInfo
      if (!paging) {
        setPageInfo(requestPageInfo)
        _sortData = { order: 'asc', orderBy: null }
        setSortInfo(_sortData)
        getNPSSurvey(requestFilters)
      }
      getNPSSurveyResponses({ filters: requestFilters, pageInfo: requestPageInfo, sortInfo: _sortData })
      updateTimeReload()
    },
    [pageInfo, sortInfo]
  )

  const updateTimeReload = useCallback(
    () => setUpdatedTime(`Updated: ${formatDatetime(Date.now(), 'MMM dd, hh:mm a')}`),
    []
  )

  const handleExportNpsReportExcel = useCallback(() => {
    const requestFilters = getNpsRequestFilters()
    getNpsExcelReportData(requestFilters)
    setHasNpsExcelReportRequest(true)
  }, [])

  const refreshData = useCallback(() => handleSearch(), [])

  const showScheduleTime = watch('timeRangeType') === 'CUSTOM'

  return (
    <TemplateSection>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 1 }}>
            <Typography>{updatedTime}</Typography>
            <IconButton onClick={refreshData}>
              {isFetchingProducts || isFetchingNPSSurvey ? (
                <Box sx={{ paddingLeft: 1, paddingRight: 1.5 }}>
                  <CircularLoading />
                </Box>
              ) : (
                <RefreshIcon />
              )}
            </IconButton>
            {hasExportPermission && (
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleExportNpsReportExcel,
                  disabled: hasNpsExcelReportRequest,
                  disableElevation: true
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={isFetchingNpsExcelReportData}
              >
                Export
              </ButtonLoading>
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box p={3} sx={{ border: '1px solid #E9EAEB' }}>
            <Grid container rowSpacing={2} columnSpacing={4}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Product</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="productOptions"
                      control={control}
                      render={({ field }) => (
                        <AutocompleteMultiple
                          placeholder="All Responses"
                          options={
                            productOptions?.map((option: any) => ({
                              label: option.name,
                              value: option.id
                            })) ?? []
                          }
                          limitTags={2}
                          sx={{ minWidth: 'initial' }}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography textAlign="start">Total Responses</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {isFetchingNPSSurvey ? (
                      <Skeleton animation="wave" variant="rectangular" width="100%" height="36px" />
                    ) : (
                      <Typography
                        textAlign="start"
                        sx={{
                          fontSize: '20px',
                          lineHeight: '36px',
                          marginBottom: '0px'
                        }}
                        paragraph
                      >
                        {_get(npsSurvey, 'totalResponses', 0)}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>Time Range</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="timeRangeType"
                      defaultValue="UP_TO_CURRENT"
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          {_map(TIME_FILTER_OPTIONS, item => (
                            <FormControlLabel
                              key={_get(item, 'value')}
                              value={_get(item, 'value')}
                              control={<Radio size="small" />}
                              label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                              sx={{
                                width: 'fit-content'
                              }}
                            />
                          ))}
                        </RadioGroup>
                      )}
                    />

                    {showScheduleTime && (
                      <Grid container columnSpacing={4} sx={{ marginTop: 1 }}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" marginBottom={1}>
                                From
                              </Typography>
                              <TextField
                                {...register('startDate')}
                                fullWidth
                                size="small"
                                error={!!_get(errors, 'startDate', '')}
                                helperText={_get(errors, 'startDate.message', '')}
                                type="date"
                                sx={{ minHeight: '0px' }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography variant="subtitle2" marginBottom={1}>
                                To
                              </Typography>
                              <TextField
                                {...register('endDate')}
                                fullWidth
                                size="small"
                                error={!!_get(errors, 'endDate', '')}
                                helperText={_get(errors, 'endDate.message', '')}
                                type="date"
                                sx={{ minHeight: '0px' }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ marginX: '4px' }}>
                <Controller
                  name="excludeInternal"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CheckboxWithLabel
                      label={'Exclude responses from users who have email domain @opswat.com'}
                      checkboxProps={{ onChange, checked: value }}
                      restProps={{
                        sx: {
                          width: 'fit-content'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box px={3} py={2} sx={{ border: '1px solid #E9EAEB' }}>
            <CustomTab
              defaultTab={'SUMMARY'}
              tabs={[
                {
                  label: <Typography>Summary</Typography>,
                  value: 'SUMMARY',
                  content: isFetchingNPSSurvey ? (
                    <Grid item xs={12}>
                      <Skeleton animation="wave" variant="rectangular" width="100%" height="450px" />
                    </Grid>
                  ) : (
                    <NPSSummaryTab npsSurvey={npsSurvey} />
                  )
                },
                {
                  label: <Typography>Responses</Typography>,
                  value: 'RESPONSES',
                  content: (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <NPSResponsesTab
                          pageInfo={pageInfo}
                          products={products}
                          sortInfo={sortInfo}
                          setSortInfo={setSortInfo}
                          responseList={responseList}
                          isFetchingResponseList={isFetchingResponseList}
                          setPageInfo={setPageInfo}
                          handleSearch={handleSearch}
                        />
                      </Grid>
                    </Grid>
                  )
                }
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default NPSSurvey
