import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime, fullName } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider,
  TypographyLinkClamp
} from '@opswat/react-ui'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { useAdminOrganizationUsersQuery } from 'myopswat-admin/src/api/organizationManagement'
import {
  AdminOrganizationUserFilter,
  AdminOrganizationUserQueryInput
} from 'myopswat-admin/src/api/organizationManagement/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementUserListPageURL,
  detailCustomerUserPath,
  opswatUserListPageURL
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddUserDialog from './AddUser/AddUserDialog'
import OrganizationUserFilterSection from './FiltersSection'
import RemoveUserDialog from './RemoveUser/RemoveUserDialog'
import SetSuperAdminDialog from './SetSuperAdmin/SetSuperAdminDialog'

interface OrganizationUsersProps {
  orgId: string
  setSuperAdmin?: boolean
}

const OrganizationUsers = ({ orgId, setSuperAdmin }: OrganizationUsersProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false)
  const [openRemovedUserDialog, setOpenRemoveUserDialog] = useState(false)
  const [openSetSuperAdminDialog, setOpenSetSuperAdminDialog] = useState(false)
  const [selectedOrgUser, setSelectedOrgUser] = useState<any>(null)
  const navigate = useNavigate()

  const [filter] = useState<AdminOrganizationUserFilter>({
    q: ''
  })
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isRemoveOrganizationUserDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_organization')
    }
    return false
  }, [profileData])

  const [query, setQuery] = useState<AdminOrganizationUserQueryInput>({
    id: orgId,
    filters: filter,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const { data, isFetching, refetch } = useAdminOrganizationUsersQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const handleRemoveUser = (orgUser: any) => {
    setSelectedOrgUser(orgUser)
    setOpenRemoveUserDialog(true)
  }

  const handleSetSuperAdmin = (orgUser: any) => {
    setSelectedOrgUser(orgUser)
    setOpenSetSuperAdminDialog(true)
  }

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const orgUsers = useMemo(() => {
    if (_isEmpty(data)) {
      return {
        totalCount: 0,
        results: []
      }
    }

    return data.adminOrganizationUsers
  }, [data])

  const roleMap = useMemo(() => {
    if (_isEmpty(data)) return {}

    const _roleMap = data.roles.reduce((obj: any, role: any) => {
      obj[role.id] = role
      return obj
    }, {})

    // Add Super Admin role => which is still an Admin role
    if (setSuperAdmin) {
      for (const key in _roleMap) {
        if (_roleMap[key].name === 'Admin') {
          _roleMap['superAdmin'] = { ..._roleMap[key], name: 'Super Admin' }
        }
      }
    }

    return _roleMap
  }, [data])

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => {
          return data.userId ? (
            <TypographyLinkClamp
              onClick={() => {
                if (isOpswatIncRoute) {
                  navigate(`${opswatUserListPageURL}/${data.userId}/${detailCustomerUserPath}`)
                } else {
                  navigate(`${customerManagementUserListPageURL}/${data.userId}/${detailCustomerUserPath}`)
                }
              }}
            >
              {data.name || '--'}
            </TypographyLinkClamp>
          ) : (
            <Typography variant="body2">--</Typography>
          )
        },

        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Email',
        body: (data: any) => <Typography variant="body2">{data.email}</Typography>,
        style: { minWidth: 550, width: 550 }
      },
      {
        header: 'Active',
        body: (data: any) => (
          <Typography
            sx={{
              color: data.isActive ? 'success.dark' : 'warning.dark'
            }}
            variant="body2"
          >
            {data.isActive ? 'Active' : 'Pending'}
          </Typography>
        ),
        style: { minWidth: 200, width: 200, textAlign: 'center' }
      },
      {
        header: 'Last Login',
        body: (data: any) => (
          <Typography variant="body2">{formatDatetime(data.lastLogin, DATE_FORMATS.DATE)}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Created At',
        body: (data: any) => (
          <Typography variant="body2">{formatDatetime(data.createdAt, DATE_FORMATS.DATE)}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: any) => {
          return data.invitationId && !data.isActive ? (
            <></>
          ) : (
            <Dropdown
              button={
                <IconButton>
                  <OptionIcon />
                </IconButton>
              }
              content={
                <List>
                  {/* Remove User */}
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleRemoveUser(data)
                      }}
                      disabled={!data.isRemovable}
                    >
                      Remove
                    </ListItemButton>
                  </ListItem>

                  {/* Set Super Admin */}
                  {setSuperAdmin && (
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          handleSetSuperAdmin(data)
                        }}
                        disabled={data.isSuperAdmin || !data.isAdmin}
                      >
                        Set As Super Admin
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              }
            />
          )
        },
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [isOpswatIncRoute])

  const tableData = useMemo(() => {
    return _map(orgUsers.results, (orgUser: any) => {
      const user = orgUser.user
      const rolesMap = orgUser.roleIds.map((role: string) => _get(roleMap, `${role}.name`, ''))
      const role: string = _get(rolesMap, '0', '')

      const isRemovable = !isRemoveOrganizationUserDisabled && (!orgUser.isSuperadmin || orgUsers.totalCount === 1)

      return {
        id: orgUser.id,
        invitationId: orgUser.invitationId,
        userId: user?.id,
        name: user ? fullName(user.firstName, user.lastName) : undefined,
        email: user ? user.email : orgUser.email,
        isSuperAdmin: orgUser.isSuperadmin,
        role,
        isAdmin: role.toLocaleLowerCase() === 'admin',
        roles: orgUser.roleIds.map((role: string) => _get(roleMap, `${role}.name`, '')).join(', '),
        lastLogin: formatDatetime(user.metadata?.last_admin_login) || '--',
        isActive: orgUser.isActive,
        isRemovable,
        createdAt: user ? user.createdAt : undefined
      }
    })
  }, [orgUsers])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const handleSearch = (searchData: AdminOrganizationUserFilter) => {
    setQuery(prev => ({
      ...prev,
      filters: searchData,
      pageInfo: {
        page: PAGE_DEFAULT,
        pageSize: PAGE_SIZE_DEFAULT
      }
    }))
  }

  const handleAddUser = () => {
    setOpenAddUserDialog(true)
  }

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false)
  }

  const handleCloseRemoveUserDialog = () => {
    setOpenRemoveUserDialog(false)
  }

  const handleCloseSetSuperAdminDialog = () => {
    setOpenSetSuperAdminDialog(false)
  }

  const handleAddUserSuccess = () => {
    // Hide dialog
    handleCloseAddUserDialog()
    // Refetch data
    refetch()
  }

  const handleRemoveUserSuccess = () => {
    // Hide dialog
    handleCloseRemoveUserDialog()
    // Refetch data
    refetch()
  }

  const handleSetSuperAdminSuccess = () => {
    // Hide dialog
    handleCloseSetSuperAdminDialog()
    // Refetch data
    refetch()
  }

  return (
    <TemplateSection>
      <TypographyDivider label="User Management - Users" />

      <OrganizationUserFilterSection filters={query.filters} onSearch={handleSearch} onAddUser={handleAddUser} />

      <TableAdvanced
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        isPagination
        total={orgUsers.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
      <AddUserDialog
        orgId={orgId}
        show={openAddUserDialog}
        roleMap={roleMap}
        onClose={handleCloseAddUserDialog}
        onSuccess={handleAddUserSuccess}
      />
      <RemoveUserDialog
        show={openRemovedUserDialog}
        orgUser={selectedOrgUser}
        onClose={handleCloseRemoveUserDialog}
        onSuccess={handleRemoveUserSuccess}
      />
      <SetSuperAdminDialog
        show={openSetSuperAdminDialog}
        orgUser={selectedOrgUser}
        onClose={handleCloseSetSuperAdminDialog}
        onSuccess={handleSetSuperAdminSuccess}
      />
    </TemplateSection>
  )
}
export default OrganizationUsers
