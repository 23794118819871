import _get from 'lodash/get'

import { useMemo } from 'react'
import { Helmet } from 'react-helmet'

import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { useAdminOrganizationQuery } from 'myopswat-admin/src/api/organizationManagement'
import {
  customerManagementOrganizationListPageURL,
  customerUsersPath,
  detailCustomerOrganizationPath,
  tabCustomerOrganizationCustomersPath,
  tabCustomerOrganizationHistoryPath,
  tabCustomerOrganizationSubOrganizationsPath
} from 'myopswat-admin/src/routes'

import OrganizationBasicInformation from '../BasicInfo'
import OrganizationCustomers from '../Customers'
import OrganizationHistoryListPage from '../History'
import SubOrganization from '../SubOrganizations'
import OrganizationCustomerUsers from '../Users/CustomerUsers'

interface OrganizationDetailProps {
  orgId: string
}

const OrganizationDetail = ({ orgId }: OrganizationDetailProps) => {
  const { data: organization } = useAdminOrganizationQuery(orgId)

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Organizations',
        path: customerManagementOrganizationListPageURL
      },
      {
        label: _get(organization, 'name')
      }
    ]
  }, [organization])

  const tabArray = useMemo(
    () => [
      {
        label: 'Basic Information',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Organization Information'}
              </title>
            </Helmet>
            <OrganizationBasicInformation orgId={orgId} />
          </>
        ),
        tabPath: `${customerManagementOrganizationListPageURL}/${orgId}/${detailCustomerOrganizationPath}`
      },
      {
        label: 'User List',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Organization User List'}
              </title>
            </Helmet>
            <OrganizationCustomerUsers orgId={orgId} setSuperAdmin />
          </>
        ),
        tabPath: `${customerManagementOrganizationListPageURL}/${orgId}/${customerUsersPath}`
      },
      {
        label: 'Organization History',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Organization History'}
              </title>
            </Helmet>
            <OrganizationHistoryListPage orgId={orgId} />
          </>
        ),
        tabPath: `${customerManagementOrganizationListPageURL}/${orgId}/${tabCustomerOrganizationHistoryPath}`
      },
      {
        label: 'Customers',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Organization Customers'}
              </title>
            </Helmet>
            <OrganizationCustomers orgId={orgId} />
          </>
        ),
        tabPath: `${customerManagementOrganizationListPageURL}/${orgId}/${tabCustomerOrganizationCustomersPath}`
      },
      {
        label: 'Sub Organizations',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Sub Organizations'}
              </title>
            </Helmet>
            <SubOrganization orgId={orgId} orgData={organization} />
          </>
        ),
        tabPath: `${customerManagementOrganizationListPageURL}/${orgId}/${tabCustomerOrganizationSubOrganizationsPath}`
      }
    ],
    [orgId, organization]
  )

  return (
    <>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />
      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default OrganizationDetail
