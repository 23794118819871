import _debounce from 'lodash/debounce'
import _map from 'lodash/map'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'
import { IAdminUserFilter } from 'myopswat-admin/src/api/userManagement/types'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CUSTOMER_MANAGEMENT_FILTER, CUSTOMER_MANAGEMENT_FILTER_LABELS } from '../constants'

interface FilterSectionProps {
  filters: IAdminUserFilter
  onSearch: (searchData: IAdminUserFilter) => void
  onRenderExportButton: () => JSX.Element
}

const BoxUserListFilter = ({ filters, onSearch, onRenderExportButton }: FilterSectionProps) => {
  const { t: translate } = useTranslation()
  const defaultFilter = {
    q: '',
    usersOf: [],
    excludeInternal: false
  }

  const {
    control,
    reset,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm<IAdminUserFilter>({
    mode: 'onChange',
    defaultValues: defaultFilter,
    values: filters
  })

  const q = watch('q')
  const usersOf = watch('usersOf')

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const handleSearch = useCallback(() => {
    const searchData = {
      ...getValues(),
      usersOf: usersOf,
      q: q
    }
    onSearch(searchData)
  }, [q, usersOf])

  useEffect(() => {
    if (isChangedTextSearch) {
      const _search = _debounce(handleSearch, 300)

      _search()

      return () => {
        _search.cancel()
      }
    }
  }, [isChangedTextSearch, handleSearch])

  const handleApplyFilter = useCallback(() => {
    handleSearch()
  }, [handleSearch])

  const renderCheckboxFilter = useCallback(
    (name: any, label: string, enumType: any, enumLabels: any) => {
      return (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{label}</Typography>
          </Grid>
          {_map(Object.values(enumType), (item: any, idx: any) => {
            return (
              <Grid item xs={12} spacing={2} key={idx.toString()}>
                <Controller
                  name={name}
                  control={control}
                  render={({ field: { value, onChange } }: any) => (
                    <CheckboxWithLabel
                      label={
                        <Typography variant="subtitle2" textTransform="capitalize">
                          {enumLabels[item] || '--'}
                        </Typography>
                      }
                      checkboxProps={{
                        checked: watch(name).includes(item),
                        onChange: (e: any) => {
                          if (e.target.checked) {
                            onChange([...value, item])
                          } else {
                            onChange(value.filter((status: any) => status !== item))
                          }
                        },
                        sx: {
                          marginLeft: '12px'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            )
          })}
        </>
      )
    },
    [control, watch]
  )

  const renderOtherFilter = useCallback(() => {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Other</Typography>
        </Grid>
        <Grid item xs={12} spacing={2}>
          <Controller
            name='excludeInternal'
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={
                  <Typography variant="subtitle2" textTransform="capitalize">
                    Exclude internal
                  </Typography>
                }
                checkboxProps={{
                  checked: watch('excludeInternal'),
                  onChange: (e: any) => {
                    onChange(e.target.checked)
                  },
                  sx: {
                    marginLeft: '12px'
                  }
                }}
              />
            )}
          />
        </Grid>
      </>
    )
  }, [control])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextFieldSearch
              placeholder={translate('filterUserName/Email') || ''}
              value={value}
              onChange={e => onChange(e.target.value)}
              onClearText={() => onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <Grid container>
          <Grid item xs="auto">
          <DropdownFilter
            button={
              <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                Filter
              </Button>
            }
            content={
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item container xs={6} spacing={2}>
                  {renderCheckboxFilter(
                    'usersOf',
                    'Users of',
                    CUSTOMER_MANAGEMENT_FILTER,
                    CUSTOMER_MANAGEMENT_FILTER_LABELS
                  )}
                </Grid>
                <Grid item container xs={6} spacing={2}>
                  {renderOtherFilter()}
                </Grid>
              </Grid>
            }
            onResetFilter={() => {
              reset({...defaultFilter, q})
            }}
            onResetChange={() => {
              reset({...filters, q})
            }}
            onApply={handleApplyFilter}
          />
          </Grid>
          <Grid item xs="auto">
            {onRenderExportButton()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxUserListFilter
