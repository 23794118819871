import { gql } from 'graphql-request'

import { apiGraphql } from '../base-query'
import { IAdminReleaseInformationInput, ReleaseInformationType } from '../product/types'
import { IReleaseInformationQuery } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminReleaseInformationList: builder.query<ReleaseInformationType, IReleaseInformationQuery>({
      query: ({ filters, pageInfo, sortInfo }) => ({
        document: gql`
          query (
            $releaseInfoFilters: AdminReleaseInformationFiltersInput
            $pageInfo: PageInfoType
            $sortInfo: SortInfoType
          ) {
            adminReleaseInformation(filters: $releaseInfoFilters, pageInfo: $pageInfo, sortInfo: $sortInfo) {
              results {
                id
                productId
                productName
                version
                status
                platformStatuses {
                  platformId
                  platformName
                  status
                }
                releaseLevel
                sendReleaseEmail
                featureHeader
                featureDescription
                createdAt
                updatedAt
                executedAt
                creatorEmail
              }
              totalCount
            }
          }
        `,
        variables: {
          releaseInfoFilters: filters,
          pageInfo,
          sortInfo
        }
      })
    }),
    adminReleaseInformationDetail: builder.query<ReleaseInformationType, string>({
      query: id => ({
        document: gql`
          query ($id: UUID) {
            adminReleaseInformationDetail(id: $id) {
              id
              productId
              productName
              version
              status
              platformStatuses {
                platformId
                platformName
                status
              }
              releaseLevel
              sendReleaseEmail
              featureHeader
              featureDescription
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    checkReleaseInformationProductAndVersion: builder.query<any, any>({
      query: ({ productId, version, platformIds, infoId }) => ({
        document: gql`
          query ($productId: UUID, $version: String, $platformIds: [Int], $infoId: String) {
            adminReleaseInformationVersionCheck(
              productId: $productId
              version: $version
              platformIds: $platformIds
              infoId: $infoId
            ) {
              existProductVersion
              existProductVersionPlatform
            }
          }
        `,
        variables: {
          productId,
          version,
          platformIds,
          infoId
        }
      })
    }),
    createReleaseInformation: builder.mutation<any, IAdminReleaseInformationInput>({
      query: input => ({
        document: gql`
          mutation adminReleaseInformationCreate($input: AdminReleaseInformationCreateInput!) {
            adminReleaseInformationCreate(input: $input) {
              message
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    updateReleaseInformation: builder.mutation<any, IAdminReleaseInformationInput>({
      query: input => ({
        document: gql`
          mutation adminReleaseInformationUpdate($input: AdminReleaseInformationUpdateInput!) {
            adminReleaseInformationUpdate(input: $input) {
              message
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    deleteReleaseInformation: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation adminReleaseInformationRemove($input: AdminReleaseInformationRemoveInput!) {
            adminReleaseInformationRemove(input: $input) {
              message
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            id
          }
        }
      })
    }),
    adminReleaseInformationEventLogs: builder.query<any, any>({
      query: ({ pageInfo, filters, sortInfo }) => ({
        document: gql`
          query ($pageInfo: PageInfoType, $filters: EventLogsFilterInput, $sortInfo: SortInfoType) {
            adminReleaseInformationEventLogs(pageInfo: $pageInfo, filters: $filters, sortInfo: $sortInfo) {
              results {
                id
                productName
                releasePlatforms
                historyReleaseInformation {
                  id
                  productId
                  version
                  status
                  platformStatuses {
                    platformId
                    platformName
                    status
                  }
                  releaseLevel
                  sendReleaseEmail
                  featureHeader
                  featureDescription
                  createdAt
                  updatedAt
                }
                changes {
                  field
                  old
                  new
                }
                historyUpdatedBy {
                  firstName
                  lastName
                  email
                }
                historyDate
                historyType
                historyUpdatedReason
              }
              totalCount
            }
          }
        `,
        variables: {
          pageInfo,
          filters,
          sortInfo
        }
      })
    })
  })
})

export const {
  useLazyAdminReleaseInformationListQuery,
  useLazyAdminReleaseInformationDetailQuery,
  useLazyCheckReleaseInformationProductAndVersionQuery,
  useLazyAdminReleaseInformationEventLogsQuery,
  useCreateReleaseInformationMutation,
  useUpdateReleaseInformationMutation,
  useDeleteReleaseInformationMutation
} = api
