import { useEffect, useMemo, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Autocomplete, ButtonLoading, DialogAdvanced, Grid, TextField } from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useAdminCustomerOrganizationChangeUserRoleMutation } from '../../../../../api/organizationManagement'
import _get from 'lodash/get'

interface ChangeUserDialogProps {
  show: boolean
  roleMap: any
  onClose: () => void
  onSuccess: () => void
  orgUser: any
}

const ChangeUserRoleDialog = ({ show, roleMap, orgUser, onClose, onSuccess }: ChangeUserDialogProps) => {
  const [selectedRole, setSelectedRole] = useState<any>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [oldRole, setOldRole] = useState<string>('')
  const roleList = useMemo(() => {
    if (_isEmpty(roleMap)) return {}

    const roles: any = Object.values(roleMap).filter((role: any) => role.name != 'Super Admin');
    return roles.reduce((obj: any, role: any) => {
      obj[role.id] = role
      return obj
    }, {})
  }, [roleMap])

  const [changeUserRole, { isLoading }] = useAdminCustomerOrganizationChangeUserRoleMutation()

  useEffect(() => {
    if (orgUser) {
      Object.values(roleMap).map((value: any, index) => {
        if (value.id === orgUser.portalRoleId) {
          setOldRole(value.id)
          setSelectedRole(value.id)
        }
      })
    }
  }, [orgUser])

  const handleSubmit = async () => {
    try {
      const data = {
        id: orgUser.id,
        role: selectedRole
      }
      const res = await changeUserRole(data).unwrap()
      if (res.success) {
        enqueueSnackbar('Change user role was successful', { variant: 'success' })
      } else {
        enqueueSnackbar('Change user role was failed', { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
    onSuccess()
  }


  return (
    <DialogAdvanced
      title={<span>Change <span style={{ color: '#D00300' }}>{orgUser ? orgUser.email : ''}</span> role</span>}
      open={show}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Role:
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disableClearable
              value={selectedRole!}
              options={Object.keys(roleList)}
              getOptionLabel={(option: any) => {
                return _get(roleList, `${option}.name`, '')
              }}
              onChange={(_event: any, newValue: any) => {
                setSelectedRole(newValue)
                if (newValue != oldRole) {
                  setIsDisabled(false)
                } else {
                  setIsDisabled(true)
                }
              }}
              isOptionEqualToValue={(option, value) => {
                return option == value
              }}
              renderInput={(params: any) => (
                <TextField {...params} size="small" variant="outlined"/>
              )}
            />
          </Grid>
        </Grid>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: onClose
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true,
                disabled: isLoading || isDisabled
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
    />
  )
}


export default ChangeUserRoleDialog