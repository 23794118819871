import React, { FC, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { KEYS } from '@myopswat/common'
import { getTimeZoneLabel } from '@opswat/react-core'
import { CheckDisabledIcon, CheckedDisabledIcon } from '@opswat/react-icon'
import {
  Button,
  CheckboxWithLabel,
  Grid,
  TemplateSection,
  TextField,
  TextFieldArea,
  TextGrid,
  Typography
} from '@opswat/react-ui'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementUserListPageURL,
  editCustomerUserPath,
  editOpswatUserPath,
  opswatUserListPageURL
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useNavigate } from 'react-router-dom'

import { USER_FORM_LABEL_MAP, USER_TYPE_VALUES } from '../../constants'

interface IProps {
  profileData: any
  sysItemCountriesData: any
}

const BoxUserDetailView: FC<IProps> = ({ profileData, sysItemCountriesData }) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()
  const navigate = useNavigate()
  const profilePermissionData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditUserDisabled = useMemo(() => {
    if (profilePermissionData && profilePermissionData.permissions) {
      return !profilePermissionData.permissions.includes('change_user')
    }
    return false
  }, [profilePermissionData])

  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return result
  }, [profileData, sysItemCountriesData])

  const leftInfoArray = useMemo(() => {
    return [
      {
        label: `${USER_FORM_LABEL_MAP.firstName}*`,
        value: _get(profileData, 'firstName') === KEYS.FIRST_NAME_DEFAULT ? '' : _get(profileData, 'firstName')
      },
      {
        label: `${USER_FORM_LABEL_MAP.lastName}*`,
        value: _get(profileData, 'lastName') === KEYS.LAST_NAME_DEFAULT ? '' : _get(profileData, 'lastName')
      },
      {
        label: USER_FORM_LABEL_MAP.title,
        value: _get(profileData, 'title', '')
      },
      {
        label: `${USER_FORM_LABEL_MAP.companyName}`,
        value: _get(profileData, 'companyName', '')
      },
      {
        label: USER_FORM_LABEL_MAP.phoneNumber,
        value: _get(profileData, 'address.phone', '')
      },
      {
        label: `${USER_FORM_LABEL_MAP.timezone}*`,
        value: getTimeZoneLabel(_get(profileData, 'timezone', ''))
      }
    ]
  }, [profileData])

  const rightInfoArray = useMemo(() => {
    return [
      {
        label: USER_FORM_LABEL_MAP.streetAddress1,
        value: _get(profileData, 'address.streetAddress1', '')
      },
      {
        label: USER_FORM_LABEL_MAP.streetAddress2,
        value: _get(profileData, 'address.streetAddress2', '')
      },
      {
        label: USER_FORM_LABEL_MAP.city,
        value: _get(profileData, 'address.city', '')
      },
      {
        label: USER_FORM_LABEL_MAP.country,
        value: _get(country, 'name', '')
      },
      ...((_get(country, 'code', '') === 'US' && [
        {
          label: USER_FORM_LABEL_MAP.state,
          value: _get(profileData, 'address.state', '')
        }
      ]) ||
        []),
      {
        label: USER_FORM_LABEL_MAP.postalCode,
        value: _get(profileData, 'address.postalCode', '')
      }
    ]
  }, [profileData, country])

  const checkboxArray = useMemo(() => {
    const userTypes = _get(profileData, 'userTypes', [])

    return [
      {
        label: USER_FORM_LABEL_MAP.showKioskSecureImage,
        value: _get(profileData, 'showKioskSecureImage') || false
      },
      {
        label: USER_FORM_LABEL_MAP.showEnginePackage,
        value: _get(profileData, 'showEnginePackage') || false
      },
      {
        label: USER_FORM_LABEL_MAP.isOemCustomer,
        value: userTypes.includes(USER_TYPE_VALUES.oemCustomer)
      },
      {
        label: USER_FORM_LABEL_MAP.isChannelPartner,
        value: userTypes.includes(USER_TYPE_VALUES.channelPartner)
      }
    ]
  }, [profileData])

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.email}</Typography>}
              value={<Typography>{_get(profileData, 'email', '')}</Typography>}
            />

            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.note}</Typography>}
              value={
                <>
                  <TextFieldArea disabled value={_get(profileData, 'note', '')} />
                  <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 1 }}>
                    Please do not add any personal information or Confidential information here.
                  </Typography>
                </>
              }
              containerProps={{ alignItems: 'flex-start' }}
            />
          </TemplateSection>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<TextField disabled size="small" fullWidth value={_get(item, 'value')} />}
                />
              </React.Fragment>
            ))}

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.customerSupportId}</Typography>}
              value={<Typography>{_get(profileData, 'customerSupportId') || '--'}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcUserType}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.user_type', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcCountry}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.country_restricted', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcContactId}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.contact_id', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcAccountId}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.account_id', '--')}</Typography>}
            />
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {_map(checkboxArray, (item, idx) => (
          <Grid item xs={12} sm={6} md="auto" key={idx.toString()}>
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.label}</Typography>}
              checkboxProps={{
                disabled: true,
                checked: item.value,
                icon: <CheckDisabledIcon />,
                checkedIcon: <CheckedDisabledIcon />
              }}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item xs="auto">
              <Button
                variant="contained"
                onClick={() => {
                  if (isOpswatIncRoute) {
                    navigate(`${opswatUserListPageURL}/${profileData.id}/${editOpswatUserPath}`)
                  } else {
                    navigate(`${customerManagementUserListPageURL}/${profileData.id}/${editCustomerUserPath}`)
                  }
                }}
                color="primary"
                disabled={isEditUserDisabled}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default BoxUserDetailView
