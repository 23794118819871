import { FC, useEffect, useMemo } from 'react'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _has from 'lodash/has'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _merge from 'lodash/merge'
import _pickBy from 'lodash/pickBy'

import { TimezoneList, getTimeZoneLabel } from '@opswat/react-core'
import {
  Autocomplete,
  Button,
  ButtonLoading,
  CheckboxWithLabel,
  Grid,
  TemplateSection,
  TextField,
  TextFieldArea,
  TextGrid,
  Typography
} from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { useLazySysItemRegionsQuery } from 'myopswat-admin/src/api/system'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { REGEXS, SCHEMA_YUP, TEXT_LENGTHS } from '@myopswat/common'
import { useAdminUserUpdateMutation, useLazyAdminUserQuery } from 'myopswat-admin/src/api/userManagement'
import { IAdminUserUpdateInput } from 'myopswat-admin/src/api/userManagement/types'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementUserListPageURL,
  detailCustomerUserPath,
  opswatUserListPageURL
} from 'myopswat-admin/src/routes'
import { useNavigate, useParams } from 'react-router-dom'
import { USER_FORM_LABEL_MAP, USER_TYPE_VALUES } from '../../constants'

interface IProps {
  profileData: any
  sysItemCountriesData: any
}

const BoxUserDetailEdit: FC<IProps> = ({ profileData, sysItemCountriesData }) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()
  const userListPageURL = isOpswatIncRoute ? opswatUserListPageURL : customerManagementUserListPageURL

  const { userId = '' } = useParams()
  const { t: translate } = useTranslation()
  const navigate = useNavigate()

  const [adminUserUpdate, { isLoading }] = useAdminUserUpdateMutation()
  const [getSysItemRegions, { data: sysItemRegionsData }] = useLazySysItemRegionsQuery()
  const [getAdminUser] = useLazyAdminUserQuery()

  const country = useMemo(() => {
    const result = _find(
      sysItemCountriesData,
      (item: any) => _get(item, 'code') === _get(profileData, 'address.country')
    )
    return _get(result, 'code')
  }, [sysItemCountriesData])

  const defaultValues = {
    email: _get(profileData, 'email') || '',
    firstName: _get(profileData, 'firstName') || '',
    lastName: _get(profileData, 'lastName') || '',
    title: _get(profileData, 'title') || '',
    companyName: _get(profileData, 'companyName') || '',
    userTypes: _get(profileData, 'userTypes') || [],
    address: {
      country: country,
      state: _get(profileData, 'address.state'),
      streetAddress1: _get(profileData, 'address.streetAddress1') || '',
      streetAddress2: _get(profileData, 'address.streetAddress2') || '',
      city: _get(profileData, 'address.city') || '',
      phone: _get(profileData, 'address.phone') || '',
      postalCode: _get(profileData, 'address.postalCode') || ''
    },
    timezone: _get(profileData, 'timezone') || '',
    note: _isEmpty(_get(profileData, 'note')) ? '' : _get(profileData, 'note'),
    customerSupportId: _get(profileData, 'customerSupportId') || '',
    updatedReason: _get(profileData, 'updatedReason') || '',
    showKioskSecureImage: _get(profileData, 'showKioskSecureImage') || false,
    showEnginePackage: _get(profileData, 'showEnginePackage') || false
  }

  const addressSchema = yup.object().shape(
    _merge(
      {},
      ...[
        {
          country: SCHEMA_YUP.country.country.notRequired()
        },
        {
          streetAddress1: SCHEMA_YUP.streetAddress1.streetAddress1.notRequired()
        },
        SCHEMA_YUP.streetAddress2,
        {
          city: SCHEMA_YUP.citySchema.city.notRequired()
        },
        {
          postalCode: SCHEMA_YUP.postalCode.postalCode.notRequired()
        },
        {
          phone: yup
            .string()
            .trim()
            .matches(
              new RegExp(
                new RegExp(/^$/).source +
                  '|' +
                  new RegExp(`^${'\\*'.repeat(defaultValues.address.phone.length)}$`).source +
                  '|' +
                  new RegExp(REGEXS.PHONE_NUMBER_REGEX).source
              ),
              'Please enter the phone number correctly in the format. For example: +14325551212'
            )
            .max(TEXT_LENGTHS.TEXT_LENGTH_128, 'Phone is limited to 128 characters')
        }
      ]
    )
  )

  const schema = yup.object().shape(
    _merge(
      {},
      ...[
        SCHEMA_YUP.firstName,
        SCHEMA_YUP.lastName,
        {
          title: SCHEMA_YUP.title.title.notRequired()
        },
        {
          companyName: SCHEMA_YUP.companyName.companyName.notRequired()
        },
        SCHEMA_YUP.timezone,
        SCHEMA_YUP.note,
        SCHEMA_YUP.customerSupportId,
        SCHEMA_YUP.updatedReason,
        {
          address: addressSchema
        }
      ]
    )
  )

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields }
  } = useForm<IAdminUserUpdateInput>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (data: any) => {
    const commonKeyValues = _pickBy(data, (value, key) => _has(dirtyFields, key))

    if ('address' in commonKeyValues) {
      commonKeyValues.address = _pickBy(commonKeyValues.address, (value, key) => _has(dirtyFields.address, key))
    }

    if (!_isEmpty(commonKeyValues)) {
      try {
        adminUserUpdate(_merge({ userId }, commonKeyValues))
          .unwrap()
          .then(async data => {
            if (data?.success) {
              await enqueueSnackbar(translate('updateThisProfileSuccess'), {
                variant: 'success'
              })

              await getAdminUser(userId)

              await navigate(`${userListPageURL}/${userId}/${detailCustomerUserPath}`)
            } else {
              enqueueSnackbar(translate('updateThisProfileFail'), {
                variant: 'error'
              })

              data?.errors.map((error: any) => {
                enqueueSnackbar(`${error?.message}`, { variant: 'warning' })
              })
            }
          })
          .catch(() => {
            enqueueSnackbar(translate('updateThisProfileFail'), {
              variant: 'error'
            })
          })
      } catch {
        enqueueSnackbar(translate('updateThisProfileFail'), {
          variant: 'error'
        })
      }
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const isOemCustomer = useMemo(() => {
    return watch('userTypes')?.includes(USER_TYPE_VALUES.oemCustomer)
  }, [watch('userTypes')])

  const isChannelPartner = useMemo(() => {
    return watch('userTypes')?.includes(USER_TYPE_VALUES.channelPartner)
  }, [watch('userTypes')])

  const setUserTypesByCheckbox = (value: string) => {
    const userTypes = watch('userTypes') || []
    const userTypesSet = new Set(userTypes)

    if (userTypesSet.has(value)) {
      userTypesSet.delete(value)
    } else {
      userTypesSet.add(value)
    }

    setValue('userTypes', Array.from(userTypesSet), { shouldDirty: true })
  }

  useEffect(() => {
    if (watch('address.country') === 'US') {
      getSysItemRegions(234)
    }
  }, [watch('address.country')])

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.email}</Typography>}
              value={<Typography>{_get(profileData, 'email', '')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.firstName}*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'firstName', '')}
                  helperText={_get(errors, 'firstName.message', '')}
                  {...register('firstName')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.lastName}*</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'lastName', '')}
                  helperText={_get(errors, 'lastName.message', '')}
                  {...register('lastName')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.title}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'title', '')}
                  helperText={_get(errors, 'title.message', '')}
                  {...register('title')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.companyName}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'companyName', '')}
                  helperText={_get(errors, 'companyName.message', '')}
                  {...register('companyName')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.phoneNumber}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'address.phone', '')}
                  helperText={_get(errors, 'address.phone.message', '')}
                  {...register('address.phone')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.timezone}*</Typography>}
              value={
                <Controller
                  name="timezone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={TimezoneList || []}
                      value={value}
                      getOptionLabel={(option: any) => getTimeZoneLabel(option)}
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          error={!!_get(errors, 'timezone', '')}
                          helperText={_get(errors, 'timezone.message', '')}
                        />
                      )}
                    />
                  )}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.note}</Typography>}
              value={
                <Controller
                  name="note"
                  control={control}
                  render={(cProps: any) => (
                    <>
                      <TextFieldArea
                        value={cProps.field.value}
                        onChange={(e: any) => cProps.field.onChange(e.target.value)}
                        onKeyUp={(e: any) => {
                          if (_get(e, 'keyCode') === 13) {
                            handleSubmit(onSuccess, onFail)()
                          }
                        }}
                      />
                      <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 1 }}>
                        Please do not add any personal information or Confidential information here.
                      </Typography>
                    </>
                  )}
                />
              }
              containerProps={{ alignItems: 'flex-start' }}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.updatedReason}*</Typography>}
              value={
                <Controller
                  name="updatedReason"
                  control={control}
                  render={(cProps: any) => (
                    <TextFieldArea
                      value={cProps.field.value}
                      onChange={(e: any) => cProps.field.onChange(e.target.value)}
                      onKeyUp={(e: any) => {
                        if (_get(e, 'keyCode') === 13) {
                          handleSubmit(onSuccess, onFail)()
                        }
                      }}
                      error={!!_get(errors, 'updatedReason', '')}
                      helperText={_get(errors, 'updatedReason.message', '')}
                    />
                  )}
                />
              }
              containerProps={{ alignItems: 'flex-start' }}
            />
          </TemplateSection>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.streetAddress1}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'address.streetAddress1', '')}
                  helperText={_get(errors, 'address.streetAddress1.message', '')}
                  {...register('address.streetAddress1')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.streetAddress2}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'address.streetAddress2', '')}
                  helperText={_get(errors, 'address.streetAddress2.message', '')}
                  {...register('address.streetAddress2')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.city}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'address.city', '')}
                  helperText={_get(errors, 'address.city.message', '')}
                  {...register('address.city')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.country}</Typography>}
              value={
                <Controller
                  name="address.country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable
                      options={_map(sysItemCountriesData, item => _get(item, 'code')) || []}
                      value={value}
                      getOptionLabel={(option: any) =>
                        _get(
                          _find(sysItemCountriesData, item => _get(item, 'code') === option),
                          'name'
                        ) || ''
                      }
                      onChange={(event: any, newValue: any) => {
                        onChange(newValue)
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          error={!!_get(errors, 'address.country', '')}
                          helperText={_get(errors, 'address.country.message', '')}
                        />
                      )}
                    />
                  )}
                />
              }
            />

            {watch('address.country') === 'US' && (
              <TextGrid
                label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.state}</Typography>}
                value={
                  <Controller
                    name="address.state"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        disableClearable
                        options={_map(sysItemRegionsData, item => _get(item, 'name')) || []}
                        value={value}
                        getOptionLabel={(option: any) =>
                          _get(
                            _find(sysItemRegionsData, item => _get(item, 'name') === option),
                            'name'
                          ) || ''
                        }
                        onChange={(event: any, newValue: any) => {
                          onChange(newValue)
                        }}
                        renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
                      />
                    )}
                  />
                }
              />
            )}

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.postalCode}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'address.postalCode', '')}
                  helperText={_get(errors, 'address.postalCode.message', '')}
                  {...register('address.postalCode')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.customerSupportId}</Typography>}
              value={
                <TextField
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'customerSupportId', '')}
                  helperText={_get(errors, 'customerSupportId.message', '')}
                  {...register('customerSupportId')}
                />
              }
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcUserType}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.user_type', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcCountry}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.country_restricted', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcContactId}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.contact_id', '--')}</Typography>}
            />

            <TextGrid
              label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.sfdcAccountId}</Typography>}
              value={<Typography>{_get(profileData, 'sfdcData.account_id', '--')}</Typography>}
            />
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md="auto">
          <Controller
            name="showKioskSecureImage"
            control={control}
            render={(cProps: any) => (
              <CheckboxWithLabel
                label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.showKioskSecureImage}</Typography>}
                checkboxProps={{
                  checked: !!cProps.field.value,
                  onChange: (e: any) => cProps.field.onChange(e.target.checked)
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md="auto">
          <Controller
            name="showEnginePackage"
            control={control}
            render={(cProps: any) => (
              <CheckboxWithLabel
                label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.showEnginePackage}</Typography>}
                checkboxProps={{
                  checked: !!cProps.field.value,
                  onChange: (e: any) => cProps.field.onChange(e.target.checked)
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} md="auto">
          <CheckboxWithLabel
            label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.isOemCustomer}</Typography>}
            checkboxProps={{
              checked: isOemCustomer,
              onChange: () => setUserTypesByCheckbox(USER_TYPE_VALUES.oemCustomer)
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md="auto">
          <CheckboxWithLabel
            label={<Typography variant="subtitle2">{USER_FORM_LABEL_MAP.isChannelPartner}</Typography>}
            checkboxProps={{
              checked: isChannelPartner,
              onChange: () => setUserTypesByCheckbox(USER_TYPE_VALUES.channelPartner)
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs="auto">
              <Button
                variant="text"
                color="inherit"
                disabled={isLoading}
                onClick={() => {
                  navigate(`${userListPageURL}/${userId}/${detailCustomerUserPath}`)
                }}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: () => {
                    handleSubmit(onSuccess, onFail)()
                  },
                  disabled: isLoading || _isEmpty(dirtyFields)
                }}
                propsLoading={{ color: 'inherit' }}
                isLoading={isLoading}
              >
                Save
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default BoxUserDetailEdit
