import OrganizationGroupDetail from 'myopswat-admin/src/pages/UserManagementPage/Group/GroupDetail'
import { Helmet } from 'react-helmet'

const OrganizationGroupDetailContainer = () => {
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'Group Detail'}
        </title>
      </Helmet>
      <OrganizationGroupDetail />
    </>
  )
}

export default OrganizationGroupDetailContainer
