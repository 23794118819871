import { useCallback, useContext, useMemo } from 'react'
import { useWatch } from 'react-hook-form'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Box, Typography, TypographyLink } from '@opswat/react-ui'

import { RELEASE_INFORMATION_LEVEL } from 'myopswat-admin/src/api/product/types'

import ReleaseInformationLevelChip from '../../components/ReleaseInfoLevelChip'
import ReleaseInformationPlatformChip from '../../components/ReleaseInfoPlatformChip'
import { ReleaseInfoContext } from '../interface'

const ReleaseInfoPreview = () => {
  const { control, platformOptions, productOptions } = useContext(ReleaseInfoContext)

  const productId = useWatch({ control, name: 'productId' })
  const platforms = useWatch({ control, name: 'platformIds' })
  const version = useWatch({ control, name: 'version' })
  const level = useWatch({ control, name: 'releaseLevel' })
  const featureHeader = useWatch({ control, name: 'featureHeader' })
  const featureDescription = useWatch({ control, name: 'featureDescription' })
  const sendReleaseEmail = useWatch({ control, name: 'sendReleaseEmail' })

  const productReleaseNoteUrl = useMemo(
    () =>
      _get(
        _find(productOptions, item => item?.id === productId),
        'releaseNote',
        null
      ),
    [productOptions, productId]
  )

  const productName = useMemo(
    () =>
      _get(
        _find(productOptions, item => item?.id === productId),
        'name',
        ''
      ),
    [productOptions, productId]
  )

  const productParents = useMemo(() => {
    return _get(
      _find(productOptions, item => item?.id === productId),
      ['parents'],
      []
    )
  }, [productOptions, productId])

  const renderProductName = useCallback(() => {
    if (productParents.length === 1) {
      return productName || '{{ PRODUCT NAME }}'
    } else {
      return ''
    }
  }, [productId, productParents, productName])

  const renderProductFamilyName = useCallback(() => {
    if (!productId) {
      return '{{ PRODUCT FAMILY NAME }}'
    } else {
      return productParents.length !== 1 ? productName : _get(productParents, [0, 'name'], '')
    }
  }, [productId, productParents, productName])

  const renderFeatureHeader = useCallback(() => {
    if (level === RELEASE_INFORMATION_LEVEL.TIER_3 && !sendReleaseEmail) {
      return ''
    } else {
      return _isEmpty(featureHeader) ? '{{ FEATURE HEADER }}' : featureHeader
    }
  }, [level, featureHeader, sendReleaseEmail])

  const renderFeatureDescription = useCallback(() => {
    if (level === RELEASE_INFORMATION_LEVEL.TIER_3 && !sendReleaseEmail) {
      return '{{ This release will not be included in the release notification email }}'
    } else {
      return _isEmpty(featureDescription) || featureDescription === '<p></p>' ? (
        '{{ FEATURE DESCRIPTION }}'
      ) : (
        <div dangerouslySetInnerHTML={{ __html: featureDescription ?? '' }} style={{ whiteSpace: 'pre-wrap' }} />
      )
    }
  }, [level, featureDescription, sendReleaseEmail])

  return (
    <Box
      sx={{
        width: '100%',
        height: 'fit-content',
        padding: '16px',
        border: '1px solid #E7E9EE',
        borderRadius: '8px',
        backgroundColor: '#FFFFFF',
        position: 'sticky',
        top: '80px'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            marginBottom: '8px'
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: 700, color: '#050F22' }}>
            {renderProductFamilyName()}
          </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#050F22' }}>{renderProductName()}</Typography>
        </Box>
        {_isEmpty(level) ? (
          '{{ RELEASE LEVEL }}'
        ) : (
          <ReleaseInformationLevelChip level={level as RELEASE_INFORMATION_LEVEL} />
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap' }}>
        {_isEmpty(platforms)
          ? '{{ PLATFORMS }}'
          : platforms?.map((platform: any) => (
              <ReleaseInformationPlatformChip
                key={platform.id}
                platform={platformOptions.find(item => item.id === platform.value)?.name}
              />
            ))}
        <Typography sx={{ fontSize: '12px', lineHeight: '16px', fontWeight: 400, color: '#050F22' }}>
          {_isEmpty(version) ? '{{ VERSION }}' : version}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          marginY: '32px'
        }}
      >
        {renderFeatureHeader() && (
          <Typography sx={{ fontSize: '20px', fontWeight: 400, color: '#3D5176', wordBreak: 'break-all' }}>
            {renderFeatureHeader()}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            color: '#050F22',
            wordBreak: 'break-all',
            '& ol': {
              paddingInlineStart: '16px',
              marginBlock: '0px'
            },
            '& ul': {
              paddingInlineStart: '32px',
              marginBlock: '0px'
            },
            '& ol ul': {
              listStyleType: 'disc'
            },
            '& ol ul ul': {
              listStyleType: 'square'
            },
            '& li ol': {
              listStyle: 'lower-alpha'
            },
            '& li ol li ol': {
              listStyle: 'lower-roman'
            },
            '& li ol li ol li ol': {
              listStyle: 'decimal'
            }
          }}
        >
          {renderFeatureDescription()}
        </Typography>
      </Box>
      <TypographyLink
        href={productReleaseNoteUrl}
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          color: '#1D6BFC',
          cursor: 'pointer',
          width: 'fit-content',
          '&:hover': { textDecoration: 'underline' }
        }}
      >
        View Release Notes
      </TypographyLink>
    </Box>
  )
}

export default ReleaseInfoPreview
