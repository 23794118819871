import { useEffect, useMemo, useState } from 'react'

import { CollapsibleTableAdvanced, TemplateSection, Typography } from '@opswat/react-ui'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { formatDatetime } from '@opswat/react-core'
import { useLazyAdminDetailOrganizationHistoriesQuery } from 'myopswat-admin/src/api/history'
import {
  IAdminDetailOrganizationHistoryInput,
  IAdminDetailOrganizationHistoryType,
  IHistoryDiffType
} from 'myopswat-admin/src/api/history/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { MDC_LICENSE_TYPE_OPTIONS, ORGANIZATION_FORM_LABELS } from 'myopswat-admin/src/constants/organizations'
import { convertValueToString } from 'myopswat-admin/src/utils/json'

interface IProps {
  orgId: string
}

const OrganizationHistoryListPage = ({ orgId }: IProps) => {
  const [query, setQuery] = useState<IAdminDetailOrganizationHistoryInput>({
    organizationId: orgId,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const [getDetailOrganizationHistories, { data, isFetching: isLoading }] =
    useLazyAdminDetailOrganizationHistoriesQuery()

  const collapseData = useMemo(
    () => _map(data?.results, (columnData: IAdminDetailOrganizationHistoryType) => columnData.changes),
    [data]
  )

  useEffect(() => {
    getDetailOrganizationHistories(query)
  }, [query])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }

  const renderLastUpdatedBy = (data: IAdminDetailOrganizationHistoryType) => {
    let result = '--'
    if (data.historyUpdatedBy) {
      result = _get(data.historyUpdatedBy, 'email') || '--'
    } else if (data.historyUpdatedByApp) {
      result = _get(data.historyUpdatedByApp, 'name') || '--'
    }

    return <Typography variant="body2">{result}</Typography>
  }

  const renderDiffValue = (changedData: IHistoryDiffType, mode: 'new' | 'old') => {
    const field = changedData.field
    const value = _get(changedData, mode)

    if (!value) return '--'

    if (_get(ORGANIZATION_FORM_LABELS, field) === ORGANIZATION_FORM_LABELS.mdcLicenseType) {
      return _get(MDC_LICENSE_TYPE_OPTIONS, value as string, value)
    }

    if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
      return <pre>{convertValueToString(value)}</pre>
    }

    return value
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: IAdminDetailOrganizationHistoryType) => (
          <Typography variant="body2">{data.historyType || '--'}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Impacted',
        body: (data: IAdminDetailOrganizationHistoryType) => {
          if (data.historyType == 'Change User Role') {
            return (
              <Typography variant="body2">{data?.metadata?.user || '--'}</Typography>
            )
          }
          return (
          <Typography variant="body2">{'--'}</Typography>
        )},
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Reason',
        body: (data: IAdminDetailOrganizationHistoryType) => (
          <Typography variant="body2">{data.historyUpdatedReason || '--'}</Typography>
        ),
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Updated By',
        body: renderLastUpdatedBy,
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Datetime',
        body: (data: IAdminDetailOrganizationHistoryType) => (
          <Typography variant="body2">{formatDatetime(data.historyDate, undefined) || '--'}</Typography>
        ),
        style: { minWidth: 300, width: 300 }
      }
    ]
  }, [])

  const collapsedColumnArray = useMemo(() => {
    return [
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => (
          <Typography variant="body2">{_get(ORGANIZATION_FORM_LABELS, data.field, data.field) || '--'}</Typography>
        ),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => <Typography variant="body2">{renderDiffValue(data, 'new')}</Typography>,
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => <Typography variant="body2">{renderDiffValue(data, 'old')}</Typography>,
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={data?.results}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default OrganizationHistoryListPage
