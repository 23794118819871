import React, { useMemo } from 'react'

import _map from 'lodash/map'

import { Route, Routes, useNavigate } from 'react-router-dom'

import { Page404, SnackbarNotistack } from '@opswat/react-ui'

import ActiveContainer from './containers/ActiveContainer'
import ChangePasswordContainer from './containers/ChangePasswordContainer'
import ConfirmEmailContainer from './containers/ConfirmEmailContainer'
import IntegratedAppListContainer from './containers/IntegrationManagementContainer/IntegratedAppListContainer'
import IntegratedAppTokenContainer from './containers/IntegrationManagementContainer/IntegratedAppTokenContainer'
import LayoutContainer from './containers/LayoutContainer'
import LoadingContainer from './containers/LoadingContainer'
import LoginContainer from './containers/LoginContainer'
import ManageSyncBlogContainer from './containers/ManageSyncContainer/ManageSyncBlogContainer'
import MyAccountContainer from './containers/MyAccountContainer'
import RegisterContainer from './containers/RegisterContainer'
import ResendEmailContainer from './containers/ResendEmailContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import SSOContainer from './containers/SSOContainer'
import ProductDetailContainer from './containers/SystemManagementContainer/ProductDetailContainer'
import ProductListContainer from './containers/SystemManagementContainer/ProductListContainer'
import VerifyOTPContainer from './containers/VerifyOTPContainer'

import NiceModal from '@ebay/nice-modal-react'
import { SnackbarProvider } from 'notistack'
import image404 from './assets/images/404-bg.png'
import imageBg from './assets/images/mfa-bg.png'
import { withPermission } from './components/HOC/withPermission'
import { isEnabledPOCFeatures, isFalseSubmissionDisabled } from './constants'

import AccessRestrictedContainer from './containers/AccessRestrictedContainer'
import EvaluationRequestListContainer from './containers/ApprovalManagementContainer/EvaluationRequestListContainer'
import RoleObjectsCreateContainer from './containers/CustomerManagementContainer/RoleObjectsContainer/RoleObjectCreateContainer'
import RoleObjectsDetailContainer from './containers/CustomerManagementContainer/RoleObjectsContainer/RoleObjectsDetailContainer'
import RoleObjectsListContainer from './containers/CustomerManagementContainer/RoleObjectsContainer/RoleObjectsListContainer'
import UserDetailContainer from './containers/CustomerManagementContainer/UserDetailContainer'
import UserListContainer from './containers/CustomerManagementContainer/UserListContainer'
import DashboardHomeContainer from './containers/DashboardHomeContainer'
import DashboardNPSSurveyContainer from './containers/DashboardNPSSurveyContainer'
import DashboardUserContainer from './containers/DashboardUserContainer'
import MassNotificationContainer from './containers/MassNotificationContainer'
import ComposeEmailContainer from './containers/MassNotificationContainer/ComposeEmailContainer'
import ProductMailerContainer from './containers/ProductMailerContainer'
import SettingsContainer from './containers/SettingsContainer'
import CreateReleaseInformationContainer from './containers/SystemManagementContainer/CreateReleaseInformationContainer'
import FalseDetectionSubmissionListContainer from './containers/SystemManagementContainer/FalseDetectionSubmissionListContainer'
import FalseDetectionSubmissionDetailContainer from './containers/SystemManagementContainer/FalseDetectionSubmissionListContainer/FalseDetectionSubmissionDetailContainer'
import FalseSubmissionAntivirusEngineDetailContainer from './containers/SystemManagementContainer/FalseDetectionSubmissionListContainer/FalseSubmissionAntivirusEngineDetailContainer'
import NewProductContainer from './containers/SystemManagementContainer/NewProductContainer'
import ReleaseInformationListView from './containers/SystemManagementContainer/ReleaseInformationContainer'
import OrganizationManagementContainer from './containers/UserManagementContainer'
import OpswatOrganizationDetailContainer from './containers/UserManagementContainer/opswatOrganization'
import OpswatUserListContainer from './containers/UserManagementContainer/opswatUserList'
import OrganizationCreateContainer from './containers/UserManagementContainer/organizationCreate'
import OrganizationDetailContainer from './containers/UserManagementContainer/organizationDetail'
import OrganizationGroupDetailContainer from './containers/UserManagementContainer/organizationGroupDetail'
import OrganizationGroupListContainer from './containers/UserManagementContainer/organizationGroupList'
import OrganizationRoleDetailContainer from './containers/UserManagementContainer/organizationRoleDetail'
import OrganizationRoleListContainer from './containers/UserManagementContainer/organizationRoleList'
import FalseSubmissionAntivirusEngineCreate from './pages/SystemManagementPage/FalseDetectionSubmissionPage/FalseSubmissionAntivirusEngine/FalseSubmissionAntivirusEngineCreate'
import {
  activePageURL,
  approvalManagementEvaluationRequestsPageURL,
  approvalManagementPageURL,
  changePasswordPageURL,
  changePasswordSSOPageURL,
  confirmEmailPageURL,
  customerManagementOrganizationDetailPageURL,
  customerManagementOrganizationGroupDetailPageURL,
  customerManagementOrganizationGroupListPageURL,
  customerManagementOrganizationListPageURL,
  customerManagementOrganizationNewPageURL,
  customerManagementOrganizationRoleDetailPageUrl,
  customerManagementOrganizationRolesPageUrl,
  customerManagementUserDetailFlexPageURL,
  customerManagementUserListPageURL,
  dashboardFalseDetectionSubmissionAntivirusEngineDetailFlexPageURL,
  dashboardFalseDetectionSubmissionAntivirusEngineNewPageURL,
  dashboardFalseDetectionSubmissionFlexURL,
  dashboardNPSSurveyPageFlexURL,
  dashboardPageURL,
  dashboardUserPageURL,
  detailFalseDetectionSubmissionPageURL,
  loginPageURL,
  myAccountFlexPageURL,
  notificationManagementComposeEmailRouterURL,
  notificationManagementMassNotificationPageURL,
  notificationManagementSystemSubscribersPageURL,
  registerPageURL,
  resendEmailPageURL,
  resetPasswordPageURL,
  syncingManagementOpswatBlogPageURL,
  systemManagementProductDetailFlexPageURL,
  systemManagementProductListPageURL,
  systemManagementProductNewPageURL,
  systemManagementReleaseInformationCreatePageURL,
  systemManagementReleaseInformationEventLogsPageURL,
  systemManagementReleaseInformationPageURL,
  systemManagementSettingListPageURL,
  verifyOTPPageURL
} from './routes'
import {
  customerManagementRoleObjectsDetailFlexPageURL,
  customerManagementRoleObjectsEventLogsListPageURL,
  customerManagementRoleObjectsListPageURL,
  customerManagementRoleObjectsNewPageURL
} from './routes/customerManagementRoutes/roleObjectsRoutes'
import { accessRestrictedPageUrl } from './routes/errorRoutes'
import {
  integrationManagementIntegratedAppTokenURL,
  integrationManagementInternalAppListPageURL
} from './routes/integrationManagementRoutes'
import {
  opswatGroupDetailPageURL,
  opswatGroupListPageURL,
  opswatOrganizationPageURL,
  opswatRoleDetailPageURL,
  opswatRoleListPageURL,
  opswatUserDetailFlexPageURL,
  opswatUserListPageURL,
  userManagementPermissionListPageURL
} from './routes/userManagementRoutes'
import HelmetCore from './seo/HelmetCore'

interface RouteProps {
  path: string
  component?: React.ReactNode
  index?: boolean
  isHiding?: boolean
}

const App = () => {
  const navigate = useNavigate()

  const dashboardRoutes = useMemo(
    () => [
      {
        path: dashboardPageURL,
        component: <DashboardHomeContainer />
      },
      {
        path: dashboardUserPageURL,
        component: <DashboardUserContainer />
      },
      {
        path: dashboardNPSSurveyPageFlexURL,
        component: <DashboardNPSSurveyContainer />
      },
      {
        isHiding: isFalseSubmissionDisabled,
        path: dashboardFalseDetectionSubmissionFlexURL,
        component: <FalseDetectionSubmissionListContainer />
      },
      {
        path: detailFalseDetectionSubmissionPageURL,
        component: <FalseDetectionSubmissionDetailContainer />
      },
      {
        path: dashboardFalseDetectionSubmissionAntivirusEngineNewPageURL,
        component: <FalseSubmissionAntivirusEngineCreate />
      },
      {
        path: dashboardFalseDetectionSubmissionAntivirusEngineDetailFlexPageURL,
        component: <FalseSubmissionAntivirusEngineDetailContainer />
      }
    ],
    []
  )

  const myAccountRoutes = useMemo(
    () => [
      {
        path: myAccountFlexPageURL,
        component: <MyAccountContainer />
      }
    ],
    []
  )

  const userManagementRoutes = useMemo(
    () => [
      {
        path: opswatOrganizationPageURL,
        component: <OpswatOrganizationDetailContainer />
      },
      {
        path: opswatUserListPageURL,
        component: <OpswatUserListContainer />
      },
      {
        path: opswatUserDetailFlexPageURL,
        component: <UserDetailContainer />
      },
      {
        path: opswatGroupListPageURL,
        component: <OrganizationGroupListContainer />
      },
      {
        path: opswatGroupDetailPageURL,
        component: <OrganizationGroupDetailContainer />
      },
      {
        path: opswatRoleListPageURL,
        component: <OrganizationRoleListContainer />
      },
      {
        path: opswatRoleDetailPageURL,
        component: <OrganizationRoleDetailContainer />
      },
      {
        path: userManagementPermissionListPageURL,
        component: <></>,
        isHiding: true
      }
    ],
    []
  )

  const syncingManagementRoutes = useMemo(
    () => [
      {
        path: syncingManagementOpswatBlogPageURL,
        component: <ManageSyncBlogContainer />
      }
    ],
    []
  )

  const notificationManagementRoutes = useMemo(
    () => [
      {
        path: notificationManagementSystemSubscribersPageURL,
        component: <ProductMailerContainer />
      },
      {
        path: notificationManagementMassNotificationPageURL,
        component: <MassNotificationContainer />
      },
      {
        path: notificationManagementComposeEmailRouterURL,
        component: <ComposeEmailContainer />
      }
    ],
    []
  )

  const integrationManagementRoutes = useMemo(
    () => [
      {
        path: integrationManagementInternalAppListPageURL,
        component: <IntegratedAppListContainer />
      },
      {
        path: integrationManagementIntegratedAppTokenURL,
        component: <IntegratedAppTokenContainer />
      }
    ],
    []
  )

  const approvalManagementRoutes = useMemo(
    () => [
      {
        path: approvalManagementPageURL,
        component: <></>
      },
      {
        isHiding: !isEnabledPOCFeatures,
        path: approvalManagementEvaluationRequestsPageURL,
        component: <EvaluationRequestListContainer />
      }
    ],
    []
  )

  const customerManagementUserRoutes = [
    {
      path: customerManagementUserListPageURL,
      component: <UserListContainer />
    },
    {
      path: customerManagementUserDetailFlexPageURL,
      component: <UserDetailContainer />
    }
  ]

  const customerManagementOrganizationRoutes = [
    {
      path: customerManagementOrganizationListPageURL,
      component: <OrganizationManagementContainer />
    },
    {
      path: customerManagementOrganizationDetailPageURL,
      component: <OrganizationDetailContainer />
    },
    {
      path: customerManagementOrganizationNewPageURL,
      component: <OrganizationCreateContainer />
    },
    {
      path: customerManagementOrganizationGroupListPageURL,
      component: <OrganizationGroupListContainer />
    },
    {
      path: customerManagementOrganizationGroupDetailPageURL,
      component: <OrganizationGroupDetailContainer />
    },
    {
      path: customerManagementOrganizationRolesPageUrl,
      component: <OrganizationRoleListContainer />
    },
    {
      path: customerManagementOrganizationRoleDetailPageUrl,
      component: <OrganizationRoleDetailContainer />
    }
  ]

  const customerManagementRoleObjectsRoutes = [
    {
      path: customerManagementRoleObjectsListPageURL,
      component: <RoleObjectsListContainer />
    },
    {
      path: customerManagementRoleObjectsNewPageURL,
      component: <RoleObjectsCreateContainer />
    },
    {
      path: customerManagementRoleObjectsDetailFlexPageURL,
      component: <RoleObjectsDetailContainer />
    },
    {
      path: customerManagementRoleObjectsEventLogsListPageURL,
      component: <RoleObjectsListContainer />
    }
  ]

  const customerManagementRoutes = [
    ...customerManagementUserRoutes,
    ...customerManagementOrganizationRoutes,
    ...customerManagementRoleObjectsRoutes
  ]

  const systemManagementRoutes = [
    {
      path: systemManagementProductListPageURL,
      component: <ProductListContainer />
    },
    {
      path: systemManagementProductDetailFlexPageURL,
      component: <ProductDetailContainer />
    },
    {
      path: systemManagementProductNewPageURL,
      component: <NewProductContainer />
    },
    {
      path: systemManagementSettingListPageURL,
      component: <SettingsContainer />
    },
    {
      path: systemManagementReleaseInformationPageURL,
      component: <ReleaseInformationListView />
    },
    {
      path: systemManagementReleaseInformationEventLogsPageURL,
      component: <ReleaseInformationListView />
    },
    {
      path: systemManagementReleaseInformationCreatePageURL,
      component: <CreateReleaseInformationContainer />
    }
  ]

  const ssoRoutes = [
    {
      path: '/',
      component: <LoadingContainer />,
      index: true
    },
    {
      path: loginPageURL,
      component: <LoginContainer />
    },
    {
      path: registerPageURL,
      component: <RegisterContainer />
    },
    {
      path: resetPasswordPageURL,
      component: <ResetPasswordContainer />
    },
    {
      path: changePasswordPageURL,
      component: <ChangePasswordContainer />
    },
    {
      path: confirmEmailPageURL,
      component: <ConfirmEmailContainer />
    },
    {
      path: resendEmailPageURL,
      component: <ResendEmailContainer />
    },
    {
      path: verifyOTPPageURL,
      component: <VerifyOTPContainer />
    },
    {
      path: changePasswordSSOPageURL
    },
    {
      path: activePageURL,
      component: <ActiveContainer />
    }
  ]

  const errorRoutes = [
    {
      path: accessRestrictedPageUrl,
      component: <AccessRestrictedContainer />
    }
  ]

  const pageRoutes = [
    ...dashboardRoutes,
    ...myAccountRoutes,
    ...userManagementRoutes,
    ...syncingManagementRoutes,
    ...notificationManagementRoutes,
    ...integrationManagementRoutes,
    ...approvalManagementRoutes,
    ...customerManagementRoutes,
    ...systemManagementRoutes
  ]

  return (
    <>
      <HelmetCore />

      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        autoHideDuration={10000}
        maxSnack={500}
        Components={{
          success: SnackbarNotistack,
          info: SnackbarNotistack,
          error: SnackbarNotistack,
          warning: SnackbarNotistack
        }}
      >
        <NiceModal.Provider>
          <Routes>
            <Route element={<SSOContainer />}>
              {_map(ssoRoutes, (route: RouteProps) => {
                if (!route.isHiding) {
                  return <Route key={route.path} index={route.index} path={route.path} element={route.component} />
                }
              })}
            </Route>

            <Route element={<LayoutContainer />}>
              {_map(pageRoutes, (route: RouteProps) => {
                if (!route.isHiding) {
                  return (
                    <Route key={route.path} path={route.path} element={withPermission(route.component, route.path)} />
                  )
                }
              })}
            </Route>

            {_map(errorRoutes, (route: RouteProps) => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}

            <Route
              path="*"
              element={
                <Page404
                  background={imageBg}
                  icon={<img src={image404} alt="opswat-404" />}
                  text="Sorry, we can't find that page! Don't worry though, everything is STILL AWESOME!"
                  textButton="Go Back Home"
                  propsButton={{ onClick: () => navigate(dashboardPageURL) }}
                  propsTypo={{ color: 'white' }}
                />
              }
            />
          </Routes>
        </NiceModal.Provider>
      </SnackbarProvider>
    </>
  )
}

export default App
