import CancelIcon from '@mui/icons-material/Cancel'
import { DIALOGS } from '@myopswat/common'
import { OptionIcon } from '@opswat/react-icon'
import {
  Box,
  Chip,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  OpswatCheckbox,
  PageLoading,
  TableAdvanced,
  TemplateSection,
  Typography
} from '@opswat/react-ui'
import _get from 'lodash/get'
import {
  useLazySubscribersQuery,
  useRemoveSubscriberMutation,
  useUpdateSubscriberActionMutation
} from 'myopswat-admin/src/api/mailer'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import DialogNewSubscriber from './DialogNewSubscriber'

const ProductMailerPage = () => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const dialog = useDialog()
  const [getSubscribers, { data, isFetching }] = useLazySubscribersQuery()
  const [updateSubscriberAction] = useUpdateSubscriberActionMutation()
  const [removeSubscriber] = useRemoveSubscriberMutation()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isAddSubscriberDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('add_subscriber')
    }
    return false
  }, [profileData])
  const isRemoveSubscriberDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('delete_subscriber')
    }
    return false
  }, [profileData])
  const isChangeEmailNotificationDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_subscriber')
    }
    return false
  }, [profileData])

  const reloadData = () => {
    getSubscribers()
  }

  useEffect(() => {
    reloadData()
  }, [])

  const onIsEnabledChange = async (actionSlug: string, actionName: string, newValue: boolean) => {
    const actionText = newValue ? 'enable' : 'disable'
    const confirmed = await dialog.openConfirmation({
      content: `Are you sure you want to ${actionText} email notification for ${actionName}?`
    })
    if (!confirmed) return
    await updateSubscriberAction({ slug: actionSlug, isEnabled: newValue })
      .unwrap()
      .then(async (response: any) => {
        if (response.success) {
          enqueueSnackbar(translate('updateEmailNotificationSuccessfully'), { variant: 'success' })
          reloadData()
        } else {
          if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('updateEmailNotificationFailed')), {
              variant: 'error'
            })
          }
        }
      })
      .catch(() => {
        enqueueSnackbar(translate('updateEmailNotificationFailed'), { variant: 'error' })
      })
  }

  const handleRemoveSubscriber = async (actionSlug: string, actionName: string, email: string) => {
    const confirmed = await dialog.openConfirmation({
      content: `Are you sure you want to remove ${email} from ${actionName}?`
    })
    if (!confirmed) return
    await removeSubscriber({ actionSlug: actionSlug, email: email })
      .unwrap()
      .then(async (response: any) => {
        if (response.success) {
          enqueueSnackbar(translate('removeSubscriberSuccessfully'), { variant: 'success' })
          reloadData()
        } else {
          if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('removeSubscriberFailed')), {
              variant: 'error'
            })
          }
        }
      })
      .catch(() => {
        enqueueSnackbar(translate('removeSubscriberFailed'), { variant: 'error' })
      })
  }

  const columns = useMemo(() => {
    return [
      {
        header: 'User Event / Action',
        body: (data: any) => <Typography variant="body2">{data?.actionName}</Typography>,
        style: { minWidth: 180, width: '20%' }
      },
      {
        header: 'Enabled Email Notification?',
        body: (data: any) => (
          <OpswatCheckbox
            labelStyle={{ mx: 0 }}
            sx={{ margin: 'auto' }}
            label=""
            key={data?.actionSlug}
            checked={data?.isEnabled}
            disabled={isChangeEmailNotificationDisabled}
            onChange={(event: any) => {
              onIsEnabledChange(data?.actionSlug, data?.actionName, event.target.checked)
            }}
          />
        ),
        style: { minWidth: 220, width: '20%', textAlign: 'center' }
      },
      {
        header: 'Subscribed Notification Emails',
        body: (data: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {data?.emails.map((item: any) => (
              <Box>
                <Chip
                  size="small"
                  sx={{ mr: 1, my: 0.5 }}
                  onDelete={() => handleRemoveSubscriber(data.actionSlug, data.actionName, item)}
                  label={item}
                  deleteIcon={
                    <IconButton sx={{ mr: '0 !important' }} disabled={isRemoveSubscriberDisabled}>
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  }
                />
              </Box>
            ))}
          </Box>
        ),
        style: { minWidth: 500, width: '60%' }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    disabled={isAddSubscriberDisabled}
                    onClick={() => {
                      dispatch(
                        toggleDialogs({
                          [DIALOGS.NEW_SUBSCRIBER]: true,
                          [`${DIALOGS.NEW_SUBSCRIBER}_DATA`]: data
                        })
                      )
                    }}
                  >
                    Add New Subscriber
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <Box>
        {isFetching ? (
          <PageLoading color="primary" />
        ) : (
          <Box sx={{ mt: 2 }}>
            <TemplateSection>
              <TableAdvanced columns={columns} data={data} isLoading={isFetching} />
            </TemplateSection>
          </Box>
        )}
      </Box>
      <DialogNewSubscriber reloadData={reloadData}></DialogNewSubscriber>
    </TemplateSection>
  )
}

export default ProductMailerPage
