import yup from '../../../utils/yup'

export interface IRoleObjectForm {
  id: string
  code: string
  name: string
  permissions?: number[]
  componentId?: number
  reason?: string
  user?: number,
  admin?: number,
  super?: number
}

export const none_permission_id = 1

export const generateRoleObjectsCreateSchema = (globalRoles: any) => {
  const schemaFields: any = {
    code: yup.string().required('This code cannot be blank').max(256, 'Please enter maximum 255 characters.'),
    name: yup.string().required('This name cannot be blank').max(256, 'Please enter maximum 255 characters.'),
    permissions: yup.array().of(yup.number()).min(1, 'Permission must be checked').test(
      "not-only-none-permission",
      "Selecting only None permission is not allowed.",
      (value: any) => !(value.length === 1 && value[0] === none_permission_id)
    ),
    componentId: yup.number().required('Component is required'),
  };
  globalRoles.forEach((role: any) => {
    const roleKey = role?.name.toLowerCase()
    schemaFields[roleKey] = yup.number().required(`Must select permissions for ${role.name}`);
  });
  return yup.object().shape(schemaFields);
};

export const roleObjectsUpdateSchema = yup.object().shape({
  code: yup.string().required('This code cannot be blank').max(256, 'Please enter maximum 255 characters.'),
  name: yup.string().required('This name cannot be blank').max(256, 'Please enter maximum 255 characters.'),
  permissions: yup.array().of(yup.number()).min(1, 'Permission must be check'),
  componentId: yup.number().required('Component is required'),
  reason: yup.string().required('This updated reason cannot be blank')
})
