import { FC } from 'react'
import { TemplateSection, TypographyDivider } from '@opswat/react-ui'

import NPSSurveyDashboard from './Dashboards/NPSSurvey'

const DashboardNPSSurveyPage: FC<unknown> = () => {
  return (
    <TemplateSection>
      <TypographyDivider label="NPS Survey" />
      <NPSSurveyDashboard />
    </TemplateSection>
  )
}

export default DashboardNPSSurveyPage
