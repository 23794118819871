import IntegratedAppTokenPage from 'myopswat-admin/src/pages/IntegrationManagementPage/IntegratedAppTokenPage'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

const IntegratedAppTokenContainer = () => {
  const { appId = '' } = useParams()
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'Tokens Detail'}
        </title>
      </Helmet>
      <IntegratedAppTokenPage appId={appId} />
    </>
  )
}

export default IntegratedAppTokenContainer
