import { IRolePermission } from 'myopswat-admin/src/api/role/types'
import yup from 'myopswat-admin/src/utils/yup'

export interface IRoleForm {
  id: string
  name: string
  description: string
  permissions: IRolePermission[]
}

export const roleSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Group name cannot be blank')
    .max(150, 'The maximum group name length is 150 characters'),
  description: yup.string().trim().max(2000, 'The maximum description length is 2000 characters'),
  permissions: yup.array()
})
