import { yupResolver } from '@hookform/resolvers/yup'
import { Box, FormDialog, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { useAdminOrganizationAddUserMutation } from 'myopswat-admin/src/api/organizationManagement'
import { AdminOrganizationAddUserForm } from 'myopswat-admin/src/api/organizationManagement/types'
import useApiErrorHook from 'myopswat-admin/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ADD_USER_ERROR_MESSAGES } from '../../../constants'
import { addUserSchema } from '../schema'

interface AddUserDialogProps {
  orgId: string
  show: boolean
  roleMap: any
  onClose: () => void
  onSuccess: () => void
}

const AddUserDialog = ({ orgId, show, roleMap, onClose, onSuccess }: AddUserDialogProps) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: ADD_USER_ERROR_MESSAGES })
  const [addUser, { isLoading }] = useAdminOrganizationAddUserMutation()

  const defaultValues = useMemo(() => {
    const userRole: any = Object.values(roleMap).find((role: any) => role.name === 'User') || {}
    return {
      email: '',
      role: userRole.id || null
    }
  }, [roleMap])

  const formRef = useForm<AdminOrganizationAddUserForm>({
    defaultValues: defaultValues,
    values: defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(addUserSchema)
  })
  const { reset, handleSubmit, formState, register } = formRef
  const { isDirty, errors } = formState

  const handleResetForm = () => {
    reset(defaultValues)
  }

  const handleCancel = () => {
    handleResetForm()
    onClose()
  }

  const onSubmit: SubmitHandler<AdminOrganizationAddUserForm> = async (formData: AdminOrganizationAddUserForm) => {
    try {
      const role = formData.role!
      const roleId = role === 'superAdmin' ? _get(roleMap, `${formData.role}.id`) : role

      const data = {
        orgId: orgId,
        email: formData.email,
        ssoRoles: [_get(roleMap, `${formData.role}.ssoId`)],
        roles: [roleId],
        superAdmin: role === 'superAdmin'
      }

      const res = await addUser(data).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('orgAddUserSuccess'), { variant: 'success' })
        // Reset form data
        handleResetForm()
        // Callback
        onSuccess()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isDirty}
      title="Add User"
      open={show}
      onClose={onClose}
      onExited={onClose}
      onCancel={handleCancel}
      onConfirm={handleSubmit(onSubmit)}
      confirmBtnLabel="Submit"
      minHeight={100}
      maxWidth={'xs'}
      content={
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, pt: 1 }}>
          <Typography variant="subtitle1">Email</Typography>
          <TextField
            required
            fullWidth
            size="small"
            {...register(`email`)}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
        </Box>
      }
    />
  )
}

export default AddUserDialog
