import { useMemo, useState } from 'react'

import { OptionIcon } from '@opswat/react-icon'
import {
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Tooltip,
  Typography,
  TypographyDivider,
  TypographyLinkClamp,
} from '@opswat/react-ui'

import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import { useAdminOrganizationsQuery } from 'myopswat-admin/src/api/organizationManagement'
import {
  AdminOrganizationFilter,
  AdminOrganizationQueryInput
} from 'myopswat-admin/src/api/organizationManagement/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { MDC_LICENSE_TYPE_OPTIONS } from 'myopswat-admin/src/constants/organizations'
import {
  customerManagementOrganizationListPageURL,
  customerManagementOrganizationNewPageURL,
  detailCustomerOrganizationPath
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useNavigate } from 'react-router-dom'
import { DETAIL_MODE } from '../constants'
import FilterSection from './FiltersSection'
import AccountOpportunityTooltip from '../../CustomerManagement/OrganizationListPage/AccountOpportunityTooltip'

const OrganizationList = () => {
  const navigate = useNavigate()

  const defaultQuery = {
    filters: {
      q: '',
      usersOf: []
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  }

  const [query, setQuery] = useState<AdminOrganizationQueryInput>(defaultQuery)
  const { data, isFetching } = useAdminOrganizationsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const isLoading = useMemo(() => {
    return isFetching
  }, [isFetching])

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditOrganizationDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_organization')
    }
    return false
  }, [profileData])

  const tableData = useMemo(() => {
    if (_isEmpty(data)) return []
    return _map(data.results, (org: any) => ({
      id: org.id,
      name: org.name,
      accountId: org.accountId,
      mdcLicenseType: MDC_LICENSE_TYPE_OPTIONS[org.mdcLicenseType],
      ssoId: org.ssoId,
      opportunityId: org.opportunityId
    }))
  }, [data])

  const handleSearch = (searchData: AdminOrganizationFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const handleEditDetail = (org: any) => {
    navigate(
      `${customerManagementOrganizationListPageURL}/${org.id}/${detailCustomerOrganizationPath}?mode=${DETAIL_MODE.EDIT}`
    )
  }

  const handleCreate = () => {
    navigate(customerManagementOrganizationNewPageURL)
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => (
          <TypographyLinkClamp
            onClick={() => {
              navigate(`${customerManagementOrganizationListPageURL}/${data.id}/${detailCustomerOrganizationPath}`)
            }}
          >
            {data.name || '--'}
          </TypographyLinkClamp>
        ),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'MD Cloud License Type',
        body: (data: any) => <Typography variant="body2">{data?.mdcLicenseType || 'No License'}</Typography>,
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'SSO Id',
        body: (data: any) =>
          data?.ssoId ? (
            <Tooltip
              title={data?.ssoId}
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '500px'
                  }
                }
              }}
              arrow
            >
              <Typography
                variant="body2"
                sx={{
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {data?.ssoId}
              </Typography>
            </Tooltip>
          ) : (
            <Typography variant="body2">--</Typography>
          ),
        style: { minWidth: 250, width: 250, textAlign: 'left' }
      },
      {
        header: 'Account ID/Opportunity ID',
        body: AccountOpportunityTooltip,
        style: { textAlign: 'left' }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      handleEditDetail(data)
                    }}
                    disabled={isEditOrganizationDisabled}
                  >
                    Edit Detail
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <TypographyDivider label="Organizations" />

      <FilterSection filters={query.filters} onSearch={handleSearch} onCreate={handleCreate} />

      <TableAdvanced
        columns={columnArray}
        isLoading={isLoading}
        data={tableData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default OrganizationList
