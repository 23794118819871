import { yupResolver } from '@hookform/resolvers/yup'
import { fullName } from '@opswat/react-core'
import { Box, Button, ButtonLoading, Grid } from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import { useAdminGroupUpdateMutation } from 'myopswat-admin/src/api/group'
import { useAdminRolesQuery } from 'myopswat-admin/src/api/role'
import { OPSWAT_ORGANIZATION_ID, PAGE_DEFAULT } from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementOrganizationListPageURL,
  detailOrganizationGroupPath,
  detailUserGroupPath,
  opswatGroupListPageURL,
  organizationGroupsPath
} from 'myopswat-admin/src/routes'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import GroupForm from './GroupForm/GroupForm'
import { IGroupForm, groupSchema } from './GroupForm/GroupSchema'

interface IProps {
  groupData: any
  handleDataUpdate: any
}

const GroupEditView = ({ groupData, handleDataUpdate }: IProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { orgId: organizationId = '', groupId = '' } = useParams()
  const orgId = isOpswatIncRoute ? OPSWAT_ORGANIZATION_ID : organizationId

  const { data: roleData } = useAdminRolesQuery({
    filters: { organizationId: groupData.organization.id },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: 100
    }
  })

  const [adminGroupUpdate, { isLoading }] = useAdminGroupUpdateMutation()

  const initValues = useMemo(() => {
    return {
      id: groupData.id,
      name: groupData.name,
      description: groupData.description,
      roles: groupData.roles,
      users: groupData.users.map((item: any) => ({
        ...item,
        id: item.id,
        fullName: fullName(item.firstName, item.lastName)
      }))
    }
  }, [groupData])

  const formRef = useForm<IGroupForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(groupSchema)
  })

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef

  const roles = useMemo(() => {
    if (_isEmpty(roleData)) {
      return []
    }
    return roleData.results.map((item: any) => ({
      id: item.id,
      name: item.name,
      description: item.description
    }))
  }, [roleData])

  const groupDetailPageURL = isOpswatIncRoute
    ? `${opswatGroupListPageURL}/${groupId}/${detailUserGroupPath}`
    : `${customerManagementOrganizationListPageURL}/${orgId}/${organizationGroupsPath}/${groupId}/${detailOrganizationGroupPath}`

  const onSubmit = async (data: IGroupForm) => {
    try {
      const updatingData: any = {
        id: groupData.id
      }
      Object.keys(dirtyFields).forEach(field => {
        if (field === 'roles' || field === 'users') {
          updatingData[`${field.slice(0, -1)}Ids`] = data[field].map((item: any) => item.id)
        } else {
          updatingData[field] = data[field as keyof IGroupForm]
        }
      })

      const res = await adminGroupUpdate(updatingData).unwrap()
      if (res.success) {
        enqueueSnackbar('The group information has been updated successfully.', { variant: 'success' })
        handleDataUpdate(res.group)
        navigate(groupDetailPageURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <GroupForm formRef={formRef} groupData={groupData} roleData={roles} />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="inherit"
              disabled={isLoading}
              onClick={() => {
                navigate(groupDetailPageURL)
              }}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
                disabled: isLoading || _isEmpty(dirtyFields)
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default GroupEditView
