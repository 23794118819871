import { dashboardPageURL } from './dashboardRoutes'

/** ----------------- PATHNAME -------------------- */
export const falseDetectionSubmissionPath = 'false-detection-submission'
export const falseDetectionSubmissionsPath = 'submissions'
export const falseDetectionSubmissionEventLogsPath = 'event-logs'
export const falseDetectionAntivirusEnginePath = 'engines'
export const falseDetectionAntivirusEngineNewPath = 'new'
export const falseDetectionAntivirusEngineIdPath = ':engineId'
export const falseDetectionTabPath = ':tabPath'
export const falseDetectionDetailPath = 'detail'
export const falseDetectionIdPath = ':submissionId'
export const FalseDetectionEditPath = 'edit'

/** ----------------- ROUTE -------------------- */
export const dashboardFalseDetectionSubmissionBaseURL = `${dashboardPageURL}/${falseDetectionSubmissionPath}`
export const dashboardFalseDetectionSubmissionFlexURL = `${dashboardPageURL}/${falseDetectionSubmissionPath}/${falseDetectionTabPath}`
export const dashboardFalseDetectionSubmissionListURL = `${dashboardPageURL}/${falseDetectionSubmissionPath}/${falseDetectionSubmissionsPath}`
export const dashboardFalseDetectionSubmissionAntivirusEngineListURL = `${dashboardPageURL}/${falseDetectionSubmissionPath}/${falseDetectionAntivirusEnginePath}`
export const dashboardFalseDetectionSubmissionAntivirusEngineNewPageURL = `${dashboardFalseDetectionSubmissionAntivirusEngineListURL}/${falseDetectionAntivirusEngineNewPath}`
export const dashboardFalseDetectionSubmissionAntivirusEngineDetailFlexPageURL = `${dashboardFalseDetectionSubmissionAntivirusEngineListURL}/${falseDetectionAntivirusEngineIdPath}/${falseDetectionTabPath}`
export const detailFalseDetectionSubmissionPageURL = `${dashboardFalseDetectionSubmissionListURL}/${falseDetectionIdPath}/${falseDetectionDetailPath}`

export const dashboardFalseDetectionSubmissionEventLogsURL = `${dashboardPageURL}/${falseDetectionSubmissionPath}/${falseDetectionSubmissionEventLogsPath}`
