import _get from 'lodash/get'

import { FC, useEffect, useState } from 'react'

import { DIALOGS } from '@myopswat/common'
import { Button, DialogAdvanced, Grid, Typography } from '@opswat/react-ui'

import useDialog from './DialogHook'

const ConfirmationDialog: FC<unknown> = () => {
  const { onConfirm, onCancel, dialogState } = useDialog()

  const [countdownValue, setCountdownValue] = useState<number>(0)

  const openDialog = _get(dialogState, DIALOGS.CONFIRM, false)

  const countdown = _get(dialogState, `${DIALOGS.CONFIRM}_DATA.countdown`, 0)

  const confirmText = _get(dialogState, `${DIALOGS.CONFIRM}_DATA.confirmText`, 'Yes')

  const countdownText = countdownValue > 0 ? `(${countdownValue})` : ''

  useEffect(() => {
    setCountdownValue(countdown)
    const interval = setInterval(() => {
      setCountdownValue(prev => (prev > 0 ? prev - 1 : 0))
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [openDialog])

  return (
    <DialogAdvanced
      title={_get(dialogState, `${DIALOGS.CONFIRM}_DATA.title`, 'Confirmation')}
      open={openDialog}
      disableBackdropClick={_get(dialogState, `${DIALOGS.CONFIRM}_DATA.disableBackdropClick`, false)}
      onClose={onCancel}
      content={
        <>
          <Typography variant="body2">{_get(dialogState, `${DIALOGS.CONFIRM}_DATA.content`, '--')}</Typography>
          {_get(dialogState, `${DIALOGS.CONFIRM}_CUSTOM_CONTENT`)?.()}
        </>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              disableElevation
              variant="text"
              color="inherit"
              onClick={onCancel}
              sx={{
                textTransform: 'none'
              }}
            >
              {_get(dialogState, `${DIALOGS.CONFIRM}_DATA.cancelText`, 'Cancel')}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              disableElevation
              variant="contained"
              color="primary"
              disabled={countdownValue > 0}
              onClick={onConfirm}
              sx={{
                textTransform: 'none'
              }}
            >
              {`${confirmText} ${countdownText}`}
            </Button>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: _get(dialogState, `${DIALOGS.CONFIRM}_DATA.maxWidth`, 'xs')
      }}
    />
  )
}

export default ConfirmationDialog
