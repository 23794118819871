import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime, fullName } from '@opswat/react-core'
import { Avatar, Box, Button, Grid, TextGrid, Typography } from '@opswat/react-ui'
import _map from 'lodash/map'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementOrganizationListPageURL,
  editOrganizationGroupPath,
  opswatGroupListPageURL,
  organizationGroupsPath
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

interface IProps {
  groupData: any
  creatorData: any
}

const GroupDetailView = ({ groupData, creatorData }: IProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const navigate = useNavigate()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditGroupDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_group')
    }
    return false
  }, [profileData])

  const groupEditPageURL = isOpswatIncRoute
    ? `${opswatGroupListPageURL}/${groupData.id}/${editOrganizationGroupPath}`
    : `${customerManagementOrganizationListPageURL}/${groupData.organization.id}/${organizationGroupsPath}/${groupData.id}/${editOrganizationGroupPath}`

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <TextGrid label={'Name*'} value={groupData.name} />
          <TextGrid label={'Description'} value={groupData.description} />
          <TextGrid label={'Organization'} value={groupData.organization.name} />
          <TextGrid label={'Roles'} value={groupData.roles.map((i: any) => i.name).join(', ')} />
          <TextGrid
            label={'Users'}
            value={
              <Box sx={{ maxHeight: '350px', minHeight: '150px', height: 'auto', overflowY: 'scroll' }}>
                {_map(groupData.users, item => (
                  <Box display="flex" sx={{ alignItems: 'center', p: 1 }}>
                    <Avatar alt={fullName(item.firstName, item.lastName)} sx={{ width: 28, height: 28 }} />
                    <Typography sx={{ ml: 1 }}>
                      {fullName(item.firstName, item.lastName)} ({item.email})
                    </Typography>
                  </Box>
                ))}
              </Box>
            }
          />
          <TextGrid label={'Creator'} value={creatorData?.fullName} />
          <TextGrid
            label={'Updated at'}
            value={formatDatetime(groupData.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          />
          <TextGrid
            label={'Created at'}
            value={formatDatetime(groupData.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={() => navigate(groupEditPageURL)}
              color="primary"
              disabled={isEditGroupDisabled}
            >
              Edit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default GroupDetailView
