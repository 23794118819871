import { Grid } from '@opswat/react-ui'
import ChartWidget from '../ChartWidget/ChartWidget'
import { NPS_COLORS } from '../contants'

export interface INpsTrackerChart {
  percentData: any[]
  categoriesData: any[]
  seriesData: any[]
}

export const NpsTrackerChart = ({ categoriesData, seriesData, percentData }: INpsTrackerChart) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ChartWidget
          type="bar"
          categories={categoriesData}
          series={[
            {
              name: 'Response(s)',
              type: 'column',
              data: percentData
            }
          ]}
          options={{
            title: {
              text: 'Percentage (%)',
              style: {
                fontFamily: 'Roboto'
              }
            },
            dataLabels: {
              formatter: function (val: any) {
                return val === 0 ? '' : val
              },
              offsetY: -20,
              style: {
                fontSize: '12px',
                colors: ['#304758']
              }
            },
            colors: [
              function ({ dataPointIndex }: { dataPointIndex: number }) {
                if (dataPointIndex <= 6) {
                  return NPS_COLORS.RED
                } else if (dataPointIndex <= 8) {
                  return NPS_COLORS.GRAY
                } else {
                  return NPS_COLORS.GREEN
                }
              }
            ],
            tooltip: {
              custom: function (props: any) {
                return `
                <div style="
                  display: flex;
                  height: 50px;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 0px 8px;
                  background-color: rgba(233, 234, 235, 1);
                ">
                  <div style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                  ">
                    <span style="font-size: 16px; font-weight: 500;">${percentData[props.dataPointIndex]}%</span>
                    <div style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      gap: -3px;
                    ">
                      <span style="font-size: 14px; font-weight: 500;">${seriesData[props.dataPointIndex]}</span>
                      <span style="font-size: 12px; font-weight: 400;">Responses</span>
                    </div>
                  </div>
                </div>
              `
              }
            }
          }}
          height={400}
        />
      </Grid>
    </Grid>
  )
}
