import { apiGraphql } from '../base-query'
import { gql } from 'graphql-request'

import { AdminRoleObjectFiltersInput, IRoleObjectInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminRoleObjects: builder.query<any, AdminRoleObjectFiltersInput>({
      query: ({ filters, pageInfo, sortInfo }) => ({
        document: gql`
          query ($filters: AdminRoleObjectFiltersInput, $pageInfo: PageInfoType, $sortInfo: SortInfoType) {
            adminRoleObjects(filters: $filters, pageInfo: $pageInfo, sortInfo: $sortInfo) {
              results {
                id
                code
                name
                permissions
                componentId
                updatedByUser
                updatedAt
              }
              totalCount
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo,
          sortInfo: sortInfo
        }
      })
    }),
    adminRoleObjectsComponent: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            adminRoleObjectsComponent {
              id
              name
            }
          }
        `
      })
    }),
    adminRoleObjectCreate: builder.mutation<any, IRoleObjectInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminRoleObjectCreateInput!) {
            adminRoleObjectCreate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminRoleObjectUpdate: builder.mutation<any, IRoleObjectInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminRoleObjectUpdateInput!) {
            adminRoleObjectUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminRoleObjectDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminRoleObject(id: "${id}") {
              id
              code
              name
              permissions
              componentId
              assignedPermissions {
                roleName
                selectedPermissions
              }
            }
          }
        `
      })
    }),
    adminRoleObjectDelete: builder.mutation<any, IRoleObjectInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminRoleObjectDeleteInput!) {
            adminRoleObjectDelete(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminRoleObjectEventLogs: builder.query<any, any>({
      query: ({ pageInfo, filters, sortInfo }) => ({
        document: gql`
          query ($pageInfo: PageInfoType, $filters: EventLogsFilterInput, $sortInfo: SortInfoType) {
            adminRoleObjectEventLogs(pageInfo: $pageInfo, filters: $filters, sortInfo: $sortInfo) {
              results {
                id
                historyRoleObject {
                    id
                    name
                    code
                }
                historyUpdatedBy {
                  email
                }
                historyDate
                historyType
                historyUpdatedReason
                name
                changes {
                    field
                    old
                    new
                }
              }
              totalCount
            }
          }
        `,
        variables: {
          pageInfo,
          filters,
          sortInfo
        }
      })
    }),
    adminGlobalRoles: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
          adminRoleObjectGlobalRoles {
              id
              name
            }
          }
        `,
      })
    }),
  })
})

export const {
  useAdminRoleObjectsQuery,
  useLazyAdminRoleObjectsComponentQuery,
  useAdminRoleObjectCreateMutation,
  useAdminRoleObjectUpdateMutation,
  useLazyAdminRoleObjectDetailQuery,
  useAdminRoleObjectDeleteMutation,
  useLazyAdminRoleObjectEventLogsQuery,
  useLazyAdminGlobalRolesQuery
} = api
