import { formatDatetime, fullName } from '@opswat/react-core'
import { CollapsibleTableAdvanced, TemplateSection, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import _join from 'lodash/join'
import _map from 'lodash/map'
import { useLazyAdminDetailProductHistoriesQuery } from 'myopswat-admin/src/api/history'
import {
  IAdminDetailProductHistoryInput,
  IAdminDetailProductHistoryType,
  IHistoryDiffType
} from 'myopswat-admin/src/api/history/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { convertValueToString } from 'myopswat-admin/src/utils/json'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PRODUCT_FORM_LABEL_MAP } from '../../constants'
import { useTypedSelector } from 'myopswat-admin/src/store'
interface IProps {
  product: any
  productMap: any
  solutionMap: any
}
const ProductHistoryListPage = ({ product, productMap, solutionMap }: IProps) => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const [query, setQuery] = useState<IAdminDetailProductHistoryInput>({
    productId: _get(product, 'id'),
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })
  const [getDetailProductHistories, { data, isFetching: isLoading }] = useLazyAdminDetailProductHistoriesQuery()

  const collapseData = useMemo(
    () =>
      _map(data?.results, (columnData: IAdminDetailProductHistoryType) => {
        return !columnData.hiddenRelease
          ? columnData.changes
          : [
              {
                field: 'Hide',
                old: 'False',
                new: 'True'
              }
            ]
      }),
    [data]
  )

  useEffect(() => {
    getDetailProductHistories(query)
  }, [query])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }

  const convertParentIdsToParentProducts = useCallback(
    (parentIds: string) => {
      return _join(
        _map(parentIds.split(', '), parentId => productMap[parentId]),
        ', '
      )
    },
    [productMap]
  )

  const convertSolutionIdsToSolutions = useCallback(
    (solutionIds: string) => {
      return _join(
        _map(solutionIds.split(', '), solutionId => solutionMap[solutionId]),
        ', '
      )
    },
    [solutionMap]
  )

  const convertLicensedProductIdsToLicensedProducts = useCallback(
    (licensedProductIds: string) => {
      return _join(
        _map(licensedProductIds.split(', '), licensedProductId => productMap[licensedProductId]),
        ', '
      )
    },
    [productMap]
  )

  const renderImpactedColumn = (data: IAdminDetailProductHistoryType) => {
    if (!data.hiddenRelease) return <Typography variant="body2">{_get(product, 'name', '--')}</Typography>

    return (
      <Typography variant="body2">
        {_get(product, 'name', '--')} / Version {_get(data, 'hiddenRelease.semVer', '--')}
      </Typography>
    )
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: IAdminDetailProductHistoryType) => (
          <Typography variant="body2">{data.historyType || '--'}</Typography>
        ),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Impacted',
        body: renderImpactedColumn,
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'Reason',
        body: (data: IAdminDetailProductHistoryType) => (
          <Typography variant="body2">{data.historyUpdatedReason || '--'}</Typography>
        ),
        style: { minWidth: 250, width: 450 }
      },
      {
        header: 'Updated By',
        body: (data: IAdminDetailProductHistoryType) => (
          <Typography variant="body2">
            {_get(data, 'historyUpdatedBy')
              ? fullName(data.historyUpdatedBy?.firstName, data.historyUpdatedBy?.lastName)
              : '--'}
          </Typography>
        ),
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'Datetime',
        body: (data: IAdminDetailProductHistoryType) => (
          <Typography variant="body2">
            {formatDatetime(data.historyDate, undefined, profileData?.timezone) || '--'}
          </Typography>
        ),
        style: { minWidth: 175, width: 175 }
      }
    ]
  }, [])

  const collapsedColumnArray = useMemo(() => {
    return [
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => {
          let field = _get(PRODUCT_FORM_LABEL_MAP, data.field, data.field) || '--'
          if (field === 'parentIds') {
            field = PRODUCT_FORM_LABEL_MAP.parentProducts
          } else if (field === 'solutionIds') {
            field = PRODUCT_FORM_LABEL_MAP.solutions
          }
          return <Typography variant="body2">{field}</Typography>
        },
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => {
          let change = convertValueToString(data.new || '--')

          if (data.field === 'parentIds') {
            change = convertParentIdsToParentProducts(change)
          } else if (data.field === 'solutionIds') {
            change = convertSolutionIdsToSolutions(change)
          } else if (data.field === 'licensedProductsDisplay') {
            change = convertLicensedProductIdsToLicensedProducts(change)
          }

          return <Typography variant="body2">{change}</Typography>
        },
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => {
          let old = convertValueToString(data.old || '--')

          if (data.field === 'parentIds') {
            old = convertParentIdsToParentProducts(old)
          } else if (data.field === 'solutionIds') {
            old = convertSolutionIdsToSolutions(old)
          } else if (data.field === 'licensedProductsDisplay') {
            old = convertLicensedProductIdsToLicensedProducts(old)
          }

          return <Typography variant="body2">{old}</Typography>
        },
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <CollapsibleTableAdvanced
        columns={columnArray}
        collapsedColumns={collapsedColumnArray}
        isLoading={isLoading}
        data={data?.results}
        collapsedData={collapseData}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}
export default ProductHistoryListPage
