import _get from 'lodash/get'
import { useMemo } from 'react'
import { useTypedSelector } from '../store'

interface IProps {
  targetPerrmissions: string[]
}

const useCheckPermissions = ({ targetPerrmissions }: IProps) => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const permission = useMemo(() => {
    // Get user permission from Profile
    const userPermissions: string[] = _get(profileData, 'permissions', [])

    const result: any = {}
    targetPerrmissions.forEach(permission => {
      let hasPermission = false
      if (userPermissions.includes(permission)) {
        hasPermission = true
      }
      result[permission] = hasPermission
    })

    return result
  }, [profileData])

  return {
    permission
  }
}

export { useCheckPermissions as default }
