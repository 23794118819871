import { gql } from 'graphql-request'
import { apiGraphql } from '../../base-query'
import {
  IFalseSubmissionAntivirusEngineInput,
  IFalseSubmissionAntivirusEngineFilterInput,
  IFalseSubmissionFilterInput,
  IFalseSubmissionResendEmailInput,
  IFalseSubmissionUpdateInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminFalseSubmissions: builder.query<any, IFalseSubmissionFilterInput>({
      query: input => ({
        document: gql`
          query adminFalseSubmissions($filters: AdminFalseSubmissionFiltersInput, $pageInfo: PageInfoType) {
            adminFalseSubmissions(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                createdAt
                updatedAt
                filename
                hash
                inputType
                url
                md5
                sha1
                sha256
                sha384
                sha512
                size
                metadata
                createdByFullname
                createdByEmail
                category
                cxStatus
                analystNote
                validity
                note
                productIds
                productNames
                caseNumber
                caseId
                idIssueJira
                keyIssueJira
              }
            }
          }
        `,
        variables: input
      })
    }),
    adminFalseSubmission: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminFalseSubmission(id: "${id}") {
              id
              password
            }
          }
        `
      })
    }),
    adminFalseSubmissionUpdate: builder.mutation<any, IFalseSubmissionUpdateInput>({
      query: payload => ({
        document: gql`
          mutation ($input: AdminFalseSubmissionUpdateInput!) {
            adminFalseSubmissionUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    adminDownloadFalseSubmission: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          query {
            adminDownloadFalseSubmission(id: ${id}) {
              url
            }
          }
        `
      })
    }),
    adminFalseSubmissionResendEmail: builder.mutation<any, IFalseSubmissionResendEmailInput>({
      query: payload => ({
        document: gql`
          mutation ($input: AdminFalseSubmissionResendEmailInput!) {
            adminFalseSubmissionResendEmail(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: payload
        }
      })
    }),
    adminFalseSubmissionAntivirusEngines: builder.query<any, IFalseSubmissionAntivirusEngineFilterInput>({
      query: input => ({
        document: gql`
          query adminFalseSubmissionAntivirusEngines(
            $filters: AdminFalseDetectAntivirusEngineFiltersInput!
            $pageInfo: PageInfoType
          ) {
            adminFalseSubmissionAntivirusEngines(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                createdAt
                engineName
                vendorName
                country
                detection
                heuristicDetection
                isActive
              }
            }
          }
        `,
        variables: input
      })
    }),
    adminFalseSubmissionAntivirusEngineCreate: builder.mutation<any, IFalseSubmissionAntivirusEngineInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminFalseSubmissionAntivirusEngineCreateInput!) {
            adminFalseSubmissionAntivirusEngineCreate(input: $input) {
              engine {
                id
              }
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminFalseSubmissionAntivirusEngineDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminFalseSubmissionAntivirusEngine(id: "${id}") {
              id
              engineName
              vendorName
              country
              detection
              heuristicDetection
              isActive
              createdAt
            }
          }
        `
      })
    }),
    adminFalseSubmissionAntivirusEngineUpdate: builder.mutation<any, IFalseSubmissionAntivirusEngineInput>({
      query: input => ({
        document: gql`
          mutation ($input: AdminFalseDetectionAntivirusEngineUpdateInput!) {
            adminFalseSubmissionAntivirusEngineUpdate(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: input
        }
      })
    }),
    adminFalseSubmissionDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminFalseSubmissionDetail(id: "${id}") {
              id
              createdAt
              filename
              hash
              inputType
              url
              md5
              sha1
              sha256
              sha384
              sha512
              size
              metadata
              createdByFullname
              createdByEmail
              category
              cxStatus
              analystNote
              validity
              note
              productIds
              productNames
              antivirusEngine
              detectionName
              purpose
              userConsent
              updatedAt
              fileOrigin
              submissionScreenshotUrl
              submissionScreenshotName
            }
          }
        `
      })
    }),
    adminFalseSubmissionEventLogs: builder.query<any, any>({
      query: ({ pageInfo, filters, sortInfo }) => ({
        document: gql`
          query adminFalseSubmissionEventLogs(
            $filters: EventLogsFilterInput
            $pageInfo: PageInfoType
            $sortInfo: SortInfoType
          ) {
            adminFalseSubmissionEventLogs(pageInfo: $pageInfo, sortInfo: $sortInfo, filters: $filters) {
              totalCount
              results {
                id
                impacted
                historyDate
                historyType
                historyUpdatedReason
                historyUpdatedBy {
                  id
                  email
                }
                historyUpdatedByApp {
                  id
                  name
                }
                changes {
                  field
                  new
                  old
                }
              }
            }
          }
        `,
        variables: {
          pageInfo,
          filters,
          sortInfo
        }
      })
    })
  })
})
export const {
  useAdminFalseSubmissionsQuery,
  useLazyAdminFalseSubmissionsQuery,
  useLazyAdminFalseSubmissionQuery,
  useAdminFalseSubmissionUpdateMutation,
  useAdminDownloadFalseSubmissionMutation,
  useAdminFalseSubmissionResendEmailMutation,
  useAdminFalseSubmissionAntivirusEnginesQuery,
  useAdminFalseSubmissionAntivirusEngineCreateMutation,
  useLazyAdminFalseSubmissionAntivirusEngineDetailQuery,
  useAdminFalseSubmissionAntivirusEngineUpdateMutation,
  useAdminFalseSubmissionDetailQuery,
  useLazyAdminFalseSubmissionEventLogsQuery
} = api
