function cleanHtmlTags(htmlString: string) {
  // remove html tags using regex
  const cleanText = htmlString.replace(/<[^>]+>/g, '')
  return cleanText
}

function removeExtraWhitespaces(text: string) {
  // strip leading and trailing spaces and remove extra whitespaces between lines
  return text
    .split('\n')
    .map(line => line.trim())
    .filter(line => line.length > 0)
    .join('\n')
}

function stripHTMLTags(text: string) {
  const cleanText = cleanHtmlTags(text)
  return removeExtraWhitespaces(cleanText)
}

export { stripHTMLTags }
