import React from 'react'
import { Control } from 'react-hook-form'

import { IMassNotificationInputType, IMassNotificationQuery } from 'myopswat-admin/src/api/massEmail/types'
import { QueryPagination } from 'myopswat-admin/src/api/types'

export type MassNotificationContextProps = {
  columns: any[]
  totalCount: number
  results: IMassNotificationInputType[]
  control: Control<IMassNotificationQuery>
  pageInfo: QueryPagination
  isFetching: boolean
  handleReset: () => void
  handleQuery: (name: string, value: any) => void
  handleSearch: () => void
  handlePagination: (pageNumber: number, pageSize: number) => void
}

export const MassNotificationContext = React.createContext<MassNotificationContextProps>({
  results: [],
  columns: [],
  totalCount: 0,
  pageInfo: {} as QueryPagination,
  control: {} as Control<IMassNotificationQuery>,
  isFetching: false,
  handleReset: () => {
    return
  },
  handleQuery: () => {
    return
  },
  handleSearch: () => {
    return
  },
  handlePagination: () => {
    return
  }
})
