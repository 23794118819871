import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { TemplateFormLogin, TemplateSection, Box, Typography, Grid, TypographyLink } from '@opswat/react-ui'
import { OpswatIcon } from '@opswat/react-icon'

import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'
import { resendEmailPageURL, resetPasswordPageURL } from 'myopswat-admin/src/routes'

interface IProps {
  children?: React.ReactNode
}

const ConfirmEmailPage: FC<IProps> = () => {
  const navigate = useNavigate()

  return (
    <TemplateFormLogin background={imageBg}>
      <TemplateSection spacing={4}>
        <Box>
          <OpswatIcon />
        </Box>

        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h1">Create your OPSWAT Account</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Manage multiple OPSWAT products remotely, improving productivity flows, providing better visibility
                    between team members and more.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h3">Check your email to activate your account</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Thank you for registering - confirm your account by clicking the button in your email. If you didn't
                    receive the activation email you can click&nbsp;
                    <TypographyLink onClick={() => navigate(resendEmailPageURL)}>resend</TypographyLink>
                    &nbsp;to try again. Please check your spam or junk folder incase it's redirected there.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    If no email is received within ten minutes, check the email address you submitted is correct
                    or&nbsp;
                    <TypographyLink onClick={() => navigate(resetPasswordPageURL)}>reset your password</TypographyLink>.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TemplateSection>
    </TemplateFormLogin>
  )
}

export default ConfirmEmailPage
