import React, { FC, useEffect } from 'react'

import _get from 'lodash/get'

import { Box, Typography, TextField, ButtonLoading, Alert, Grid, AlertTitle } from '@opswat/react-ui'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { IAccountResendInput } from 'myopswat-admin/src/api/account/types'

interface IProps {
  children?: React.ReactNode
  setStep: (value: string) => void
  isSuccess: boolean | undefined
  isLoading: boolean
  handleAccountResend: (data: IAccountResendInput) => void
  errorMessage?: string
}

const RequestEmail: FC<IProps> = ({ setStep, isSuccess, isLoading, handleAccountResend, errorMessage }) => {
  const defaultValues = {
    email: ''
  }

  const schema = yup.object().shape({
    email: yup.string().trim().required('Email cannot be blank').email('Please enter the correct email structure')
  })

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (data: any) => {
    handleAccountResend({
      email: _get(data, 'email', ''),
      customHost: process.env.REACT_APP_FRONT_END_HOST || ''
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  useEffect(() => {
    if (isSuccess) {
      setStep('sent')
    }
  }, [isSuccess])

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        minHeight: '500px',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">Resend activation email</Typography>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Email</Typography>
          <Controller
            name="email"
            control={control}
            render={(cProps: any) => (
              <TextField
                autoFocus
                placeholder="Email address"
                size="small"
                fullWidth
                error={!!_get(errors, 'email', '')}
                helperText={_get(errors, 'email.message', '')}
                required
                onKeyUp={(e: any) => {
                  if (_get(e, 'keyCode') === 13) {
                    handleSubmit(onSuccess, onFail)()
                  }
                }}
                value={cProps.field.value}
                onChange={(e: any) => cProps.field.onChange(e.target.value.toLowerCase())}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: handleSubmit(onSuccess, onFail),
            disabled: isLoading || !watch('email'),
            fullWidth: true
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isLoading}
        >
          Resend Email
        </ButtonLoading>
      </Box>
    </Box>
  )
}

export default RequestEmail
