import { customerManagementPageURL } from '../customerManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const customerOrganizationsPath = 'organizations'
export const customerOrganizationIdParam = ':orgId'
export const customerOrganizationNewPath = 'new'
export const detailCustomerOrganizationPath = 'detail'
export const tabCustomerOrganizationPath = ':tabPath'
export const tabCustomerOrganizationHistoryPath = 'history'
export const tabCustomerOrganizationCustomersPath = 'customers'
export const tabCustomerOrganizationSubOrganizationsPath = 'sub-organizations'

/** ----------------- ROUTE -------------------- */
export const customerManagementOrganizationListPageURL = `${customerManagementPageURL}/${customerOrganizationsPath}`
export const customerManagementOrganizationNewPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationNewPath}`
export const customerManagementOrganizationDetailPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${tabCustomerOrganizationPath}`
export const customerManagementOrganizationHistoryPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${tabCustomerOrganizationHistoryPath}`
export const customerManagementOrganizationCustomersPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${tabCustomerOrganizationCustomersPath}`
export const customerManagementOrganizationSubOrganizationsPageURL = `${customerManagementOrganizationListPageURL}/${customerOrganizationIdParam}/${tabCustomerOrganizationSubOrganizationsPath}`
