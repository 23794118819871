import { Grid, TextFieldSearch } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { AdminOrganizationFilter } from 'myopswat-admin/src/api/organizationManagement/types'
import { useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FilterSectionProps {
  filters: AdminOrganizationFilter
  onSearch: (searchData: AdminOrganizationFilter) => void
}

const FilterSection = ({ filters, onSearch }: FilterSectionProps) => {
  const { t: translate } = useTranslation()
  const {
    control,
    getValues,
    formState: { dirtyFields }
  } = useForm<AdminOrganizationFilter>({
    mode: 'onChange',
    defaultValues: filters
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields])

  const q = useWatch({
    control: control,
    name: 'q'
  })

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          ...getValues(),
          q: q
        }
        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterOrgName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  onSearch({
                    ...getValues(),
                    q: ''
                  })
                }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default FilterSection
