import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-admin/src/api/base-query'
import { AdminRoleInputType, AdminRoleQueryInput, AdminRolesQueryInput, IRoleFiltersInput } from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminRoles: builder.query<any, AdminRolesQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminRoleFiltersInput, $pageInfo: PageInfoType) {
            adminRoles(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                accessLevel
                name
                description
                ssoId
                updatedAt
                createdAt
                organization {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          filters: filters,
          pageInfo: pageInfo
        }
      })
    }),
    adminRole: builder.query<any, AdminRoleQueryInput>({
      query: ({ id, organizationId }) => ({
        document: gql`
          query {
            adminRole(id: "${id}", organizationId: "${organizationId}") {
              id
              name
              description
              updatedAt
              createdAt
              organization {
                id
                name
              }
              contentTypePermissions {
                displayName
                mappingName
                permissions {
                  id
                  name
                  codename
                  isVisible
                }
              }
              creatorId
            }
          }
        `
      })
    }),
    roles: builder.query<any, IRoleFiltersInput>({
      query: ({ q }) => ({
        document: gql`
          query ($filters: RoleFiltersInput) {
            roles(filters: $filters) {
              id
              accessLevel
              name
              description
              ssoId
              updatedAt
              createdAt
              permissions {
                id
                name
                codename
                contentTypeId
                isVisible
              }
              creatorId
            }
          }
        `,
        variables: {
          filters: {
            q: q
          }
        }
      })
    }),
    roleCreate: builder.mutation<any, any>({
      query: ({ name, permissionIds }) => ({
        document: gql`
          mutation ($input: RoleInput!) {
            roleCreate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input: {
            name: name,
            permissionIds: permissionIds || []
          }
        }
      })
    }),
    roleDelete: builder.mutation<any, string>({
      query: id => ({
        document: gql`
        mutation {
          roleDelete(id: "${id}") {
              success
              errors
          }
      }
        `
      })
    }),
    adminRoleUpdate: builder.mutation<any, AdminRoleInputType>({
      query: input => ({
        document: gql`
          mutation adminRoleUpdate($input: RoleInput!) {
            adminRoleUpdate(input: $input) {
              errors
              success
              role {
                id
                name
                description
                updatedAt
                createdAt
                organization {
                  id
                  name
                }
                contentTypePermissions {
                  displayName
                  mappingName
                  permissions {
                    id
                    name
                    codename
                    isVisible
                  }
                }
                creatorId
              }
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useAdminRolesQuery,
  useAdminRoleQuery,
  useRolesQuery,
  useLazyRolesQuery,
  useRoleCreateMutation,
  useRoleDeleteMutation,
  useAdminRoleUpdateMutation
} = api
