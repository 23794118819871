import { IGroupRole, IGroupUser } from 'myopswat-admin/src/api/group/types'
import yup from 'myopswat-admin/src/utils/yup'

export interface IGroupForm {
  id: string
  name: string
  description: string
  roles: IGroupRole[]
  users: IGroupUser[]
}

export const groupSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required('Group name cannot be blank')
    .max(150, 'The maximum group name length is 150 characters'),
  description: yup.string().trim().max(2000, 'The maximum description length is 2000 characters'),
  roles: yup.array(),
  users: yup.array()
})
