import { useLazyAdminFalseSubmissionAntivirusEngineDetailQuery } from '../../../../api/analyzer/falseSubmission'
import { useEffect, useMemo } from 'react'
import {
  dashboardFalseDetectionSubmissionAntivirusEngineListURL,
  FalseDetectionEditPath
} from 'myopswat-admin/src/routes'
import { TypographyDivider } from '@opswat/react-ui'
import FalseSubmissionAntivirusEngineDetailView from './FalseSubmissionAntivirusEngineDetailView'
import { useParams } from 'react-router-dom'
import FalseSubmissionAntivirusEngineDetailEdit from './FalseSubmissionAntivirusEngineEdit'
import { useLazySysItemCountriesQuery } from '../../../../api/system'
import { Helmet } from 'react-helmet'
import FalseSubmissionAntivirusEngineDetailLoading from './FalseSubmissionAntivirusEngineDetailLoading'

interface IProps {
  engineId: string
}
const FalseSubmissionAntivirusEngineDetailPage = ({ engineId }: IProps) => {
  const { tabPath = '' } = useParams()
  const [getEngineDetail, { data, isFetching }] = useLazyAdminFalseSubmissionAntivirusEngineDetailQuery()
  const [getSysItemCountries, { data: sysItemCountriesData }] = useLazySysItemCountriesQuery()

  const engine = useMemo(() => {
    return data || {}
  }, [data])

  const isRender = useMemo(() => {
    return engine && !isFetching
  }, [engine, isFetching])

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Antivirus Engine List',
        path: dashboardFalseDetectionSubmissionAntivirusEngineListURL
      },
      {
        label: engine?.engineName || '--'
      }
    ]
  }, [engine])
  useEffect(() => {
    if (engineId && !data) {
      getEngineDetail(engineId)
    }
  }, [engineId])

  useEffect(() => {
    getSysItemCountries()
  }, [])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          Antivirus Engine Detail
        </title>
      </Helmet>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && <FalseSubmissionAntivirusEngineDetailLoading />}

      {isRender &&
        (tabPath === FalseDetectionEditPath ? (
          <FalseSubmissionAntivirusEngineDetailEdit engine={engine} sysItemCountriesData={sysItemCountriesData} />
        ) : (
          <FalseSubmissionAntivirusEngineDetailView engine={engine} sysItemCountriesData={sysItemCountriesData} />
        ))}
    </>
  )
}
export default FalseSubmissionAntivirusEngineDetailPage
