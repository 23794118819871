import { DATE_FORMATS, DIALOGS } from '@myopswat/common'
import { dateDiffInDays, formatDatetime } from '@opswat/react-core'
import { Box, ButtonLoading, DateRangePicker, DialogAdvanced, Grid } from '@opswat/react-ui'
import { addDays } from 'date-fns'
import _get from 'lodash/get'
import { debounce } from 'lodash'
import { useLazyAdminFalseSubmissionExportQuery } from 'myopswat-admin/src/api/export'
import { SIX_MONTH_DAYS } from 'myopswat-admin/src/constants/dateTime'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'

const DialogFalseDetectionExport: FC<unknown> = () => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const { enqueueSnackbar } = useSnackbar()

  const debouncedEnqueueSnackbar = debounce((message, options) => {
    enqueueSnackbar(message, options)
  }, 300)

  const defaultValues = {
    startDate: addDays(new Date(), -6),
    endDate: new Date(),
    key: 'selection'
  }

  const [state, setState] = useState(defaultValues)

  const handleClose = () => {
    setState(defaultValues)
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_EXPORT]: false,
        [`${DIALOGS.FALSE_DETECTION_EXPORT}_DATA`]: ''
      })
    )
  }

  const [falseSubmissionExport, { isFetching }] = useLazyAdminFalseSubmissionExportQuery()

  const handleSubmit = async () => {
    if (state) {
      let toDate = state.endDate
      toDate = new Date(toDate.toString())
      toDate.setHours(23, 59, 59)

      falseSubmissionExport({
        fromDate: state.startDate,
        toDate: toDate
      })
        .unwrap()
        .then(async response => {
          const blob = await response.clone().blob()
          if (blob.size === 0) {
            enqueueSnackbar('No data available for the selected time range. Please adjust your time range', {
              variant: 'warning'
            })
          } else {
            const element = document.createElement('a')
            element.href = URL.createObjectURL(await response.blob())
            element.download = `My OPSWAT_False Detection Submission_${formatDatetime(
              state.startDate,
              DATE_FORMATS.EXPORT_FORMAT
            )}_${formatDatetime(state.endDate, DATE_FORMATS.EXPORT_FORMAT)}.xlsx`
            document.body.appendChild(element)
            element.click()
            enqueueSnackbar('The False Detection Submission Excel file has been successfully downloaded.', {
              variant: 'success'
            })
          }
        })
    } else {
      enqueueSnackbar('Please select a date range', { variant: 'warning' })
    }
  }

  const handleOnChange = (item: any) => {
    if (item.selection && item.selection.startDate && item.selection.endDate) {
      const diff_in_days = dateDiffInDays(item.selection.startDate, item.selection.endDate)
      if (diff_in_days <= SIX_MONTH_DAYS) {
        setState(item.selection)
      } else {
        debouncedEnqueueSnackbar('Time range must not be greater than 6 months.', { variant: 'warning' })
      }
    }
  }

  return (
    <DialogAdvanced
      title={'Export False Detection Submission'}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_EXPORT, false)}
      onClose={() => handleClose()}
      content={
        <Box sx={{ minHeight: 425, justifyContent: 'center', display: 'flex' }}>
          <DateRangePicker
            onChange={(item: any) => handleOnChange(item)}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state]}
            direction="horizontal"
          />
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isFetching}
            >
              Export
            </ButtonLoading>
          </Grid>
        </Grid>
      }
      dialogProps={{ maxWidth: 'md' }}
    />
  )
}
export default DialogFalseDetectionExport
