import _get from 'lodash/get'

import { CSSProperties, useCallback, useMemo } from 'react'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { TableAdvanced, TemplateSection, TypographyLineClamp, TypographyLinkClamp } from '@opswat/react-ui'

import { useLazyAdminSubOrganizationsQuery } from 'myopswat-admin/src/api/organization'
import { PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'
import useSearch from 'myopswat-admin/src/hooks/useSearch'
import { customerManagementOrganizationListPageURL, detailCustomerUserPath } from 'myopswat-admin/src/routes'

import SubOrganizationHeader from './searchFilter'

interface SubOrganizationProps {
  orgId: string
  orgData?: any
}

const SubOrganization = ({ orgId, orgData }: SubOrganizationProps) => {
  const { control, isFetching, results, pageInfo, totalCount, setValue, handleQuery, handlePagination, ...searchData } =
    useSearch<any>({
      searchCallback: useLazyAdminSubOrganizationsQuery,
      defaultValues: {
        pageInfo: {
          page: 0,
          pageSize: 10
        },
        filters: {
          q: ''
        },
        orgId
      }
    })

  const renderNameCell = useCallback((data: any) => {
    if (!data) return '--'

    return (
      <TypographyLinkClamp
        line={1}
        href={`${customerManagementOrganizationListPageURL}/${data.id}/${detailCustomerUserPath}`}
        typographyProps={{
          tooltipValue: data.name,
          tooltipPlacement: 'top',
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }
        }}
      >
        {data.name || '--'}
      </TypographyLinkClamp>
    )
  }, [])

  const renderValueCell = useCallback((value: any, sx?: CSSProperties) => {
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = _get(data, key)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE_TIME)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderUpdatedAt = useCallback((data: any) => {
    const latestEventLogAt = _get(data, 'latestEventLogAt')
    if (!latestEventLogAt) return renderDateCell(data, 'updatedAt')

    return renderDateCell(data, 'latestEventLogAt')
  }, [])

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: renderNameCell,
        style: { minWidth: '18vw', width: '18vw' }
      },
      {
        header: 'Opportunity ID',
        body: (data: any) => renderValueCell(_get(data, 'opportunityId')),
        style: { minWidth: '18vw', width: '18vw' }
      },
      {
        header: 'SSO ID',
        body: (data: any) => renderCell(data, 'ssoId'),
        style: { minWidth: '18vw', width: '18vw' }
      },
      {
        header: 'Last Updated By',
        body: (data: any) => renderCell(data, 'latestEventLogBy'),
        style: { minWidth: '15vw', width: '15vw' }
      },
      {
        header: 'Last Updated',
        body: renderUpdatedAt,
        style: { minWidth: 'calc(100% - calc(18vw * 3) - 15vw)', width: 'calc(100% - calc(18vw * 3) - 15vw)' }
      }
    ]
  }, [])

  const HeaderSection = useMemo(
    () => <SubOrganizationHeader control={control} setValue={setValue} handleQuery={handleQuery} orgData={orgData} />,
    [control, setValue, handleQuery]
  )

  const TableMemo = useMemo(
    () => (
      <TableAdvanced
        isPagination
        columns={columns}
        isLoading={isFetching}
        data={results}
        total={totalCount}
        page={pageInfo.page}
        pageSize={pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePagination}
        {...searchData}
      />
    ),
    [columns, searchData, isFetching, results, totalCount, pageInfo, handlePagination]
  )

  return orgData ? (
    <TemplateSection>
      {HeaderSection}
      {TableMemo}
    </TemplateSection>
  ) : (
    <></>
  )
}
export default SubOrganization
