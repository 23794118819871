/** ----------------- ROUTE -------------------- */
export const loginPageURL = '/login'
export const registerPageURL = '/register'
export const resetPasswordPageURL = '/reset-password'
export const changePasswordPageURL = '/change-password'
export const changePasswordSSOPageURL = '/changePassword'
export const confirmEmailPageURL = '/confirm-email'
export const resendEmailPageURL = '/resend-email'
export const verifyOTPPageURL = '/verify-otp'
export const activePageURL = '/active'
export const organizationAcceptInvitationURL = '/accepted-invite'
