import _debounce from 'lodash/debounce'

import { Grid, TextFieldSearch } from '@opswat/react-ui'
import { IAdminEvaluationRequestFilter } from 'myopswat-admin/src/api/evaluation-request/types'
import { useEffect, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface FilterSectionProps {
  filters: IAdminEvaluationRequestFilter
  onSearch: (searchData: IAdminEvaluationRequestFilter) => void
}

const BoxEvaluationRequestListFilter = ({ filters, onSearch }: FilterSectionProps) => {
  const { t: translate } = useTranslation()
  const {
    control,
    getValues,
    formState: { dirtyFields }
  } = useForm<IAdminEvaluationRequestFilter>({
    mode: 'onChange',
    defaultValues: filters
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields])

  const q = useWatch({
    control: control,
    name: 'q'
  })

  useEffect(() => {
    if (isChangedTextSearch) {
      const handleSearch = _debounce(() => {
        const searchData = {
          ...getValues(),
          q: q
        }

        onSearch(searchData)
      }, 300)

      handleSearch()
      return () => {
        handleSearch.cancel()
      }
    }
  }, [q, isChangedTextSearch])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextFieldSearch
              placeholder={translate('filterUserName/Email/Product') || ''}
              value={value}
              onChange={e => onChange(e.target.value)}
              onClearText={() => onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        {/* <Button variant="contained" color="primary">
          New User
        </Button> */}
      </Grid>
    </Grid>
  )
}

export default BoxEvaluationRequestListFilter
