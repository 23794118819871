import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _merge from 'lodash/merge'

import { useAdminReleaseCreateMutation } from 'myopswat-admin/src/api/product'
import { IAdminReleaseInput } from 'myopswat-admin/src/api/product/types'

import { yupResolver } from '@hookform/resolvers/yup'

import { formatDatetime } from '@opswat/react-core'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { convertDateToEpochTime } from 'myopswat-admin/src/utils/dateTime'
import { validateAssets } from 'myopswat-admin/src/utils/validator'
import { enqueueSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { adminReleaseCreateSchema } from '../SchemaRelease'

interface IProps {
  optimisticCreatingHandler: (data: IAdminReleaseInput) => void
  handleClose: () => void
}

const useAdminReleaseCreate = ({ optimisticCreatingHandler, handleClose }: IProps) => {
  // Logic: Dialog
  const { t: translate } = useTranslation()
  const { productId = '' } = useParams()

  const productDetailData = useTypedSelector(state => state?.api?.queries?.[`productDetail("${productId}")`]?.data)

  const defaultValues = useMemo<IAdminReleaseInput>(() => {
    const defaultDateStr = formatDatetime(Date.now(), 'yyyy-MM-dd')

    return {
      platformIds: [],
      assets: '',
      tags: [],
      semVer: '',
      version: '',
      versionSuffix: '',
      date: defaultDateStr,
      releaseDate: defaultDateStr,
      note: 'ADMIN CREATED',
      epochTime: convertDateToEpochTime(defaultDateStr)
    }
  }, [])

  // Logic: Form
  const [adminReleaseCreate, { isLoading }] = useAdminReleaseCreateMutation()

  const formRef = useForm<IAdminReleaseInput>({
    resolver: yupResolver(adminReleaseCreateSchema),
    defaultValues
  })

  const onSuccess = (data: IAdminReleaseInput) => {
    try {
      validateAssets(data.assets, data.platformIds)
    } catch (err) {
      formRef.setError('assets', { type: 'custom', message: (err as Error).message })
      return
    }

    const convertValues = _merge(data, { assets: JSON.parse(data.assets) })
    // Format epochTime
    convertValues['epochTime'] = Number(convertValues['epochTime'])
    const payload = _merge(convertValues, {
      productId: _get(productDetailData, 'adminProduct.id'),
      rmProductId: _get(productDetailData, 'adminProduct.rmId')
    })

    if (!_isEmpty(convertValues)) {
      adminReleaseCreate(payload)
        .unwrap()
        .then((data: any) => {
          if (data?.success) {
            enqueueSnackbar(translate('createReleaseProductSuccess'), {
              variant: 'success'
            })
            optimisticCreatingHandler({
              ...payload,
              id: _get(data, 'release.id', ''),
              semVer: _get(data, 'release.semVer', '')
            })
            handleClose()
          } else {
            enqueueSnackbar(translate('createReleaseProductFail'), {
              variant: 'error'
            })

            data?.errors.map((error: any) => {
              enqueueSnackbar(`${error?.message}`, { variant: 'warning' })
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(translate('createReleaseProductFail'), {
            variant: 'error'
          })
        })
    }
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return {
    formRef,
    onSuccess,
    onFail,
    isLoading,
    defaultValues
  }
}

export default useAdminReleaseCreate
