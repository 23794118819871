import { Box, Grid, Skeleton, TextGrid } from '@opswat/react-ui'

const FalseDetectionSubmissionDetailLoading = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <Box>
          <TextGrid
            label={'File'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Status'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Category'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Hash MD5'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Hash SHA1'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Hash SHA256'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Products'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Antivirus engines'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'AV detection/Threat name'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Image of Detection/Report Alert'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'The origin of the file'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'The purpose of the submitted file'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Why this is a false detection?'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Agree to share the files with AV vendors for further analysis'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Analysis Summary'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Submitted At'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Submitted By'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
          <TextGrid
            label={'Last Updated'}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
          />
        </Box>
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item xs="auto">
            <Skeleton animation="wave" variant="rectangular" width="30%" height="35px" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FalseDetectionSubmissionDetailLoading
