import _get from 'lodash/get'

import { useSnackbar } from 'notistack'
import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { DIALOGS } from '@myopswat/common'
import { Autocomplete, Box, ButtonLoading, DialogAdvanced, Grid, MenuItem, TextField } from '@opswat/react-ui'

import { useAdminFalseSubmissionUpdateMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { FalseSubmissionStatuses } from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'

interface IProps {
  reloadData: () => void
}

const DialogFalseDetectionSetStatus: FC<IProps> = ({ reloadData }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const { enqueueSnackbar } = useSnackbar()

  const {
    control,
    setValue,
    watch,
    formState: { dirtyFields }
  } = useForm({
    defaultValues: {
      cxStatus: ''
    }
  })

  useEffect(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_STATUS}_DATA`)) {
      const data = _get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_STATUS}_DATA`)
      const status = _get(data, 'cxStatus', '')
      setValue('cxStatus', status, { shouldDirty: false })
    }
  }, [dialogType])

  const handleClose = (isRefetch = false) => {
    if (isRefetch) {
      reloadData()
    }
    setValue('cxStatus', '')
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_SET_STATUS]: false,
        [`${DIALOGS.FALSE_DETECTION_SET_STATUS}_DATA`]: ''
      })
    )
  }

  const [submitFalseSubmissionUpdate, { isLoading }] = useAdminFalseSubmissionUpdateMutation()

  const statusOptions: any[] = useMemo(() => {
    return FalseSubmissionStatuses.map((item: any) => ({
      value: item.value,
      label: item.name,
      color: item.color,
      disabled: false
    }))
  }, [])

  const handleSubmit = async () => {
    if (watch('cxStatus')) {
      const selectedItem = watch('cxStatus')
      const falseSubmissionId = _get(_get(dialogType, `${DIALOGS.FALSE_DETECTION_SET_STATUS}_DATA`), 'id', '')
      const payload: any = { id: falseSubmissionId }
      payload['cxStatus'] = selectedItem
      submitFalseSubmissionUpdate(payload)
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            enqueueSnackbar(`Updated submission status successfully`, { variant: 'success' })
            handleClose(true)
          } else if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', `Updating submission status has failed`), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(`Updating submission status has failed`, { variant: 'error' })
        })
    } else {
      enqueueSnackbar('Please select an item', { variant: 'warning' })
    }
  }

  return (
    <DialogAdvanced
      title={`Set Status`}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_SET_STATUS, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <Controller
            name="cxStatus"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                options={statusOptions}
                value={statusOptions.find(item => _get(item, 'value') === value)}
                getOptionLabel={(option: any) => _get(option, 'label', '')}
                onChange={(_: any, newValue: any) => {
                  onChange(newValue.value)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    multiline
                    size="small"
                    variant="outlined"
                    placeholder="Select Status"
                    sx={{
                      minHeight: '0px',
                      '& .MuiOutlinedInput-input': {
                        fontWeight: '500',
                        textTransform: 'uppercase'
                      }
                    }}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <MenuItem
                      {...props}
                      disabled={option.disabled}
                      key={option.key}
                      value={option.value}
                      sx={{
                        whiteSpace: 'normal',
                        textTransform: 'uppercase',
                        color: option.color,
                        ...option.styles
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  )
                }}
              />
            )}
          />
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                disableElevation: true,
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true,
                disabled: !dirtyFields.cxStatus,
                disableElevation: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Submit
            </ButtonLoading>
          </Grid>
        </Grid>
      }
    />
  )
}
export default DialogFalseDetectionSetStatus
