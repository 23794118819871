import { useMemo } from 'react'

import _capitalize from 'lodash/capitalize'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _join from 'lodash/join'
import _map from 'lodash/map'

import {
  Chip,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider,
  TypographyLineClamp,
  TypographyLink
} from '@opswat/react-ui'
import { useLazyProductsQuery } from 'myopswat-admin/src/api/product'

import {
  detailSystemProductPath,
  editSystemProductPath,
  productIdReleaseListPath,
  systemManagementProductListPageURL
} from 'myopswat-admin/src/routes'
import { useNavigate } from 'react-router-dom'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'

import { useTypedSelector } from 'myopswat-admin/src/store'
import { PRODUCT_LICENSE_TYPE_FREE } from '../constants'
import BoxProductListFilter from './BoxProductListFilter'

const ProductListPage = () => {
  const navigate = useNavigate()
  const [getProducts, { data: productsData, isFetching: isFetchingProducts }] = useLazyProductsQuery()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditProductDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_product')
    }
    return false
  }, [profileData])
  const isViewReleaseDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('view_release')
    }
    return false
  }, [profileData])

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => (
          <>
            <TypographyLink
              color="primary"
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
              variant="body2"
              onClick={() => {
                navigate(`${systemManagementProductListPageURL}/${data?.id}/${detailSystemProductPath}`)
              }}
            >
              {_get(data, 'name', '--')}
            </TypographyLink>

            {data?.hide && (
              <>
                &nbsp;
                <Chip label="Hidden" color="error" variant="outlined" size="small" />
              </>
            )}
            {_includes(data?.licenseTypes, PRODUCT_LICENSE_TYPE_FREE) && (
              <>
                &nbsp;
                <Chip label="Free" color="default" size="small" />
              </>
            )}
          </>
        ),
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Tags',
        body: (data: any) => (
          <TypographyLineClamp line={2} variant="body2">
            {_join(
              _map(_get(data, 'tags', []), item => _capitalize(item)),
              ', '
            )}
          </TypographyLineClamp>
        ),
        style: { minWidth: 280, width: 280 }
      },
      {
        header: 'Solutions',
        body: (data: any) => (
          <Typography variant="body2">
            {_join(
              _map(_get(data, 'solutions', []), item => item?.name),
              ', '
            )}
          </Typography>
        ),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Updated At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data?.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Created At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data?.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`${systemManagementProductListPageURL}/${data?.id}/${editSystemProductPath}`)
                    }}
                    disabled={isEditProductDisabled}
                  >
                    Edit Detail
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`${systemManagementProductListPageURL}/${data?.id}/${productIdReleaseListPath}`)
                    }}
                    disabled={isViewReleaseDisabled}
                  >
                    Release List
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <TypographyDivider label="Products" />

      <BoxProductListFilter getProducts={getProducts} />

      <TableAdvanced columns={columnArray} isLoading={isFetchingProducts} data={productsData} />
    </TemplateSection>
  )
}

export default ProductListPage
