import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { TextField, Tooltip, useTheme } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  disabled: boolean
  placeholder?: string
  tooltip?: string
  endAdornment?: React.ReactNode
}

const ComposeTextField: FC<IProps> = ({ tooltip, endAdornment, ...rest }) => {
  const theme = useTheme()

  return (
    <Tooltip
      arrow
      placement="right"
      title={tooltip}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            whiteSpace: 'pre-line',
            minWidth: '200px'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <TextField
        {...rest}
        fullWidth
        size="small"
        sx={{
          minHeight: 'auto',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.text.secondary
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.disabled
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CB0704 !important'
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            paddingRight: 0
          }
        }}
        InputProps={{
          endAdornment
        }}
      />
    </Tooltip>
  )
}

export default ComposeTextField
