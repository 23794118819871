import Quill from 'quill'
import { FC } from 'react'
import ReactDOMServer from 'react-dom/server'

import _map from 'lodash/map'

import { Box, IconButton, useTheme } from '@opswat/react-ui'

interface IProps {
  className: string
  value?: string
  Icon?: FC<any>
  options?: any[]
  onClick?: () => void
}

const ToolbarButton: FC<IProps> = ({ className, value, Icon, options, onClick }) => {
  const theme = useTheme()
  const quillIcons: any = Quill.import('ui/icons')
  const buttonLabel = className.replace('ql-', '')

  if (options && Icon) {
    quillIcons[buttonLabel] = ReactDOMServer.renderToString(<Icon />)
  } else {
    Icon && delete quillIcons[buttonLabel]
  }

  return options ? (
    <Box
      sx={{
        display: 'inline',
        '& .ql-color-picker': {
          width: '30px !important',
          height: '30px !important'
        },
        '&& .ql-picker-label': {
          padding: '0px !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          width: '30px',
          height: '30px',
          marginTop: '2px',
          '> svg': {
            fill: '#757575'
          },
          '> svg:hover': {
            fill: theme.palette.primary.main
          }
        },
        '&& .ql-picker.ql-expanded .ql-picker-options': {
          marginTop: '10px !important'
        }
      }}
    >
      <select className={className}>
        {_map(options, option => (
          <option value={option}></option>
        ))}
      </select>
    </Box>
  ) : (
    <IconButton
      value={value}
      className={className}
      sx={{
        display: 'inline-flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        cursor: 'pointer !important',
        width: '30px !important',
        height: '30px !important',
        padding: '0px !important',
        '> svg:hover': {
          fill: theme.palette.primary.main
        }
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          width: '16px',
          height: '16px'
        }}
      >
        {Icon && <Icon color="#757575" />}
      </Box>
    </IconButton>
  )
}

export default ToolbarButton
