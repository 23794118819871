import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, ButtonLoading, Grid } from '@opswat/react-ui'
import { useLazyProfileQuery } from 'myopswat-admin/src/api/account'
import { useAdminRoleUpdateMutation } from 'myopswat-admin/src/api/role'
import { OPSWAT_ORGANIZATION_ID } from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementOrganizationListPageURL,
  detailOrganizationRolePath,
  detailUserRolePath,
  opswatRoleListPageURL,
  organizationRolesPath
} from 'myopswat-admin/src/routes'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import RoleForm from './RoleForm/RoleForm'
import { IRoleForm, roleSchema } from './RoleForm/RoleSchema'

interface IProps {
  roleData: any
  isFetchingPermissionData: boolean
  handleDataUpdate: any
  contentTypePermissions: any
  permissionCodenames: any
  rolePermissions: string[]
}

const RoleEditView = ({
  roleData,
  isFetchingPermissionData,
  handleDataUpdate,
  contentTypePermissions,
  permissionCodenames,
  rolePermissions
}: IProps) => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { orgId: organizationId = '', roleId = '' } = useParams()
  const orgId = isOpswatIncRoute ? OPSWAT_ORGANIZATION_ID : organizationId

  const [adminRoleUpdate, { isLoading }] = useAdminRoleUpdateMutation()
  const [getProfile] = useLazyProfileQuery()

  const formRef = useForm<IRoleForm>({
    defaultValues: roleData,
    mode: 'onSubmit',
    resolver: yupResolver(roleSchema)
  })

  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
  useEffect(() => {
    if (rolePermissions) {
      setSelectedPermissions(rolePermissions)
    }
  }, [rolePermissions])

  const onSubmit = async (data: IRoleForm) => {
    try {
      const updatingData: any = {
        id: roleData.id
      }
      Object.keys(dirtyFields).forEach(field => {
        updatingData[field] = data[field as keyof IRoleForm]
      })
      updatingData['permissionCodenames'] = selectedPermissions

      const res = await adminRoleUpdate(updatingData).unwrap()
      if (res.success) {
        enqueueSnackbar('The role information has been updated successfully.', { variant: 'success' })
        handleDataUpdate(res.role)

        const detailRolePageURL = isOpswatIncRoute
          ? `${opswatRoleListPageURL}/${roleId}/${detailUserRolePath}`
          : `${customerManagementOrganizationListPageURL}/${orgId}/${organizationRolesPath}/${roleId}/${detailOrganizationRolePath}`
        navigate(detailRolePageURL)
        getProfile()
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <RoleForm
            formRef={formRef}
            permissionCodenames={permissionCodenames}
            contentTypePermissions={contentTypePermissions}
            isFetchingPermissionData={isFetchingPermissionData}
            selectedPermissions={selectedPermissions}
            setSelectedPermissions={setSelectedPermissions}
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="inherit"
              disabled={isLoading}
              onClick={() => {
                const detailRolePageURL = isOpswatIncRoute
                  ? `${opswatRoleListPageURL}/${roleId}/${detailUserRolePath}`
                  : `${customerManagementOrganizationListPageURL}/${roleData.organization.id}/${organizationRolesPath}/${roleData.id}/${detailOrganizationRolePath}`

                navigate(detailRolePageURL)
              }}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
                disabled: isLoading
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default RoleEditView
