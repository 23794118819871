import RoleObjectsList from '../../../../pages/CustomerManagement/RoleObjects/RoleObjectsListPage'
import {
  customerManagementRoleObjectsEventLogsListPageURL,
  customerManagementRoleObjectsListPageURL,
} from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'
import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'
import RoleObjectsEvenLogsPage from '../../../../pages/CustomerManagement/RoleObjects/RoleObjectsEventLogsPage'
import useCheckPermissions from '../../../../hooks/useCheckPermissions'

const RoleObjectsListContainer = () => {
  const { permission } = useCheckPermissions({
    targetPerrmissions: [
      'view_role_object',
      'add_role_object',
      'change_role_object',
      'delete_role_object'
    ]
  })
  const tabArray = [
    {
      label: 'Role Objects',
      content: <RoleObjectsList permissions={permission}/>,
      tabPath: customerManagementRoleObjectsListPageURL
    },
    {
      label: 'Event Logs',
      content: <RoleObjectsEvenLogsPage />,
      tabPath: customerManagementRoleObjectsEventLogsListPageURL
    }
  ]
  return (
    <>
      <TypographyDivider isDivider={false} label="Role Objects" />
      <TabAdvanced borderBottom="1px solid #E9EAEB" tabs={tabArray} actions={<></>} />
    </>
  )
}

export default RoleObjectsListContainer
