import { escape as htmlEscape } from 'html-escaper'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'

import { utcToZonedTime } from 'date-fns-tz'
import _add from 'date-fns/add'
import _roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import _get from 'lodash/get'
import _union from 'lodash/union'

import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  useAdminMassNotifyCreateMutation,
  useAdminMassNotifyDeleteMutation,
  useAdminMassNotifyUpdateMutation,
  useLazyAdminMassNotifyCheckEnableGmailQuery,
  useLazyAdminMassNotifyGetRecipientsQuery,
  useLazyAdminMassNotifyItemQuery
} from 'myopswat-admin/src/api/massEmail'
import { IMassNotificationInputType } from 'myopswat-admin/src/api/massEmail/types'
import { useLazyBasicActivationServerProductsQuery } from 'myopswat-admin/src/api/product'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import { notificationManagementMassNotificationPageURL } from 'myopswat-admin/src/routes'

import ComposeEmailPage from '.'
import { ComposeEmailConfirm } from './components'
import { ComposeEmailContext, SPECIAL_PRODUCT_OPTIONS } from './interface'
import {
  MAX_EMAIL_DISPLAY_LIMIT,
  MAX_EMAIL_TOOLTIP_LIMIT,
  UTC_TIMEZONE_CODE,
  handleMapEmailList,
  handleMapRequest,
  handleMapResponse
} from './utils'

const ComposeEmailProvider: React.FC<any> = () => {
  const dialog = useDialog()
  const navigate = useNavigate()

  const { emailId } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [searchParams, setSearchParams] = useSearchParams()

  const viewMode = emailId ? searchParams.get('mode') ?? 'view' : 'create'

  const defaultValues = useMemo(
    () => ({
      title: '',
      recipientEmails: '',
      products: [],
      content: '<div style="padding-left: 30px; padding-right: 30px;">\n\nYour content here\n\n</div>',
      sendingPurpose: '',
      sendType: 'SCHEDULED',
      scheduleTime: _roundToNearestMinutes(utcToZonedTime(_add(new Date(), { days: 1 }), UTC_TIMEZONE_CODE), {
        nearestTo: 15,
        roundingMethod: 'ceil'
      }),
      relatedProductsRecipientEmails: [],
      productRecipientsText: '',
      productRecipientsTooltip: '',
      isCriticalAlert: false,
      recipients: ''
    }),
    []
  )

  const [createEmail, { isLoading: isCreating }] = useAdminMassNotifyCreateMutation()
  const [updateEmail, { isLoading: isUpdating }] = useAdminMassNotifyUpdateMutation()
  const [deleteEmail, { isLoading: isDeleting }] = useAdminMassNotifyDeleteMutation()
  const [loadEmail, { isLoading: isLoadingEmail }] = useLazyAdminMassNotifyItemQuery()
  const [loadProducts, { isLoading: isLoadingProducts }] = useLazyBasicActivationServerProductsQuery()
  const [loadRecipients, { isFetching: isLoadingRecipients }] = useLazyAdminMassNotifyGetRecipientsQuery()
  const [checkEnableGmail] = useLazyAdminMassNotifyCheckEnableGmailQuery()

  const [productOptions, setProductOptions] = useState<any[]>([])
  const [defaultAllowGmail, setDefaultAllowGmail] = useState<boolean>(false)

  const { control, watch, setValue, reset, trigger, handleSubmit } = useForm<any>({
    defaultValues
  })

  const handleResetForm = () => {
    reset()
  }

  const handleToggleEdit = () => {
    setSearchParams({ mode: 'edit' })
  }

  const handleMapProductOptions = (options: any[], products: any[]) => {
    return options.filter((item: any) => products.some(product => item.value === product.id))
  }

  const handleLoadProducts = useCallback(async (products?: any[]) => {
    await loadProducts()
      .unwrap()
      .then(response => {
        const options = response.map((item: any) => ({ label: item.name, value: item.id }))
        const newOptions = [...SPECIAL_PRODUCT_OPTIONS, ...options]
        setProductOptions(newOptions)
        products && setValue('products', handleMapProductOptions(newOptions, products))
      })
      .catch(error => {
        console.log('log:  error', error)
      })
  }, [])

  const handleLoadRecipients = useCallback(
    async (products: any[], isCriticalAlert: boolean) => {
      await loadRecipients({
        productIds: products.map((item: any) => item.value),
        isCriticalAlert
      })
        .unwrap()
        .then(response => {
          const emails: string[] = _get(response, 'recipientEmails', []).filter(
            (item: string) => defaultAllowGmail || !item.endsWith('gmail.com')
          )
          emails.sort((a: string, b: string) => a.localeCompare(b))
          setValue('relatedProductsRecipientEmails', emails)
          setValue('productRecipientsText', handleMapEmailList(emails, MAX_EMAIL_DISPLAY_LIMIT, '; '))
          setValue('productRecipientsTooltip', handleMapEmailList(emails, MAX_EMAIL_TOOLTIP_LIMIT, '\n'))
        })
        .catch(error => {
          console.log('log:  error', error)
        })
    },
    [defaultAllowGmail]
  )

  const handleSetIsCriticalAlert = useCallback((value: boolean) => {
    setValue('isCriticalAlert', value)
  }, [])

  const handleLoadEmail = useCallback(async (id: string) => {
    await loadEmail(id)
      .unwrap()
      .then((response: any) => {
        const data = handleMapResponse(response)
        response &&
          Object.entries(data).forEach(([key, value]) => {
            value && setValue(key, value)
          })
        if (productOptions.length === 0) {
          handleLoadProducts(data.products ?? [])
        }
      })
      .catch(error => {
        console.log('log:  error', error)
      })
  }, [])

  const handleSubmitData = useCallback(async (data: IMassNotificationInputType, isDraft: boolean) => {
    const request = handleMapRequest({ ...data, isDraft, content: htmlEscape(data.content ?? '') })
    let confirmed: any = false
    if (isDraft) {
      confirmed = true
    } else {
      const emailList = _union(request.relatedProductsRecipientEmails, request.recipientEmails)
      confirmed = await dialog.openConfirmation(
        {
          maxWidth: 'xl',
          title: 'Mass Mailing Confirmation',
          content: '',
          cancelText: 'Cancel and recheck',
          confirmText: 'Yes, I confirm',
          disableBackdropClick: true,
          countdown: 15
        },
        () => <ComposeEmailConfirm emailList={emailList} emailContent={data.content} />
      )
    }
    if (confirmed) {
      await (data.id ? updateEmail(request) : createEmail(request))
        .unwrap()
        .then(async response => {
          if (response?.success) {
            const successMessage = isDraft ? 'Your draft has been saved' : 'Email sent successfully'
            enqueueSnackbar(successMessage, {
              variant: 'success'
            })
            navigate(notificationManagementMassNotificationPageURL)
          } else {
            const errorCode = _get(response, ['errors', 0, 'code'])
            if (errorCode === 'not_allow_send_email_notify_to_gmail') {
              const errorMessage = _get(response, ['errors', 0, 'message'])
              enqueueSnackbar(`Error! Email list contains Gmail addresses: ${errorMessage.split(':')[1]}`, {
                variant: 'error'
              })
            } else if (errorCode === 'exceed_allowed_update_email_time') {
              enqueueSnackbar('Unable to update the email because it is in the process of being sent', {
                variant: 'error'
              })
            } else {
              enqueueSnackbar(
                `${
                  data.id ? 'Updating' : 'Creating'
                } email notification has failed. Please give the system a moment then try again.`,
                {
                  variant: 'error'
                }
              )
            }
          }
        })
        .catch(error => {
          console.log('log:  error', error)
          enqueueSnackbar('Saving email notification has failed. Please give the system a moment then try again.', {
            variant: 'error'
          })
        })
    }
  }, [])

  const handleSubmitDraft = useCallback(async (data: IMassNotificationInputType) => {
    await handleSubmitData(data, true)
  }, [])

  const handleSubmitSend = useCallback(async (data: IMassNotificationInputType) => {
    await checkEnableGmail({}).then(async (response: any) => {
      const allowGmail = _get(response, ['data', 'isAllowed'])
      if (allowGmail) {
        const confirmed = await dialog.openConfirmation({
          title: 'Sending Confirmation',
          content:
            'Your recipient list includes Gmail addresses, which may increase the likelihood of your emails being marked as spam. Please confirm if you wish to proceed with sending the emails to this list.',
          cancelText: 'Cancel and recheck',
          confirmText: 'Yes, I confirm',
          disableBackdropClick: true
        })
        if (confirmed) {
          await handleSubmitData(data, false)
        }
      } else {
        await handleSubmitData(data, false)
      }
    })
  }, [])

  const handleDelete = useCallback(async () => {
    const confirmed = await dialog.openConfirmation({
      content: 'Are you sure you want to delete this email notification?'
    })
    if (confirmed && emailId) {
      await deleteEmail(emailId)
        .unwrap()
        .then(async data => {
          if (data?.success) {
            await enqueueSnackbar('Deleted email notification successfully', {
              variant: 'success'
            })
            navigate(notificationManagementMassNotificationPageURL)
          } else {
            await enqueueSnackbar(
              'Deleting email notification has failed. Please give the system a moment then try again.',
              {
                variant: 'error'
              }
            )
          }
        })
        .catch(error => {
          console.log('log: error', error)
        })
    }
  }, [])

  const handleGetRecipients = useCallback(async () => {
    const status = watch('deliveryStatus')?.toUpperCase()
    if (!status || ['DRAFT', 'PENDING'].includes(status)) {
      const productList = watch('products')
      const filteredList = watch('products').filter((item: any) => item.value)
      // prevent lists with null values
      if (filteredList.length > 0 || productList.length === filteredList.length) {
        if (productList.length > 0) handleLoadRecipients(productList, watch('isCriticalAlert'))
        else {
          setValue('relatedProductsRecipientEmails', [])
          setValue('productRecipientsText', '')
          setValue('productRecipientsTooltip', '')
        }
      }
    }
  }, [watch('deliveryStatus'), watch('products'), watch('isCriticalAlert')])

  useEffect(() => {
    if (emailId) {
      handleLoadEmail(emailId)
    } else {
      handleLoadProducts()
    }
  }, [emailId])

  useEffect(() => {
    handleGetRecipients()
  }, [watch('products'), watch('isCriticalAlert')])

  useEffect(() => {
    checkEnableGmail({}).then(response => {
      setDefaultAllowGmail(_get(response, ['data', 'isAllowed']))
    })
  }, [])

  return (
    <ComposeEmailContext.Provider
      value={{
        viewMode,
        control,
        productOptions,
        trigger,
        handleSubmit,
        handleToggleEdit,
        handleSubmitDraft,
        handleSubmitSend,
        handleDelete,
        handleSetIsCriticalAlert,
        handleResetForm,
        setValue,
        isLoadingEmail,
        isLoadingProducts,
        isLoadingRecipients,
        isCreating,
        isUpdating,
        isDeleting
      }}
    >
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {viewMode === 'create' ? 'Compose Email' : 'Email Detail'}
        </title>
      </Helmet>
      <ComposeEmailPage />
    </ComposeEmailContext.Provider>
  )
}
export default ComposeEmailProvider
