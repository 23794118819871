import React from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'

import { ReleaseInformationType } from 'myopswat-admin/src/api/product/types'
import { IReleaseInformationQuery } from 'myopswat-admin/src/api/releaseInformation/types'
import { QueryPagination, QuerySortInfo } from 'myopswat-admin/src/api/types'

export type ReleaseInformationContextProps = {
  columns: any[]
  totalCount: number
  results: ReleaseInformationType[]
  control: Control<IReleaseInformationQuery>
  setValue: UseFormSetValue<IReleaseInformationQuery>
  pageInfo: QueryPagination
  sortInfo: QuerySortInfo
  isFetching: boolean
  handleReset: () => void
  handleQuery: (name?: string, value?: any) => void
  handleSearch: () => void
  handleSort: (orderBy?: string, order?: string) => void
  handlePagination: (pageNumber: number, pageSize: number) => void
}

export const ReleaseInformationContext = React.createContext<ReleaseInformationContextProps>({
  results: [],
  columns: [],
  totalCount: 0,
  pageInfo: {} as QueryPagination,
  sortInfo: {} as QuerySortInfo,
  control: {} as Control<IReleaseInformationQuery>,
  setValue: {} as UseFormSetValue<IReleaseInformationQuery>,
  isFetching: false,
  handleReset: () => {
    return
  },
  handleQuery: () => {
    return
  },
  handleSearch: () => {
    return
  },
  handleSort: () => {
    return
  },
  handlePagination: () => {
    return
  }
})
