import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, ButtonLoading, DialogAdvanced, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import yup from 'myopswat-admin/src/utils/yup'
import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DIALOGS_ADMIN } from '../../../components/Dialog/dialogVars'
import { selectDialogs, toggleDialogs } from '../../../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from '../../../store'

import { enqueueSnackbar } from 'notistack'
import { useAdminFalseSubmissionUpdateMutation } from '../../../api/analyzer/falseSubmission'
import { FALSE_DETECTION_SUPPORT_TICKET_TYPE } from '../constants'

export interface AdminFalseDetectionLinkTicketForm {
  ticketType: any
  ticketId: string
  caseNumber?: string
  caseId?: string
}

export const addSupportTicketSchema = yup.object().shape({
  ticketType: yup.string(),
  caseNumber: yup.string().when(
    'ticketType', {
      is: (val: any) => {
        return val === FALSE_DETECTION_SUPPORT_TICKET_TYPE[1].code
      },
      then: schema => schema.matches(
        /^[A-Za-z0-9]+$/, 'The case number cannot contain special characters'
      ).trim().required('The case number cannot be blank'),
      otherwise: schema => schema.nullable(),
    }).max(50, 'Please enter maximum 50 characters.'),
  ticketId: yup.string().when(
    'ticketType', {
    is: (val: any) => {
      return val === FALSE_DETECTION_SUPPORT_TICKET_TYPE[0].code
    },
    then: schema => schema.matches(
      /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/
    , 'The ticket number has an incorrect structure, please follow the hint'
    ).trim().required('The ticket ID cannot be blank'),
    otherwise: schema => schema.nullable(),
  }).max(50, 'Please enter maximum 50 characters.'),
  caseId: yup.string().when(
    'ticketType', {
      is: (val: any) => {
        return val === FALSE_DETECTION_SUPPORT_TICKET_TYPE[1].code
      },
      then: schema => schema.matches(
        /^[A-Za-z0-9]+$/, 'The case ID cannot contain special characters'
      ).trim().required('The case ID cannot be blank'),
      otherwise: schema => schema.nullable(),
    }).max(50, 'Please enter maximum 50 characters.'),
})

interface IProps {
  handleCloseSetSupportLink: (isRefetch: boolean) => void
  falseSubmission: any
}

const DialogFalseDetectionLinkCase: FC<IProps> = ({ handleCloseSetSupportLink, falseSubmission }) => {
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const [submitFalseSubmissionUpdate, { isLoading }] = useAdminFalseSubmissionUpdateMutation()

  const initialValues = useMemo(() => ({
    ticketType: null,
    ticketId: _get(falseSubmission, 'keyIssueJira', ''),
    caseNumber: _get(falseSubmission, 'caseNumber', ''),
    caseId: _get(falseSubmission, 'caseId', ''),
  }), [falseSubmission]);

  const formRef = useForm<AdminFalseDetectionLinkTicketForm>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(addSupportTicketSchema)
  })
  useEffect(() => {
    Object.entries(initialValues).forEach(([key, value]: any) => {
      setValue(key, value, { shouldDirty: false })
    })
  }, [initialValues])

  const {
    formState,
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = formRef
  const watchedFields: any = getValues()
  const isSubmitDisabled = useMemo(() => {
    const requiredCaseNumber = watchedFields.ticketType === FALSE_DETECTION_SUPPORT_TICKET_TYPE[1].code;
    if (requiredCaseNumber) {
      return !formState.isDirty || !formState.dirtyFields.ticketType || watchedFields.caseNumber == null || watchedFields?.caseNumber?.trim() == '' || watchedFields.caseId == null || watchedFields.caseId?.trim() == ''
    }
    return !formState.isDirty || !formState.dirtyFields.ticketType || watchedFields.ticketId == null || watchedFields.ticketId?.trim() === ''
  }, [watchedFields])

  useEffect(() => {
    Object.entries(initialValues).forEach(([key, value]: any) => {
      if (key != 'ticketType') {
        setValue(key, value, { shouldDirty: false })
      }
    })
  }, [watchedFields.ticketType])


  const onSubmit = async (data: any) => {
    const submissionId = falseSubmission?.id
    if (submissionId) {
      data.ticketId = watchedFields.ticketType === FALSE_DETECTION_SUPPORT_TICKET_TYPE[1].code ? data.caseId : data.ticketId;
      if (watchedFields.ticketType === FALSE_DETECTION_SUPPORT_TICKET_TYPE[0].code && 'caseNumber' in data) {
        delete data.caseNumber;
      }
      delete data.caseId;
      submitFalseSubmissionUpdate(
        {
          id: submissionId,
          ...data
        }
      )
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            enqueueSnackbar(`Link ticket support successfully`, { variant: 'success' })
            handleClose(true)
          } else if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', `Link ticket support failed`), {
              variant: 'error'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(`Link ticket support failed`, { variant: 'error' })
        })
    }
  };

  const onFail = (e: any) => {
    console.error(e)
  }


  const handleClose = (isRefetch = false) => {
    handleCloseSetSupportLink(isRefetch)
    reset({
      ticketType: null,
      ticketId: '',
      caseNumber: '',
      caseId: ''
    })
    dispatch(
      toggleDialogs({
          [DIALOGS_ADMIN.FALSE_DETECTION_LINK_TICKET]: false,
        }
      )
    )
  }

  return (
    <DialogAdvanced
      title={`Link Support case/Jira ticket`}
      open={_get(dialogType, DIALOGS_ADMIN.FALSE_DETECTION_LINK_TICKET, false)}
      onClose={() => handleClose()}
      content={
        <>
          <Grid item xs="auto">
            <Typography variant="subtitle1">Support case/Jira*</Typography>
            <Controller
              name={`ticketType`}
              control={control}
              render={({ field }) => (
                <Autocomplete
                  disableClearable
                  options={FALSE_DETECTION_SUPPORT_TICKET_TYPE}
                  getOptionLabel={(option: any) => option.name || ''}
                  value={
                    FALSE_DETECTION_SUPPORT_TICKET_TYPE.find((item) => item.code === field.value)
                    || { id: 0, name: '', code: '' }
                  }
                  onChange={(_, newValue) => {
                    if (field.value?.code != newValue?.code) {
                      clearErrors()
                    }
                    field.onChange(newValue?.code);
                  }}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.code === value.code;
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      error={!!errors?.ticketType}
                      helperText={errors?.ticketType?.message}
                      sx={{fontWeight: 500}}
                    />
                  )}
                />
              )}
            />
          </Grid>
          {watchedFields.ticketType && (
            <>
              {watchedFields.ticketType === FALSE_DETECTION_SUPPORT_TICKET_TYPE[0].code  ? (
                <Grid item xs="auto">
                  <Typography variant="subtitle1">Ticket Number*</Typography>
                  <Controller
                    name="ticketId"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        placeholder={`Sample: FDS2-222`}
                        fullWidth
                        size="small"
                        value={field.value || ''}
                        onChange={(e: any) => field.onChange(e.target.value)}
                        error={!!_get(errors, 'ticketId', '')}
                        helperText={_get(errors, 'ticketId.message', '')}
                        sx={{fontWeight: 500}}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs="auto">
                    <Typography variant="subtitle1">Case Number*</Typography>
                    <Controller
                      name="caseNumber"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          placeholder={`Sample: 00118364`}
                          fullWidth
                          size="small"
                          value={field.value || ''}
                          onChange={(e: any) =>field.onChange(e.target.value)}
                          error={!!_get(errors, 'caseNumber', '')}
                          helperText={_get(errors, 'caseNumber.message', '')}
                          sx={{fontWeight: 500}}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Typography variant="subtitle1">Case Id*</Typography>
                    <Controller
                      name="caseId"
                      control={control}
                      render={({field}) => (
                        <TextField
                          placeholder={`Sample: 500UQ00000MMM0RYAX`}
                          fullWidth
                          size="small"
                          value={field.value || ''}
                          onChange={(e: any) => field.onChange(e.target.value)}
                          error={!!_get(errors, 'caseId', '')}
                          helperText={_get(errors, 'caseId.message', '')}
                          sx={{fontWeight: 500}}
                        />
                      )}
                    />

                  </Grid>
                </>
              )}
            </>
          )}
        </>
      }
      actions={
      <>
        <Grid>
          <ButtonLoading
            propsButton={{
              variant: 'text',
              color: 'inherit',
              onClick: () => handleClose()
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={false}
          >
            Cancel
          </ButtonLoading>
        </Grid>
        <Grid>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              onClick: handleSubmit(onSubmit, onFail),
              fullWidth: true,
              disabled: isSubmitDisabled
            }}
            propsLoading={{ color: 'inherit' }}
            isLoading={isLoading}
          >
            Submit
          </ButtonLoading>
        </Grid>
      </>}
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}


export default DialogFalseDetectionLinkCase
