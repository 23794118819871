import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { IAntivirusEngineForm } from './FalseSubmissionAntivirusEngineSchema'
import { ButtonLoading, Grid, TemplateSection } from '@opswat/react-ui'
import FalseSubmissionAntivirusEngineForm, { antivirusEngineSchema } from './Form/FalseSubmissionAntivirusEngineForm'
import {
  dashboardFalseDetectionSubmissionAntivirusEngineListURL,
  falseDetectionDetailPath
} from 'myopswat-admin/src/routes'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSnackbar } from 'notistack'
import { useAdminFalseSubmissionAntivirusEngineUpdateMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'

interface IProps {
  engine: any
  sysItemCountriesData: any
}

const FalseSubmissionAntivirusEngineDetailEdit = ({ engine, sysItemCountriesData }: IProps) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [updateAntivirusEngine, { isLoading }] = useAdminFalseSubmissionAntivirusEngineUpdateMutation()

  const initValues = useMemo(() => {
    return {
      engineName: engine.engineName,
      vendorName: engine.vendorName,
      country: engine.country,
      detection: engine.detection,
      heuristicDetection: engine.heuristicDetection,
      isActive: engine.isActive
    }
  }, [engine])

  const formRef = useForm<IAntivirusEngineForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(antivirusEngineSchema)
  })
  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef
  const onSubmit = async (data: IAntivirusEngineForm) => {
    try {
      const dataUpdated: any = {
        id: engine.id
      }
      Object.keys(dirtyFields).forEach(field => {
        dataUpdated[field] = data[field as keyof IAntivirusEngineForm]
      })
      const res = await updateAntivirusEngine(dataUpdated).unwrap()
      if (res.success) {
        enqueueSnackbar('Update antivirus engine successfully.', { variant: 'success' })
        navigate(dashboardFalseDetectionSubmissionAntivirusEngineListURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  return (
    <TemplateSection spacing={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <FalseSubmissionAntivirusEngineForm formRef={formRef} sysItemCountriesData={sysItemCountriesData} />
          <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'inherit',
                  onClick: () => {
                    navigate(
                      `${dashboardFalseDetectionSubmissionAntivirusEngineListURL}/${engine?.id}/${falseDetectionDetailPath}`
                    )
                  },
                  disabled: isLoading
                }}
              >
                Cancel
              </ButtonLoading>
            </Grid>
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSubmit(onSubmit, onError),
                  disabled: isLoading
                }}
                isLoading={isLoading}
              >
                Save
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default FalseSubmissionAntivirusEngineDetailEdit
