import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { useContext } from 'react'

import { Box, ButtonLoading, Grid } from '@opswat/react-ui'

import { ReleaseInfoContext } from '../interface'

const CreateReleaseInfoActions = () => {
  const { isFormValid, isCreating, isChecking, errors, handleSubmit, handleSave, handleReset } =
    useContext(ReleaseInfoContext)

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        borderTop: '1px solid #e7e9ee',
        backgroundColor: '#ffffff',
        position: 'sticky',
        bottom: 0,
        paddingY: '16px !important',
        paddingRight: '16px'
      }}
    >
      <ButtonLoading
        propsButton={{
          variant: 'text',
          color: 'inherit',
          disableElevation: true,
          onClick: handleReset
        }}
        propsLoading={{ color: 'inherit', fillColor: '#e0e0e0' }}
      >
        Cancel
      </ButtonLoading>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            disableElevation: true,
            onClick: handleSubmit(handleSave),
            disabled: !isFormValid || isCreating || !_isEmpty(_get(errors, 'version', ''))
          }}
          isLoading={isCreating || isChecking}
          propsLoading={{ color: 'inherit' }}
        >
          Save
        </ButtonLoading>
      </Box>
    </Grid>
  )
}

export default CreateReleaseInfoActions
