import { yupResolver } from '@hookform/resolvers/yup'
import { DATE_FORMATS, DIALOGS, handleDownloadFile } from '@myopswat/common'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import {
  Autocomplete,
  Box,
  ButtonLoading, Chip,
  Grid, OpswatCheckbox,
  TextField,
  TextFieldArea, TextGrid, Tooltip,
  Typography
} from '@opswat/react-ui'
import _get from 'lodash/get'
import { enqueueSnackbar } from 'notistack'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  useAdminDownloadFalseSubmissionMutation,
  useAdminFalseSubmissionUpdateMutation
} from '../../../../api/analyzer/falseSubmission'
import {
  FalseSubmissionFileOrigins,
  FalseSubmissionStatuses, IFalseSubmissionUpdateInput
} from '../../../../api/analyzer/falseSubmission/types'
import useDialog from '../../../../components/Dialog/DialogHook'
import { toggleDialogs } from '../../../../containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from '../../../../store'
import yup from '../../../../utils/yup'
import DialogFalseDetectionDownloadInfo from '../../FalseDetectionSubmissionPage/DialogFalseDetectionDownloadInfo'
import _isEmpty from 'lodash/isEmpty'

export const FalseDetectionSubmissionSchema = yup.object().shape({
  id: yup.string(),
  cxStatus: yup.string(),
  analystNote: yup.string().nullable().max(1000, 'Note is limited to 1000 characters')
})

interface IProps {
  falseDetectionSubmission: any
  handleCancel?: () => void
  handleAfterSave?: () => void

}

const FalseDetectionSubmissionEdit = ({ falseDetectionSubmission, handleCancel, handleAfterSave }: IProps) => {
  const dialog = useDialog()
  const dispatch = useAppDispatch()

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [adminDownloadFalseSubmission] = useAdminDownloadFalseSubmissionMutation()
  const [submitFalseSubmissionUpdate, { isLoading }] = useAdminFalseSubmissionUpdateMutation()
  const initialValues = useMemo(() => {
    return {
      id: falseDetectionSubmission.id,
      cxStatus: falseDetectionSubmission.cxStatus,
      analystNote: falseDetectionSubmission.analystNote,
    }
  }, [falseDetectionSubmission])

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields }
  } = useForm<IFalseSubmissionUpdateInput>({
    resolver: yupResolver(FalseDetectionSubmissionSchema),
    defaultValues: initialValues
  })

  const onSubmit = async (data: any) => {
    const updatedData: any = {
      id: data.id,
    }
    Object.keys(dirtyFields).forEach(field => {
      const k = field as keyof IFalseSubmissionUpdateInput
      updatedData[k] = data[k]
    })
    submitFalseSubmissionUpdate(updatedData)
      .unwrap()
      .then(async (response: any) => {
        if (response.success) {
          enqueueSnackbar(`Update submission successfully`, { variant: 'success' })
          handleAfterSave?.()
        } else if (_get(response, 'errors.0.message')) {
          enqueueSnackbar(_get(response, 'errors.0.message', `Update submission failed`), {
            variant: 'error'
          })
        }
      })
      .catch(() => {
        enqueueSnackbar(`Update submission failed`, { variant: 'error' })
      })
  };
  const onFail = (e: any) => {
    console.error(e)
  }

  const handleDownload = async (data: any) => {
    const confirmed = await dialog.openConfirmation({
      content:
        'Please be cautious as the file you are downloading might contain malicious code. Do you still want to download this file?',
      title: `Download ${data.filename}`
    })
    if (!confirmed) return
    await adminDownloadFalseSubmission(data.id)
      .unwrap()
      .then(response => {
        handleDownloadFile(response.url)
        dispatch(
          toggleDialogs({
            [DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO]: true,
            [`${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`]: data
          })
        )
      })
      .catch(() => {
        enqueueSnackbar('Download file failed', { variant: 'error' })
      })
  }
  const fileOrigin = FalseSubmissionFileOrigins.find(
    (item) => item.value === falseDetectionSubmission.fileOrigin
  ) || null

  return (
    <>
      <Grid container>
        <Grid xs={12} sm={12} md={10} lg={10}>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2.5 }}>
            <TextGrid
              label={'Id'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    { falseDetectionSubmission?.id ? `#${falseDetectionSubmission?.id.toString().padStart(8, '0')}` : ''}
                  </Typography>
                </Box>
              }
            />
            <TextGrid
              label={'File'}

              value={
                <>
                  <Typography
                    color="primary"
                    sx={{
                      overflowWrap: 'anywhere',
                      cursor: falseDetectionSubmission.inputType === 'file' ? 'pointer' : 'default',
                    }}
                    variant="body2"
                    onClick={() => {
                      if (falseDetectionSubmission.inputType === 'file') {
                        handleDownload(falseDetectionSubmission)
                      }
                    }}
                  >
                    {falseDetectionSubmission.inputType === 'file'
                      ? falseDetectionSubmission.filename
                      : falseDetectionSubmission?.hash}
                  </Typography>
                  {falseDetectionSubmission.inputType === 'file' && (
                    <>
                      <Chip label="File" variant="outlined" color="primary" size="small" sx={{ mt: 0.5 }} />
                      <Chip
                        label={humanFileSize(falseDetectionSubmission.size)}
                        variant="outlined"
                        color="default"
                        size="small"
                        sx={{ mt: 0.5, ml: 1 }}
                      />
                    </>
                  )}
                  {falseDetectionSubmission.inputType === 'hash' && (
                    <Chip label="Hash" variant="outlined" color="error" size="small" sx={{ mt: 0.5 }} />
                  )}
                </>
              }
            />

            <TextGrid
              label={'Status'}
              value={
                <Controller
                  name="cxStatus"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={FalseSubmissionStatuses}
                      getOptionLabel={(option) => option.name}
                      value={
                        FalseSubmissionStatuses.find(
                          (status) => status.value === field.value
                        ) || null
                      }
                      isOptionEqualToValue={(option, value) => option.value === value?.value}
                      onChange={(_, newValue) => {
                        field.onChange(newValue?.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="Select Status"
                          error={!!errors?.cxStatus}
                          helperText={errors?.cxStatus?.message}
                        />
                      )}
                      renderOption={(props, option) => (
                        <Typography
                          {...props}
                          variant="body2"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: option.color,
                            fontWeight: 500
                          }}
                        >
                          {option.name}
                        </Typography>
                      )}
                    />
                  )}
                />
              }
            />

            <TextGrid
              label={'Category'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.category} />}
            />

            <TextGrid
              label={'Hash MD5'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.md5} />}

            />

            <TextGrid
              label={'Hash SHA1'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.sha1} />}

            />

            <TextGrid
              label={'Hash SHA256'}
              value={<TextField disabled size="small" fullWidth value={falseDetectionSubmission?.sha256} />}

            />

            <TextGrid
              label={'Products'}
              value={
                <Tooltip
                  title={falseDetectionSubmission?.productNames ? falseDetectionSubmission?.productNames.join(', \n') : ''}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}

                >
                  <TextField disabled size="small" fullWidth value={falseDetectionSubmission?.productNames ? falseDetectionSubmission?.productNames.join(', ') : ''} />
                </Tooltip>
              }
            />

            <TextGrid
              label={'Antivirus engines'}
              value={
                <Tooltip
                  title={falseDetectionSubmission?.antivirusEngine}
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}

                >
                  <TextField disabled size="small" fullWidth value={falseDetectionSubmission?.antivirusEngine} />
                </Tooltip>
              }
            />

            <TextGrid
              label={'AV detection/Threat name'}
              value={<TextField disabled fullWidth size="small" value={falseDetectionSubmission?.detectionName}/>}

            />


            <TextGrid
              label={'Image of Detection/Report Alert'}
              value={
                <Box sx={{ display: 'flex', fontSize: '14px' }}>
                  {falseDetectionSubmission?.submissionScreenshotName ? (
                    <Typography >
                      <a target="_blank" href={falseDetectionSubmission?.submissionScreenshotUrl}>
                        {falseDetectionSubmission?.submissionScreenshotName}
                      </a>
                    </Typography>
                  ) : (
                    <Typography >
                      {'--'}
                    </Typography>
                  )}
                </Box>
              }
            />

            <TextGrid
              label={'The origin of the file'}
              value={<TextField disabled fullWidth size="small" value={fileOrigin?.name}/>}

            />

            <TextGrid
              label={'The purpose of the submitted file'}
              value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission?.purpose}/>}

            />

            <TextGrid
              label={'Why this is a false detection?'}
              value={<TextFieldArea disabled fullWidth size="small" value={falseDetectionSubmission?.note}/>}

            />

            <TextGrid
              label={'Agree to share the files with AV vendors for further analysis'}

              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <OpswatCheckbox label="" disabled checked={falseDetectionSubmission.userConsent} />
                </Box>
              }
            />

            <TextGrid
              label={'Analysis Summary'}
              value={
                <Controller
                  name="analystNote"
                  control={control}
                  render={({ field }) => (
                    <TextFieldArea
                      fullWidth
                      value={field.value}
                      onChange={(e: any) => field.onChange(e.target.value)}
                      error={!!_get(errors, 'analystNote', '')}
                      helperText={_get(errors, 'analystNote.message', '')}
                      sx={{
                        '& .MuiInputBase-input': {
                          fontWeight: 500
                        },
                      }}
                    />
                  )}
                />
              }
            />

            <TextGrid
              label={'Submitted At'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {formatDatetime(falseDetectionSubmission?.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
                  </Typography>
                </Box>
              }
            />

            <TextGrid
              label={'Submitted By'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {falseDetectionSubmission?.createdByEmail}
                  </Typography>
                </Box>
              }
            />

            <TextGrid
              label={'Last Updated'}
              value={
                <Box sx={{ display: 'flex', fontSize: '16px' }}>
                  <Typography >
                    {formatDatetime(falseDetectionSubmission?.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
                  </Typography>
                </Box>
              }
            />

          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'text',
                  color: 'inherit',
                  onClick: handleCancel,
                  disabled: isLoading
                }}
              >
                Cancel
              </ButtonLoading>
            </Grid>

            <Grid item xs="auto">
              <ButtonLoading
                propsButton={{
                  variant: 'contained',
                  color: 'primary',
                  disableElevation: true,
                  onClick: handleSubmit(onSubmit, onFail),
                  disabled: isLoading || _isEmpty(dirtyFields)
                }}
                isLoading={isLoading}
                propsLoading={{ color: 'inherit' }}
              >
                Save
              </ButtonLoading>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogFalseDetectionDownloadInfo />
    </>
  )
}

export default FalseDetectionSubmissionEdit
