import { FC, useEffect, useMemo } from 'react'

import _debounce from 'lodash/debounce'

import { Button, Grid, TextFieldSearch } from '@opswat/react-ui'

import { IProductFilterInput } from 'myopswat-admin/src/api/product/types'
import { useTranslation } from 'react-i18next'

import { systemManagementProductNewPageURL } from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

interface IProps {
  getProducts: (params: IProductFilterInput) => void
}

const BoxReleaseListFilter: FC<IProps> = ({ getProducts }) => {
  const navigate = useNavigate()
  const { t: translate } = useTranslation()
  const searchParams = new URLSearchParams(location.search)
  const qParams = searchParams.get('q') || ''

  const defaultValues = {
    q: qParams
  }

  const { control, watch } = useForm<any>({
    defaultValues: defaultValues
  })

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isCreateProductDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('add_product')
    }
    return false
  }, [profileData])

  useEffect(() => {
    const handleSearch = _debounce(() => {
      getProducts({
        q: watch('q')
      })
    }, 300)

    handleSearch()

    return () => {
      handleSearch.cancel()
    }
  }, [watch('q')])

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterProductName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(systemManagementProductNewPageURL)
          }}
          disabled={isCreateProductDisabled}
        >
          New Product
        </Button>
      </Grid>
    </Grid>
  )
}

export default BoxReleaseListFilter
