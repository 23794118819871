import { useMemo } from 'react'

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'

import { ButtonLoading, Grid } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { useAdminUpdateOrganizationMutation } from 'myopswat-admin/src/api/organizationManagement'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import OrganizationEditForm from './EditForm'
import { AdminOrganizationEditForm, organizationEditSchema } from './schema'

interface OrganizationEditProps {
  org: any
  afterSave?: () => void
  onCancel?: () => void
}

const OrganizationEdit = ({ org, afterSave, onCancel }: OrganizationEditProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const initValues = useMemo(() => {
    const values: any = {
      id: org.id,
      name: org.name,
      rank: org.rank,
      ssoId: org.ssoId,
      accountType: _get(org, 'accountType', '--'),
      preferredPartner: _get(org, 'preferredPartner', '--'),
      distributors: _get(org, 'distributor'),
      resellers: _get(org, 'reseller'),
      accountId: org.accountId,
      opportunityId: org.opportunityId,
      mdcLicenseType: org.mdcLicenseType,
      isOemCustomer: org.isOemCustomer,
      showKioskSecureImage: org.showKioskSecureImage,
      showEnginePackage: org.showEnginePackage,
      isManageSubOrganization: org.isManageSubOrganization,
      note: org.note,
      updatedReason: '',
      updatedAt: org.updatedAt,
      createdAt: org.createdAt
    }
    return values
  }, [org])

  const formRef = useForm<AdminOrganizationEditForm>({
    defaultValues: initValues,
    mode: 'onChange',
    resolver: yupResolver(organizationEditSchema)
  })

  const [updateOrg, { isLoading }] = useAdminUpdateOrganizationMutation()
  const {
    handleSubmit,
    formState: { dirtyFields }
  } = formRef

  const onSubmit = async (org: AdminOrganizationEditForm) => {
    try {
      const orgUpdated: any = {
        id: org.id
      }
      Object.keys(dirtyFields).forEach(field => {
        const k = field as keyof AdminOrganizationEditForm

        if (k === 'distributors' || k === 'resellers') {
          const data = org[k]
          orgUpdated[k] = data?.id ? [data.id] : []
        } else {
          orgUpdated[k] = org[k]
        }
      })

      const res = await updateOrg(orgUpdated).unwrap()
      if (res.success) {
        enqueueSnackbar('The organization information has updated successfully.', { variant: 'success' })
        afterSave && afterSave()
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0]?.message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <OrganizationEditForm formRef={formRef} />
      </Grid>

      <Grid item xs={12} sm={12} md={10} lg={8}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: onCancel,
                disabled: isLoading
              }}
            >
              Cancel
            </ButtonLoading>
          </Grid>

          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
                disabled: isLoading || _isEmpty(dirtyFields)
              }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OrganizationEdit
