import { TableLoading, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import { useAdminPermissionsQuery } from 'myopswat-admin/src/api/permission'
import { useAdminRoleQuery } from 'myopswat-admin/src/api/role'
import { useLazyAdminBasicUsersQuery } from 'myopswat-admin/src/api/userManagement'
import {
  OPSWAT_ORGANIZATION_ID,
  OPSWAT_ORGANIZATION_NAME,
  PAGE_DEFAULT,
  PAGE_SIZE_DEFAULT
} from 'myopswat-admin/src/constants'
import { useCheckOpswatIncRoute } from 'myopswat-admin/src/hooks/useCheckOpswatIncRoute'
import {
  customerManagementOrganizationListPageURL,
  detailUserRolePath,
  editUserRolePath,
  opswatRoleListPageURL,
  organizationRolesPath
} from 'myopswat-admin/src/routes'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import RoleDetailView from './RoleDetailView'
import RoleEditView from './RoleEditView'

const OrganizationRoleDetail = () => {
  const { isOpswatIncRoute } = useCheckOpswatIncRoute()

  const { orgId: organizationId = '', roleId = '', mode = '' } = useParams()
  const orgId = isOpswatIncRoute ? OPSWAT_ORGANIZATION_ID : organizationId

  const { data: roleData, isFetching: isFetchingRoleData } = useAdminRoleQuery(
    {
      id: roleId,
      organizationId: orgId
    },
    {
      refetchOnMountOrArgChange: true
    }
  )
  const { data: permissionData, isFetching: isFetchingPermissionData } = useAdminPermissionsQuery({
    filters: { q: '' },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: 100
    }
  })
  const [getUsers, { data: userData }] = useLazyAdminBasicUsersQuery()

  const [role, setRole] = useState<any>()
  const [rolePermissions, setRolePermissions] = useState<string[]>([])

  const navigate = useNavigate()

  const isRender = useMemo(() => {
    setRole(roleData)
    return roleData && !isFetchingRoleData && permissionData && !isFetchingPermissionData
  }, [roleData, isFetchingRoleData, permissionData, isFetchingPermissionData])

  const roleListPageURL = useMemo(
    () =>
      isOpswatIncRoute
        ? opswatRoleListPageURL
        : `${customerManagementOrganizationListPageURL}/${orgId}/${organizationRolesPath}`,
    [isOpswatIncRoute, orgId]
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: isOpswatIncRoute ? OPSWAT_ORGANIZATION_NAME : 'System Management'
      },
      {
        label: 'Roles',
        path: roleListPageURL
      },
      {
        label: roleData?.name || '--'
      }
    ]
  }, [roleData])

  const handleNavigate = (path: string) => {
    if (path !== window.location.pathname) {
      navigate(path)
    }
  }

  useEffect(() => {
    if (mode === editUserRolePath) {
      handleNavigate(`${roleListPageURL}/${roleId}/${editUserRolePath}`)
    } else {
      handleNavigate(`${roleListPageURL}/${roleId}/${detailUserRolePath}`)
    }
  }, [userData])

  const creatorData = useMemo(() => {
    if (userData && userData.totalCount > 0) {
      return userData.results[0]
    }
  }, [userData])

  const contentTypePermissions = useMemo(() => {
    const contentTypePermissionMap: any = {}
    if (permissionData && permissionData.results) {
      permissionData.results.forEach((permission: any) => {
        if (permission.isVisible) {
          if (!(permission['contentTypeDetail']['id'] in contentTypePermissionMap)) {
            contentTypePermissionMap[permission['contentTypeDetail']['id']] = {
              displayName: permission['contentTypeDetail']['displayName'],
              mappingName: permission['contentTypeDetail']['mappingName'],
              permissions: [],
              otherPermissions: []
            }
          }
          const item: any = {
            id: permission.id,
            codename: permission.codename,
            name: permission.name.replace('.', '')
          }
          if (
            ['add_', 'change_', 'view_', 'delete_'].some((action: string) => permission.codename.startsWith(action))
          ) {
            contentTypePermissionMap[permission['contentTypeDetail']['id']]['permissions'].push(item)
          } else {
            contentTypePermissionMap[permission['contentTypeDetail']['id']]['otherPermissions'].push(item)
          }
        }
      })
    }
    const results = Object.values(contentTypePermissionMap)
    results.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName))
    return results
  }, [permissionData])

  const permissionCodenames = useMemo(() => {
    if (permissionData && permissionData.results) {
      const results = permissionData.results.map((item: any) => item.codename)
      return results
    }
    return []
  }, [permissionData])

  const handleDataUpdate = (updatedData: any) => {
    setRole(updatedData)
  }

  useEffect(() => {
    if (mode === editUserRolePath) {
      handleNavigate(`${roleListPageURL}/${roleId}/${editUserRolePath}`)
    } else {
      handleNavigate(`${roleListPageURL}/${roleId}/${detailUserRolePath}`)
    }
  }, [])

  useEffect(() => {
    if (roleData && roleData.creatorId) {
      getUsers({
        filters: { ids: [roleData.creatorId] },
        pageInfo: {
          page: PAGE_DEFAULT,
          pageSize: PAGE_SIZE_DEFAULT
        }
      })
    }
  }, [roleData])

  useEffect(() => {
    let permissions: string[] = []
    if (role) {
      role.contentTypePermissions.forEach((item: any) => {
        permissions = permissions.concat(
          item.permissions.filter((perm: any) => perm.isVisible === true).map((perm: any) => perm.codename)
        )
      })
    }
    setRolePermissions(permissions)
  }, [permissionData, role])

  return (
    <TemplateSection>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && <TableLoading />}

      {isRender &&
        (mode === 'edit' ? (
          <RoleEditView
            roleData={role}
            permissionCodenames={permissionCodenames}
            contentTypePermissions={contentTypePermissions}
            isFetchingPermissionData={isFetchingPermissionData}
            handleDataUpdate={handleDataUpdate}
            rolePermissions={rolePermissions}
          />
        ) : (
          <RoleDetailView
            roleData={role}
            creatorData={creatorData}
            permissionCodenames={permissionCodenames}
            contentTypePermissions={contentTypePermissions}
            rolePermissions={rolePermissions}
          />
        ))}
    </TemplateSection>
  )
}

export default OrganizationRoleDetail
