import _get from 'lodash/get'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  OpswatCheckbox,
  TextField,
  Typography,
  TypographyLinkClamp
} from '@opswat/react-ui'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { MDC_LICENSE_TYPE_OPTIONS, ORGANIZATION_FORM_LABELS } from 'myopswat-admin/src/constants/organizations'
import { customerManagementOrganizationListPageURL, detailCustomerUserPath } from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useMemo } from 'react'

interface OrganizationViewProps {
  org: any
  onEdit?: () => void
}

const OrganizationView = ({ org, onEdit }: OrganizationViewProps) => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const isEditOrganizationDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_organization')
    }
    return false
  }, [profileData])

  const renderPreferredPartner = (org: any) => {
    const preferredPartner = _get(org, 'preferredPartner')

    if (!preferredPartner) return '--'

    const orgHref = `${customerManagementOrganizationListPageURL}/${_get(
      preferredPartner,
      'id'
    )}/${detailCustomerUserPath}`
    const preferredPartnerName = _get(org, 'preferredPartner.name', '--')

    return (
      <TypographyLinkClamp
        line={1}
        href={orgHref}
        sx={{ fontWeight: 500 }}
        typographyProps={{
          tooltipValue: preferredPartnerName,
          tooltipPlacement: 'top',
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }
        }}
      >
        {preferredPartnerName}
      </TypographyLinkClamp>
    )
  }

  const renderDistributorReseller = (org: any, type: 'distributor' | 'reseller') => {
    const info = _get(org, type)
    if (!info) return '--'

    const orgHref = `${customerManagementOrganizationListPageURL}/${_get(info, 'id')}/${detailCustomerUserPath}`
    const infoName = _get(info, 'name', '--')
    const infoAccountId = _get(info, 'accountId', '--')
    const infoSsoId = _get(info, 'ssoId', '--')
    const infoValue = [infoAccountId, infoName, infoSsoId].join(' - ')

    return (
      <TypographyLinkClamp
        line={1}
        href={orgHref}
        sx={{ fontWeight: 500 }}
        typographyProps={{
          tooltipValue: infoValue,
          tooltipPlacement: 'top',
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }
        }}
      >
        {infoValue}
      </TypographyLinkClamp>
    )
  }

  if (org)
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <GridView label={ORGANIZATION_FORM_LABELS.id} value={org.id} />
          <GridView
            label={`${ORGANIZATION_FORM_LABELS.name}`}
            value={<TextField fullWidth size="small" value={org.name} disabled />}
          />
          <GridView label={ORGANIZATION_FORM_LABELS.rank} value={org.rank} />
          <GridView
            label={ORGANIZATION_FORM_LABELS.mdcLicenseType}
            value={
              <Autocomplete
                options={[]}
                value={MDC_LICENSE_TYPE_OPTIONS[org?.mdcLicenseType]}
                renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
                disabled
              />
            }
          />
          <GridView label={ORGANIZATION_FORM_LABELS.ssoId} value={org.ssoId} />
          <GridView
            label={`${ORGANIZATION_FORM_LABELS.accountId}`}
            value={<TextField fullWidth size="small" disabled value={org.accountId || ''} />}
          />
          <GridView
            label={`${ORGANIZATION_FORM_LABELS.opportunityId}`}
            value={<TextField fullWidth size="small" disabled value={org.opportunityId || ''} />}
          />
          <GridView label={ORGANIZATION_FORM_LABELS.sfdcAccountType} value={_get(org, 'accountType', '--') || '--'} />
          <GridView label={ORGANIZATION_FORM_LABELS.sfdcPreferredPartner} value={renderPreferredPartner(org)} />
          <GridView
            label={ORGANIZATION_FORM_LABELS.sfdcDistributors}
            value={renderDistributorReseller(org, 'distributor')}
          />
          <GridView label={ORGANIZATION_FORM_LABELS.sfdcResellers} value={renderDistributorReseller(org, 'reseller')} />
          <GridView
            label={ORGANIZATION_FORM_LABELS.isOemCustomer}
            value={<OpswatCheckbox label="" disabled checked={org.isOemCustomer} />}
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.showKioskSecureImage}
            value={<OpswatCheckbox label="" disabled checked={org.showKioskSecureImage} />}
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.showEnginePackage}
            value={<OpswatCheckbox label="" disabled checked={org.showEnginePackage} />}
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.isManageSubOrganization}
            value={<OpswatCheckbox label="" disabled checked={org.isManageSubOrganization} />}
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.note}
            value={
              <>
                <TextField fullWidth multiline rows={6} size="small" value={org.note} disabled />
                <Typography color="textSecondary" variant="body2" sx={{ paddingTop: 1 }}>
                  Please do not add any personal information or Confidential information here.
                </Typography>
              </>
            }
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.updatedAt}
            value={formatDatetime(_get(org, 'updatedAt'), DATE_FORMATS.DATE_TIME, 'Etc/GMT')}
          />
          <GridView
            label={ORGANIZATION_FORM_LABELS.createdAt}
            value={formatDatetime(_get(org, 'createdAt'), DATE_FORMATS.DATE_TIME, 'Etc/GMT')}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={onEdit} color="primary" disabled={isEditOrganizationDisabled}>
              Edit
            </Button>
          </Box>
        </Grid>
      </Grid>
    )

  return null
}

export default OrganizationView
