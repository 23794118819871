import { Grid } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import { IProductFilterInput } from 'myopswat-admin/src/api/product/types'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'

interface IProps {
  getIntegratedApps: (params: IProductFilterInput) => void
}

const IntegratedAppListFilter: FC<IProps> = ({ getIntegratedApps }) => {
  const searchParams = new URLSearchParams(location.search)
  const qParams = searchParams.get('q') || ''

  const defaultValues = {
    q: qParams
  }
  // eslint-disable-next-line
  const { control, watch } = useForm<any>({
    defaultValues: defaultValues
  })

  useEffect(() => {
    const handleSearch = _debounce(() => {
      getIntegratedApps({
        q: watch('q')
      })
    }, 300)

    handleSearch()

    return () => {
      handleSearch.cancel()
    }
  }, [watch('q')])

  return (
    <Grid container justifyContent="space-between">
      {/* <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={(cProps: any) => (
            <TextFieldSearch
              placeholder={translate('filterIntegratedAppName') || ''}
              value={cProps.field.value}
              onChange={(e: any) => cProps.field.onChange(e.target.value)}
              onClearText={() => cProps.field.onChange('')}
              onKeyUp={(e: any) => {
                if (e.target.value === '') {
                  getIntegratedApps({
                    q: watch('q')
                  })
                }
              }}
            />
          )}
        />
      </Grid> */}
    </Grid>
  )
}

export default IntegratedAppListFilter
