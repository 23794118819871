import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import {
  IAdminBasicUserList,
  IAdminBasicUserQueryInput,
  IAdminUserDeleteInput,
  IAdminUserList,
  IAdminUserQueryInput,
  IAdminUserUpdateInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminUsers: builder.query<IAdminUserList, IAdminUserQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminUserFiltersInput, $pageInfo: PageInfoType) {
            adminUsers(filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                firstName
                lastName
                fullName
                email
                userTypes
                lastLogin
                timezone
                createdAt
                isActive
              }
              totalCount
            }
          }
        `,
        variables: {
          filters,
          pageInfo
        }
      })
    }),
    adminUser: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminUser(id: "${id}") {
              id
              firstName
              lastName
              title
              email
              userTypes
              timezone
              note
              customerSupportId
              companyName
              address {
                id
                streetAddress1
                streetAddress2
                city
                state
                phone
                country
                postalCode
              }
              showEnginePackage
              showKioskSecureImage
              sfdcData
            }
          }
        `
      })
    }),
    adminBasicUsers: builder.query<IAdminBasicUserList, IAdminBasicUserQueryInput>({
      query: ({ filters, pageInfo }) => ({
        document: gql`
          query ($filters: AdminBasicUserFiltersInput, $pageInfo: PageInfoType) {
            adminBasicUsers(filters: $filters, pageInfo: $pageInfo) {
              results {
                id
                fullName
              }
              totalCount
            }
          }
        `,
        variables: {
          filters,
          pageInfo
        }
      })
    }),
    adminUserUpdate: builder.mutation<any, IAdminUserUpdateInput>({
      query: input => ({
        document: gql`
          mutation adminUserUpdate($input: AdminUserUpdateInput!) {
            adminUserUpdate(input: $input) {
              success
              errors
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    adminUserDelete: builder.mutation<any, IAdminUserDeleteInput>({
      query: ({ userId, reason }) => ({
        document: gql`
          mutation ADMIN_USER_DELETE($input: AdminUserDeleteInput!) {
            adminUserDelete(input: $input) {
              success
              errors
              user {
                id
                firstName
                lastName
                email
              }
            }
          }
        `,
        variables: {
          input: {
            userId,
            reason
          }
        }
      })
    }),
    adminUserSettings: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminUserSettings(id: "${id}") {
              subscribedEmail {
                update
                newsletter
                blog
                salesOutreach
                productRelease
                sendAllPurchasedProducts
                academyNewsletter
                selectedProductIds
                unsubscribedAll
                consented
              }
            }
          }
        `
      })
    })
  })
})

export const {
  useAdminUsersQuery,
  useLazyAdminUsersQuery,
  useLazyAdminUserQuery,
  useLazyAdminBasicUsersQuery,
  useAdminUserUpdateMutation,
  useAdminUserDeleteMutation,
  useLazyAdminUserSettingsQuery
} = api
