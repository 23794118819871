import { Box, PageBackground, TemplateSection, Typography, useTheme } from '@opswat/react-ui'

import imageBg from '../../assets/images/mfa-bg.png'
import accessRestrictedIcon from '../../assets/images/access-restricted-icon.png'
import { OpswatIcon } from '@opswat/react-icon'

export default function AccessRestrictedPage() {
  const theme = useTheme()

  return (
    <PageBackground background={imageBg}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3
        }}
      >
        <Box
          sx={{
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: theme.palette.background.paper,
            p: 5,
            [theme.breakpoints.up('xs')]: {
              width: '480px',
              height: 'auto',
              minHeight: '378px'
            }
          }}
        >
          <TemplateSection spacing={4}>
            <Box>
              <OpswatIcon />
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Box width="fit-content" mb="24px">
                <img src={accessRestrictedIcon} alt="access-restricted-icon" />
              </Box>
              <Typography variant="h1" component="h1" paragraph sx={{ fontSize: 24 }}>
                Access Restricted
              </Typography>
              <Typography variant="body2" textAlign="center" sx={{ fontSize: 14 }}>
                You need to connect to OPSWAT's SDP (MetaDefender Endpoint) to access My OPSWAT Admin site.
              </Typography>
            </Box>
          </TemplateSection>
        </Box>
      </Box>
    </PageBackground>
  )
}
