import { FC, useState } from 'react'

import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { Box, Button, DialogAdvanced, Grid, TextField, Typography } from '@opswat/react-ui'

import { DIALOGS } from '@myopswat/common'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'

const DialogConfirmWithReason: FC<unknown> = () => {
  const [reason, setReason] = useState('')
  const dialogType = useTypedSelector(selectDialogs)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.CONFIRM_WITH_REASON]: false,
        [`${DIALOGS.CONFIRM_WITH_REASON}_TITLE`]: '',
        [`${DIALOGS.CONFIRM_WITH_REASON}_CONTENT`]: '',
        [`${DIALOGS.CONFIRM_WITH_REASON}_ACTION`]: null
      })
    )
  }

  return (
    <DialogAdvanced
      title={_get(dialogType, `${DIALOGS.CONFIRM_WITH_REASON}_TITLE`) || '--'}
      open={_get(dialogType, DIALOGS.CONFIRM_WITH_REASON, false)}
      disableBackdropClick={false}
      content={
        <Box>
          <Typography variant="body2">{_get(dialogType, `${DIALOGS.CONFIRM_WITH_REASON}_CONTENT`) || '--'}</Typography>
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ py: 0.5, minWidth: '80px' }}>
              Input Reason
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              sx={{ minHeight: '37px' }}
              onChange={e => {
                setReason(e.target.value)
              }}
              fullWidth
            />
          </Grid>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button variant="text" color="inherit" onClick={() => handleClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              variant="contained"
              color="primary"
              disabled={_isEmpty(reason)}
              onClick={() => {
                const cb: (reason: string) => void = _get(dialogType, `${DIALOGS.CONFIRM_WITH_REASON}_ACTION`, () =>
                  console.error(`${DIALOGS.CONFIRM}_TITLE`)
                )

                handleClose()
                cb(reason)
              }}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'xs'
      }}
    />
  )
}

export default DialogConfirmWithReason
