import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { useParams } from 'react-router-dom'

import MyAccountPage from 'myopswat-admin/src/pages/MyAccountPage'
import { useAppDispatch } from 'myopswat-admin/src/store'
import { saveEditMode, saveSubmitMode } from 'myopswat-admin/src/containers/MyAccountContainer/myAccountContainerSlice'

const MyInformationContainer = () => {
  const { tabPath = '' } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (tabPath) {
      dispatch(saveEditMode(''))
      dispatch(saveSubmitMode(''))
    }
  }, [tabPath])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'My Account'}
        </title>
      </Helmet>
      <MyAccountPage />
    </>
  )
}

export default MyInformationContainer
