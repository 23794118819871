import { useContext, useMemo } from 'react'

import { Box, TableAdvanced, TemplateSection } from '@opswat/react-ui'

import { PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'

import { MassNotificationContext } from './interface'
import MassNotificationListFilter from './searchFilter'

const MassNotificationPage = () => {
  const { isFetching, control, columns, results, totalCount, pageInfo, handleQuery, handlePagination } =
    useContext(MassNotificationContext)

  const FilterMemo = useMemo(() => <MassNotificationListFilter control={control} handleQuery={handleQuery} />, [])

  const TableMemo = useMemo(
    () => (
      <TableAdvanced
        isPagination
        isLoading={isFetching}
        columns={columns}
        data={results}
        total={totalCount}
        page={pageInfo.page}
        pageSize={pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePagination}
      />
    ),
    [columns, results, totalCount, isFetching, pageInfo]
  )

  return (
    <TemplateSection>
      <Box sx={{ my: 2.5 }}>
        {FilterMemo}
        {TableMemo}
      </Box>
    </TemplateSection>
  )
}

export default MassNotificationPage
