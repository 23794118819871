import { FC } from 'react'

import { ControllerRenderProps } from 'react-hook-form'

import { TextField, useTheme } from '@opswat/react-ui'

interface IProps extends ControllerRenderProps {
  disabled: boolean
  placeholder?: string
}

const ComposeTextArea: FC<IProps> = props => {
  const theme = useTheme()

  return (
    <TextField
      {...props}
      fullWidth
      multiline
      rows={15}
      sx={{
        '& .MuiInputBase-root': {
          padding: 0
        },
        '& .MuiInputBase-input': {
          padding: '6px 10px'
        },
        '& textarea': {
          resize: 'vertical',
          overflowX: 'hidden'
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.text.secondary
        },
        '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.text.disabled
        }
      }}
    />
  )
}

export default ComposeTextArea
