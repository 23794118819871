import { useMemo } from 'react'

import _filter from 'lodash/filter'
import _flatten from 'lodash/flatten'
import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _sortBy from 'lodash/sortBy'

import { TableAdvanced, Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { formatDatetime } from '@opswat/react-core'

interface IProps {
  pageInfo: any
  setPageInfo: any
  sortInfo?: any
  setSortInfo?: any
  products: any[]
  responseList: {
    headers: any[]
    results: any[]
    totalCount: number
    order: any[]
    sortableHeaders?: any[]
  }
  isFetchingResponseList: boolean
  handleSearch: (pageInfo?: any, sortInfo?: any) => void
}

const NPSResponsesTab = ({
  pageInfo,
  setPageInfo,
  sortInfo,
  setSortInfo,
  products,
  responseList,
  isFetchingResponseList,
  handleSearch
}: IProps) => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const columnArray: any[] = useMemo(() => {
    const mapped_header = _reduce(
      _flatten(responseList.headers),
      (mapped_header, item) => ({ ...mapped_header, ...item }),
      {}
    )
    const show_header_entries = _filter(Object.entries(mapped_header), ([, value]: string[]) =>
      _isNumber(_get(responseList.order, value))
    )
    const sorted_header_entries = _sortBy(show_header_entries, ([, value]: string[]) => _get(responseList.order, value))

    const convertProductIdToName = (productId?: string | null) => {
      if (productId === null) return 'All Products'
      return products.find((item: any) => item.id === productId)?.name || '--'
    }

    return [
      ..._map(sorted_header_entries, ([key, value]: string[], index: number) => ({
        key,
        header: <Typography>{value}</Typography>,
        isSortable: responseList.sortableHeaders?.includes(key),
        body: (data: any) => {
          let cellValue = _get(data, key) ?? _get(data, `answer.${key}`)
          if (Array.isArray(cellValue)) {
            cellValue = cellValue.join(', ')
          } else if (typeof cellValue === 'string' && ['yes', 'no'].includes(cellValue.toLowerCase())) {
            cellValue = cellValue?.toLowerCase() === 'yes' ? 'Yes' : 'No'
          } else if (typeof cellValue === 'boolean') {
            cellValue = cellValue ? 'Yes' : 'No'
          } else if (value?.toLowerCase().includes('product')) {
            cellValue = convertProductIdToName(cellValue)
          } else if (key === 'submittedAt') {
            cellValue = formatDatetime(cellValue + 'Z', 'yyyy-MM-dd HH:mm:ss', profileData?.timezone)
          }

          return (
            <Tooltip arrow placement="top-start" title={cellValue}>
              <span>
                <TypographyLineClamp variant="body2" line={1}>
                  {cellValue || '--'}
                </TypographyLineClamp>
              </span>
            </Tooltip>
          )
        },
        style: { minWidth: '200px', width: index === sorted_header_entries.length - 1 ? 'auto' : '200px' }
      }))
    ]
  }, [responseList])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== pageInfo.pageSize) page = PAGE_DEFAULT
    const newPageInfo = { page, pageSize }
    setPageInfo(newPageInfo)
    handleSearch(newPageInfo)
  }

  const handleOrderChange = (orderBy: string) => {
    const isAsc = sortInfo.orderBy === orderBy && sortInfo.order === 'asc'
    const newSortInfo = { order: isAsc ? 'desc' : 'asc', orderBy }
    setSortInfo(newSortInfo)

    // Reset page to 1 when sorting
    const newPageInfo = { ...pageInfo, page: PAGE_DEFAULT }
    setPageInfo(newPageInfo)
    handleSearch(newPageInfo, newSortInfo)
  }

  return (
    <TableAdvanced
      isPagination
      boxMinHeight={'0px'}
      columns={columnArray}
      isLoading={isFetchingResponseList}
      data={responseList.results}
      total={responseList.totalCount}
      page={pageInfo.page}
      pageSize={pageInfo.pageSize}
      pageSizes={PAGE_SIZES_DEFAULT}
      onPageChange={handlePaginationOnChange}
      order={sortInfo.order}
      orderBy={sortInfo.orderBy}
      onOrderChange={handleOrderChange}
    />
  )
}

export default NPSResponsesTab
