import { DATE_FORMATS, DIALOGS, handleDownloadFile } from '@myopswat/common'
import { formatDatetime, humanFileSize } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import {
  Box,
  Chip,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Tooltip,
  Typography,
  TypographyLinkClamp,
  TypographyTooltipClamp
} from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import {
  useAdminDownloadFalseSubmissionMutation,
  useAdminFalseSubmissionsQuery
} from 'myopswat-admin/src/api/analyzer/falseSubmission'
import {
  FalseSubmission,
  FalseSubmissionCategoryEnum,
  FalseSubmissionStatusEnum,
  IFalseSubmissionFilter,
  IFalseSubmissionFilterInput
} from 'myopswat-admin/src/api/analyzer/falseSubmission/types'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import { PAGE_DEFAULT, PAGE_SIZES_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import DialogFalseDetectionNote from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage/DialogFalseDetectionNote'
import DialogFalseDetectionSetStatus from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage/DialogFalseDetectionSetStatus'
import DialogFalseDetectionSetValidity from 'myopswat-admin/src/pages/SystemManagementPage/FalseDetectionSubmissionPage/DialogFalseDetectionSetValidity'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { enqueueSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DIALOGS_ADMIN } from '../../../components/Dialog/dialogVars'
import { falseDetectionDetailPath } from '../../../routes'
import DialogFalseDetectionDownloadInfo from './DialogFalseDetectionDownloadInfo'
import DialogFalseDetectionLinkCase from './DialogFalseDetectionLinkCase'
import DialogFalseDetectionResend from './DialogFalseDetectionResend'
import FalseSubmissionFilter from './FalseSubmissionFilter'
import { Helmet } from 'react-helmet'

const FalseDetectionSubmissionListPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const dialog = useDialog()

  const [query, setQuery] = useState<IFalseSubmissionFilterInput>({
    filters: {
      q: '',
      categories: [],
      validities: [],
      submittedBy: null,
      cxStatuses: [],
      inputTypes: []
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const {
    data: falseSubmissionData,
    isFetching: isFetchingFalseSubmissions,
    refetch: refetchFalseSubmissions
  } = useAdminFalseSubmissionsQuery(query, {
    refetchOnMountOrArgChange: true
  })

  const [adminDownloadFalseSubmission] = useAdminDownloadFalseSubmissionMutation()
  const [selectedFalseSubmission, setSelectedFalseSubmission] = useState<any>({})

  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isChangeFalseDetectionDisabled = useMemo(() => {
    if (profileData?.permissions) {
      return !profileData.permissions.includes('change_falsesubmission')
    }
    return false
  }, [profileData])
  const isFileDownloadDisabled = useMemo(() => {
    if (profileData?.permissions) {
      return !profileData.permissions.includes('filedownload_falsesubmission')
    }
    return false
  }, [profileData])
  const isResendEmailDisabled = useMemo(() => {
    if (profileData?.permissions) {
      return !profileData.permissions.includes('resend_falsesubmission')
    }
    return false
  }, [profileData])

  const falseSubmissions = useMemo(() => {
    if (falseSubmissionData) {
      return falseSubmissionData.results
    }
    return []
  }, [falseSubmissionData])

  const totalCount = useMemo(() => {
    if (_isEmpty(falseSubmissionData)) {
      return 0
    }
    return falseSubmissionData.totalCount
  }, [falseSubmissionData])

  const handleDownload = async (data: any) => {
    const confirmed = await dialog.openConfirmation({
      content:
        'Please be cautious as the file you are downloading might contain malicious code. Do you still want to download this file?',
      title: `Download ${data.filename}`
    })
    if (!confirmed) return
    await adminDownloadFalseSubmission(data.id)
      .unwrap()
      .then(response => {
        handleDownloadFile(response.url)
        dispatch(
          toggleDialogs({
            [DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO]: true,
            [`${DIALOGS.FALSE_DETECTION_DOWNLOAD_INFO}_DATA`]: data
          })
        )
      })
      .catch(() => {
        enqueueSnackbar('Download file failed', { variant: 'error' })
      })
  }

  const handleShortenHashValue = (hash: string) => {
    if (!hash) return ''
    return hash.length > 5 ? `${hash.slice(0, 5)}...${hash.slice(-5)}` : hash
  }

  const handleRenderID = (data: FalseSubmission) => (
    <TypographyTooltipClamp line={1} tooltipValue={`#${data.id.toString().padStart(8, '0')}`} variant="body2">
      #{data.id.toString().padStart(8, '0')}
    </TypographyTooltipClamp>
  )

  const handleRenderCategory = (data: FalseSubmission) => (
    <Typography variant="body2">{FalseSubmissionCategoryEnum[data.category]}</Typography>
  )

  const handleRenderFileName = (data: FalseSubmission) => (
    <>
      <TypographyTooltipClamp
        color="primary"
        variant="body2"
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          wordBreak: 'break-all',
          '&:hover': { textDecoration: 'underline' }
        }}
        onClick={() => handleDownload(data)}
        tooltipValue={data.inputType === 'file' ? data.filename : data.hash || '--'}
      >
        {data.inputType === 'file' ? data.filename : data.hash || '--'}
      </TypographyTooltipClamp>
      {data.inputType === 'file' && (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
          <Chip label="File" variant="outlined" color="primary" size="small" sx={{ mt: 0.5 }} />
          <Chip label={humanFileSize(data.size)} variant="outlined" color="default" size="small" />
        </Box>
      )}
      {data.inputType === 'hash' && (
        <Chip label="Hash" variant="outlined" color="error" size="small" sx={{ mt: 0.5 }} />
      )}
    </>
  )

  const handleRenderHash = (data: FalseSubmission) => (
    <>
      {data.inputType === 'file' && (
        <Tooltip
          arrow
          title={[`MD5: ${data.md5}`, `SHA1: ${data.sha1}`, `SHA256: ${data.sha256}`].join('\n')}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }}
        >
          <Typography
            variant="body2"
            sx={{
              width: 'fit-content'
            }}
          >
            SHA256: {handleShortenHashValue(data.sha256)}
          </Typography>
        </Tooltip>
      )}
      {data.inputType === 'hash' && (
        <TypographyTooltipClamp line={1} variant="body2" tooltipValue={data.hash}>
          {data.hash}
        </TypographyTooltipClamp>
      )}
    </>
  )

  const handleRenderProducts = (data: FalseSubmission) => (
    <TypographyTooltipClamp
      line={2}
      variant="body2"
      tooltipValue={data?.productNames ? data?.productNames.join('\n ') : '--'}
      style={{
        wordBreak: 'break-word'
      }}
    >
      {data?.productNames ? data?.productNames.join(', ') : '--'}
    </TypographyTooltipClamp>
  )

  const handleRenderStatus = (data: FalseSubmission) => {
    const statusValue = FalseSubmissionStatusEnum[data.cxStatus]
    let backgroundColor = '',
      textColor = ''

    switch (data.cxStatus) {
      case 'IN_QUEUE':
        backgroundColor = '#FDBD0D'
        textColor = '#000000'
        break
      case 'IN_PROGRESS':
        backgroundColor = '#1D6BFC'
        textColor = '#FFFFFF'
        break
      case 'CONFIRMED_MALICIOUS':
        backgroundColor = '#D00300'
        textColor = '#FFFFFF'
        break
      case 'CONFIRMED_BENIGN':
        backgroundColor = '#008A00'
        textColor = '#FFFFFF'
        break
      case 'INCONCLUSIVE':
        backgroundColor = '#F59209'
        textColor = '#000000'
        break
      case 'REJECTED':
        backgroundColor = '#BCBFC3'
        textColor = '#1B273C'
        break
    }

    return (
      <TypographyTooltipClamp
        line={1}
        tooltipValue={statusValue}
        backgroundColor={backgroundColor}
        textColor={textColor}
        sx={{
          backgroundColor,
          color: textColor,
          padding: '0px 4px',
          width: 'fit-content',
          fontSize: '12px',
          fontWeight: 500,
          display: 'inline'
        }}
      >
        {statusValue}
      </TypographyTooltipClamp>
    )
  }

  const handleRenderSupportCaseNo = (data: FalseSubmission) =>
    data.caseNumber ? (
      <TypographyLinkClamp
        line={2}
        color="primary"
        variant="body2"
        sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
        href={`${process.env.REACT_APP_OPSWAT_GO_URL}/s/case/${data.caseId}`}
        typographyProps={{
          tooltipValue: `#${data.caseNumber}`,
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          },
          tooltipPlacement: 'top'
        }}
      >
        {`#${data.caseNumber}`}
      </TypographyLinkClamp>
    ) : (
      <Typography variant="body2">--</Typography>
    )

  const handleRenderJiraTicketNo = (data: FalseSubmission) =>
    data.keyIssueJira ? (
      <TypographyLinkClamp
        line={2}
        color="primary"
        variant="body2"
        sx={{ cursor: 'pointer', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
        href={`${process.env.REACT_APP_FALSE_SUBMISSION_JIRA_URL}/${data.keyIssueJira}`}
        typographyProps={{
          tooltipValue: data.keyIssueJira,
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          },
          tooltipPlacement: 'top'
        }}
      >
        {data.keyIssueJira}
      </TypographyLinkClamp>
    ) : (
      <Typography variant="body2">--</Typography>
    )

  const handleRenderSubmittedAt = (data: FalseSubmission) => {
    const value = formatDatetime(data?.createdAt, DATE_FORMATS.DATE_TIME)
    return (
      <TypographyTooltipClamp line={2} variant="body2" tooltipValue={value}>
        {value}
      </TypographyTooltipClamp>
    )
  }

  const handleRenderUpdatedAt = (data: FalseSubmission) => {
    const value = formatDatetime(data?.updatedAt, DATE_FORMATS.DATE_TIME)
    return (
      <TypographyTooltipClamp line={2} variant="body2" tooltipValue={value}>
        {value}
      </TypographyTooltipClamp>
    )
  }

  const handleCloseSetSupportLink = (isRefetch = false) => {
    if (isRefetch) {
      refetchFalseSubmissions()
    }
    setSelectedFalseSubmission({})
  }

  const handleRenderActions = (data: FalseSubmission) => (
    <Dropdown
      button={
        <IconButton>
          <OptionIcon />
        </IconButton>
      }
      content={
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                navigate(`${data?.id}/${falseDetectionDetailPath}`)
              }}
              disabled={false}
            >
              View detail
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(
                  toggleDialogs({
                    [DIALOGS.FALSE_DETECTION_SET_STATUS]: true,
                    [`${DIALOGS.FALSE_DETECTION_SET_STATUS}_DATA`]: data
                  })
                )
              }}
              disabled={isChangeFalseDetectionDisabled}
            >
              Set Status
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(
                  toggleDialogs({
                    [DIALOGS.FALSE_DETECTION_NOTE]: true,
                    [`${DIALOGS.FALSE_DETECTION_NOTE}_DATA`]: data
                  })
                )
              }}
              disabled={isChangeFalseDetectionDisabled}
            >
              Update Analysis Summary
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                handleDownload(data)
              }}
              disabled={isFileDownloadDisabled || data?.inputType === 'hash'}
            >
              Download File
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                setSelectedFalseSubmission(data)
                dispatch(
                  toggleDialogs({
                    [DIALOGS_ADMIN.FALSE_DETECTION_LINK_TICKET]: true
                  })
                )
              }}
              disabled={isChangeFalseDetectionDisabled}
            >
              Link Support case/Jira ticket
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(
                  toggleDialogs({
                    [DIALOGS.FALSE_DETECTION_RESEND]: true,
                    [`${DIALOGS.FALSE_DETECTION_RESEND}_DATA`]: data
                  })
                )
              }}
              disabled={isResendEmailDisabled}
            >
              Resend Email
            </ListItemButton>
          </ListItem>
        </List>
      }
    />
  )

  const columns = useMemo(() => {
    return [
      {
        header: 'ID',
        body: handleRenderID,
        style: { minWidth: '6vw', width: '6vw', pr: 1 }
      },
      {
        header: 'File Name / Hash',
        body: handleRenderFileName,
        style: { minWidth: '10vw', width: '10vw', pr: 1 }
      },
      {
        header: 'Category',
        body: handleRenderCategory,
        style: { minWidth: '6vw', width: '6vw', pr: 1 }
      },
      {
        header: 'Hash',
        body: handleRenderHash,
        style: { minWidth: '10vw', width: '10vw', pr: 1 }
      },
      {
        header: 'Products',
        body: handleRenderProducts,
        style: { minWidth: '10vw', width: '10vw', pr: 1 }
      },
      {
        header: 'Status',
        body: handleRenderStatus,
        style: { minWidth: '10vw', width: '10vw', pr: 1 }
      },
      {
        header: 'Support Case No.',
        body: handleRenderSupportCaseNo,
        style: { minWidth: '7vw', width: '7vw', pr: 1 }
      },
      {
        header: 'Jira Ticket No.',
        body: handleRenderJiraTicketNo,
        style: { minWidth: '7vw', width: '7vw', pr: 1 }
      },
      {
        header: 'Submitted At',
        body: handleRenderSubmittedAt,
        style: { minWidth: '6vw', width: '6vw', pr: 1 }
      },
      {
        header: 'Last Updated',
        body: handleRenderUpdatedAt,
        style: { minWidth: '6vw', width: '6vw', pr: 1 }
      },
      {
        header: '',
        body: handleRenderActions,
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page: page, pageSize: pageSize } }))
  }

  const handleSearch = (searchData: IFalseSubmissionFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const TableMemo = useMemo(() => {
    return (
      <TableAdvanced
        isPagination
        columns={columns}
        isLoading={isFetchingFalseSubmissions}
        data={falseSubmissions}
        total={totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePaginationOnChange}
      />
    )
  }, [columns, falseSubmissions, isFetchingFalseSubmissions, query.pageInfo, totalCount])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          False Detection Submissions
        </title>
      </Helmet>

      <TemplateSection>
        <FalseSubmissionFilter filters={query.filters} onSearch={handleSearch} />
        {TableMemo}
        <DialogFalseDetectionSetValidity reloadData={refetchFalseSubmissions} />
        <DialogFalseDetectionSetStatus reloadData={refetchFalseSubmissions} />
        <DialogFalseDetectionNote reloadData={refetchFalseSubmissions} />
        <DialogFalseDetectionResend />
        <DialogFalseDetectionLinkCase
          handleCloseSetSupportLink={handleCloseSetSupportLink}
          falseSubmission={selectedFalseSubmission}
        />
        <DialogFalseDetectionDownloadInfo />
      </TemplateSection>
    </>
  )
}

export default FalseDetectionSubmissionListPage
