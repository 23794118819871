import { utcToZonedTime } from 'date-fns-tz'
import { unescape as htmlUnescape } from 'html-escaper'
import _uniq from 'lodash/uniq'

import { IMassNotificationInputType } from 'myopswat-admin/src/api/massEmail/types'

export const MAX_EMAIL_DISPLAY_LIMIT = 3
export const MAX_EMAIL_TOOLTIP_LIMIT = 40
export const UTC_TIMEZONE_CODE = 'Etc/GMT'

function convertDateToUTC(date?: Date) {
  if (!date) {
    return null
  }
  const result = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  result.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
  return result
}

export const handleMapEmailList = (emails: string[], maxLength: number, delimiter: string) => {
  return `${emails.slice(0, maxLength).join(delimiter)}${
    emails.length > maxLength ? `${delimiter} (+${emails.length - maxLength} more emails)` : ''
  }`
}

export const handleMapRequest = (data: IMassNotificationInputType) => {
  const request: IMassNotificationInputType = {
    id: data.id,
    title: data.title,
    content: data.content,
    sendingPurpose: data.sendingPurpose,
    isDraft: data.isDraft,
    isCriticalAlert: data.isCriticalAlert,
    productIds: data.products?.map(item => item.value) ?? [],
    sendAt: data.sendType === 'SCHEDULED' ? convertDateToUTC(data.scheduleTime) : null,
    recipientEmails: _uniq(
      data.recipients
        ?.split(';')
        .map(item => item.trim())
        .filter(item => item)
    ),
    relatedProductsRecipientEmails: _uniq(
      data.relatedProductsRecipientEmails?.map(item => item.trim()).filter(item => item)
    )
  }
  return request
}

export const handleMapResponse = (data: IMassNotificationInputType) => {
  let sendType = ''
  if (data.deliveryStatus?.toUpperCase() === 'DRAFT') {
    sendType = data.sendAt ? 'SCHEDULED' : 'IMMEDIATELY'
  } else {
    sendType = data.isImmediately ? 'IMMEDIATELY' : 'SCHEDULED'
  }
  const response: IMassNotificationInputType = {
    ...data,
    content: htmlUnescape(data.content ?? ''),
    products: [...(data.products ?? []), ...(data.productOptions?.map((item: any) => ({ id: item })) ?? [])],
    productIds: [...(data.products?.map((item: any) => item.id) ?? []), ...(data.productOptions ?? [])],
    recipients: data.recipientEmails?.join('; '),
    sendType,
    scheduleTime: data.sendAt ? utcToZonedTime(new Date(String(data.sendAt)), UTC_TIMEZONE_CODE) : undefined,
    relatedProductsRecipientEmails:
      data.deliveryStatus?.toUpperCase() === 'DELIVERED' ? data.relatedProductsRecipientEmails : [],
    productRecipientsText: handleMapEmailList(data.relatedProductsRecipientEmails ?? [], MAX_EMAIL_DISPLAY_LIMIT, '; '),
    productRecipientsTooltip: handleMapEmailList(
      data.relatedProductsRecipientEmails ?? [],
      MAX_EMAIL_TOOLTIP_LIMIT,
      '\n'
    )
  }
  return response
}
