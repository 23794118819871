import _debounce from 'lodash/debounce'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _intersection from 'lodash/intersection'

import { yupResolver } from '@hookform/resolvers/yup'
import { escape as htmlEscape, unescape as htmlUnescape } from 'html-escaper'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { useLazyEmailSubscriptionProductsWithDetailQuery } from 'myopswat-admin/src/api/product'
import type { IAdminReleaseInformationInput } from 'myopswat-admin/src/api/product/types'
import {
  RELEASE_INFORMATION_LEVEL,
  RELEASE_INFORMATION_LEVEL_LABELS,
  RELEASE_INFORMATION_STATUS
} from 'myopswat-admin/src/api/product/types'
import {
  useCreateReleaseInformationMutation,
  useLazyAdminReleaseInformationDetailQuery,
  useLazyCheckReleaseInformationProductAndVersionQuery,
  useUpdateReleaseInformationMutation
} from 'myopswat-admin/src/api/releaseInformation'
import { systemManagementReleaseInformationPageURL } from 'myopswat-admin/src/routes'

import { Helmet } from 'react-helmet'
import CreateReleaseInfoPage from '.'
import { releaseInformationSchema } from './components/ReleaseInfoValidationSchema'
import { ReleaseInfoContext } from './interface'

const CreateReleaseInfoProvider = () => {
  const navigate = useNavigate()

  const { infoId } = useParams()

  const [loadProducts, { data: productOptions, isFetching: isFetchingProducts }] =
    useLazyEmailSubscriptionProductsWithDetailQuery()
  const [loadReleaseInfo] = useLazyAdminReleaseInformationDetailQuery()
  const [createReleaseInfo, { isLoading: isCreating }] = useCreateReleaseInformationMutation()
  const [updateReleaseInfo, { isLoading: isUpdating }] = useUpdateReleaseInformationMutation()
  const [checkExistingProductAndVersion, { isFetching: isChecking }] =
    useLazyCheckReleaseInformationProductAndVersionQuery()

  const [warningMessage, setWarningMessage] = useState<string>('')

  const initialValues = useMemo<IAdminReleaseInformationInput>(() => {
    return {
      productId: '',
      platformIds: [],
      version: '',
      releaseLevel: '',
      sendReleaseEmail: false,
      featureHeader: '',
      featureDescription: ''
    }
  }, [])

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid, dirtyFields }
  } = useForm<IAdminReleaseInformationInput>({
    resolver: yupResolver(releaseInformationSchema),
    defaultValues: initialValues
  })

  const isCreatePage = useMemo(() => infoId === 'create', [infoId])

  const platformOptions = useMemo(() => {
    if (!watch('productId')) return []

    const releaseMetadata = _get(
      _find(productOptions, item => item.id === watch('productId')),
      'releaseMetadata',
      ''
    )

    return _get(releaseMetadata, 'platforms', [])
  }, [watch('productId'), productOptions])

  const releaseInformationLevels = useMemo(() => {
    return [
      {
        label: `${RELEASE_INFORMATION_LEVEL_LABELS[RELEASE_INFORMATION_LEVEL.CRITICAL].toUpperCase()}`,
        value: RELEASE_INFORMATION_LEVEL.CRITICAL
      },
      {
        label: `${RELEASE_INFORMATION_LEVEL_LABELS[RELEASE_INFORMATION_LEVEL.TIER_1].toUpperCase()} (Tier 1)`,
        value: RELEASE_INFORMATION_LEVEL.TIER_1
      },
      {
        label: `${RELEASE_INFORMATION_LEVEL_LABELS[RELEASE_INFORMATION_LEVEL.TIER_2].toUpperCase()} (Tier 2)`,
        value: RELEASE_INFORMATION_LEVEL.TIER_2
      },
      {
        label: `${RELEASE_INFORMATION_LEVEL_LABELS[RELEASE_INFORMATION_LEVEL.TIER_3].toUpperCase()} (Tier 3)`,
        value: RELEASE_INFORMATION_LEVEL.TIER_3
      }
    ]
  }, [])

  const enableSendReleaseEmail = useMemo(() => {
    if (watch('releaseLevel') === RELEASE_INFORMATION_LEVEL.TIER_3) {
      return true
    }

    return false
  }, [watch('releaseLevel')])

  const isRequiredFeaturesInput = useMemo(() => {
    // If release level is not tier 3, then feature headline is required
    if (watch('releaseLevel') !== RELEASE_INFORMATION_LEVEL.TIER_3) {
      return true
    }

    // If release level is tier 3 and send release email is checked, then feature headline is required
    if (watch('sendReleaseEmail')) {
      return true
    }

    return false
  }, [watch('sendReleaseEmail'), watch('releaseLevel')])

  const handleLoadProducts = useCallback(async (platformStatuses?: any[]) => {
    await loadProducts()
      .unwrap()
      .then(() => {
        setValue(
          'platformIds',
          platformStatuses?.map((item: any) => ({
            label: item.platformName,
            value: item.platformId
          })) ?? []
        )
      })
  }, [])

  const handleLoadReleaseInformation = useCallback(async (id?: string) => {
    id &&
      (await loadReleaseInfo(id)
        .unwrap()
        .then(async response => {
          Object.entries(response).forEach(([key, value]: any) => {
            setValue(key, value, { shouldDirty: false })
          })
          setValue('featureDescription', htmlUnescape(response.featureDescription ?? ''), { shouldDirty: false })
          handleLoadProducts(response.platformStatuses)
        })
        .catch(() => {
          enqueueSnackbar(
            'This release information does not exist or cannot be found. Please re-check and then try again.',
            {
              variant: 'error'
            }
          )
        }))
  }, [])

  const handleCheckExistingProductAndVersion = useCallback(async () => {
    const productId = watch('productId')
    const version = watch('version')
    const platformIds = watch('platformIds').map((item: any) => item.value)
    const infoId = watch('id')
    if (!productId || !version) return
    await checkExistingProductAndVersion({ productId, version, platformIds, infoId })
      .unwrap()
      .then(response => {
        if ((_get(response, 'existProductVersionPlatform') ?? []).length > 0) {
          const productName = productOptions?.find((item: any) => item.id === productId)?.name
          const platformNames = platformOptions
            .filter((item: any) => _get(response, 'existProductVersionPlatform').includes(item.id))
            .map((item: any) => item.name)
            .join(', ')
          const version = watch('version')
          const message = `This Release Information (${productName}, ${platformNames} and ${version}) already exists`
          setError('version', { message })
          setWarningMessage('')
        } else if (_get(response, 'existProductVersion', false)) {
          clearErrors('version')
          setWarningMessage(
            'We already have the release description for this version. Please go back to the list and double-check to avoid creating duplicates.'
          )
        } else {
          setWarningMessage('')
          clearErrors('version')
        }
      })
  }, [watch('productId'), watch('version'), watch('id'), platformOptions, productOptions])

  const handleCheckDebounced = useCallback(
    _debounce(() => {
      handleCheckExistingProductAndVersion()
    }, 300),
    [handleCheckExistingProductAndVersion]
  )

  const handleMapRequest = useCallback((values: IAdminReleaseInformationInput) => {
    const request: any = {}
    if (values.status === RELEASE_INFORMATION_STATUS.PARTIALLY_DELIVERED) {
      request['id'] = values.id
      request['platformIds'] = values.platformIds?.map((item: any) => item.value)
      request['updatedReason'] = values.updatedReason
    } else {
      Object.entries(values).forEach(([key, value]: any) => {
        if (
          ['productName', 'platformStatuses', 'status'].includes(key) ||
          (key === 'sendReleaseEmail' && values.releaseLevel !== RELEASE_INFORMATION_LEVEL.TIER_3)
        ) {
          return
        } else if (key === 'productId') {
          if (!values.id) request[key] = value
        } else if (key === 'platformIds') {
          request[key] = value.map((item: any) => item.value)
        } else if (key === 'featureDescription') {
          request[key] = htmlEscape(value ?? '')
        } else {
          request[key] = value
        }
      })
    }
    return request
  }, [])

  const handleSave = useCallback(async (values: IAdminReleaseInformationInput) => {
    const request = handleMapRequest(values)
    await (values.id ? updateReleaseInfo(request as IAdminReleaseInformationInput) : createReleaseInfo(request))
      .unwrap()
      .then(async response => {
        if (response?.success) {
          await enqueueSnackbar('Saved release information successfully', {
            variant: 'success'
          })
          navigate(systemManagementReleaseInformationPageURL)
        } else {
          const errorCode = _get(response, ['errors', 0, 'code'])
          if (errorCode === 'release_info_existing') {
            setError('productId', { message: 'This Release Information already exists' })
            setError('productId', { message: 'This Release Information already exists' })
          } else {
            enqueueSnackbar(_get(response, ['errors', 0, 'message']), {
              variant: 'error'
            })
          }
        }
      })
      .catch(() => {
        enqueueSnackbar('Saving release information has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
      })
  }, [])

  const handleReset = useCallback(() => {
    reset()
    navigate(systemManagementReleaseInformationPageURL)
  }, [])

  useEffect(() => {
    setValue('platformIds', [])
  }, [watch('productId')])

  useEffect(() => {
    if (!isRequiredFeaturesInput) {
      clearErrors('featureHeader')
      clearErrors('featureDescription')
    }
  }, [isRequiredFeaturesInput, watch('releaseLevel')])

  useEffect(() => {
    if (isCreatePage || _intersection(Object.keys(dirtyFields), ['productId', 'version', 'platformIds']).length > 0) {
      handleCheckDebounced()
    }
  }, [watch('productId'), watch('version'), watch('platformIds'), isCreatePage, dirtyFields])

  useEffect(() => {
    if (isCreatePage) {
      loadProducts()
    } else {
      handleLoadReleaseInformation(infoId)
    }
  }, [infoId])

  return (
    <ReleaseInfoContext.Provider
      value={{
        control,
        errors,
        isFormValid: isValid && Object.keys(dirtyFields).length > 0,
        viewMode: isCreatePage ? 'create' : 'edit',
        productOptions,
        isFetchingProducts,
        platformOptions,
        releaseInformationLevels,
        enableSendReleaseEmail,
        isRequiredFeaturesInput,
        setValue,
        handleSave,
        handleSubmit,
        handleReset,
        trigger,
        isCreating: isCreating || isUpdating,
        isChecking,
        warningMessage
      }}
    >
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {`${isCreatePage ? 'Create' : 'Edit'} Release Information`}
        </title>
      </Helmet>
      <CreateReleaseInfoPage />
    </ReleaseInfoContext.Provider>
  )
}

export default CreateReleaseInfoProvider
