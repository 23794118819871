import { FC } from 'react'

import { Chip } from '@opswat/react-ui'

interface IProps {
  platform: string
}

const ReleaseInformationPlatformChip: FC<IProps> = ({ platform }) => {
  return (
    <Chip
      color="primary"
      sx={{
        color: '#3D5176',
        backgroundColor: '#FFFFFF',
        height: '21px',
        fontSize: '10px',
        borderRadius: '50px',
        textTransform: 'uppercase',
        '& .MuiChip-label': {
          padding: '0px',
          lineHeight: '13px',
          letterSpacing: '1.5px'
        }
      }}
      label={platform}
      size="small"
    />
  )
}

export default ReleaseInformationPlatformChip
