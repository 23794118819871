import { QueryPagination } from '../../types'

export interface IFalseSubmissionFilter {
  q?: string
  categories: string[]
  validities: string[]
  submittedBy: string | null
  cxStatuses: string[]
  inputTypes: string[]
  all?: boolean
}

export interface IFalseSubmissionFilterInput {
  filters: IFalseSubmissionFilter
  pageInfo: QueryPagination
}

export enum FalseSubmissionCategoryEnum {
  false_positive = 'False Positive',
  false_negative = 'False Negative'
}

export enum FalseSubmissionProcessStatusEnum {
  new = 'New',
  analyzing = 'Analyzing',
  validated = 'Validated',
  published = 'Published'
}

export enum FalseSubmissionValidityEnum {
  valid = 'Valid',
  invalid = 'Invalid',
  none = 'To be determined'
}

export enum FalseSubmissionStatusEnum {
  IN_QUEUE = 'IN QUEUE',
  IN_PROGRESS = 'IN PROGRESS',
  CONFIRMED_MALICIOUS = 'CONFIRMED MALICIOUS',
  CONFIRMED_BENIGN = 'CONFIRMED BENIGN',
  INCONCLUSIVE = 'INCONCLUSIVE',
  REJECTED = 'REJECTED'
}

export interface FalseSubmission {
  id: number
  createdAt: Date
  updatedAt: Date
  filename: string
  hash: string
  inputType: string
  url: string
  md5: string
  sha1: string
  sha256: string
  size: number
  metadata: unknown
  createdByFullname: string
  createdByEmail: string
  category: keyof typeof FalseSubmissionCategoryEnum
  cxStatus: keyof typeof FalseSubmissionStatusEnum
  analystStatus: keyof typeof FalseSubmissionStatusEnum
  validity: keyof typeof FalseSubmissionValidityEnum
  analystNote: string
  note: string
  productNames: string[]
  caseNumber?: string
  caseId?: string
  idIssueJira?: string
  keyIssueJira?: string
}

export interface IFalseSubmissionAnalyzeInput {
  id: string
  productIds: string[]
}

export interface IFalseSubmissionUpdateInput {
  id: string
  validity?: string
  cxStatus?: string
  analystStatus?: string
  analystNote?: string
  ticketType?: string
  ticketId?: string
  caseNumber?: string
}

export interface IFalseSubmissionResendEmailInput {
  id: string
  recipientEmails: string[]
}

export interface IFalseSubmissionAntivirusEngineFilter {
  q?: string
}

export interface IFalseSubmissionAntivirusEngineFilterInput {
  filters: IFalseSubmissionAntivirusEngineFilter
  pageInfo: QueryPagination
}

export interface IFalseSubmissionAntivirusEngineInput {
  engineName: string
  vendorName?: string
  country?: string
  detection?: string
  heuristicDetection?: string
}

export const FalseSubmissionInputTypes = [
  { name: 'File', value: 'file' },
  { name: 'Hash', value: 'hash' }
]

export const FalseSubmissionCategories = [
  { name: 'False Positive', value: 'false_positive' },
  { name: 'False Negative', value: 'false_negative' }
]

export const FalseSubmissionValidities = [
  { name: 'Valid', value: 'valid' },
  { name: 'Invalid', value: 'invalid' },
  { name: 'To be determined', value: 'none' }
]

export const FalseSubmissionStatuses = [
  { name: 'IN QUEUE', value: 'IN_QUEUE', color: '#FDBD0D' },
  { name: 'IN PROGRESS', value: 'IN_PROGRESS', color: '#1D6BFC' },
  { name: 'CONFIRMED MALICIOUS', value: 'CONFIRMED_MALICIOUS', color: '#D00300' },
  { name: 'CONFIRMED BEGIN', value: 'CONFIRMED_BENIGN', color: '#008A00' },
  { name: 'INCONCLUSIVE', value: 'INCONCLUSIVE', color: '#ED6706' },
  { name: 'REJECTED', value: 'REJECTED', color: '#707682' }
]


export const FalseSubmissionFileOrigins = [
  { name: 'Downloaded from the Internet', value: 'INTERNET_DOWNLOAD' },
  { name: 'Internal file (from your own company)', value: 'INTERNAL_FILE' },
  { name: 'Attachment from unknown/untrusted source', value: 'ATTACHMENT_FROM_UNTRUSTED_SOURCE' },
  { name: 'Received from a partner or trusted vendor (emailed, transferred)', value: 'TRUSTED_VENDOR' }
]
