import { useCallback, useContext, useMemo } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import _find from 'lodash/find'
import _get from 'lodash/get'

import {
  Autocomplete,
  AutocompleteMultiple,
  Box,
  FormHelperText,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@opswat/react-ui'

import { RELEASE_INFORMATION_LEVEL, RELEASE_INFORMATION_STATUS } from 'myopswat-admin/src/api/product/types'
import GridView from 'myopswat-admin/src/components/Grid/GridView'

import { RELEASE_INFORMATION_FORM_LABEL_MAP } from '../../../constants'
import { ReleaseInfoContext } from '../interface'
import QuillEditor from './QuillEditor'

export const RELEASE_PLATFORMS_ALL_OPTION = {
  id: -1,
  name: 'All Platforms',
  displayName: 'All Platforms'
}

const ReleaseInfoForm = () => {
  const {
    control,
    errors,
    setValue,
    trigger,
    viewMode,
    warningMessage,
    productOptions,
    platformOptions,
    releaseInformationLevels,
    enableSendReleaseEmail,
    isRequiredFeaturesInput
  } = useContext(ReleaseInfoContext)

  const theme = useTheme()
  const productId = useWatch({ control, name: 'productId' })
  const platformStatuses = useWatch({ control, name: 'platformStatuses' })
  const overallStatus = useWatch({ control, name: 'status' })
  const featureHeader = useWatch({ control, name: 'featureHeader' })

  const latestProductVersions = _get(
    _find(productOptions, item => item?.id === productId),
    ['latestReleases'],
    []
  )
    .map((item: any) => item.semVer)
    .join(', ')

  const isDisabled = useMemo(() => {
    return (
      overallStatus &&
      [
        RELEASE_INFORMATION_STATUS.PARTIALLY_DELIVERED,
        RELEASE_INFORMATION_STATUS.DELIVERED,
        RELEASE_INFORMATION_STATUS.SKIPPED
      ].includes(overallStatus)
    )
  }, [overallStatus])

  const tagProps = useMemo(() => {
    const props: any = {}
    platformStatuses?.forEach((item: any) => {
      props[item.platformId] = {
        clearable: item.status !== RELEASE_INFORMATION_STATUS.DELIVERED,
        color: [RELEASE_INFORMATION_STATUS.PENDING, RELEASE_INFORMATION_STATUS.SKIPPED].includes(item.status)
          ? '#616875'
          : '#FFFFFF',
        backgroundColor: item.status === RELEASE_INFORMATION_STATUS.DELIVERED ? '#008A00' : '#E9EAEB',
        padding: item.status === RELEASE_INFORMATION_STATUS.DELIVERED ? '4px 8px' : '4px 4px 4px 8px'
      }
    })
    return props
  }, [platformStatuses])

  const handleGetRemainingPlatforms = useCallback(() => {
    return platformOptions.filter(
      (option: any) =>
        !platformStatuses?.some(
          (item: any) => item.status === RELEASE_INFORMATION_STATUS.DELIVERED && item.platformId === option.value
        )
    )
  }, [platformOptions, platformStatuses])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <GridView
        required
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.productName}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <Controller
            name="productId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disabled={viewMode === 'edit'}
                options={productOptions ?? []}
                value={_find(productOptions ?? [], item => item?.id === value)}
                getOptionLabel={(option: any) => option.name || '--'}
                onChange={(_: any, newValue: any) => {
                  onChange(newValue?.id)
                }}
                renderInput={(params: any) => (
                  <TextField
                    error={!!_get(errors, 'productId', '')}
                    helperText={_get(errors, 'productId.message', '')}
                    {...params}
                    size="small"
                    variant="outlined"
                    sx={{
                      minHeight: 'auto',
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: theme.palette.text.secondary
                      },
                      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.text.disabled
                      }
                    }}
                  />
                )}
              />
            )}
          />
        }
      />
      <GridView
        required
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.platforms}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <Controller
            name="platformIds"
            control={control}
            render={({ field }: any) => (
              <AutocompleteMultiple
                {...field}
                disabled={
                  overallStatus &&
                  [RELEASE_INFORMATION_STATUS.DELIVERED, RELEASE_INFORMATION_STATUS.SKIPPED].includes(overallStatus)
                }
                limitTags={10}
                options={
                  (platformOptions.length > 1
                    ? [RELEASE_PLATFORMS_ALL_OPTION, ...platformOptions]
                    : platformOptions
                  ).map((item: any) => ({
                    label: item.name,
                    value: item.id
                  })) || []
                }
                onChange={(newValue: any) => {
                  // If user chooses all platforms
                  if (newValue.some((item: any) => item.value === RELEASE_PLATFORMS_ALL_OPTION.id)) {
                    const remainingOptions = handleGetRemainingPlatforms()
                    // Render all platforms to the input
                    field.onChange(remainingOptions.map((item: any) => ({ label: item.name, value: item.id })))
                  } else {
                    field.onChange(newValue)
                  }
                }}
                tagProps={tagProps}
                getOptionDisabled={(option: any) =>
                  platformStatuses?.some(
                    (item: any) =>
                      item.status === RELEASE_INFORMATION_STATUS.DELIVERED && item.platformId === option.value
                  )
                }
              />
            )}
          />
        }
      />
      <GridView
        required
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.version}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <Controller
            name="version"
            control={control}
            render={({ field }: any) => (
              <>
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'version', '')}
                  helperText={_get(errors, 'version.message', '')}
                  placeholder={latestProductVersions}
                  disabled={isDisabled}
                  sx={{
                    minHeight: 'auto',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: theme.palette.text.secondary
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.text.disabled
                    }
                  }}
                />
                {warningMessage && (
                  <Box sx={{ marginTop: '4px' }}>
                    <FormHelperText component="label" sx={{ color: '#fdb00d' }}>
                      {warningMessage}
                    </FormHelperText>
                  </Box>
                )}
              </>
            )}
          />
        }
      />
      <GridView
        required
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.releaseLevel}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <Controller
            name="releaseLevel"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable
                disabled={isDisabled}
                options={releaseInformationLevels}
                value={_find(releaseInformationLevels, item => item.value === value)}
                getOptionLabel={(option: any) => option.label || '--'}
                onChange={(_: any, newValue: any) => {
                  onChange(newValue.value)

                  // If the release level is tier 3, then disable the send release email
                  setValue('sendReleaseEmail', newValue.value !== RELEASE_INFORMATION_LEVEL.TIER_3)
                }}
                renderInput={(params: any) => {
                  return (
                    <>
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        disabled={isDisabled}
                        sx={{
                          minHeight: 'auto',
                          '& .MuiFormHelperText-root': {
                            marginLeft: '0px',
                            marginTop: '6px'
                          },
                          '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: theme.palette.text.secondary
                          },
                          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.text.disabled
                          }
                        }}
                      />
                      {value === RELEASE_INFORMATION_LEVEL.TIER_3 && (
                        <Box sx={{ marginTop: '4px' }}>
                          <FormHelperText component="label" sx={{ color: '#fdb00d' }}>
                            {'By default, MINOR Level will not be included in the release notification email.'}
                          </FormHelperText>
                        </Box>
                      )}
                    </>
                  )
                }}
              />
            )}
          />
        }
      />
      {enableSendReleaseEmail && (
        <GridView
          label={RELEASE_INFORMATION_FORM_LABEL_MAP.sendReleaseEmail}
          labelProps={{
            variant: 'inherit'
          }}
          value={
            <Controller
              name={'sendReleaseEmail'}
              control={control}
              render={({ field }) => {
                return (
                  <Switch
                    disabled={!enableSendReleaseEmail || overallStatus === RELEASE_INFORMATION_STATUS.SKIPPED}
                    checked={field.value}
                    onClick={(e: any) => {
                      field.onChange(e.target.checked)
                    }}
                    label={''}
                    sx={{
                      marginTop: '-8px',
                      marginLeft: '2px',
                      '& .MuiSwitch-track': {
                        width: '40px !important',
                        height: '20px !important',
                        backgroundColor: `${field.value ? '#1D6BFC' : '#bdbdbd'} !important`,
                        opacity: '1 !important',
                        borderRadius: '50px !important',
                        border: '0px !important',
                        '&::before, &::after': {
                          content: '""',
                          position: 'absolute',
                          top: '40%',
                          transform: 'translateY(-50%)',
                          width: '12px',
                          height: '12px',
                          color: '#FFFFF'
                        },
                        '&::before': {
                          left: '12px',
                          top: '40%',
                          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
                        },
                        '&::after': {
                          right: '16px',
                          top: '42%',
                          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path xmlns="http://www.w3.org/2000/svg" d="M5 11L11 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path xmlns="http://www.w3.org/2000/svg" d="M11 11L5 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>')`
                        }
                      },
                      '& .MuiSwitch-thumb': {
                        boxShadow: 'none',
                        width: '15px',
                        height: '15px',
                        margin: '1px 2px 0px 2px',
                        borderRadius: '50px',
                        backgroundColor: 'white !important'
                      }
                    }}
                  />
                )
              }}
            />
          }
          labelSx={{
            marginBottom: '10px'
          }}
        />
      )}
      <GridView
        required={isRequiredFeaturesInput}
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.featureHeader}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <>
            <Controller
              name="featureHeader"
              control={control}
              rules={{
                max: 100
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  error={!!_get(errors, 'featureHeader', '')}
                  helperText={_get(errors, 'featureHeader.message', '')}
                  disabled={!isRequiredFeaturesInput || isDisabled}
                  sx={{
                    minHeight: 'auto',
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: theme.palette.text.secondary
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.text.disabled
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                      '& > fieldset': {
                        borderColor: 'rgba(0, 0, 0, 0.1)'
                      }
                    }
                  }}
                  onChange={(e: any) => {
                    const newValue = e.target.value
                    field.onChange(newValue.length > 100 ? newValue.slice(0, 100) : newValue)
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0.5 }}>
              <Typography component="label" variant="caption" color="#707ea4">
                {featureHeader?.length}/100
              </Typography>
            </Box>
          </>
        }
      />
      <GridView
        required={isRequiredFeaturesInput}
        label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.featureDescription}`}
        labelProps={{
          variant: 'inherit'
        }}
        value={
          <Controller
            name="featureDescription"
            control={control}
            render={({ field }: any) => <QuillEditor {...field} disabled={!isRequiredFeaturesInput || isDisabled} />}
          />
        }
      />
      {overallStatus !== RELEASE_INFORMATION_STATUS.SKIPPED &&
        overallStatus !== RELEASE_INFORMATION_STATUS.DELIVERED &&
        viewMode === 'edit' && (
          <GridView
            label={`${RELEASE_INFORMATION_FORM_LABEL_MAP.updatedReason}`}
            labelProps={{
              variant: 'inherit'
            }}
            value={
              <Controller
                name="updatedReason"
                control={control}
                render={({ field }: any) => (
                  <>
                    <TextField
                      {...field}
                      multiline
                      minRows={5}
                      size="small"
                      fullWidth
                      error={!!_get(errors, 'updatedReason', '')}
                      helperText={_get(errors, 'updatedReason.message', '')}
                      onChange={(e: any) => {
                        field.onChange(e.target.value)
                        trigger('updatedReason')
                      }}
                    />

                    <Box sx={{ marginTop: '2px' }}>
                      <FormHelperText component="label" sx={{ color: '#fdb00d' }}>
                        Please clearly explain why you need to update this data. This input is only used for writing
                        event logs and is not sent to the customer.
                      </FormHelperText>
                    </Box>
                  </>
                )}
              />
            }
          />
        )}
    </Box>
  )
}

export default ReleaseInfoForm
