import { useCallback, useEffect, useMemo } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import { Controller, useForm } from 'react-hook-form'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import {
  Button,
  CheckboxWithLabel,
  DropdownFilter,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@opswat/react-ui'

import {
  EVENT_TIME_FRAME_FILTER,
  EVENT_TIME_FRAME_FILTER_OPTIONS,
  EVENT_LOGS_TYPE,
  EVENT_LOGS_TYPE_LABELS
} from 'myopswat-admin/src/constants'

interface IProps {
  handleFiltersChange: (query: any) => void
  queryData: any
}

const EventLogsFilter = ({ handleFiltersChange, queryData }: IProps) => {
  const defaultValues = { timeFrame: _get(queryData, 'filters.timeFrame', null) }

  const {
    control: controlFilter,
    register: registerFilter,
    watch: watchFilter,
    reset: resetFilter,
    setValue: setValueFilter,
    formState: { errors }
  } = useForm<any>({
    defaultValues: {
      ...defaultValues,
      startDate: null,
      endDate: null,
      eventTypes: []
    }
  })

  const showCustomRange = useMemo(() => {
    return watchFilter('timeFrame') === EVENT_TIME_FRAME_FILTER.CUSTOM
  }, [watchFilter('timeFrame')])

  const handleApplyFilter = useCallback(() => {
    handleFiltersChange({
      timeFrame: watchFilter('timeFrame'),
      startDate: watchFilter('startDate'),
      endDate: watchFilter('endDate'),
      eventTypes: watchFilter('eventTypes')
    })
  }, [watchFilter])

  const eventTimeFrameRadioOptions = useCallback(
    (item: any) => (
      <FormControlLabel
        key={_get(item, 'value')}
        value={_get(item, 'value')}
        control={<Radio size="small" />}
        label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
        sx={{
          width: 'fit-content'
        }}
      />
    ),
    [watchFilter('timeFrame')]
  )

  const eventTypesCheckbox = useCallback(
    (item: any, idx: any) => (
      <Grid item xs={12} spacing={1} key={idx.toString()} marginBottom={1.5}>
        <Controller
          name={'eventTypes'}
          control={controlFilter}
          render={({ field: { value, onChange } }: any) => (
            <CheckboxWithLabel
              label={
                <Typography variant="subtitle2" textTransform="capitalize">
                  {EVENT_LOGS_TYPE_LABELS[item] || '--'}
                </Typography>
              }
              checkboxProps={{
                checked: watchFilter('eventTypes').includes(item),
                onChange: (e: any) => {
                  if (e.target.checked) {
                    onChange([...value, item])
                  } else {
                    onChange(value.filter((status: any) => status !== item))
                  }
                },
                sx: {
                  marginLeft: '12px'
                }
              }}
            />
          )}
        />
      </Grid>
    ),
    [watchFilter('eventTypes')]
  )

  useEffect(() => {
    if (queryData) {
      setValueFilter('timeFrame', _get(queryData, 'filters.timeFrame', null))
      setValueFilter('startDate', _get(queryData, 'filters.startDate', null))
      setValueFilter('endDate', _get(queryData, 'filters.endDate', null))
      setValueFilter('eventTypes', _get(queryData, 'filters.eventTypes', []))
    }
  }, [queryData])

  return (
    <Grid
      container
      justifyContent={'flex-end'}
      sx={{
        marginY: 2.5
      }}
    >
      <DropdownFilter
        button={
          <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
            Filter
          </Button>
        }
        content={
          <Grid container spacing={2} paddingTop={2} paddingX={2}>
            {/* Time Frame filter */}
            <Grid item container xs={6} spacing={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Time Frame</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={controlFilter}
                    name={'timeFrame'}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        {_map(EVENT_TIME_FRAME_FILTER_OPTIONS, eventTimeFrameRadioOptions)}
                      </RadioGroup>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Event Type filter */}
            <Grid item container xs={6} spacing={2}>
              <Grid container spacing={1} display={'block'}>
                <Grid item xs={12} marginBottom={1.5}>
                  <Typography variant="subtitle1">Event</Typography>
                </Grid>
                {_map(Object.values(EVENT_LOGS_TYPE), eventTypesCheckbox)}
              </Grid>
            </Grid>

            {/* Custom Time Frame Filter */}
            <Grid item container xs={12} spacing={2}>
              {showCustomRange && (
                <Grid container columnSpacing={4} sx={{ marginTop: 1 }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" marginBottom={1}>
                          From
                        </Typography>
                        <TextField
                          {...registerFilter('startDate')}
                          fullWidth
                          size="small"
                          error={!!_get(errors, 'startDate', '')}
                          type="date"
                          sx={{ minHeight: '0px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" marginBottom={1}>
                          To
                        </Typography>
                        <TextField
                          {...registerFilter('endDate')}
                          fullWidth
                          size="small"
                          error={!!_get(errors, 'endDate', '')}
                          type="date"
                          sx={{ minHeight: '0px' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        onResetFilter={() => {
          resetFilter()
        }}
        onResetChange={() => {
          resetFilter()
        }}
        onApply={handleApplyFilter}
      />
    </Grid>
  )
}

export default EventLogsFilter
