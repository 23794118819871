import { useCallback, useEffect, useMemo, useState } from 'react'
import { ButtonLoading, CollapsibleTableAdvanced, DialogAdvanced, Grid, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _filter from 'lodash/filter'
import _join from 'lodash/join'
import { formatDatetime, fullName } from '@opswat/react-core'
import { useLazyAdminDetailReleaseHistoriesQuery } from 'myopswat-admin/src/api/history'
import {
  IAdminDetailReleaseHistoryInput,
  IAdminDetailReleaseHistoryType,
  IHistoryDiffType
} from 'myopswat-admin/src/api/history/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { RELEASE_FORM_LABEL_MAP } from '../../../constants'
import { convertValueToString } from 'myopswat-admin/src/utils/json'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { DIALOGS } from '@myopswat/common'
import { useParams } from 'react-router-dom'

const DialogReleaseHistoryList = () => {
  const dispatch = useAppDispatch()
  const { productId = '' } = useParams()
  const productDetailData = useTypedSelector(state => state?.api?.queries?.[`productDetail("${productId}")`]?.data)
  const dialogType = useTypedSelector(selectDialogs)
  const isDialogOpen = _get(dialogType, DIALOGS.RELEASE_HISTORIES, false)
  const releaseData = _get(dialogType, `${DIALOGS.RELEASE_HISTORIES}_DATA`, {})
  const releaseId = _get(releaseData, 'id', '')

  const [query, setQuery] = useState<IAdminDetailReleaseHistoryInput>({
    releaseId,
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  })

  const [getDetailReleaseHistories, { data, isFetching: isLoading }] = useLazyAdminDetailReleaseHistoriesQuery()
  const collapseData = useMemo(
    () => _map(data?.results, (columnData: IAdminDetailReleaseHistoryType) => columnData.changes),
    [data]
  )

  useEffect(() => setQuery(statePrev => Object.assign({}, statePrev, { releaseId })), [releaseId])

  useEffect(() => {
    if (isDialogOpen && query.releaseId) {
      getDetailReleaseHistories(query)
    }
  }, [isDialogOpen, query])

  const platformIdsMap = useMemo(() => {
    const platforms = _get(productDetailData, 'adminProduct.releaseMetadata.platforms', [])
    return _reduce(
      platforms,
      (result, platform) => {
        result[_get(platform, 'id')] = platform
        return result
      },
      {}
    )
  }, [productDetailData])

  const convertPlatformIdsToPlatforms = useCallback(
    (platformIds: string) => {
      return _join(
        _filter(
          _map(platformIds.split(', '), (platformId: string) => _get(platformIdsMap, `${platformId}.displayName`, ''))
        ),
        ', '
      )
    },
    [platformIdsMap]
  )

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Event',
        body: (data: IAdminDetailReleaseHistoryType) => (
          <Typography variant="body2">{data.historyType || '--'}</Typography>
        ),
        style: { minWidth: 100, width: 100 }
      },
      {
        header: 'Reason',
        body: (data: IAdminDetailReleaseHistoryType) => (
          <Typography variant="body2">{data.historyUpdatedReason || '--'}</Typography>
        ),
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Updated By',
        body: (data: IAdminDetailReleaseHistoryType) => (
          <Typography variant="body2">
            {fullName(data.historyUpdatedBy.firstName, data.historyUpdatedBy.lastName) || '--'}
          </Typography>
        ),
        style: { minWidth: 250, width: 250 }
      },
      {
        header: 'Datetime',
        body: (data: IAdminDetailReleaseHistoryType) => (
          <Typography variant="body2">{formatDatetime(data.historyDate, undefined) || '--'}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      }
    ]
  }, [])

  const collapsedColumnArray = useMemo(() => {
    return [
      {
        header: 'Field',
        body: (data: IHistoryDiffType) => {
          let field = _get(RELEASE_FORM_LABEL_MAP, data.field, data.field) || '--'

          if (field === 'platformIds') {
            field = RELEASE_FORM_LABEL_MAP.platforms
          }

          return <Typography variant="body2">{field}</Typography>
        },
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Change',
        body: (data: IHistoryDiffType) => {
          let change = convertValueToString(data.new || '--')

          if (data.field === 'platformIds') {
            change = convertPlatformIdsToPlatforms(change)
          }

          return <Typography variant="body2">{change}</Typography>
        },
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Was',
        body: (data: IHistoryDiffType) => {
          let old = convertValueToString(data.old || '--')

          if (data.field === 'platformIds') {
            old = convertPlatformIdsToPlatforms(old)
          }

          return <Typography variant="body2">{old}</Typography>
        },
        style: { minWidth: 350, width: 350 }
      }
    ]
  }, [])

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.RELEASE_HISTORIES]: false,
        [`${DIALOGS.RELEASE_HISTORIES}_DATA`]: ''
      })
    )
  }

  return (
    <DialogAdvanced
      open={isDialogOpen}
      onClose={() => handleClose()}
      content={
        <>
          <CollapsibleTableAdvanced
            columns={columnArray}
            collapsedColumns={collapsedColumnArray}
            isLoading={isLoading}
            data={data?.results}
            collapsedData={collapseData}
            isPagination
            total={data?.totalCount}
            page={query.pageInfo.page}
            pageSize={query.pageInfo.pageSize}
            onPageChange={handlePaginationOnChange}
          />
        </>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Cancel
            </ButtonLoading>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'md'
      }}
    />
  )
}
export default DialogReleaseHistoryList
