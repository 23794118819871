import { useCallback, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'
import { Controller, useForm } from 'react-hook-form'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import {
  Button,
  CheckboxWithLabel,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@opswat/react-ui'

import {
  EVENT_TIME_FRAME_FILTER,
  EVENT_TIME_FRAME_FILTER_OPTIONS,
  EVENT_LOGS_TYPE,
  EVENT_LOGS_TYPE_LABELS
} from 'myopswat-admin/src/constants'
import DropdownFilterRoleObject from './DropdownFilterRoleObject'

interface IProps {
  handleFiltersChange: (query: any) => void
  queryData: any
}

const EventLogsFiltersSection = ({ handleFiltersChange, queryData }: IProps) => {
  const defaultValues = { timeFrame: _get(queryData, 'filters.timeFrame', null) }
  const [showCustomRange, setShowCustomRange] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const {
    control: controlFilter,
    register: registerFilter,
    watch: watchFilter,
    reset: resetFilter,
    setValue: setValueFilter,
    formState: { errors }
  } = useForm<any>(
    {
      defaultValues: {
        ...defaultValues,
        startDate: null,
        endDate: null,
        eventTypes: []
      },
      mode: "onChange"
    })

  useEffect(() => {
    const timeFrame = watchFilter('timeFrame');
    const isCustomRange = timeFrame === EVENT_TIME_FRAME_FILTER.CUSTOM;
    setShowCustomRange(isCustomRange);
    if (isCustomRange) {
      const startDate = watchFilter('startDate');
      const endDate = watchFilter('endDate');
      const isValidRange = startDate && endDate && new Date(startDate) <= new Date(endDate);
      setDisabled(!isValidRange);
    } else {
      setDisabled(false);
    }
  }, [watchFilter('timeFrame'), watchFilter('startDate'), watchFilter('endDate')]);


  const handleApplyFilter = useCallback(() => {
    const query: any = {
      timeFrame: watchFilter('timeFrame'),
      eventTypes: watchFilter('eventTypes'),
    }
    if (watchFilter('timeFrame') === EVENT_TIME_FRAME_FILTER.CUSTOM) {
      query['startDate'] = watchFilter('startDate')
      query['endDate'] = watchFilter('endDate')
    }
    handleFiltersChange(query)
  }, [watchFilter])

  const eventTimeFrameRadioOptions = useCallback(
    (item: any) => (
      <FormControlLabel
        key={_get(item, 'value')}
        value={_get(item, 'value')}
        control={<Radio size="small" />}
        label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
        sx={{
          width: 'fit-content'
        }}
      />
    ),
    [watchFilter('timeFrame')]
  )

  const eventTypesCheckbox = useCallback(
    (item: any, idx: any) => (
      <Grid item xs={12} spacing={1} key={idx.toString()} marginBottom={1.5}>
        <Controller
          name={'eventTypes'}
          control={controlFilter}
          render={({ field: { value, onChange } }: any) => (
            <CheckboxWithLabel
              label={
                <Typography variant="subtitle2" textTransform="capitalize">
                  {EVENT_LOGS_TYPE_LABELS[item] || '--'}
                </Typography>
              }
              checkboxProps={{
                checked: watchFilter('eventTypes').includes(item),
                onChange: (e: any) => {
                  if (e.target.checked) {
                    onChange([...value, item])
                  } else {
                    onChange(value.filter((status: any) => status !== item))
                  }
                },
                sx: {
                  marginLeft: '12px'
                }
              }}
            />
          )}
        />
      </Grid>
    ),
    [watchFilter('eventTypes')]
  )

  useEffect(() => {
    if (queryData) {
      setValueFilter('timeFrame', _get(queryData, 'filters.timeFrame', null))
      setValueFilter('eventTypes', _get(queryData, 'filters.eventTypes', []))
    }
  }, [queryData])

  return (
    <Grid
      container
      justifyContent={'flex-end'}
      sx={{
        marginY: 2.5
      }}
    >
      <DropdownFilterRoleObject
        button={
          <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
            Filter
          </Button>
        }
        content={
          <Grid container spacing={2} paddingTop={2} paddingX={2}>
            {/* Time Frame filter */}
            <Grid item container xs={6} spacing={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Time Frame</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={controlFilter}
                    name={'timeFrame'}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        {_map(EVENT_TIME_FRAME_FILTER_OPTIONS, eventTimeFrameRadioOptions)}
                      </RadioGroup>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Event Type filter */}
            <Grid item container xs={6} spacing={2}>
              <Grid container spacing={1} display={'block'}>
                <Grid item xs={12} marginBottom={1.5}>
                  <Typography variant="subtitle1">Event</Typography>
                </Grid>
                {_map(Object.values(EVENT_LOGS_TYPE), eventTypesCheckbox)}
              </Grid>
            </Grid>

            {/* Custom Time Frame Filter */}
            <Grid item container xs={12} spacing={2}>
              {showCustomRange && (
                <Grid container columnSpacing={4} sx={{ marginTop: 1 }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" marginBottom={1}>
                          From
                          <span>*</span>
                        </Typography>
                        <TextField
                          {...registerFilter('startDate', { required: 'Must input start date' })}
                          fullWidth
                          size="small"
                          error={!!_get(errors, 'startDate', '')}
                          type="date"
                          sx={{ minHeight: '0px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle2" marginBottom={1}>
                          To
                          <span>*</span>
                        </Typography>
                        <TextField
                          {...registerFilter('endDate', { required: 'Must input end date' })}
                          fullWidth
                          size="small"
                          error={!!_get(errors, 'endDate', '')}
                          type="date"
                          sx={{ minHeight: '0px' }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        onResetFilter={() => {
          resetFilter({
            timeFrame: null,
            startDate: null,
            endDate: null,
            eventTypes: []
          })
        }}
        onResetChange={() => {
          resetFilter({ ...queryData.filters })
        }}
        onApply={handleApplyFilter}
        disabled={disabled}
      />
    </Grid>
  )
}

export default EventLogsFiltersSection
