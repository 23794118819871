import { DIALOGS } from '@myopswat/common'
import { Box, Button, TableAdvanced, TemplateSection, Typography, TypographyDivider } from '@opswat/react-ui'
import _get from 'lodash/get'
import {
  useIntegratedAppTokenCreateMutation,
  useLazyIntegratedAppDetailQuery,
  useLazyIntegratedAppTokenListQuery
} from 'myopswat-admin/src/api/integrated-app'
import { EMPTY_STRING } from 'myopswat-admin/src/constants'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { integrationManagementInternalAppListPageURL } from 'myopswat-admin/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import DialogAppToken from './DialogAppToken'

interface IProps {
  appId: string
}

const IntegratedAppTokenPage = ({ appId }: IProps) => {
  const [getIntegratedAppDetail, { data: integratedAppData }] = useLazyIntegratedAppDetailQuery()
  const [getAppTokens, { data: appTokensData, isFetching: isFetchingAppTokens }] = useLazyIntegratedAppTokenListQuery()
  const [appTokenCreate, { data: appTokenCreateData }] = useIntegratedAppTokenCreateMutation()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isGenerateTokenDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('add_apptoken')
    }
    return false
  }, [profileData])

  const dispatch = useAppDispatch()

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Internal Applications',
        path: integrationManagementInternalAppListPageURL
      },
      {
        label: integratedAppData?.name || EMPTY_STRING
      }
    ]
  }, [integratedAppData])

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => <Typography variant="body2">{_get(data, 'name', EMPTY_STRING)}</Typography>,
        style: { minWidth: 400, width: 400 }
      },
      {
        header: 'Code',
        body: (data: any) => <Typography variant="body2">{'**' + data.tokenLast4 || EMPTY_STRING}</Typography>,
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Description',
        body: (data: any) => <Typography variant="body2">{data.app.name}</Typography>,
        style: { minWidth: 340, width: 340 }
      },
      // Mocked column to avoid UI table layout break
      {
        header: '',
        body: () => <></>,
        style: { minWidth: 0 }
      }
    ]
  }, [])

  const handleGenerateToken = async () => {
    try {
      await appTokenCreate({ appId: appId }).unwrap()
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    getIntegratedAppDetail(appId)
    getAppTokens({ appId })
  }, [])

  return (
    <TemplateSection>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleGenerateToken().then(async () => {
              dispatch(
                toggleDialogs({
                  [DIALOGS.APP_TOKEN]: true,
                  [`${DIALOGS.APP_TOKEN}_DATA`]: integratedAppData
                })
              )
            })
          }}
          disabled={isGenerateTokenDisabled}
        >
          Generate Token
        </Button>
      </Box>
      <TableAdvanced columns={columnArray} isLoading={isFetchingAppTokens} data={appTokensData} />
      <DialogAppToken getAppTokens={getAppTokens} token={appTokenCreateData?.token} appData={integratedAppData} />
    </TemplateSection>
  )
}

export default IntegratedAppTokenPage
