import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import _get from 'lodash/get'

import { handleForceLogout } from '@myopswat/common'
import {
  Avatar,
  Box,
  Divider,
  Dropdown,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
  Tooltip,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'

import { useAccountLogoutMutation } from 'myopswat-admin/src/api/account'
import { myAccountPersonalInformationPageURL } from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'

import { fullName } from '@opswat/react-core'

const RightMenu: FC<unknown> = () => {
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  // TODO: Logout
  const [accountLogout] = useAccountLogoutMutation()
  const handleLogout = async () => {
    try {
      await accountLogout().unwrap()
      await handleForceLogout()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs></Grid>

        <Grid item xs="auto">
          <Dropdown
            button={
              <IconButton size="small">
                <Avatar
                  alt={fullName(_get(profileData, 'firstName'), _get(profileData, 'lastName'))}
                  sx={{ width: 28, height: 28 }}
                />
              </IconButton>
            }
            content={
              <List disablePadding>
                <ListItem disablePadding disableGutters>
                  <ListItemButton sx={{ display: 'block' }}>
                    {_get(profileData, 'email', '') ? (
                      <>
                        <Tooltip
                          title={`${_get(profileData, 'firstName', '')} ${_get(profileData, 'lastName', '')}`}
                          arrow
                        >
                          <Box>
                            <TypographyLineClamp line={1}>{`${_get(profileData, 'firstName', '')} ${_get(
                              profileData,
                              'lastName',
                              ''
                            )}`}</TypographyLineClamp>
                          </Box>
                        </Tooltip>
                        <Tooltip title={_get(profileData, 'email')} arrow>
                          <Box>
                            <TypographyLineClamp line={1} variant="caption" color="textSecondary">
                              {_get(profileData, 'email') || ''}
                            </TypographyLineClamp>
                          </Box>
                        </Tooltip>
                      </>
                    ) : (
                      <Skeleton animation="wave" variant="text" width="100%" />
                    )}
                  </ListItemButton>
                </ListItem>

                <Divider sx={{ width: '90%', margin: '0 auto' }} />
                <ListItem disablePadding disableGutters component={Link} to={myAccountPersonalInformationPageURL}>
                  <ListItemButton>
                    <Typography variant="subtitle2">My Account</Typography>
                  </ListItemButton>
                </ListItem>

                <Divider sx={{ width: '90%', margin: '0 auto' }} />
                <ListItem disablePadding disableGutters>
                  <ListItemButton onClick={handleLogout}>
                    <Typography variant="subtitle2">Sign out</Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            }
            propsPaper={{ sx: { width: '220px' } }}
            propsPopper={{
              sx: { zIndex: 1202 }
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default React.memo(RightMenu)
