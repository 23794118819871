import { systemManagementPageURL } from '../systemManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const systemProductsPath = 'products'
export const systemProductIdPath = ':productId'
export const systemProductNewPath = 'new'
export const detailSystemProductPath = 'detail'
export const editSystemProductPath = 'edit'
export const tabSystemProductPath = ':tabPath'
export const tabSystemProductHistoryPath = 'history'

/** ----------------- ROUTE -------------------- */
export const systemManagementProductListPageURL = `${systemManagementPageURL}/${systemProductsPath}`
export const systemManagementProductNewPageURL = `${systemManagementProductListPageURL}/${systemProductNewPath}`
export const systemManagementProductDetailFlexPageURL = `${systemManagementProductListPageURL}/${systemProductIdPath}/${tabSystemProductPath}`
export const systemManagementProductHistoryPageURL = `${systemManagementProductListPageURL}/${systemProductIdPath}/${tabSystemProductHistoryPath}`
