import OrganizationRoleDetail from 'myopswat-admin/src/pages/UserManagementPage/Role/RoleDetail'
import { Helmet } from 'react-helmet'

const OrganizationRoleDetailContainer = () => {
  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'Role Detail'}
        </title>
      </Helmet>
      <OrganizationRoleDetail />
    </>
  )
}

export default OrganizationRoleDetailContainer
