import * as yup from 'yup'

export const NPS_VALIDATION_SCHEMA = yup.object().shape({
  surveyPeriod: yup.lazy(value =>
    value === ''
      ? yup
          .string()
          .test(
            'noEOrSign',
            'Single input field with number only. The input range is 0 to 10000.',
            (value: any) => typeof value === 'number' && !/[eE+-]/.test(value.toString())
          )
          .required('Survey Period is required')
      : yup
          .number()
          .integer('Single input field with number only. The input range is 0 to 10000.')
          .required('Survey Period is required')
          .min(0, 'Single input field with number only. The input range is 0 to 10000.')
          .max(10_000, 'Single input field with number only. The input range is 0 to 10000.')
  ),
  skipPeriod: yup.lazy(value =>
    value === ''
      ? yup
          .string()
          .test(
            'noEOrSign',
            'Single input field with number only. The input range is 0 to 365.',
            (value: any) => typeof value === 'number' && !/[eE+-]/.test(value.toString())
          )
          .required('Skip period is required')
      : yup
          .number()
          .required('Skip period is required')
          .integer('Single input field with number only. The input range is 0 to 365.')
          .min(0, 'Single input field with number only. The input range is 0 to 365.')
          .max(365, 'Single input field with number only. The input range is 0 to 365.')
          .test(
            'is-less',
            'The number cannot be greater than or equal to the NPS Survey Period. Please enter a valid value.',
            function (value, context) {
              const { surveyPeriod } = context.parent
              return value < surveyPeriod || surveyPeriod === 0
            }
          )
  )
})
