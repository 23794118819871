import { useCallback, useEffect } from 'react'
import { Control, Controller, useForm, UseFormSetValue, useWatch } from 'react-hook-form'

import _get from 'lodash/get'
import _map from 'lodash/map'
import _omit from 'lodash/omit'

import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import {
  AutocompleteMultiple,
  Button,
  CheckboxWithLabel,
  DropdownFilter,
  Grid,
  TextFieldSearch,
  Typography
} from '@opswat/react-ui'

import { useLazyEmailSubscriptionProductsQuery } from 'myopswat-admin/src/api/product'
import {
  RELEASE_INFORMATION_LEVEL,
  RELEASE_INFORMATION_LEVEL_LABELS,
  RELEASE_INFORMATION_STATUS,
  RELEASE_INFORMATION_STATUS_LABELS
} from 'myopswat-admin/src/api/product/types'

interface IProps {
  control: Control<any>
  setValue: UseFormSetValue<any>
  handleQuery: (name?: string, value?: any) => void
}

const ReleaseInformationSearchFilter = ({ control, setValue, handleQuery }: IProps) => {
  const [loadProducts, { data: productOptions }] = useLazyEmailSubscriptionProductsQuery()

  const currentValues = useWatch({ control, name: 'filters' })

  const {
    control: controlFilter,
    watch: watchFilter,
    reset: resetFilter,
    setValue: setValueFilter
  } = useForm<any>({
    defaultValues: {
      statuses: [],
      products: [],
      productIds: [],
      releaseLevels: []
    }
  })

  const renderTextFieldSearch = () => {
    return (
      <Controller
        name={`filters.q`}
        control={control}
        render={({ field: { value } }) => (
          <TextFieldSearch
            placeholder={'Search for product name, feature header, feature description...'}
            value={value}
            onChange={e => handleQuery('q', e.target.value)}
            onClearText={() => handleQuery('q', '')}
            sx={{
              minHeight: 'auto',
              '& .MuiInput-underline:before': { borderBottomColor: '#B7C3DC' }
            }}
          />
        )}
      />
    )
  }

  const renderCheckboxFilter = 
    (name: string, label: string, enumType: any, enumLabels: any) => {
      return (
        <Grid item container xs={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{label}</Typography>
          </Grid>
          {_map(Object.values(enumType), (item: any, idx: any) => {
            return (
              <Grid item xs={12} spacing={2} key={idx.toString()}>
                <Controller
                  name={name}
                  control={controlFilter}
                  render={({ field: { value, onChange } }: any) => (
                    <CheckboxWithLabel
                      label={
                        <Typography variant="subtitle2" textTransform="capitalize">
                          {enumLabels[item] || '--'}
                        </Typography>
                      }
                      checkboxProps={{
                        checked: watchFilter(name).includes(item),
                        onChange: (e: any) => {
                          if (e.target.checked) {
                            onChange([...value, item])
                          } else {
                            onChange(value.filter((status: any) => status !== item))
                          }
                        },
                        sx: {
                          marginLeft: '12px'
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            )
          })}
        </Grid>
      )
    }

  const renderProductFilter = () => {
    return (
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Products</Typography>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="products"
            control={controlFilter}
            render={({ field }) => (
              <AutocompleteMultiple
                placeholder="Select products"
                options={
                  productOptions?.map((option: any) => ({
                    label: option.name,
                    value: option.id
                  })) ?? []
                }
                limitTags={2}
                sx={{ minWidth: 'initial' }}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    )
  }

  const handleApplyFilter =  () => {
    const filters = {
      ...watchFilter(),
      productIds: watchFilter('products').map((item: any) => item.value),
      q: currentValues.q
    }
    setValue('filters', _omit(filters, ['products']))
    handleQuery()
  }

  useEffect(() => {
    loadProducts()
  }, [])

  useEffect(() => {
    Object.entries(currentValues).forEach(([key, value]) => {
      setValueFilter(key, value)
    })
    setValueFilter(
      'products',
      productOptions
        ?.filter((item: any) => _get(currentValues, 'productIds', []).includes(item.id))
        ?.map((option: any) => ({
          label: option.name,
          value: option.id
        })) ?? []
    )
  }, [currentValues, productOptions])

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        marginBottom: 3
      }}
    >
      <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {renderTextFieldSearch()}
      </Grid>
      <Grid item xs={12} sm={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        <DropdownFilter
          button={
            <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={1} alignItems="flex-start">
              {renderCheckboxFilter(
                'statuses',
                'Status',
                RELEASE_INFORMATION_STATUS,
                RELEASE_INFORMATION_STATUS_LABELS
              )}
              {renderCheckboxFilter(
                'releaseLevels',
                'Release Level',
                RELEASE_INFORMATION_LEVEL,
                RELEASE_INFORMATION_LEVEL_LABELS
              )}
              {renderProductFilter()}
            </Grid>
          }
          onResetFilter={() => {
            resetFilter()
          }}
          onResetChange={() => {
            resetFilter()
          }}
          onApply={handleApplyFilter}
        />
      </Grid>
    </Grid>
  )
}

export default ReleaseInformationSearchFilter
