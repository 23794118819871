import _get from 'lodash/get'

import { CSSProperties, useCallback, useMemo } from 'react'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { TableAdvanced, TemplateSection, TypographyLineClamp, TypographyLinkClamp } from '@opswat/react-ui'

import { useLazyAdminOrganizationCustomersQuery } from 'myopswat-admin/src/api/organization'
import { PAGE_SIZES_DEFAULT } from 'myopswat-admin/src/constants'
import useSearch from 'myopswat-admin/src/hooks/useSearch'
import { customerManagementOrganizationListPageURL, detailCustomerUserPath } from 'myopswat-admin/src/routes'

import OrganizationCustomersSearchFilter from './searchFilter'

interface OrganizationCustomersProps {
  orgId: string
}

const OrganizationCustomers = ({ orgId }: OrganizationCustomersProps) => {
  const { control, isFetching, results, pageInfo, totalCount, setValue, handleQuery, handlePagination, ...searchData } =
    useSearch<any>({
      searchCallback: useLazyAdminOrganizationCustomersQuery,
      defaultValues: {
        pageInfo: {
          page: 0,
          pageSize: 10
        },
        filters: {
          q: '',
          relationship: [],
          orgId
        }
      }
    })

  const renderNameCell = useCallback((data: any) => {
    return (
      <TypographyLinkClamp
        line={1}
        href={`${customerManagementOrganizationListPageURL}/${data.id}/${detailCustomerUserPath}`}
        typographyProps={{
          tooltipValue: data.name,
          tooltipPlacement: 'top',
          tooltipProps: {
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }
        }}
      >
        {data.name || '--'}
      </TypographyLinkClamp>
    )
  }, [])

  const renderValueCell = useCallback((value: any, sx?: CSSProperties) => {
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = _get(data, key)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE_TIME)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderUpdatedAt = useCallback((data: any) => {
    const latestEventLogAt = _get(data, 'latestEventLogAt')
    if (!latestEventLogAt) return renderDateCell(data, 'updatedAt')

    return renderDateCell(data, 'latestEventLogAt')
  }, [])

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        body: renderNameCell,
        style: { minWidth: '18vw', width: '18vw' }
      },
      {
        header: 'Account ID / Opportunity ID',
        body: (data: any) =>
          renderValueCell([_get(data, 'accountId') || '--', _get(data, 'opportunityId') || '--'].join(' / ')),
        style: { minWidth: '15vw', width: '15vw' }
      },
      {
        header: 'SSO ID',
        body: (data: any) => renderCell(data, 'ssoId'),
        style: { minWidth: '15vw', width: '15vw' }
      },
      {
        header: 'Customer Relationship',
        body: (data: any) =>
          renderValueCell(_get(data, 'customerTypes', []).join(', '), { textTransform: 'capitalize' }),
        style: { minWidth: '10vw', width: '10vw' }
      },
      {
        header: 'Last Updated By',
        body: (data: any) => renderCell(data, 'latestEventLogBy'),
        style: { minWidth: '12vw', width: '12vw' }
      },
      {
        header: 'Last Updated',
        body: renderUpdatedAt,
        style: { minWidth: '10vw', width: '10vw' }
      },
      {
        header: '',
        body: () => <></>,
        style: { minWidth: '0px', width: 'auto' }
      }
    ]
  }, [])

  const FilterMemo = useMemo(
    () => <OrganizationCustomersSearchFilter control={control} setValue={setValue} handleQuery={handleQuery} />,
    [control, setValue, handleQuery]
  )

  const TableMemo = useMemo(
    () => (
      <TableAdvanced
        isPagination
        columns={columns}
        isLoading={isFetching}
        data={results}
        total={totalCount}
        page={pageInfo.page}
        pageSize={pageInfo.pageSize}
        pageSizes={PAGE_SIZES_DEFAULT}
        onPageChange={handlePagination}
        {...searchData}
      />
    ),
    [columns, searchData, isFetching, results, totalCount, pageInfo, handlePagination]
  )

  return (
    <TemplateSection>
      {FilterMemo}
      {TableMemo}
    </TemplateSection>
  )
}
export default OrganizationCustomers
