import React from 'react'

import Divider from '@mui/material/Divider'
import { Card, Grid } from '@opswat/react-ui'

import { ApexOptions } from 'apexcharts'
import Chart from 'react-apexcharts'

interface IForm {
  timeRange: string
  viewBy: string
}

interface IProps {
  header?: React.ReactNode
  title?: string | React.ReactNode
  filter?: React.ReactNode
  onFilter?: (data: IForm) => void
  categories: any
  type:
    | 'line'
    | 'area'
    | 'bar'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'candlestick'
    | 'boxPlot'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | 'rangeArea'
    | 'treemap'
  series: ApexOptions['series']
  width?: string | number
  height?: string | number
  options?: ApexOptions
  [key: string]: any
}

const ChartWidget = ({ header, title, categories, options, ...props }: IProps) => {
  return (
    <Card variant="outlined">
      <Grid container>{header && <>{header}</>}</Grid>
      <Divider variant="fullWidth" sx={{ padding: 0 }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {title}
        </Grid>
        <Grid item xs={12}>
          <Chart
            style={{
              width: '100%',
              height: 'auto'
            }}
            options={{
              chart: {
                toolbar: {
                  show: true
                },
                zoom: {
                  enabled: false
                }
              },
              plotOptions: {
                bar: {
                  columnWidth: '30%',
                  horizontal: false,
                  dataLabels: {
                    position: 'top' // top, center, bottom
                  }
                }
              },
              dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                  colors: ['#304758']
                }
              },
              stroke: {
                width: [0, 4]
              },
              xaxis: {
                categories
              },
              legend: {
                show: true
              },
              ...options
            }}
            {...props}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default ChartWidget
