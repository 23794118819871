import { useContext } from 'react'

import { useWatch } from 'react-hook-form'

import { ButtonLoading, Grid, TemplateSection } from '@opswat/react-ui'

import { ComposeEmailActions, ComposeEmailForm, ComposeEmailPreview } from './components'
import { ComposeEmailContext } from './interface'

const ComposeEmailPage = () => {
  const { control, viewMode, handleToggleEdit } = useContext(ComposeEmailContext)

  const deliveryStatus = useWatch({ control, name: 'deliveryStatus' })
  const isEditingDraftOrPending = ['Draft', 'Pending'].includes(deliveryStatus) && viewMode === 'edit'
  const isViewingDraftOrPending = ['Draft', 'Pending'].includes(deliveryStatus) && viewMode === 'view'

  return (
    <TemplateSection>
      <Grid container rowSpacing={1.5} sx={{ margin: 0 }}>
        {isViewingDraftOrPending && (
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', position: 'sticky', bottom: 0, paddingBottom: 2 }}
          >
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
                onClick: handleToggleEdit
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Edit
            </ButtonLoading>
          </Grid>
        )}

        <Grid
          container
          item
          xs={12}
          columnSpacing={6}
          sx={{ paddingTop: `${isViewingDraftOrPending ? 0 : 32}px !important`, paddingBottom: '32px' }}
        >
          <Grid item xs={12} md={6}>
            <ComposeEmailForm />
          </Grid>

          <Grid item xs={12} md={6}>
            <ComposeEmailPreview />
          </Grid>
        </Grid>

        {(isEditingDraftOrPending || viewMode === 'create') && <ComposeEmailActions />}
      </Grid>
    </TemplateSection>
  )
}

export default ComposeEmailPage
