import React, { FC } from 'react'

import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'

import { myAccountPersonalInformationPageURL } from 'myopswat-admin/src/routes'

import General from './General'

const MyInformationPage: FC<unknown> = () => {
  const tabArray = [
    {
      label: 'User Detail',
      content: <General />,
      tabPath: myAccountPersonalInformationPageURL
    }
  ]

  return (
    <>
      <TypographyDivider label="My Account" />
      <TabAdvanced tabs={tabArray} />
    </>
  )
}

export default MyInformationPage
