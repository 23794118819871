import { FC, useMemo } from 'react'

import _get from 'lodash/get'

import _format from 'date-fns/format'

import { Alert, Autocomplete, Box, ButtonLoading, Grid, TextField, Typography } from '@opswat/react-ui'

import { yupResolver } from '@hookform/resolvers/yup'
import { EntrySyncUpInput } from 'myopswat-admin/src/api/entry/types'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

interface IProps {
  handleEntrySyncUp: (data: EntrySyncUpInput) => void
  isLoadingEntrySyncUp: boolean
}

interface IEntryType {
  code: string
  name: string
}

interface IForm {
  entryType: IEntryType
  startDate: string
  endDate: string
}

const SyncBlogPage: FC<IProps> = ({ handleEntrySyncUp, isLoadingEntrySyncUp }) => {
  const blogTypes = [
    {
      name: 'OPSWAT Blog',
      code: 'instaBlog'
    },
    {
      name: 'Product Update',
      code: 'pressRelease'
    },
    {
      name: 'All',
      code: 'all'
    }
  ]
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isSyncBlogDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return ['view_entry', 'change_entry', 'delete_entry'].some(item => !profileData.permissions.includes(item))
    }
    return false
  }, [profileData])

  const defaultValues = {
    entryType: {
      name: 'All',
      code: 'all'
    },
    startDate: '',
    endDate: _format(new Date(), 'yyyy-MM-dd')
  }

  const schema = yup.object().shape({
    entryType: yup.object().shape({
      name: yup.string().trim().required('Entry Type cannot be blank'),
      code: yup.string().trim().required('Entry Type cannot be blank')
    }),
    startDate: yup.string().trim().required('Start Date cannot be blank')
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues
  })

  const onSuccess = (data: any) => {
    const payload = {
      entryType: _get(data, 'entryType.code', ''),
      startDate: _get(data, 'startDate', '')
    }
    handleEntrySyncUp(payload)
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  return (
    <>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1">Blog Type*</Typography>

            <Controller
              name="entryType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disableClearable
                  options={blogTypes}
                  value={value}
                  getOptionLabel={(option: any) => _get(option, 'name', '')}
                  onChange={(event: any, newValue: any) => {
                    onChange(newValue)
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      error={!!_get(errors, 'entryType', '')}
                      helperText={_get(errors, 'entryType.name.message', '')}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1">Start Date</Typography>
            <TextField
              {...register('startDate')}
              placeholder="Start Date"
              size="small"
              fullWidth
              error={!!_get(errors, 'startDate.message', '')}
              helperText={_get(errors, 'startDate.message', '')}
              required
              type="date"
              inputProps={{
                max: watch('endDate')
              }}
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
          <Typography variant="subtitle1">To Day</Typography>
          <TextField
            {...register('endDate')}
            placeholder="First Name"
            size="small"
            fullWidth
            required
            type="date"
            disabled
          />
        </Grid> */}

          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSuccess, onFail),
                disabled: isSyncBlogDisabled,
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Sync Blog
            </ButtonLoading>
          </Grid>
        </Grid>
      </Box>

      {isLoadingEntrySyncUp && (
        <Box>
          <Alert
            variant="filled"
            severity="info"
            sx={{
              backgroundColor: '#42a5f5'
            }}
          >
            Synchronizing...
          </Alert>
        </Box>
      )}
    </>
  )
}

export default SyncBlogPage
