import { Box, Typography } from '@opswat/react-ui'

export const CheckboxContent = ({ title, description }: { title: string; description: string }) => {
  return (
    <>
      <Typography variant="body2">{title}</Typography>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {description}
        </Typography>
      </Box>
    </>
  )
}
