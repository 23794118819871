import { useEffect, useMemo } from 'react'
import {
  useLazyAdminRoleObjectDetailQuery,
  useLazyAdminRoleObjectsComponentQuery
} from '../../../../api/roleObjectsManagement'
import { Helmet } from 'react-helmet'
import { TypographyDivider } from '@opswat/react-ui'
import RoleObjectsDetailLoading from './RoleObjectsDetailLoading'
import RoleObjectsDetailEdit from './RoleObjectsDetailEdit'
import { customerManagementRoleObjectsListPageURL } from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'

interface IProps {
  roleObjectId: string
}
const RoleObjectsDetailPage = ({ roleObjectId }: IProps) => {
  const listPermission = [
    {
      id: 1,
      name: 'None'
    },
    {
      id: 2,
      name: 'Read Only'
    },
    {
      id: 3,
      name: 'Full Access'
    }
  ]

  const [getRoleObjectComponents, { data: roleObjectComponentsData }] = useLazyAdminRoleObjectsComponentQuery()

  const [getRoleObject, { data, isFetching }] = useLazyAdminRoleObjectDetailQuery()

  const roleObject = useMemo(() => {
    return data || {}
  }, [data])

  const roleObjectComponents = useMemo(() => {
    return roleObjectComponentsData || []
  }, [roleObjectComponentsData])

  const isRender = useMemo(() => {
    return roleObject && !isFetching && roleObjectComponents
  }, [roleObject, isFetching])

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Role Objects',
        path: customerManagementRoleObjectsListPageURL
      },
      {
        label: roleObject?.name || '--'
      }
    ]
  }, [roleObject])

  useEffect(() => {
    if (roleObjectId && !data) {
      getRoleObject(roleObjectId)
    }
  }, [roleObjectId])

  useEffect(() => {
    getRoleObjectComponents()
  }, [getRoleObjectComponents])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          Role object Detail
        </title>
      </Helmet>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && <RoleObjectsDetailLoading />}

      {isRender && (
        <RoleObjectsDetailEdit
          roleObject={roleObject}
          roleObjectComponents={roleObjectComponents}
          listPermission={listPermission}
        />
      )}
    </>
  )
}
export default RoleObjectsDetailPage
