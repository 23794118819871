import { AdminRoleObjectFilters } from '../../../../api/roleObjectsManagement/types'
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useMemo } from 'react'
import _debounce from 'lodash/debounce'
import {
  Button,
  CheckboxWithLabel,
  DropdownFilter,
  Grid,
  TextFieldSearch,
  Typography
} from '@opswat/react-ui'
import _map from 'lodash/map'
import { FilterIcon, SolidDownIcon } from '@opswat/react-icon'
import { customerManagementRoleObjectsNewPageURL } from '../../../../routes/customerManagementRoutes/roleObjectsRoutes'
import { useNavigate } from 'react-router-dom'
import _get from 'lodash/get'

interface FilterSectionProps {
  listPermission: any
  roleObjectComponents: any
  filters: AdminRoleObjectFilters
  onSearch: (searchData: AdminRoleObjectFilters) => void
  userPermissions: any
}
const RoleObjectsFiltersSection = ({ filters, onSearch, listPermission, roleObjectComponents, userPermissions }: FilterSectionProps) => {
  const navigate = useNavigate()
  const defaultFilter = {
    q: '',
    componentId: [],
    permissions: []
  }

  const {
    control,
    reset,
    getValues,
    watch,
    formState: { dirtyFields }
  } = useForm<AdminRoleObjectFilters>({
    mode: 'onChange',
    defaultValues: defaultFilter,
    values: filters
  })

  const isChangedTextSearch = useMemo(() => {
    return dirtyFields.q
  }, [dirtyFields.q])

  const q = watch('q')
  const componentId = watch('componentId')
  const permissions = watch('permissions')

  const handleSearch = useCallback(() => {
    const searchData = {
      ...getValues(),
      componentId: componentId,
      permissions: permissions,
      q: q
    }
    onSearch(searchData)
  }, [q, componentId, permissions])

  useEffect(() => {
    if (isChangedTextSearch) {
      const _search = _debounce(handleSearch, 300)

      _search()

      return () => {
        _search.cancel()
      }
    }
  }, [isChangedTextSearch, handleSearch])

  const handleApplyFilter = useCallback(() => {
    handleSearch()
  }, [handleSearch])

  const renderComponentCheckbox = useCallback((item: any, idx: number) => {
    return (
      <Grid item xs={6} key={idx.toString()} py={0.5}>
        <Controller
          name="componentId"
          control={control}
          render={({ field }: any) => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.name}</Typography>}
              checkboxProps={{
                checked: field.value.includes(item.id),
                onChange: (e: any) => {
                  field.onChange(
                    e.target.checked ? [...field.value, item.id] : field.value.filter((i: any) => i !== item.id)
                  )
                }
              }}
              restProps={{
                sx: {
                  marginLeft: '-6px'
                }
              }}
            />
          )}
        />
      </Grid>
    )
  }, [control, watch])

  const renderPermissionCheckbox = useCallback((item: any, idx: number) => {
    return (
      <Grid item xs={12} key={idx.toString()} py={0.5}>
        <Controller
          name="permissions"
          control={control}
          render={({ field }: any) => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.name}</Typography>}
              checkboxProps={{
                checked: field.value.includes(item.id),
                onChange: (e: any) => {
                  field.onChange(
                    e.target.checked ? [...field.value, item.id] : field.value.filter((i: any) => i !== item.id)
                  )
                }
              }}
              restProps={{
                sx: {
                  marginLeft: '-6px'
                }
              }}
            />
          )}
        />
      </Grid>
    )
  }, [control, watch])

  return (
    <Grid container justifyContent="space-between" py={3.5}>
      <Grid item xs={12} sm={4}>
        <Controller
          name="q"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextFieldSearch
              placeholder={'Search by code, name'}
              value={value}
              onChange={e => onChange(e.target.value)}
              onClearText={() => {
                onChange('')
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs="auto">
        <Grid container>
          <Grid item xs="auto">
            <DropdownFilter
              button={
                <Button color="inherit" variant="text" startIcon={<FilterIcon />} endIcon={<SolidDownIcon />}>
                  Filter
                </Button>
              }
              content={
                <Grid item container xs={12} spacing={2} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">My OPSWAT Component</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {_map(roleObjectComponents, (item: any, idx: any) => renderComponentCheckbox(item, idx))}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Permissions</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {_map(listPermission, (item: any, idx: any) => renderPermissionCheckbox(item, idx))}
                  </Grid>
                </Grid>
              }
              onResetFilter={() => {
                reset({ ...defaultFilter, q})
              }}
              onResetChange={() => {
                reset({ ...filters, q})
              }}
              onApply={handleApplyFilter}
            />
          </Grid>
          {
            _get(userPermissions, 'add_role_object', false) ? (
              <Grid item xs="auto">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(customerManagementRoleObjectsNewPageURL)
                  }}
                  disabled={false}
                >
                  New Role Object
                </Button>
              </Grid>
            ) : (
              <></>
            )
          }
        </Grid>
      </Grid>
    </Grid>
  )
}
export default RoleObjectsFiltersSection
