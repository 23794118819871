import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import {
  Box,
  Button,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider
} from '@opswat/react-ui'
import _get from 'lodash/get'
import { useLazyIntegratedAppListQuery } from 'myopswat-admin/src/api/integrated-app'
import { EMPTY_STRING } from 'myopswat-admin/src/constants'
import {
  integratedAppIdTokenListPath,
  integratedAppListPath,
  integrationManagementNewIntegratedAppURL,
  integrationManagementPageURL
} from 'myopswat-admin/src/routes'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import IntegratedAppListFilter from './IntegratedAppListFilter'
import { useTypedSelector } from 'myopswat-admin/src/store'

const IntegratedAppListPage = () => {
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const navigate = useNavigate()
  const [getIntegratedApps, { data: integratedAppsData, isFetching: isFetchingIntegratedApps }] =
    useLazyIntegratedAppListQuery()

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: any) => <Typography variant="body2">{_get(data, 'name', EMPTY_STRING)}</Typography>,
        style: { minWidth: 300, width: 300 }
      },
      {
        header: 'Code',
        body: (data: any) => <Typography variant="body2">{_get(data, 'code', EMPTY_STRING)}</Typography>,
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Updated At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data?.updatedAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Created At',
        body: (data: any) => (
          <Typography variant="body2">
            {formatDatetime(data?.createdAt, DATE_FORMATS.DATE_TIME, profileData?.timezone)}
          </Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: any) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(
                        `${integrationManagementPageURL}/${integratedAppListPath}/${data?.id}/${integratedAppIdTokenListPath}`
                      )
                    }}
                  >
                    View Tokens
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  return (
    <TemplateSection>
      <Box>
        <TypographyDivider label="Internal Applications" />
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={true}
          variant="contained"
          color="primary"
          onClick={() => {
            navigate(integrationManagementNewIntegratedAppURL)
          }}
        >
          New Application
        </Button>
      </Box>
      <IntegratedAppListFilter getIntegratedApps={getIntegratedApps} />
      <TableAdvanced columns={columnArray} isLoading={isFetchingIntegratedApps} data={integratedAppsData} />
    </TemplateSection>
  )
}

export default IntegratedAppListPage
