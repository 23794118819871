import { Autocomplete, Box, OpswatCheckbox, TextField, Tooltip } from '@opswat/react-ui'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { MDC_LICENSE_TYPE_OPTIONS, ORGANIZATION_FORM_LABELS } from 'myopswat-admin/src/constants/organizations'
import { Controller, UseFormReturn } from 'react-hook-form'
import { AdminOrganizationCreateForm } from './schema'

interface OrganizationFormInputProps {
  formRef: UseFormReturn<AdminOrganizationCreateForm>
}

const OrganizationCreateForm = ({ formRef }: OrganizationFormInputProps) => {
  const {
    control,
    register,
    getValues,
    formState: { errors }
  } = formRef

  const rankValue = Number(getValues('rank')) || 1

  return (
    <Box sx={{ mt: 1 }}>
      <GridView
        label={`${ORGANIZATION_FORM_LABELS.name}*`}
        value={
          <TextField
            fullWidth
            size="small"
            error={'name' in errors}
            {...register('name')}
            helperText={errors.name?.message}
          />
        }
      />
      <GridView
        label={`${ORGANIZATION_FORM_LABELS.accountId}`}
        value={
          <Tooltip
            title="Only rank 1 can have Account Id"
            arrow
            placement="top"
            disableFocusListener={rankValue === 1}
            disableHoverListener={rankValue === 1}
          >
            <Box>
              <TextField
                disabled={rankValue !== 1}
                variant={rankValue !== 1 ? 'filled' : 'outlined'}
                fullWidth
                error={'accountId' in errors}
                {...register('accountId')}
                size="small"
                helperText={errors.accountId?.message}
              />
            </Box>
          </Tooltip>
        }
      />
      <GridView
        label={`${ORGANIZATION_FORM_LABELS.opportunityId}`}
        value={
          <Tooltip
            title="Only rank 2 can have Opportunity Id"
            arrow
            placement="top"
            disableFocusListener={rankValue === 2}
            disableHoverListener={rankValue === 2}
          >
            <Box>
              <TextField
                disabled={rankValue !== 2}
                variant={rankValue !== 2 ? 'filled' : 'outlined'}
                fullWidth
                error={'opportunityId' in errors}
                {...register('opportunityId')}
                size="small"
                helperText={errors.opportunityId?.message}
              />
            </Box>
          </Tooltip>
        }
      />
      <GridView
        label={ORGANIZATION_FORM_LABELS.mdcLicenseType}
        value={
          <Controller
            name="mdcLicenseType"
            control={control}
            render={({ field }) => (
              <Autocomplete
                options={Object.keys(MDC_LICENSE_TYPE_OPTIONS)}
                getOptionLabel={(option: any) => {
                  return MDC_LICENSE_TYPE_OPTIONS[option]
                }}
                value={field.value}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                renderInput={(params: any) => <TextField {...params} size="small" variant="outlined" />}
              />
            )}
          />
        }
      />
      <GridView
        label={ORGANIZATION_FORM_LABELS.isOemCustomer}
        value={
          <Controller
            name={'isOemCustomer'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={field.value}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={ORGANIZATION_FORM_LABELS.showKioskSecureImage}
        value={
          <Controller
            name={'showKioskSecureImage'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={field.value}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={ORGANIZATION_FORM_LABELS.showEnginePackage}
        value={
          <Controller
            name={'showEnginePackage'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={field.value}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={ORGANIZATION_FORM_LABELS.isManageSubOrganization}
        value={
          <Controller
            name={'isManageSubOrganization'}
            control={control}
            render={({ field }) => (
              <OpswatCheckbox
                label=""
                checked={field.value}
                onChange={(event: any) => {
                  field.onChange(event.target.checked)
                }}
              />
            )}
          />
        }
      />
      <GridView
        label={`${ORGANIZATION_FORM_LABELS.note}`}
        value={
          <TextField
            fullWidth
            multiline
            rows={6}
            size="small"
            error={'note' in errors}
            {...register('note')}
            helperText={errors.note?.message}
          />
        }
      />
    </Box>
  )
}

export default OrganizationCreateForm
