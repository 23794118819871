import React, { FC } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { Typography, Skeleton, Grid, TemplateSection, TextGrid } from '@opswat/react-ui'

interface IProps {
  children?: React.ReactNode
}

const GeneralInfoLoading: FC<IProps> = () => {
  const leftInfoArray = [
    {
      label: 'First Name*'
    },
    {
      label: 'Last Name*'
    },
    {
      label: 'Title*'
    },
    {
      label: 'Phone Number*'
    },
    {
      label: 'Time Zone*'
    }
  ]

  const rightInfoArray = [
    {
      label: 'Address1*'
    },
    {
      label: 'Address2'
    },
    {
      label: 'City*'
    },
    {
      label: 'State'
    },
    {
      label: 'Country*'
    },
    {
      label: 'Postal Code*'
    }
  ]

  return (
    <TemplateSection>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            <TextGrid
              label={<Typography variant="subtitle2">Email</Typography>}
              value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
            />

            {_map(leftInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TemplateSection>
            {_map(rightInfoArray, (item, idx) => (
              <React.Fragment key={idx.toString()}>
                <TextGrid
                  label={<Typography variant="subtitle2">{_get(item, 'label')}</Typography>}
                  value={<Skeleton animation="wave" variant="rectangular" width="100%" height="35px" />}
                />
              </React.Fragment>
            ))}
          </TemplateSection>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <TextGrid
            label={<Typography variant="subtitle2">Note</Typography>}
            value={<Skeleton animation="wave" variant="rectangular" width="100%" height="100px" />}
            containerProps={{ alignItems: 'flex-start' }}
          />
        </Grid>
      </Grid>
    </TemplateSection>
  )
}

export default GeneralInfoLoading
