/** ----------------- PATHNAME -------------------- */
export const organizationListPath = 'organizations'
export const generalInformationPath = 'detail'
export const groupsPath = 'groups'
export const usersPath = 'users'
export const rolesPath = 'application-roles'
export const organizationSecurityPath = 'security'
export const eventHistoryPath = 'event-history'

/** ----------------- ROUTE -------------------- */
export const myOrganizationPageURL = '/my-organization'
export const myOrganizationFlexPageURL = `/${myOrganizationPageURL}/:tabPath`
export const myOrganizationListPageURL = `${myOrganizationPageURL}/${organizationListPath}`
export const myOrganizationGeneralInfoPageURL = `${myOrganizationPageURL}/${generalInformationPath}`
export const myOrganizationGroupPageURL = `${myOrganizationPageURL}/${groupsPath}`
export const myOrganizationUsersPageURL = `${myOrganizationPageURL}/${usersPath}`
export const myOrganizationRolesPageURL = `${myOrganizationPageURL}/${rolesPath}`
export const myOrganizationSecurityPageURL = `${myOrganizationPageURL}/${organizationSecurityPath}`
export const myOrganizationEventHistoryPageURL = `${myOrganizationPageURL}/${eventHistoryPath}`
