export const MDC_LICENSE_TYPE_OPTIONS: Record<string, string> = {
  'non-license': 'No License',
  professional: 'Professional',
  commercial: 'Commercial',
  enterprise: 'Enterprise'
}

export const ORGANIZATION_FORM_LABELS = {
  id: 'Id',
  name: 'Name',
  rank: 'Rank',
  ssoId: 'SSO Id',
  accountId: 'Account Id',
  opportunityId: 'Opportunity Id',
  mdcLicenseType: 'MD Cloud License Type',
  isOemCustomer: 'Is OEM Customer',
  showKioskSecureImage: 'IS Kiosk Secure Image',
  showEnginePackage: 'Can Access V4 Definition Updates',
  isManageSubOrganization: 'Manage Sub organizations',
  sfdcAccountType: 'SFDC Account Type',
  sfdcPreferredPartner: 'SFDC Preferred Partner',
  sfdcDistributors: 'SFDC Distributor',
  sfdcResellers: 'SFDC Reseller',
  note: 'Note',
  updatedReason: 'Updated Reason',
  updatedAt: 'Updated At (UTC)',
  createdAt: 'Created At (UTC)'
}
