import { Helmet } from 'react-helmet'

import { TypographyDivider } from '@opswat/react-ui'

import useCheckPermissions from 'myopswat-admin/src/hooks/useCheckPermissions'
import PortalSettingsProvider from 'myopswat-admin/src/pages/SystemManagementPage/SettingsPage/context'

const SettingsContainer = () => {
  const { permission } = useCheckPermissions({
    targetPerrmissions: ['add_survey', 'change_survey']
  })

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          {'Portal Settings'}
        </title>
      </Helmet>

      <TypographyDivider isDivider={false} label="Portal Settings" />

      <PortalSettingsProvider permission={permission} />
    </>
  )
}

export default SettingsContainer
