import { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

import { handleCheckLogin, handleForceLogout } from '@myopswat/common'
import { PageLoading } from '@opswat/react-ui'

import { useLazyProfileQuery } from 'myopswat-admin/src/api/account'
import imageBg from 'myopswat-admin/src/assets/images/mfa-bg.png'
import LayoutPage from '../../pages/LayoutPage'

const LayoutContainer: FC<unknown> = () => {
  const { t: translate } = useTranslation()
  const location = useLocation()
  const isToken = handleCheckLogin()

  const { enqueueSnackbar } = useSnackbar()

  const [isRender, setIsRender] = useState<boolean>(false)

  const [getProfile] = useLazyProfileQuery()

  const [profileData, setProfileData] = useState()

  useEffect(() => {
    if (isToken) {
      getProfile()
        ?.unwrap()
        .then(response => {
          setIsRender(true)
          setProfileData(response)
          if (_get(response, 'errors.0.message')) {
            enqueueSnackbar(_get(response, 'errors.0.message', translate('getProfileFail')), {
              variant: 'error'
            })
          }
        })
        .catch(error => {
          console.error(error)
          setIsRender(true)
        })
    } else {
      handleForceLogout()
    }
  }, [isToken])

  if (isRender && _includes(location.pathname, 'mfa')) return <Outlet />
  if (isRender) return <LayoutPage profileData={profileData} />
  return <PageLoading background={imageBg} color="primary" />
}

export default LayoutContainer
