import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import { AppTokenCreateInput, AppTokenInput, IntegratedAppFilterInput } from './types'

export const integratedAppQueries = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    integratedAppList: builder.query<any, IntegratedAppFilterInput>({
      query: filters => ({
        document: gql`
          query ($filters: IntegratedAppFiltersInput) {
            adminIntegratedApps(filters: $filters) {
              id
              code
              name
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    integratedAppDetail: builder.query<any, string>({
      query: id => ({
        document: gql`
          query {
            adminIntegratedApp(id: "${id}") {
              id
              code
              name
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          filters: {}
        }
      })
    }),
    integratedAppTokenList: builder.query<any, AppTokenInput>({
      query: ({ appId }) => ({
        document: gql`
          query {
            appTokens(appId: "${appId}") {
              id
              name
              tokenLast4
              app {
                name
              }
            }
          }
        `,
        variables: {
          filters: {}
        }
      })
    }),
    integratedAppTokenCreate: builder.mutation<any, AppTokenCreateInput>({
      query: input => ({
        document: gql`
          mutation ($input: AppTokenCreateInput!) {
            appTokenCreate(input: $input) {
              success
              errors
              token
            }
          }
        `,
        variables: {
          input: input
        }
      })
    })
  })
})

export const {
  useIntegratedAppListQuery,
  useLazyIntegratedAppListQuery,
  useLazyIntegratedAppDetailQuery,
  useLazyIntegratedAppTokenListQuery,
  useIntegratedAppTokenCreateMutation
} = integratedAppQueries
