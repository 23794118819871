import _isEmpty from 'lodash/isEmpty'
import _join from 'lodash/join'

import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonLoading, Grid } from '@opswat/react-ui'
import { useLazyProductDetailQuery, useProductUpdateMutation } from 'myopswat-admin/src/api/product'
import { detailSystemProductPath, systemManagementProductListPageURL } from 'myopswat-admin/src/routes'
import yup from 'myopswat-admin/src/utils/yup'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ProductForm from '../Form/ProductForm'
import { IProductForm, productSchema } from '../Form/ProductSchema'
import { escape as htmlEscape } from 'html-escaper'

interface IProps {
  product: any
  productMap: any
  asIdsProductMap: any
  solutionMap: any
  supportCasePlatforms: string[]
}

const BoxProductDetailEdit = ({ product, productMap, asIdsProductMap, solutionMap, supportCasePlatforms }: IProps) => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [getProductDetail] = useLazyProductDetailQuery()

  const initValues = useMemo(() => {
    return {
      id: product.id,
      name: product.name,
      slug: product.slug,
      shortName: product.shortName,
      codeName: product.codeName,
      description: product.description,
      documentation: product.documentation,
      note: product.note,
      solutionIds: product.solutionIds,
      parentIds: product.parentIds,
      licensedProductsDisplay: product.licensedProductsDisplay,
      resources: _isEmpty(product.resources) ? '' : JSON.stringify(product.resources),
      type: product.type,
      tags: product.tags,
      licenseTypes: product.licenseTypes,
      downloadable: product.downloadable,
      asIds: _join(product.asIds, ','),
      blogTags: _join(product.blogTags, ','),
      hide: product.hide,
      avatarFile: product.avatar,
      thumbnailFile: product.thumbnail,
      releaseMetadata: _isEmpty(product.releaseMetadata) ? '' : JSON.stringify(product.releaseMetadata),
      rmId: product.rmId,
      showLatestVersion: product.showLatestVersion,
      updatedReason: '',
      iconFile: product.icon,
      supportCasePlatforms: product.supportCasePlatforms
    }
  }, [product])

  const editProductSchema = productSchema.shape({
    updatedReason: yup.string().trim().required()
  })

  const formRef = useForm<IProductForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(editProductSchema)
  })
  const {
    handleSubmit,
    getValues,
    formState: { dirtyFields }
  } = formRef

  const [productUpdate, { isLoading }] = useProductUpdateMutation()

  const onSubmit = async (data: IProductForm) => {
    try {
      const dataUpdated: any = {
        id: product.id
      }

      let isEditRmId = false

      Object.keys(dirtyFields).forEach(field => {
        if (field === 'asIds') {
          dataUpdated['asIds'] = data.asIds ? data.asIds.split(',').map(id => Number(id)) : []
        } else if (field === 'resources') {
          dataUpdated['resources'] = data.resources ? JSON.parse(data.resources) : []
        } else if (field === 'blogTags') {
          dataUpdated['blogTags'] = data.blogTags ? data.blogTags.split(',') : []
        } else if (field === 'releaseMetadata') {
          isEditRmId = true
          dataUpdated['releaseMetadata'] = data.releaseMetadata ? JSON.parse(data.releaseMetadata) : {}
        } else if (field === 'rmId') {
          isEditRmId = true
          dataUpdated[field] = data.rmId ? Number(data.rmId) : null
        } else if (field === 'description') {
          dataUpdated[field] = htmlEscape(data.description ?? '')
        } else if (field === 'documentation') {
          dataUpdated[field] = htmlEscape(data.documentation ?? '')
        } else if (field === 'iconFile' && data?.iconFile == null) {
          dataUpdated['icon'] = ''
        }
        else {
          dataUpdated[field] = data[field as keyof IProductForm]
        }
      })

      // Ensure unique & match Release Management Id with Release metadata "id"
      if (isEditRmId) {
        if (Number(data.rmId) !== JSON.parse(data.releaseMetadata).id) {
          formRef.setError(
            'rmId',
            {
              message: 'Release Management Id must match Release metadata "id"'
            },
            { shouldFocus: true }
          )

          formRef.setError('releaseMetadata', {
            message: 'Release metadata "id" must match Release Management Id'
          })

          return
        }
      }

      const res = await productUpdate(dataUpdated).unwrap()
      if (res.success) {
        await enqueueSnackbar('The product information has updated successfully.', { variant: 'success' })
        await getProductDetail(product?.id)
        navigate(`${systemManagementProductListPageURL}/${product.id}/${detailSystemProductPath}`)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={10} lg={8}>
        <ProductForm
          formRef={formRef}
          productMap={productMap}
          asIdsProductMap={asIdsProductMap}
          thumbnail={getValues('thumbnailFile')!}
          avatar={getValues('avatarFile')!}
          solutionMap={solutionMap}
          isEdit
          icon={getValues('iconFile')}
          supportCasePlatforms={supportCasePlatforms}
        />

        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => {
                  navigate(`${systemManagementProductListPageURL}/${product?.id}/${detailSystemProductPath}`)
                },
                disabled: isLoading
              }}
            >
              Cancel
            </ButtonLoading>
          </Grid>

          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: handleSubmit(onSubmit),
                disabled: isLoading || _isEmpty(dirtyFields)
              }}
              isLoading={isLoading}
            >
              Save
            </ButtonLoading>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BoxProductDetailEdit
