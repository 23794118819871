import { Autocomplete, Box, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { AdminOrganizationAddUserForm } from 'myopswat-admin/src/api/organizationManagement/types'
import { Controller, UseFormReturn } from 'react-hook-form'

interface IProps {
  formRef: UseFormReturn<AdminOrganizationAddUserForm>
  roleMap: any
}

const AddUserForm = ({ formRef, roleMap }: IProps) => {
  const {
    control,
    register,
    formState: { errors }
  } = formRef

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">Email</Typography>
          <TextField
            required
            fullWidth
            size="small"
            {...register(`email`)}
            error={!!errors?.email}
            helperText={errors?.email && errors?.email?.message}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="subtitle1">Role</Typography>
          <Controller
            name={`role`}
            control={control}
            render={({ field }) => (
              <Autocomplete
                disableClearable
                options={Object.keys(roleMap)}
                getOptionLabel={(option: any) => {
                  return _get(roleMap, `${option}.name`, '')
                }}
                value={field.value!}
                onChange={(_event: any, newValue: any) => {
                  field.onChange(newValue)
                }}
                isOptionEqualToValue={(option, value) => option == value}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="outlined"
                    error={!!errors?.role}
                    helperText={errors?.role && errors?.role.message}
                  />
                )}
              />
            )}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default AddUserForm
