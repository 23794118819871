export const PRODUCT_LICENSE_TYPE_OPTIONS: Record<string, string> = {
  FREE: 'Free Product'
}

export const PRODUCT_LICENSE_TYPE_FREE = 'FREE'

export const PRODUCT_TAG_OPTIONS: Record<string, string> = {
  hardware: 'Hardware',
  'on-premise': 'On-premises',
  oem: 'OEM',
  'cloud-based': 'Cloud-based',
  subproduct: 'Sub Product',
  module: 'Module',
  utility: 'Utility',
  'activate-license': 'Activate License',
  undownloadable: 'Undownloadable',
  evaluable: 'Evaluable',
  scannable: 'Scannable',
  'product-release-subscription': 'Product Release Subscription'
}

export enum RESOURCE_TYPE {
  SAMPLE_CODE = 'sample_code',
  VIDEO = 'video'
}

export enum DETAIL_MODE {
  VIEW = 'view',
  EDIT = 'edit'
}

export enum PRODUCT_DETAIL_TAB {
  BASIC = 'basic',
  RELEASE = 'release'
}

export const RESOURCE_SAMPLE = [
  {
    code: 'sample_code',
    displayName: 'Sample Code',
    link: 'https://sample_code.zip'
  },
  {
    code: 'video',
    displayName: 'Video',
    link: 'https://video_link.mp4'
  }
]
export const RELEASE_METADATA = {
  id: 111111,
  codeName: 'code-name',
  platforms: [
    {
      id: 111111,
      name: 'Platform Name',
      displayName: 'Platform Display Name'
    }
  ]
}

export const DESCRIPTION_SAMPLE = `<div><strong>Overview</strong><p class="m-t-sm m-b-none"></p></div>`
export const DOCUMENT_SAMPLE = `<div><strong>Resource</strong><ul class="doc-link"><li><a href="" target="_blank">Documentation</a></li></ul></div>`
export const ASSETS_SAMPLE = [
  {
    name: 'name',
    displayName: '1.0.0',
    platformId: 111111,
    link: 'sample.opswat.com',
    md5: 'md5',
    sha1: 'sha1',
    sha256: 'sha256',
    size: 'size',
    source: 'ADMIN',
    version: '1.0.0',
    displayOnly: false,
    releaseDate: Math.floor(Date.now() / 1000)
  }
]

export const RELEASE_TAGS = ['hide']

export const PRODUCT_FORM_LABEL_MAP = {
  name: 'Name',
  slug: 'Slug',
  shortName: 'Short Name',
  codeName: 'Code Name',
  asIds: 'AS Ids',
  releaseManagementId: 'Release Management Id',
  blogTags: 'Blog Tags',
  thumbnail: 'Thumbnail',
  image: 'Image',
  description: 'Description',
  resources: 'Resources',
  releaseMetadata: 'Release Metadata',
  documentation: 'Documentation',
  type: 'Type',
  tags: 'Tags',
  licenseType: 'License Types',
  solutions: 'Solutions',
  note: 'Note',
  parentProducts: 'Parent Products',
  licensedProductsDisplay: 'Display only with licensed Products',
  downloadable: 'Downloadable',
  hide: 'Hide',
  showLatestVersion: 'Show Latest Version',
  updatedReason: 'Updated Reason',
  icon: 'Product Icon',
  supportCasePlatforms: 'Platform show on Support Case'
}

export const RELEASE_FORM_LABEL_MAP = {
  semVersion: 'Sem Version',
  version: 'Version',
  versionSuffix: 'Version Suffix',
  date: 'Date(UTC)',
  releaseDate: 'Relese Date(UTC)',
  epochTime: 'Epoch Time(UTC)',
  tags: 'Tags',
  platforms: 'Platforms',
  assets: 'Assets',
  note: 'Note',
  updatedReason: 'Updated Reason'
}

export const RELEASE_NOTE_FORM_LABEL_MAP = {
  title: 'Title',
  description: 'Description',
  status: 'Status',
  version: 'Version',
  platforms: 'Platforms'
}

export const RELEASE_INFORMATION_FORM_LABEL_MAP = {
  productName: 'Product Name',
  version: 'Version',
  platforms: 'Platforms',
  releaseLevel: 'Release Level',
  sendReleaseEmail: 'Send Release Email',
  featureHeader: 'Feature Header',
  featureDescription: 'Feature Description',
  updatedReason: 'Update Reason'
}

export const RELEASE_INFORMATION_DELIVERED_LOG_MESSAGE = 'release-info-delivered'
export const RELEASE_INFORMATION_SKIPPED_LOG_MESSAGE = 'release-info-skipped'

export const FALSE_DETECTION_SUPPORT_TICKET_TYPE = [
  {
    id: 1,
    name: 'Jira',
    code: 'jira'
  },
  {
    id: 2,
    name: 'Support Service',
    code: 'support_case'
  }
]
