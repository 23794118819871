import { useEffect, useMemo } from 'react'
import { dashboardFalseDetectionSubmissionAntivirusEngineListURL } from 'myopswat-admin/src/routes'
import { ButtonLoading, Grid, TemplateSection, TypographyDivider } from '@opswat/react-ui'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import FalseSubmissionAntivirusEngineForm, { antivirusEngineSchema } from './Form/FalseSubmissionAntivirusEngineForm'
import { useAdminFalseSubmissionAntivirusEngineCreateMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { useSnackbar } from 'notistack'
import { IAntivirusEngineForm } from './FalseSubmissionAntivirusEngineSchema'
import { useLazySysItemCountriesQuery } from '../../../../api/system'
import { Helmet } from 'react-helmet'

const FalseSubmissionAntivirusEngineCreate = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [createAntivirusEngine, { isLoading: isCreating }] = useAdminFalseSubmissionAntivirusEngineCreateMutation()
  const [getSysItemCountries, { data: sysItemCountriesData }] = useLazySysItemCountriesQuery()

  const initValues = useMemo(() => {
    return {
      engineName: '',
      vendorName: '',
      country: '',
      detection: '',
      heuristicDetection: '',
      isActive: true
    }
  }, [])

  const formRef = useForm<IAntivirusEngineForm>({
    defaultValues: initValues,
    mode: 'onSubmit',
    resolver: yupResolver(antivirusEngineSchema)
  })
  const { handleSubmit } = formRef
  const onSubmit = async (data: IAntivirusEngineForm) => {
    try {
      const res = await createAntivirusEngine(data).unwrap()
      if (res.success) {
        enqueueSnackbar('Create antivirus engine successfully.', { variant: 'success' })
        navigate(dashboardFalseDetectionSubmissionAntivirusEngineListURL)
      } else if (res.errors) {
        enqueueSnackbar(res.errors[0].message, { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Antivirus Engine List',
        path: dashboardFalseDetectionSubmissionAntivirusEngineListURL
      },
      {
        label: 'New Antivirus Engine'
      }
    ]
  }, [])

  useEffect(() => {
    getSysItemCountries()
  }, [])

  return (
    <>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          New Antivirus Engine
        </title>
      </Helmet>
      <TemplateSection>
        <TypographyDivider breadcrumbsArray={breadcrumbs} />
        <Grid container>
          <Grid item xs={12} sm={12} md={10} lg={8}>
            <FalseSubmissionAntivirusEngineForm formRef={formRef} sysItemCountriesData={sysItemCountriesData} />
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'inherit',
                    onClick: () => {
                      navigate(dashboardFalseDetectionSubmissionAntivirusEngineListURL)
                    },
                    disabled: isCreating
                  }}
                >
                  Cancel
                </ButtonLoading>
              </Grid>
              <Grid item xs="auto">
                <ButtonLoading
                  propsButton={{
                    variant: 'contained',
                    color: 'primary',
                    onClick: handleSubmit(onSubmit, onError),
                    disabled: isCreating
                  }}
                  isLoading={isCreating}
                >
                  Create
                </ButtonLoading>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TemplateSection>
    </>
  )
}
export default FalseSubmissionAntivirusEngineCreate
