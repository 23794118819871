import { ConfirmDialog, styled } from '@opswat/react-ui'
import { useAdminOrganizationSetSuperAdminMutation } from 'myopswat-admin/src/api/organizationManagement'
import useApiErrorHook from 'myopswat-admin/src/hooks/useApiErrorHook'
import { useSnackbar } from 'notistack'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface SetSuperAdminDialogProps {
  show: boolean
  orgUser: any
  onClose: () => void
  onSuccess: () => void
}
const HighLightEmail = styled('span')(({ theme }) => ({
  color: theme.palette.error.dark,
  fontWeight: 500
}))

const SetSuperAdminDialog = ({ show, orgUser, onClose, onSuccess }: SetSuperAdminDialogProps) => {
  const { t: translate } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { showErrorMessage } = useApiErrorHook({ errorMap: {} })
  const [setSuperAdmin, { isLoading }] = useAdminOrganizationSetSuperAdminMutation()

  const content = useMemo(() => {
    return (
      <>
        Do you want to set <HighLightEmail>{orgUser?.email}</HighLightEmail> as the Super Admin of the organization?
      </>
    )
  }, [orgUser])

  const handleSetSuperAdmin = async () => {
    try {
      const res = await setSuperAdmin({
        id: orgUser.id
      }).unwrap()
      if (res.success) {
        enqueueSnackbar(translate('orgSetSuperAdminSuccess'), { variant: 'success' })
        // Callback
        onSuccess()
      } else {
        showErrorMessage(res.errors)
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <ConfirmDialog
      open={show}
      onExited={onClose}
      onClose={onClose}
      onConfirm={handleSetSuperAdmin}
      isLoading={isLoading}
      content={content}
    />
  )
}

export default SetSuperAdminDialog
