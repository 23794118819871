import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'

import { unescape as htmlUnescape } from 'html-escaper'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  RELEASE_INFORMATION_LEVEL,
  RELEASE_INFORMATION_STATUS,
  RELEASE_INFORMATION_STATUS_LABELS,
  ReleaseInformationType
} from 'myopswat-admin/src/api/product/types'
import {
  useDeleteReleaseInformationMutation,
  useLazyAdminReleaseInformationListQuery
} from 'myopswat-admin/src/api/releaseInformation'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import useSearch from 'myopswat-admin/src/hooks/useSearch'
import { systemManagementReleaseInformationPageURL } from 'myopswat-admin/src/routes/systemManagementRoutes/releaseInformation'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CircleCheckIcon, ClockIcon, NotAvailableIcon, PendingIcon } from '@opswat/react-icon'
import { ActionButton, Box, Tooltip, Typography, TypographyLineClamp } from '@opswat/react-ui'

import ReleaseInformationPage from '.'
import ReleaseInformationLevelChip from './components/ReleaseInfoLevelChip'
import { ReleaseInformationContext } from './interface'
import { stripHTMLTags } from './utils'

interface IProps {
  permission: any
}

const ReleaseInformationProvider: React.FC<IProps> = ({ permission }) => {
  const dialog = useDialog()
  const navigate = useNavigate()

  const [deletReleaseInformation] = useDeleteReleaseInformationMutation()

  const searchData = useSearch<ReleaseInformationType>({
    searchCallback: useLazyAdminReleaseInformationListQuery,
    defaultValues: {
      pageInfo: {
        page: 0,
        pageSize: 10
      },
      sortInfo: {
        order: 'desc',
        orderBy: 'updatedAt'
      }
    },
    isSortable: true
  })

  const handleDeleteReleaseInformation = useCallback(async (id: string) => {
    await deletReleaseInformation(id)
      .unwrap()
      .then(async response => {
        if (response?.success) {
          await enqueueSnackbar('Deleted release information successfully', {
            variant: 'success'
          })
          searchData.handleSearch()
        } else {
          enqueueSnackbar(_get(response, ['errors', 0, 'message']), {
            variant: 'error'
          })
        }
      })
      .catch(() => {
        enqueueSnackbar('Deleting release information has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
      })
  }, [])

  const handleConfirmDelete = useCallback(async (id: string) => {
    const confirmed = await dialog.openConfirmation({
      content:
        'Are you sure you want to delete this data? This action cannot be undone, and the deleted data will be permanently removed from the system.',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
    if (confirmed) {
      handleDeleteReleaseInformation(id)
    }
  }, [])

  const renderProductName = useCallback((data: ReleaseInformationType) => {
    return (
      <TypographyLineClamp variant="body2" line={1} tooltipValue={data.productName}>
        {data.productName}
      </TypographyLineClamp>
    )
  }, [])

  const renderPlatforms = useCallback((data: ReleaseInformationType) => {
    return _map(data.platformStatuses, (platform: any, idx: number) => {
      let color = 'inherit'
      const deliveryStatus = _get(data, `platformStatuses.${idx}.status`)

      if (deliveryStatus === RELEASE_INFORMATION_STATUS.PENDING) {
        color = '#ED6706'
      } else if (deliveryStatus === RELEASE_INFORMATION_STATUS.DELIVERED) {
        color = '#008A00'
      }

      return (
        <TypographyLineClamp variant="body2" line={1} tooltipValue={platform.platformName} color={color}>
          {platform.platformName}
        </TypographyLineClamp>
      )
    })
  }, [])

  const renderVersion = useCallback((data: ReleaseInformationType) => {
    return (
      <Typography variant="body2" component="div">
        {data.version}
      </Typography>
    )
  }, [])

  const renderReleaseLevel = useCallback((data: ReleaseInformationType) => {
    const levelIndex = Object.values(RELEASE_INFORMATION_LEVEL).indexOf(data.releaseLevel)
    const releaseLevel = Object.values(RELEASE_INFORMATION_LEVEL)[levelIndex]
    return <ReleaseInformationLevelChip level={releaseLevel} />
  }, [])

  const renderFeatureHeader = useCallback((data: ReleaseInformationType) => {
    return (
      <TypographyLineClamp variant="body2" line={1} tooltipValue={data.featureHeader}>
        {data.featureHeader ?? '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderFeatureDescription = useCallback((data: ReleaseInformationType) => {
    const strippedDescription = stripHTMLTags(data.featureDescription ?? '')
    const escapedDescription = htmlUnescape(strippedDescription)
    return (
      <Tooltip
        arrow
        title={
          <div dangerouslySetInnerHTML={{ __html: data.featureDescription ?? '' }} style={{ whiteSpace: 'pre-wrap' }} />
        }
      >
        <span>
          <TypographyLineClamp variant="body2" line={1}>
            {escapedDescription ?? '--'}
          </TypographyLineClamp>
        </span>
      </Tooltip>
    )
  }, [])

  const renderStatus = useCallback((data: ReleaseInformationType) => {
    const statusIndex = Object.values(RELEASE_INFORMATION_STATUS).indexOf(data.status as RELEASE_INFORMATION_STATUS)
    const status = Object.values(RELEASE_INFORMATION_STATUS)[statusIndex] as RELEASE_INFORMATION_STATUS

    let color = '#3D4A68'
    let icon: any
    switch (status) {
      case RELEASE_INFORMATION_STATUS.PENDING:
        color = '#ED6706'
        icon = <ClockIcon color={color} size={20} />
        break
      case RELEASE_INFORMATION_STATUS.DELIVERED:
        color = '#008A00'
        icon = <CircleCheckIcon color={color} size={20} />
        break
      case RELEASE_INFORMATION_STATUS.PARTIALLY_DELIVERED:
        color = '#3d4a68'
        icon = <PendingIcon color={color} size={18} />
        break
      case RELEASE_INFORMATION_STATUS.SKIPPED:
        color = '#A4A8AE'
        icon = <NotAvailableIcon color={color} size={20} />
        break
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {icon}
        <TypographyLineClamp
          variant="body2"
          line={1}
          fontWeight={500}
          tooltipValue={RELEASE_INFORMATION_STATUS_LABELS[status]}
          sx={{
            color,
            textTransform: 'capitalize'
          }}
        >
          {RELEASE_INFORMATION_STATUS_LABELS[status]}
        </TypographyLineClamp>
      </Box>
    )
  }, [])

  const renderCreatedBy = useCallback((data: ReleaseInformationType) => {
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.creatorEmail}>
        {data.creatorEmail ?? '--'}
      </TypographyLineClamp>
    )
  }, [])

  const renderLastUpdated = useCallback((data: ReleaseInformationType) => {
    const value = formatDatetime(data.updatedAt, DATE_FORMATS.DATE_TIME)
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={value}>
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderExecutedAt = useCallback((data: ReleaseInformationType) => {
    const value = formatDatetime(data.executedAt, DATE_FORMATS.DATE_TIME)
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={value}>
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActions = useCallback(
    (data: ReleaseInformationType) => {
      const items: any[] = []

      if (
        data.status !== RELEASE_INFORMATION_STATUS.DELIVERED &&
        data.status !== RELEASE_INFORMATION_STATUS.SKIPPED &&
        _get(permission, 'change_release_description', false)
      ) {
        items.push({
          label: 'Edit',
          onClick: () => {
            navigate(`${systemManagementReleaseInformationPageURL}/${data.id}`)
          }
        })
      }

      if (data.status === RELEASE_INFORMATION_STATUS.PENDING && _get(permission, 'delete_release_description', false)) {
        items.push({
          label: 'Delete',
          onClick: () => handleConfirmDelete(data.id)
        })
      }

      return _isEmpty(items) ? (
        <></>
      ) : (
        <ActionButton
          items={items}
          paperStyles={{
            minWidth: '150px'
          }}
        />
      )
    },
    [permission]
  )

  const columns = useMemo(() => {
    return [
      {
        isSortable: true,
        key: 'productName',
        header: 'Product Name',
        body: (data: ReleaseInformationType) => renderProductName(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Platforms',
        body: (data: ReleaseInformationType) => renderPlatforms(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Version',
        body: (data: ReleaseInformationType) => renderVersion(data),
        style: { minWidth: '5vw', width: '5vw' }
      },
      {
        header: 'Release Level',
        body: (data: ReleaseInformationType) => renderReleaseLevel(data),
        style: { minWidth: '8vw', maxWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Feature Header',
        body: (data: ReleaseInformationType) => renderFeatureHeader(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Feature Description',
        body: (data: ReleaseInformationType) => renderFeatureDescription(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Status',
        body: (data: ReleaseInformationType) => renderStatus(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Created By',
        body: (data: ReleaseInformationType) => renderCreatedBy(data),
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        isSortable: true,
        key: 'updatedAt',
        header: 'Last Updated',
        body: (data: ReleaseInformationType) => renderLastUpdated(data),
        style: { minWidth: '9vw', width: '9vw' }
      },
      {
        key: 'executedAt',
        header: 'Executed At',
        body: (data: ReleaseInformationType) => renderExecutedAt(data),
        style: { minWidth: '9vw', width: '9vw' }
      },
      {
        header: '',
        body: (data: ReleaseInformationType) => renderActions(data),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  const contextValue = useMemo(() => ({ columns, ...searchData }), [columns, searchData])

  return (
    <ReleaseInformationContext.Provider value={contextValue}>
      <ReleaseInformationPage />
    </ReleaseInformationContext.Provider>
  )
}
export default ReleaseInformationProvider
