import React, { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { Typography, TypographyLink, Grid } from '@opswat/react-ui'

import { resetPasswordPageURL } from 'myopswat-admin/src/routes'

interface IProps {
  children?: React.ReactNode
}

const EmailSent: FC<IProps> = () => {
  const navigate = useNavigate()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h3">Activation email has been resent</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          In case you're having trouble locating the email, please check spam or junk folders.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          If no email is received within ten minutes, check the email address you submitted is correct or&nbsp;
          <TypographyLink onClick={() => navigate(resetPasswordPageURL)}>reset your password</TypographyLink>.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default EmailSent
