import { validateAsIds, validateBlogTags, validateJSONString } from 'myopswat-admin/src/utils/validator'
import * as yup from 'yup'

export interface IProductForm {
  id: string
  name: string
  slug: string
  shortName: string
  codeName: string
  avatar: string
  documentation: string
  description: string
  note: string
  parentIds: string[]
  licensedProductsDisplay: string[]
  asIds: string
  blogTags: string
  type: string[]
  tags: string[]
  licenseTypes: string[]
  solutionIds: number[]
  downloadable: boolean
  productImage: string | null
  resources: string
  hide: boolean
  avatarFile: string | File
  thumbnailFile: string | File
  releaseMetadata: string
  rmId: number
  showLatestVersion?: boolean
  updatedReason?: string
  iconFile: string | File
  supportCasePlatforms?: string[]
}

export const productSchema = yup.object().shape({
  name: yup.string().trim().required('Product name cannot be blank').max(256, 'Please enter maximum 255 characters.'),
  parentIds: yup.array().of(yup.string().trim()),
  licensedProductsDisplay: yup.array().of(yup.string().trim()),
  avatar: yup.string().trim().max(256, 'Please enter maximum 255 characters.'),
  slug: yup.string().trim().required('Product slug cannot be blank').max(256, 'Please enter maximum 255 characters.'),
  shortName: yup.string().trim().max(256, 'Please enter maximum 255 characters.'),
  codeName: yup.string().trim().max(256, 'Please enter maximum 100 characters.'),
  description: yup.string().trim().max(2000, 'Please enter maximum 2000 characters.'),
  note: yup.string().trim().max(500, 'Please enter maximum 00 characters.'),
  resources: yup
    .string()
    .trim()
    .test('resources-json', 'Please enter a valid json string format.', value => validateJSONString(value!)),
  releaseMetadata: yup
    .string()
    .trim()
    .test('release-metadata-json', 'Please enter a valid json string format.', value => validateJSONString(value!)),
  documentation: yup.string().trim().max(2000, 'Please enter maximum 2000 characters.'),
  type: yup.array().of(yup.string().trim()),
  tags: yup.array().of(yup.string().trim()),
  downloadable: yup.boolean(),
  hide: yup.boolean(),
  licenseTypes: yup.array().of(yup.string().trim()),
  solutions: yup.array().of(yup.number()),
  asIds: yup
    .string()
    .trim()
    .test('as-ids', 'Please enter list numbers with comma separator.', value => validateAsIds(value!)),
  blogTags: yup
    .string()
    .trim()
    .test('blog-tags', 'Please enter list tags with comma separator.', value => validateBlogTags(value!)),
  icon: yup.string().trim().max(256, 'Please enter maximum 255 characters.'),
  supportCasePlatforms: yup.array().of(yup.string().trim()),
})
