import { useEffect, useMemo } from 'react'

import _get from 'lodash/get'
import _map from 'lodash/map'

import { CheckDisabledIcon, CheckedDisabledIcon } from '@opswat/react-icon'
import {
  AutocompleteMultiple,
  Box,
  CheckboxWithLabel,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  Tooltip,
  Typography,
  TypographyDivider
} from '@opswat/react-ui'
import { useEmailSubscriptionProductsQuery } from 'myopswat-admin/src/api/product'
import { useLazyAdminUserSettingsQuery } from 'myopswat-admin/src/api/userManagement'
import { RefCallBack } from 'react-hook-form'
import { CheckboxContent } from './components/CheckboxContent'

interface IProps {
  profileData: any
}

const UserPreferencesPage = ({ profileData }: IProps) => {
  const [getUserSettings, { data: userSettingsData, isFetching }] = useLazyAdminUserSettingsQuery()
  const sendAllPurchasedProducts = String(_get(userSettingsData, 'subscribedEmail.sendAllPurchasedProducts'))
  const isCustomer = useMemo(() => {
    return _get(userSettingsData, 'isCustomer', false)
  }, [userSettingsData])
  const { data: productOptions } = useEmailSubscriptionProductsQuery()

  const SUBSCRIPTION_CONTENT: any = useMemo(() => {
    return {
      update: {
        title: 'OPSWAT Updates',
        description: 'News about Webinars, Events, Resources, and Solutions (4-6 emails per month)'
      },
      newsletter: {
        title: _get(profileData, 'accountInfo.isOemCustomer', false)
          ? 'Company Activity Newsletter/OEM Newsletter'
          : 'Company Activity Newsletter',
        description: 'Summary of Recent Company Activities - 1 email per Month'
      },
      blog: {
        title: 'Cybersecurity Blog',
        description: 'Product Updates, Technology Tips, and Company News - 3-5 Weekly emails'
      },
      productRelease: {
        title: 'Product Releases',
        description: 'Email notifications whenever OPSWAT releases a new version of a downloadable product.'
      },
      academyNewsletter: {
        title: 'OPSWAT Academy - Newsletter',
        description: 'Summary of OPSWAT Academy activities (1 email per month)'
      }
    }
  }, [profileData])

  const UNSUBSCRIBED_ALL_CONTENT = useMemo(() => {
    return {
      title: 'Unsubscribe from all communications',
      description:
        'Remove yourself from all emails. Interest topics are still used to improve the experience on pages of My OPSWAT.'
    }
  }, [])

  useEffect(() => {
    getUserSettings(_get(profileData, 'id'))
  }, [])

  const selectedProductsInfo = useMemo(() => {
    return (
      productOptions
        ?.filter((option: any) => _get(userSettingsData, `subscribedEmail.selectedProductIds`, []).includes(option.id))
        .map((option: any) => option.name) || []
    )
  }, [productOptions])

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={10} lg={8}>
          {isFetching ? (
            <Grid container spacing={2} paddingLeft={'10px'}>
              <Grid item xs={12}>
                <Skeleton height="50px" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {_map(Object.keys(SUBSCRIPTION_CONTENT), (_: any, index: number) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Skeleton height="50px" />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Skeleton height="50px" />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} paddingLeft={'10px'}>
              <Grid item xs={12}>
                <TypographyDivider label="Email Subscriptions" propsTypo={{ variant: 'body1' }} />
              </Grid>

              <Grid
                item
                xs={12}
                container
                spacing={2}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)'
                }}
              >
                {_map(Object.keys(SUBSCRIPTION_CONTENT), (key: any, index: number) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      gridRow: `span ${['productRelease', 'blog'].includes(key) ? 2 : 1}`,
                      gridColumn: 'span 1'
                    }}
                  >
                    <CheckboxWithLabel
                      label={
                        <CheckboxContent
                          title={SUBSCRIPTION_CONTENT[key].title!}
                          description={SUBSCRIPTION_CONTENT[key].description}
                        />
                      }
                      checkboxProps={{
                        disabled: true,
                        checked: _get(userSettingsData, `subscribedEmail.${key}`),
                        icon: <CheckDisabledIcon />,
                        checkedIcon: <CheckedDisabledIcon />
                      }}
                      restProps={{ sx: { alignItems: 'flex-start' } }}
                    />

                    {key === 'productRelease' && _get(userSettingsData, `subscribedEmail.${key}`) && (
                      <Box sx={{ padding: '8px 24px' }}>
                        <RadioGroup sx={{ px: 0.5 }}>
                          <FormControlLabel
                            disabled
                            value={'true'}
                            control={
                              <Radio
                                sx={{
                                  padding: '5px'
                                }}
                                size="small"
                                checked={sendAllPurchasedProducts === 'true'}
                              />
                            }
                            label={
                              <Tooltip
                                arrow
                                placement="top"
                                title={!isCustomer && 'Purchase OPSWAT products to enable this option'}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      color: '#1B273C',
                                      backgroundColor: '#E9EAEB'
                                    }
                                  },
                                  arrow: {
                                    sx: {
                                      color: '#E9EAEB'
                                    }
                                  }
                                }}
                              >
                                <Typography variant="body2" color={'#737da3'}>
                                  Only products for which you hold licenses
                                </Typography>
                              </Tooltip>
                            }
                          />
                          <FormControlLabel
                            value={'false'}
                            sx={{ mb: 1 }}
                            control={
                              <Radio
                                disabled
                                sx={{
                                  padding: '5px'
                                }}
                                size="small"
                                checked={sendAllPurchasedProducts === 'false'}
                              />
                            }
                            label={
                              <Typography variant="body2" color={'#737da3'}>
                                Customize options
                              </Typography>
                            }
                          />
                        </RadioGroup>
                        {sendAllPurchasedProducts === 'false' && (
                          <Tooltip
                            arrow
                            placement="top"
                            title={selectedProductsInfo.join(', ')}
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  color: '#1B273C',
                                  backgroundColor: '#E9EAEB'
                                }
                              },
                              arrow: {
                                sx: {
                                  color: '#E9EAEB'
                                }
                              }
                            }}
                          >
                            <span>
                              <AutocompleteMultiple
                                disabled
                                options={
                                  productOptions?.map((option: any) => ({
                                    label: option.name,
                                    value: option.id
                                  })) ?? []
                                }
                                value={
                                  productOptions
                                    ?.filter((item: any) =>
                                      _get(userSettingsData, `subscribedEmail.selectedProductIds`).includes(item.id)
                                    )
                                    .map((item: any) => ({ label: item.name, value: item.id })) || []
                                }
                                name={''}
                                ref={{} as RefCallBack}
                                onChange={() => {
                                  return
                                }}
                                onBlur={() => {
                                  return
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </Box>
                    )}

                    {key === 'academyNewsletter' && (
                      <CheckboxWithLabel
                        label={
                          <CheckboxContent
                            title={UNSUBSCRIBED_ALL_CONTENT.title}
                            description={UNSUBSCRIBED_ALL_CONTENT.description}
                          />
                        }
                        checkboxProps={{
                          disabled: true,
                          checked: _get(userSettingsData, 'subscribedEmail.unsubscribedAll'),
                          icon: <CheckDisabledIcon />,
                          checkedIcon: <CheckedDisabledIcon />
                        }}
                        restProps={{ sx: { alignItems: 'flex-start', mt: 2 } }}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default UserPreferencesPage
