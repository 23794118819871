import { NpsSettingsUpdateInput } from 'myopswat-admin/src/api/portalSettings/types'
import React from 'react'
import { UseFormReturn } from 'react-hook-form'

export type PortalSettingsContextProps = {
  npsForm: UseFormReturn<NpsSettingsUpdateInput>
  isFetching: boolean
  isSubmitting: boolean
  isEditMode: boolean
  activeTabIndex: number
  hasEditPermission: boolean
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>
  handleResetForm: (tabIndex: number) => void
  handleSubmitForm: (tabIndex: number) => void
  handleCheckValidForm: (tabIndex: number) => boolean
}

export const PortalSettingsContext = React.createContext<PortalSettingsContextProps>({
  npsForm: {} as UseFormReturn<NpsSettingsUpdateInput>,
  isFetching: false,
  isSubmitting: false,
  isEditMode: false,
  activeTabIndex: 0,
  hasEditPermission: false,
  setIsEditMode: () => {
    return
  },
  setActiveTabIndex: () => {
    return
  },
  handleResetForm: () => {
    return
  },
  handleSubmitForm: () => {
    return
  },
  handleCheckValidForm: () => false
})
