import { useCallback, useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _omit from 'lodash/omit'

import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'

import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from '../constants'

interface IProps {
  searchCallback: UseLazyQuery<any>
  defaultValues?: any
  isSortable?: boolean
}

export default function useSearch<ResponseType>({ searchCallback, defaultValues, isSortable = false }: IProps) {
  const [results, setResults] = useState<ResponseType[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const { control, setValue, watch, reset } = useForm<any>({
    defaultValues: {
      ...defaultValues,
      filters: {
        q: '',
        ..._get(defaultValues, 'filters', {})
      },
      pageInfo: {
        page: _get(defaultValues, 'pageInfo.page') ?? PAGE_DEFAULT,
        pageSize: _get(defaultValues, 'pageInfo.pageSize') ?? PAGE_SIZE_DEFAULT
      },
      sortInfo: {
        order: _get(defaultValues, 'sortInfo.order') ?? 'asc',
        orderBy: _get(defaultValues, 'sortInfo.orderBy') ?? ''
      }
    }
  })

  const filters = watch('filters')
  const pageInfo = watch('pageInfo')
  const sortInfo = watch('sortInfo')

  const handlePagination = useCallback(
    (pageNumber: number, pageSize: number) => {
      if (pageSize !== pageInfo.pageSize) {
        pageNumber = PAGE_DEFAULT
      }
      setValue('pageInfo', { page: pageNumber, pageSize })
    },
    [pageInfo]
  )

  const handleSort = useCallback(
    (orderBy?: string, order?: string) => {
      setValue('sortInfo', { ...sortInfo, order, orderBy })
      handleQueryDebounced()
    },
    [sortInfo]
  )

  const handleSearchDebounced = _debounce(() => {
    handleSearch()
  }, 300)

  const handleQueryDebounced = _debounce(() => {
    setValue('pageInfo', { ...pageInfo, page: 0 })
    handleSearch()
  }, 300)

  const handleQuery = useCallback(
    (name?: string, value?: any) => {
      name && setValue('filters', { ...filters, [`${name}`]: value })
      handleQueryDebounced()
    },
    [filters]
  )

  const handleReset = useCallback(() => {
    reset()
    handleSearchDebounced()
  }, [])

  const [getLazyList, { isFetching }] = searchCallback()

  const handleSearch = useCallback(async () => {
    const request = isSortable ? watch() : _omit(watch(), ['sortInfo'])
    await getLazyList(request)
      .unwrap()
      .then((response: any) => {
        setResults(_get(response, 'results') ?? [])
        setTotalCount(_get(response, 'totalCount') ?? 0)
      })
      .catch(error => {
        console.log('log: error', error)
      })
  }, [isSortable, watch])

  useEffect(() => {
    handleSearch()
  }, [pageInfo])

  return {
    control,
    setValue,
    isFetching,
    totalCount,
    results,
    pageInfo,
    sortInfo,
    handleQuery,
    handleReset,
    handleSort,
    handleSearch,
    handlePagination
  }
}
