import { OptionIcon } from '@opswat/react-icon'
import {
  ButtonLoading,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  TemplateSection,
  Typography,
  TypographyDivider,
  TypographyLinkClamp
} from '@opswat/react-ui'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { formatDatetime } from '@opswat/react-core'
import { useLazyUsersResponseExcelExportQuery } from 'myopswat-admin/src/api/account'
import { useAdminUserDeleteMutation, useLazyAdminUsersQuery } from 'myopswat-admin/src/api/userManagement'
import {
  IAdminUserFilter,
  IAdminUserInputType,
  IAdminUserQueryInput
} from 'myopswat-admin/src/api/userManagement/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-admin/src/constants'
import { toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import {
  customerManagementUserListPageURL,
  detailCustomerUserPath,
  editCustomerUserPath
} from 'myopswat-admin/src/routes'
import { useTypedSelector } from 'myopswat-admin/src/store'
import { useNavigate } from 'react-router-dom'
import BoxUserListFilter from './BoxUserListFilter'

import { BLOB_FILE_TYPES, DATE_FORMATS, DIALOGS, handleCreateFile } from '@myopswat/common'
import _get from 'lodash/get'

const UserListPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [adminUserDelete] = useAdminUserDeleteMutation()
  const [hasUserExportRequest, setHasUserExportRequest] = useState(false)
  const [getUsersResponseExcelExportData, { data: usersResponseExcelExportData, isFetching: isFetchingExportData, isError }] = useLazyUsersResponseExcelExportQuery()
  const profileData: any = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const isEditUserDisabled = useMemo(() => {
    if (profileData && profileData.permissions) {
      return !profileData.permissions.includes('change_user')
    }
    return false
  }, [profileData])

  const defaultQuery = {
    filters: {
      q: '',
      usersOf: [],
      excludeInternal: false
    },
    pageInfo: {
      page: PAGE_DEFAULT,
      pageSize: PAGE_SIZE_DEFAULT
    }
  }

  const [query, setQuery] = useState<IAdminUserQueryInput>(defaultQuery)

  const [getUsers, { data, isFetching: isLoading }] = useLazyAdminUsersQuery()

  useEffect(() => {
    getUsers(query)
  }, [query])

  const handleSearch = (searchData: IAdminUserFilter) => {
    setQuery(prev => ({ ...prev, filters: searchData }))
  }

  const handleExportUserExcel = () => {
    getUsersResponseExcelExportData(query)
    setHasUserExportRequest(true)
  }

  useEffect(() => {
    if (hasUserExportRequest && _get(usersResponseExcelExportData, 'content') && !isFetchingExportData) {
      enqueueSnackbar(t('downloadUserExcelSuccess'), {
        variant: 'success'
      })

      handleCreateFile(
        _get(usersResponseExcelExportData, 'filename', 'Customer data'),
        'xlsx',
        _get(usersResponseExcelExportData, 'content', ''),
        {
          type: BLOB_FILE_TYPES.EXCEL
        }
      )
      setHasUserExportRequest(false)
    } else if (hasUserExportRequest && isError && !isFetchingExportData) {
      enqueueSnackbar(t('downloadUserExcelFail'), {
        variant: 'error'
      })
    }
  }, [hasUserExportRequest, isError, usersResponseExcelExportData, isFetchingExportData])

  const handleDeleteAccount = (userId: any) => {
    dispatch(
      toggleDialogs({
        [DIALOGS.CONFIRM_WITH_REASON]: true,
        [`${DIALOGS.CONFIRM_WITH_REASON}_TITLE`]: 'Delete Account Confirmation',
        [`${DIALOGS.CONFIRM_WITH_REASON}_CONTENT`]:
          'Are you sure you want to delete this account. After deletion the account will not login',
        [`${DIALOGS.CONFIRM_WITH_REASON}_ACTION`]: (reason: string) => adminUserDelete({ userId: userId, reason })
      })
    )
  }

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    // If pageSize is changed, reset page
    if (pageSize !== query.pageInfo.pageSize) page = PAGE_DEFAULT
    setQuery(statePrev => Object.assign({}, statePrev, { pageInfo: { page, pageSize } }))
  }

  const columnArray = useMemo(() => {
    return [
      {
        header: 'Name',
        body: (data: IAdminUserInputType) => (
          <TypographyLinkClamp
            onClick={() => {
              navigate(`${customerManagementUserListPageURL}/${data?.id}/${detailCustomerUserPath}`)
            }}
          >
            {data.fullName || '--'}
          </TypographyLinkClamp>
        ),
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Email',
        body: (data: IAdminUserInputType) => <Typography variant="body2">{data.email || '--'}</Typography>,
        style: { minWidth: 350, width: 350 }
      },
      {
        header: 'Active',
        body: (data: IAdminUserInputType) => (
          <Typography
            sx={{
              color: data.isActive ? 'success.dark' : 'warning.dark'
            }}
            variant="body2"
          >
            {data.isActive ? 'Active' : 'Pending'}
          </Typography>
        ),
        style: { minWidth: 200, width: 200, textAlign: 'center' }
      },
      {
        header: 'Last Login',
        body: (data: IAdminUserInputType) => (
          <Typography variant="body2">{formatDatetime(data.lastLogin, DATE_FORMATS.DATE)}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: 'Created At',
        body: (data: IAdminUserInputType) => (
          <Typography variant="body2">{formatDatetime(data.createdAt, DATE_FORMATS.DATE)}</Typography>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        header: '',
        body: (data: IAdminUserInputType) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`${customerManagementUserListPageURL}/${data?.id}/${editCustomerUserPath}`)
                    }}
                    disabled={isEditUserDisabled}
                  >
                    Edit Info
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton disabled>Resend Registration Email</ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton disabled>Resend Reset Password Email</ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton disabled>Change Email Address</ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleDeleteAccount(data?.id)}>Delete Account</ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])


  const renderExportButton = useCallback(() => {
    if ((query.filters.usersOf && query.filters.usersOf?.length > 0) || query.filters.excludeInternal) {
      return (
        <ButtonLoading
          propsButton={{
            variant: 'contained',
            color: 'primary',
            onClick: handleExportUserExcel,
            disabled: hasUserExportRequest,
            disableElevation: true
          }}
          propsLoading={{ color: 'inherit' }}
          isLoading={isFetchingExportData}
        >
          Export
        </ButtonLoading>
      )
    }
    return <></>
  }, [handleExportUserExcel, JSON.stringify(query.filters)])

  return (
    <TemplateSection>
      <TypographyDivider label="Users" />

      <BoxUserListFilter filters={query.filters} onSearch={handleSearch} onRenderExportButton={renderExportButton}/>

      <TableAdvanced
        columns={columnArray}
        isLoading={isLoading}
        data={data?.results}
        isPagination
        total={data?.totalCount}
        page={query.pageInfo.page}
        pageSize={query.pageInfo.pageSize}
        onPageChange={handlePaginationOnChange}
      />
    </TemplateSection>
  )
}

export default UserListPage
