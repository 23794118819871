import React, {useMemo, useState } from 'react'
import { useAdminFalseSubmissionDetailQuery } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { TemplateSection, TypographyDivider } from '@opswat/react-ui'
import FalseDetectionSubmissionDetailLoading from './FalseDetectionSubmissionDetailLoading'
import FalseDetectionSubmissionDetail from './FalseDetectionSubmissionDetailView'
import { Helmet } from 'react-helmet'
import { dashboardFalseDetectionSubmissionListURL } from '../../../routes'
import FalseDetectionSubmissionEdit from './FalseDetectionSubmissionDetailEdit'

interface IProps {
  submissionId: string
}
const FalseDetectionSubmissionDetailPage = ({ submissionId }: IProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {
    data,
    isFetching,
    refetch
  } = useAdminFalseSubmissionDetailQuery(submissionId, {
    refetchOnMountOrArgChange: true
  })
  const falseDetectionSubmission = useMemo(() => {
    return data || {}
  }, [data])

  const onEdit = () => {
    setIsEdit(true)
  }

  const handleAfterSave = async () => {
    await refetch()
    setIsEdit(false)
  }

  const handleCancel = () => {
    setIsEdit(false)
  }

  const isRender = useMemo(() => {
    return falseDetectionSubmission && !isFetching
  }, [falseDetectionSubmission, isFetching])

  const label =  falseDetectionSubmission?.id ? `#${falseDetectionSubmission?.id.toString().padStart(8, '0')}` : ''

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'False Submission List',
        path: dashboardFalseDetectionSubmissionListURL
      },
      {
        label: label
      }
    ]
  }, [falseDetectionSubmission])

  const handlerRender = () => {
    if (!isRender) return <FalseDetectionSubmissionDetailLoading />
    if (isEdit) return <FalseDetectionSubmissionEdit falseDetectionSubmission={falseDetectionSubmission} handleCancel={handleCancel} handleAfterSave={handleAfterSave}/>

    return <FalseDetectionSubmissionDetail falseDetectionSubmission={falseDetectionSubmission} onEdit={onEdit} />
  }

  return (
    <TemplateSection>
      <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
        <title itemProp="name" lang="en">
          False submission Detail
        </title>
      </Helmet>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />
      {handlerRender()}
    </TemplateSection>
  )
}

export default FalseDetectionSubmissionDetailPage
