import { gql } from 'graphql-request'
import { apiGraphql } from '../base-query'
import {
  IAdminEvaluationRequestResponse,
  IAdminEvaluationRequestFilterInput,
  IAdminEvaluationRequestApproveInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    adminEvaluationRequests: builder.query<IAdminEvaluationRequestResponse, IAdminEvaluationRequestFilterInput>({
      query: input => ({
        document: gql`
          query adminEvaluationRequests($filters: AdminEvaluationRequestFiltersInput, $pageInfo: PageInfoType) {
            adminEvaluationRequests(filters: $filters, pageInfo: $pageInfo) {
              totalCount
              results {
                id
                status
                product {
                  id
                  name
                }
                user {
                  firstName
                  lastName
                }
                requestedAt
                approvedAt
                approvedBy {
                  firstName
                  lastName
                }
              }
            }
          }
        `,
        variables: input
      })
    }),
    adminEvaluationRequestApprove: builder.mutation<any, IAdminEvaluationRequestApproveInput>({
      query: ({ id, activationKey }) => ({
        document: gql`
          mutation ($input: AdminEvaluationRequestApproveInput!) {
            adminEvaluationRequestApprove(input: $input) {
              success
              errors
              evaluationRequest {
                id
                status
                product {
                  id
                  name
                }
                user {
                  firstName
                  lastName
                }
                requestedAt
                approvedAt
                approvedBy {
                  firstName
                  lastName
                }
              }
            }
          }
        `,
        variables: {
          input: {
            id,
            activationKey
          }
        }
      })
    })
  })
})
export const {
  useAdminEvaluationRequestsQuery,
  useLazyAdminEvaluationRequestsQuery,
  useAdminEvaluationRequestApproveMutation
} = api
