import { useEffect, useMemo } from 'react'

import { TabAdvanced, TypographyDivider } from '@opswat/react-ui'
import { useLazyProductDetailQuery } from 'myopswat-admin/src/api/product'
import { useParams } from 'react-router-dom'

import {
  detailSystemProductPath,
  editSystemProductPath,
  productIdReleaseListPath,
  systemManagementProductListPageURL,
  tabSystemProductHistoryPath
} from 'myopswat-admin/src/routes'

import { Helmet } from 'react-helmet'
import BoxProductDetailEdit from './BoxProductDetailEdit'
import BoxProductDetailHistory from './BoxProductDetailHistory'
import BoxProductDetailLoading from './BoxProductDetailLoading'
import BoxProductDetailRelease from './BoxProductDetailRelease'
import BoxProductDetailView from './BoxProductDetailView'
import { useLazySystemValueListQuery } from '../../../api/system'
import { SYSTEM_VALUE_KEYS } from '../../../constants'
import _get from 'lodash/get'
interface IProps {
  productId: string
}

const ProductDetailPage = ({ productId }: IProps) => {
  const { tabPath = '' } = useParams()

  const [getProductDetail, { data, isFetching }] = useLazyProductDetailQuery()

  const [getSystemValueList, { data: systemValueInfo }] = useLazySystemValueListQuery()


  const product = useMemo(() => {
    return data?.adminProduct || {}
  }, [data])

  const productMap = useMemo(() => {
    if (!data) return {}
    return data.adminProducts.reduce((obj: any, product: any) => ((obj[product.id] = product.name), obj), {})
  }, [data])

  const asIdsProductMap = useMemo(() => {
    if (!data) return {}

    return data.adminProducts.reduce((obj: any, product: any) => {
      if (product.asIds?.length) {
        obj[product.id] = product.name
      }
      return obj
    }, {})
  }, [data])

  const solutionMap = useMemo(() => {
    if (!data) return {}
    return data.solutions.reduce(
      (obj: any, item: { id: number; name: string }) => ((obj[item.id] = item.name), obj),
      {}
    )
  }, [data])

  const supportCasePlatforms = useMemo(() => {
    return Array.isArray(systemValueInfo)
      ? _get(
        systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.SUPPORT_CASE_PLATFORMS),
        'data',
        []
      )
      : []
  }, [systemValueInfo])

  const isRender = useMemo(() => {
    return product && productMap && solutionMap && !isFetching
  }, [product, productMap, solutionMap, isFetching])

  useEffect(() => {
    getSystemValueList([SYSTEM_VALUE_KEYS.SUPPORT_CASE_PLATFORMS])
  }, [])

  const breadcrumbs = useMemo(() => {
    return [
      {
        label: 'Product List',
        path: systemManagementProductListPageURL
      },
      {
        label: product?.name || '--'
      }
    ]
  }, [product])

  const tabArray = useMemo(() => {
    return [
      {
        label: 'Product Detail',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Product Detail'}
              </title>
            </Helmet>
            {tabPath === editSystemProductPath ? (
              <BoxProductDetailEdit
                product={product}
                productMap={productMap}
                asIdsProductMap={asIdsProductMap}
                solutionMap={solutionMap}
                supportCasePlatforms={supportCasePlatforms}
              />
            ) : (
              <BoxProductDetailView product={product} solutionMap={solutionMap} productMap={productMap} />
            )}
          </>
        ),
        tabPath:
          tabPath === editSystemProductPath
            ? `${systemManagementProductListPageURL}/${product?.id}/${editSystemProductPath}`
            : `${systemManagementProductListPageURL}/${product?.id}/${detailSystemProductPath}`
      },
      {
        label: 'Release List',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                {'Product Release List'}
              </title>
            </Helmet>
            <BoxProductDetailRelease />
          </>
        ),
        tabPath: `${systemManagementProductListPageURL}/${product?.id}/${productIdReleaseListPath}`
      },
      {
        label: 'Product History',
        content: (
          <>
            <Helmet encodeSpecialCharacters={true} titleTemplate="%s - Admin My OPSWAT" defer={false}>
              <title itemProp="name" lang="en">
                Product History
              </title>
            </Helmet>
            <BoxProductDetailHistory product={product} productMap={productMap} solutionMap={solutionMap} />
          </>
        ),
        tabPath: `${systemManagementProductListPageURL}/${product?.id}/${tabSystemProductHistoryPath}`
      }
    ]
  }, [product, tabPath])

  useEffect(() => {
    if (tabPath && productId && !data) {
      getProductDetail(productId)
    }
  }, [tabPath, productId])

  return (
    <>
      <TypographyDivider breadcrumbsArray={breadcrumbs} />

      {!isRender && <BoxProductDetailLoading />}

      {isRender && <TabAdvanced tabs={tabArray} />}
    </>
  )
}
export default ProductDetailPage
