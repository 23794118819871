import { toApiDateTime } from '@opswat/react-core'
import { apiRestful } from 'myopswat-admin/src/api/base-query'
import { IFalseSubmissionExportInput } from './types'

export const api = apiRestful.injectEndpoints({
  endpoints: builder => ({
    adminFalseSubmissionExport: builder.query<any, IFalseSubmissionExportInput>({
      query({ fromDate, toDate }) {
        return {
          url: `export/false-detection-submission/?from_date=${toApiDateTime(fromDate)}&to_date=${toApiDateTime(
            toDate
          )}`,
          responseHandler: async response => response,
          cache: 'no-cache'
        }
      }
    })
  })
})

export const { useAdminFalseSubmissionExportQuery, useLazyAdminFalseSubmissionExportQuery } = api
