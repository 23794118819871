import _get from 'lodash/get'

import { yupResolver } from '@hookform/resolvers/yup'
import { enqueueSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useLazyPortalSettingsQuery, useNpsSettingsUpdateMutation } from 'myopswat-admin/src/api/portalSettings'
import { NpsSettingsUpdateInput } from 'myopswat-admin/src/api/portalSettings/types'

import PortalSettingsPage from '.'
import { NPS_VALIDATION_SCHEMA } from './constants'
import { PortalSettingsContext } from './interface'

interface IProps {
  permission: any
}

const PortalSettingsProvider = ({ permission }: IProps) => {
  const npsForm = useForm<NpsSettingsUpdateInput>({
    defaultValues: {
      surveyPeriod: 0,
      skipPeriod: 0
    },
    resolver: yupResolver(NPS_VALIDATION_SCHEMA),
    mode: 'onChange'
  })

  const [getPortalSettings, { isFetching }] = useLazyPortalSettingsQuery()
  const [updateNpsSettings, { isLoading: isUpdatingNpsSettings }] = useNpsSettingsUpdateMutation()

  const isSubmitting = isUpdatingNpsSettings
  const hasEditPermission = _get(permission, 'add_survey', false) || _get(permission, 'change_survey', false)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [originalValues, setOriginalValues] = useState<any>({})

  const handleGetPortalSettings = () => {
    getPortalSettings()
      .unwrap()
      .then(response => {
        const { npsSurvey } = response
        if (npsSurvey) {
          const { setValue } = npsForm
          Object.entries(npsSurvey).forEach(([key, value]: any) => {
            setValue(key, value)
          })
          setOriginalValues(npsSurvey)
        }
      })
      .catch((error: any) => {
        const errorMessage = _get(error, 'errors.0.message', 'An error occurred while fetching portal settings.')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
  }

  const handleCheckValidForm = (tabIndex: number) => {
    if (tabIndex === 0) {
      return npsForm.formState.isValid
    }
    return false
  }

  const handleSubmitForm = (tabIndex: number) => {
    if (tabIndex === 0) {
      npsForm.handleSubmit(handleSubmitNpsForm)()
    }
  }

  const handleResetForm = (tabIndex: number) => {
    setIsEditMode(false)
    if (tabIndex === 0) {
      const { setValue } = npsForm
      Object.entries(originalValues).forEach(([key, value]: any) => {
        setValue(key, value)
      })
    }
  }

  const handleSubmitNpsForm = (data: NpsSettingsUpdateInput) => {
    updateNpsSettings(data)
      .unwrap()
      .then(response => {
        if (response.success) {
          enqueueSnackbar('Updated NPS Settings successfully.', { variant: 'success' })
          handleGetPortalSettings()
        } else if (response.errors) {
          enqueueSnackbar(_get(response.errors, '0.message'), { variant: 'error' })
        }
      })
      .catch((error: any) => {
        const errorMessage = _get(error, 'errors.0.message', 'An error occurred while updating NPS Settings.')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => setIsEditMode(false))
  }

  useEffect(() => {
    handleGetPortalSettings()
  }, [])

  const contextValue = useMemo(
    () => ({
      npsForm,
      isFetching,
      isSubmitting,
      isEditMode,
      activeTabIndex,
      hasEditPermission,
      setIsEditMode,
      setActiveTabIndex,
      handleResetForm,
      handleSubmitForm,
      handleCheckValidForm
    }),
    [
      npsForm,
      isFetching,
      isSubmitting,
      isEditMode,
      activeTabIndex,
      hasEditPermission,
      setIsEditMode,
      setActiveTabIndex,
      handleResetForm,
      handleSubmitForm,
      handleCheckValidForm
    ]
  )

  return (
    <PortalSettingsContext.Provider value={contextValue}>
      <PortalSettingsPage />
    </PortalSettingsContext.Provider>
  )
}

export default PortalSettingsProvider
