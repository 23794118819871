import React, { FC, useState } from 'react'
import {
  Popper,
  Fade,
  Paper,
  Typography,
  Button,
  Grid,
  ClickAwayListener} from '@opswat/react-ui'

interface IProps {
  button: React.ReactNode
  content: React.ReactNode
  onResetChange: () => void
  onResetFilter: () => void
  onApply: () => void
  propsPopper?: any | object
  propsPaper?: any | object
  resetOnSubmit?: boolean
  disabled?: boolean
}

const DropdownFilterRoleObject: FC<IProps> = ({
  button,
  content,
  onResetChange,
  onResetFilter,
  onApply,
  propsPopper,
  propsPaper,
  resetOnSubmit = true,
  disabled = false

}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)
  const [placement, setPlacement] = useState()
  const handleClick = (newPlacement: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(prev => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
  }
  const handleCloseDropdown = () => {
    setAnchorEl(null)
    setOpen(false)
    onResetChange()
  }

  const handleSubmit = () => {
    setAnchorEl(null)
    setOpen(false)
  }
  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition sx={{ zIndex: 1101 }} {...propsPopper}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ width: '350px', overflow: 'auto' }} {...propsPaper}>
              <ClickAwayListener onClickAway={handleCloseDropdown}>
                <Grid container sx={{ p: 2 }}>
                  <Grid item xs={12}>
                    {content}
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={true}>
                        <Button
                          variant="text"
                          color="inherit"
                          onClick={() => {
                            onResetFilter()
                          }}
                        >
                          Reset all filters
                        </Button>
                      </Grid>

                      <Grid item xs="auto">
                        <Button variant="text" color="inherit" onClick={handleCloseDropdown}>
                          Cancel
                        </Button>
                      </Grid>

                      <Grid item xs="auto">
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          disabled={disabled}
                          onClick={() => {
                            onApply()
                            handleSubmit()
                            resetOnSubmit && handleCloseDropdown()
                          }}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Typography onClick={handleClick('bottom-end')}>{button}</Typography>
    </>
  )

}

export default DropdownFilterRoleObject