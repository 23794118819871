import { Grid, TypographyDivider } from '@opswat/react-ui'

import { useContext } from 'react'

import { PortalSettingsContext } from '../interface'
import NpsSettings from './NpsSettings'
import NpsSettingsLoading from './NpsSettings/NpsSettingsLoading'

const SurveySettings = () => {
  const { npsForm, isFetching, isEditMode, isSubmitting } = useContext(PortalSettingsContext)

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={12}>
        <TypographyDivider label="NPS Survey" propsTypo={{ variant: 'subtitle1' }} />
      </Grid>
      <Grid item xs={12}>
        {isFetching ? <NpsSettingsLoading /> : <NpsSettings form={npsForm} isDisabled={!isEditMode || isSubmitting} />}
      </Grid>
    </Grid>
  )
}

export default SurveySettings
