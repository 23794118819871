/** ----------------- PATHNAME -------------------- */
export const integratedAppIdPath = ':appId'
export const integratedAppNewPath = 'new'
export const integratedAppListPath = 'apps'
export const integratedAppIdTab = 'tabPath'
export const integratedAppIdTokenListPath = 'tokens'
export const integratedAppIdDetailPath = 'detail'
export const integratedAppIdEditPath = 'edit'
export const intergatedInternalAppListPath = 'internal-applications'

/** ----------------- ROUTE -------------------- */
export const integrationManagementPageURL = '/integration-management'
export const integrationManagementFlexURL = '/integration-management/:path'
export const integrationManagementIntegratedAppListURL = `${integrationManagementPageURL}/${integratedAppListPath}`
export const integrationManagementIntegratedAppTokenURL = `${integrationManagementPageURL}/${integratedAppListPath}/${integratedAppIdPath}/${integratedAppIdTokenListPath}`
export const integrationManagementNewIntegratedAppURL = `${integrationManagementPageURL}/${integratedAppListPath}/${integratedAppNewPath}`
export const integrationManagementIntegratedAppDetailFlexURL = `${integrationManagementPageURL}/${integratedAppListPath}/${integratedAppIdPath}`
export const integrationManagementInternalAppListPageURL = `${integrationManagementPageURL}/${intergatedInternalAppListPath}`
