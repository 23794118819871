import { systemManagementPageURL } from './systemManagementRoutes'

/** ----------------- PATHNAME -------------------- */
export const releaseInformationPath = 'release-information'
export const releaseInformationOptionalIdPath = ':infoId?'
export const releaseInformationNewPath = 'create'
export const releaseInformationEditPath = 'edit'
export const releaseInformationEventLogsPath = 'event-logs'

/** ----------------- ROUTE -------------------- */
export const systemManagementReleaseInformationPageURL = `${systemManagementPageURL}/${releaseInformationPath}`
export const systemManagementReleaseInformationCreatePageURL = `${systemManagementReleaseInformationPageURL}/${releaseInformationOptionalIdPath}`
export const systemManagementReleaseInformationEventLogsPageURL = `${systemManagementReleaseInformationPageURL}/${releaseInformationEventLogsPath}`
