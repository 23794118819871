import yup from 'myopswat-admin/src/utils/yup'

import { RELEASE_INFORMATION_LEVEL } from 'myopswat-admin/src/api/product/types'

export const releaseInformationSchema = yup.object().shape({
  id: yup.string(),
  productId: yup.string().trim().required('Title cannot be blank'),
  version: yup
    .string()
    .trim()
    .required('Version cannot be blank')
    .matches(/\d+\.\d+\.\d+/, 'Version value should contain a semantic version X.Y.Z'),
  releaseLevel: yup.string().trim().required('Release level cannot be blank'),
  platformIds: yup.array().min(1, 'Platform cannot be blank'),
  sendReleaseEmail: yup.boolean().optional(),
  updatedReason: yup.string().trim().optional(),
  featureHeader: yup
    .string()
    .trim()
    .optional()
    .test('featureHeader', 'Feature header cannot be blank', function (value: any) {
      // If release level is not tier 3, then feature headline is required
      if (this.parent.releaseLevel !== RELEASE_INFORMATION_LEVEL.TIER_3) {
        return Boolean(value)
      }

      // If release level is tier 3 and send release email is checked, then feature headline is required
      if (this.parent.sendReleaseEmail) {
        return Boolean(value)
      }

      return true
    }),
  featureDescription: yup
    .string()
    .trim()
    .optional()
    .test('featureDescription', 'Feature Description cannot be blank', function (value: any) {
      // If release level is not tier 3, then feature headline is required
      if (this.parent.releaseLevel !== RELEASE_INFORMATION_LEVEL.TIER_3) {
        return Boolean(value)
      }

      // If release level is tier 3 and send release email is checked, then feature headline is required
      if (this.parent.sendReleaseEmail) {
        return Boolean(value)
      }

      return true
    })
})
