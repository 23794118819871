import _template from 'lodash/template'
import _unescape from 'lodash/unescape'

import { FC, useCallback } from 'react'
import { utils, writeFile } from 'xlsx'

import { DownloadIcon } from '@opswat/react-icon'
import { Box, Button, Grid, TextField, Typography, useTheme } from '@opswat/react-ui'

import { EMAIL_TEMPLATE } from '../interface'

interface IProps {
  emailList: string[] | undefined
  emailContent: string | undefined
}

const ComposeEmailConfirm: FC<IProps> = ({ emailList, emailContent }) => {
  const theme = useTheme()

  const renderPreview = _template(EMAIL_TEMPLATE)

  const exporConfirmRecipients = useCallback(() => {
    if (emailList) {
      const rows = emailList.map((email: string) => ({ email }))

      const worksheet = utils.json_to_sheet(rows, { skipHeader: true })
      const workbook = utils.book_new()
      utils.book_append_sheet(workbook, worksheet)

      const maxWidth = rows.reduce((width: number, row: any) => Math.max(width, row.email.length), 10)
      worksheet['!cols'] = [{ wch: maxWidth }]

      writeFile(workbook, 'Mass Email - Recipient List.xlsx', { compression: true })
    }
  }, [emailList])

  return (
    <Grid container item xs={12} columnSpacing={6} rowSpacing={2.5}>
      <Grid item xs={12}>
        <Typography variant="body2">
          You are about to send this email to
          <Typography component="span" fontWeight={500} fontSize={24} color="#D00300">{` ${
            emailList ? emailList.length : 0
          } `}</Typography>
          <Typography component="span" variant="body2">{`recipient${
            emailList && emailList.length > 1 ? 's' : ''
          }`}</Typography>
          . Please double-check your recipient list and the email content before hitting send.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid item xs={12} py={0.5}>
          <Typography component="label" height="20px">
            {'Recipient list'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            disabled
            size="small"
            value={emailList?.join('; ') ?? ''}
            sx={{
              minHeight: 'auto',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.secondary
              },
              '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.disabled
              },
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: '#CB0704 !important'
              },
              '& .MuiOutlinedInput-root.Mui-disabled': {
                paddingRight: 0
              },
              '& textarea': {
                maxHeight: '52vh',
                overflowY: 'auto !important',
                '&::-webkit-scrollbar': {
                  width: '7px',
                  height: '7px'
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '20px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: '#BCBFC3',
                  backgroundColor: '#BCBFC3'
                }
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          pt={1}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            disableElevation
            variant="text"
            color="inherit"
            onClick={exporConfirmRecipients}
            sx={{
              textTransform: 'none',
              color: '#1B273C',
              alignItems: 'center',
              gap: '4px'
            }}
          >
            {'Download list'}
            <DownloadIcon size={18} color="#1B273C" />
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid item xs={12} py={0.5}>
          <Typography component="label" height="20px">
            {'Content'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              boxShadow: '0px 2px 8px 0px rgba(10, 36, 86, 0.15)',
              '& .email-container': {
                display: 'block !important',
                minHeight: 'auto !important',
                maxHeight: 'calc(52vh + 2 * 8.5px + 32px)'
              },
              '& .email-body': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }
            }}
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: renderPreview({ content: _unescape(String(emailContent)) })
              }}
              sx={{
                display: 'grid',
                overflowX: 'auto',
                '&::-webkit-scrollbar': {
                  width: '7px',
                  height: '7px'
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '20px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: '#BCBFC3',
                  backgroundColor: '#BCBFC3'
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ComposeEmailConfirm
