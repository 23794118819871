import { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react'

import _debounce from 'lodash/debounce'

import Quill from 'quill'
import 'quill/dist/quill.snow.css'
import './quill.css'

import {
  ListViewIcon,
  NumberedListIcon,
  TextBoldIcon,
  TextItalicIcon,
  TextLinkIcon,
  TextUnderlineIcon
} from '@opswat/react-icon'
import { Box, Typography } from '@opswat/react-ui'

import ToolbarButton from './ToolbarButton'

const EDITOR_MAX_LENGTH = 250

interface IProps {
  value: any
  disabled?: boolean
  onChange: (event: any) => void
}

const QuillEditor: FC<IProps> = ({ value, disabled, onChange }) => {
  const [quillInstance, setQuillInstance] = useState<Quill>()

  useLayoutEffect(() => {
    // initialize the quill editor
    const quill = new Quill('#quill-editor', {
      modules: {
        toolbar: '#quill-editor-toolbar'
      },
      theme: 'snow'
    })
    if (value) quill.clipboard.dangerouslyPasteHTML(value)
    if (disabled) quill.disable()

    // disable formatting when pasting
    quill.clipboard.addMatcher(Node.ELEMENT_NODE, (_, delta) => {
      const ops: any[] = []
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert
          })
        }
      })
      delta.ops = ops
      return delta
    })

    // prevent input when max length is reached
    quill.on('text-change', () => {
      if (quill.getText().length > EDITOR_MAX_LENGTH) {
        quill.deleteText(EDITOR_MAX_LENGTH, quill.getText().length)
      }
    })

    setQuillInstance(quill)
  }, [])

  useEffect(() => {
    disabled ? quillInstance?.disable() : quillInstance?.enable()
  }, [disabled])

  useEffect(() => {
    const editorNode = document.querySelector('.ql-editor')

    if (editorNode) {
      // mutation observer to listen to changes
      const config = { attributes: true, childList: true, subtree: true, characterData: true }
      const mutationCallback = _debounce(() => {
        const content = quillInstance?.getSemanticHTML()
        content && onChange({ target: { value: content } })
      }, 100)

      const observer = new MutationObserver(mutationCallback)
      observer.observe(editorNode, config)

      // cleanup
      return () => {
        observer.disconnect()
      }
    }
  }, [quillInstance])

  const UnderlineIcon = useCallback(() => {
    return <TextUnderlineIcon size={17} color="#757575" />
  }, [])

  const NumberListIcon = useCallback(() => {
    return <NumberedListIcon size={14} color="#757575" />
  }, [])

  return (
    <>
      <Box
        id="quill-editor"
        sx={{
          '& .ql-tooltip': { zIndex: 2, Left: 0 },
          '& .ql-editor': {
            resize: 'vertical',
            minHeight: '300px'
          }
        }}
      />
      <Box
        id="quill-editor-toolbar"
        sx={{
          border: 'none !important',
          padding: '0px !important',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box sx={{ width: '50%' }}>
          <span className="ql-formats">
            <ToolbarButton className="ql-bold" Icon={TextBoldIcon} />
            <ToolbarButton className="ql-italic" Icon={TextItalicIcon} />
            <ToolbarButton className="ql-underline" Icon={UnderlineIcon} />
          </span>
          <span className="ql-formats">
            <ToolbarButton className="ql-list" value="bullet" Icon={ListViewIcon} />
            <ToolbarButton className="ql-list" value="ordered" Icon={NumberListIcon} />
            <ToolbarButton className="ql-link" Icon={TextLinkIcon} />
          </span>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 0.5, width: '50%' }}>
          <Typography variant="caption" color="#707ea4">
            {quillInstance?.getText().trimEnd().length}/{EDITOR_MAX_LENGTH}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default QuillEditor
