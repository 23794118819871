import _union from 'lodash/union'

import { useContext, useMemo } from 'react'

import { useWatch } from 'react-hook-form'

import { RemoveIcon } from '@opswat/react-icon'
import { Box, ButtonLoading, Grid, Typography } from '@opswat/react-ui'

import { ComposeEmailContext } from '../interface'
import { handleMapRequest } from '../utils'

const ComposeEmailActions = () => {
  const {
    control,
    isCreating,
    isUpdating,
    isDeleting,
    handleSubmit,
    handleSubmitSend,
    handleSubmitDraft,
    handleResetForm
  } = useContext(ComposeEmailContext)

  const values = useWatch({ control })
  const deliveryStatus = useWatch({ control, name: 'deliveryStatus' })
  const isPending = ['Pending'].includes(deliveryStatus)
  const disableDiscard = values.id !== undefined

  const emailList = useMemo(() => {
    const request = handleMapRequest(values)
    return _union(request.relatedProductsRecipientEmails, request.recipientEmails)
  }, [values])

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        borderTop: '1px solid #e7e9ee',
        backgroundColor: '#ffffff',
        position: 'sticky',
        bottom: 0,
        paddingY: '16px !important',
        paddingRight: '16px'
      }}
    >
      <ButtonLoading
        propsButton={{
          variant: 'text',
          color: 'inherit',
          disableElevation: true,
          disabled: disableDiscard,
          onClick: handleResetForm
        }}
        isLoading={isDeleting}
        propsLoading={{ color: 'inherit', fillColor: '#e0e0e0' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <RemoveIcon size={16} color={disableDiscard ? '#bdbdbd' : '#3D4A68'} />
          Discard
        </Box>
      </ButtonLoading>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
          <ButtonLoading
            propsButton={{
              variant: 'outlined',
              color: 'inherit',
              disableElevation: true,
              disabled: isCreating || isUpdating || isPending,
              onClick: () => handleSubmitDraft(values)
            }}
            isLoading={isCreating || isUpdating}
            propsLoading={{ color: 'inherit', fillColor: '#e0e0e0' }}
          >
            Save As Draft
          </ButtonLoading>
          <ButtonLoading
            propsButton={{
              variant: 'contained',
              color: 'primary',
              disableElevation: true,
              onClick: handleSubmit(handleSubmitSend)
            }}
            isLoading={isCreating || isUpdating}
            propsLoading={{ color: 'inherit' }}
          >
            Send
          </ButtonLoading>
        </Box>
        {emailList.length > 0 && (
          <Typography variant="body2">
            {'The final recipient list includes approximately '}
            <Typography component="span" fontWeight={500} color="#D00300">
              {emailList.length}
            </Typography>
            {`  email${emailList.length > 1 ? 's' : ''}.`}
          </Typography>
        )}
      </Box>
    </Grid>
  )
}

export default ComposeEmailActions
