import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ControllerRenderProps, useFormState, useWatch } from 'react-hook-form'
import { utils, writeFile } from 'xlsx'

import { utcToZonedTime } from 'date-fns-tz'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import { DownloadIcon, InformationIcon, WarningIcon } from '@opswat/react-icon'
import {
  Box,
  CheckboxWithLabel,
  DateInput,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Tooltip,
  Typography
} from '@opswat/react-ui'

import { ComposeEmailField } from '.'
import { ComposeAutocomplete, ComposeRadioGroup, ComposeTextArea, ComposeTextField, ComposeTextUpload } from '../fields'
import { ComposeEmailContext } from '../interface'
import { MAX_EMAIL_TOOLTIP_LIMIT, UTC_TIMEZONE_CODE, handleMapEmailList } from '../utils'

const ComposeEmailForm = () => {
  const {
    control,
    setValue,
    viewMode,
    productOptions,
    isLoadingEmail,
    isLoadingProducts,
    isLoadingRecipients,
    handleSetIsCriticalAlert
  } = useContext(ComposeEmailContext)

  const [showAdditionalRecipients, setShowAdditionalRecipients] = useState<boolean>(false)

  const { errors } = useFormState({ control })

  const showScheduleTime = useWatch({ control, name: 'sendType' }) === 'SCHEDULED'
  const isDraftOrPending = ['Draft', 'Pending'].includes(useWatch({ control, name: 'deliveryStatus' }))
  const enableEditing = (isDraftOrPending && viewMode === 'edit') || viewMode === 'create'
  const recipients = useWatch({ control, name: 'recipients' })
  const relatedProductsRecipients = useWatch({ control, name: 'productRecipientsText' })
  const productRecipientsTooltip = useWatch({ control, name: 'productRecipientsTooltip' })
  const productList = useWatch({ control, name: 'products' })
  const isCriticalAlert = useWatch({ control, name: 'isCriticalAlert' })
  const relatedProductsEmails = useWatch({ control, name: 'relatedProductsRecipientEmails' })

  const [requireRecipients, setRequireRecipients] = useState<boolean>(false)

  const exportRelatedProductsRecipients = useCallback(() => {
    const rows = relatedProductsEmails.map((email: string) => ({ email }))

    const worksheet = utils.json_to_sheet(rows, { skipHeader: true })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet)

    const maxWidth = rows.reduce((width: number, row: any) => Math.max(width, row.email.length), 10)
    worksheet['!cols'] = [{ wch: maxWidth }]

    writeFile(workbook, 'Mass Email.xlsx', { compression: true })
  }, [relatedProductsEmails])

  const enableCriticalAlerts = useMemo(
    () => productList.filter((product: any) => !['ALL_ACTIVE_USERS'].includes(product.value)).length > 0,
    [productList]
  )

  const renderEmailTitle = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeTextField {...props} disabled={!enableEditing} placeholder={'Email Title'} />
    ),
    [enableEditing]
  )

  const renderEmailRecipients = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeTextUpload
        {...props}
        disabled={!enableEditing}
        placeholder={'Type an email or upload a file'}
        tooltip={props.value && handleMapEmailList(props.value?.split(';'), MAX_EMAIL_TOOLTIP_LIMIT, '\n')}
      />
    ),
    [enableEditing]
  )

  const renderEmailProductRecipients = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeTextField
        {...props}
        disabled
        placeholder={'Emails auto-fill after choosing products'}
        tooltip={productRecipientsTooltip}
        endAdornment={
          <InputAdornment position="end">
            <IconButton disabled={_isEmpty(productRecipientsTooltip)} onClick={exportRelatedProductsRecipients}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '-1px',
                  marginRight: '3px'
                }}
              >
                <DownloadIcon color={_isEmpty(productRecipientsTooltip) ? '#707682' : '#1B273C'} />
              </Box>
            </IconButton>
          </InputAdornment>
        }
      />
    ),
    [productRecipientsTooltip, exportRelatedProductsRecipients]
  )

  const renderEmailProducts = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeAutocomplete
        {...props}
        errors={errors}
        disabled={!enableEditing}
        options={productOptions}
        placeholder={'Select Products'}
      />
    ),
    [enableEditing, productOptions, errors]
  )

  const renderIsCriticalAlert = useCallback(
    () => (
      <Box
        sx={{
          height: '20px',
          '& .MuiFormControlLabel-root': {
            height: '20px'
          },
          paddingLeft: 0.5
        }}
      >
        <Switch
          disabled={!enableEditing}
          checked={isCriticalAlert}
          onClick={(e: any) => {
            handleSetIsCriticalAlert(e.target.checked)
          }}
          label={'Critical Alerts subscribers only'}
          sx={{
            '& .MuiSwitch-track': {
              width: '40px !important',
              height: '20px !important',
              backgroundColor: `${isCriticalAlert ? '#1D6BFC' : '#bdbdbd'} !important`,
              opacity: '1 !important',
              borderRadius: '50px !important',
              border: '0px !important',
              '&::before, &::after': {
                content: '""',
                position: 'absolute',
                top: '40%',
                transform: 'translateY(-50%)',
                width: '12px',
                height: '12px',
                color: '#FFFFF'
              },
              '&::before': {
                left: '12px',
                top: '40%',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
              },
              '&::after': {
                right: '16px',
                top: '42%',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 16 16"><path xmlns="http://www.w3.org/2000/svg" d="M5 11L11 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path xmlns="http://www.w3.org/2000/svg" d="M11 11L5 5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>')`
              }
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: '15px',
              height: '15px',
              margin: '1px 2px 0px 2px',
              borderRadius: '50px',
              backgroundColor: 'white !important'
            }
          }}
        />
      </Box>
    ),
    [isCriticalAlert, enableEditing]
  )

  const renderEmailContent = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeTextArea {...props} disabled={!enableEditing} placeholder={'Write something here'} />
    ),
    [enableEditing]
  )

  const renderEmailPurpose = useCallback(
    (props: ControllerRenderProps) => (
      <ComposeTextField {...props} disabled={!enableEditing} placeholder={'Sending Purpose'} />
    ),
    [enableEditing]
  )

  const renderDateInput = useCallback(
    (props: ControllerRenderProps) => (
      <DateInput {...props} minDate={utcToZonedTime(new Date(), UTC_TIMEZONE_CODE)} disabled={!enableEditing} />
    ),
    [enableEditing]
  )

  const renderEmailSendTime = useCallback(
    (props: ControllerRenderProps) => (
      <>
        <ComposeRadioGroup
          {...props}
          options={[
            { label: 'Send immediately', value: 'IMMEDIATELY' },
            { label: 'Schedule time', value: 'SCHEDULED' }
          ]}
          disabled={!enableEditing}
        />
        {showScheduleTime && (
          <ComposeEmailField
            control={control}
            title={''}
            fieldName={'scheduleTime'}
            gridProps={{
              sx: {
                maxWidth: 'calc(320px + 12px) !important'
              }
            }}
            Component={renderDateInput}
          />
        )}
      </>
    ),
    [enableEditing, showScheduleTime, renderDateInput]
  )

  useEffect(() => {
    setRequireRecipients(_isEmpty(recipients) && _isEmpty(relatedProductsRecipients))
  }, [recipients, relatedProductsRecipients])

  useEffect(() => {
    if (productList.length > 0) {
      setShowAdditionalRecipients(true)
    }
  }, [productList])

  return (
    <Grid container columnSpacing={1.5} rowSpacing={4}>
      <ComposeEmailField
        control={control}
        title={'Email Title'}
        fieldName={'title'}
        isLoading={isLoadingEmail}
        rules={{
          required: 'Email title cannot be blank'
        }}
        Component={renderEmailTitle}
      />

      <Grid
        item
        container
        xs={12}
        sx={{
          display: 'flex',
          gap: 2
        }}
      >
        <Grid item xs={12} sx={{}}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography component="label">
              Send To
              <Typography component="span" color="error">
                {'*'}
              </Typography>
            </Typography>
            <Tooltip
              arrow
              placement="top-start"
              title="Ensure that at least one field is completed for recipient emails. We advise against using Gmail addresses to minimize the risk of emails being flagged as spam."
              componentsProps={{
                tooltip: {
                  sx: {
                    color: '#1B273C',
                    backgroundColor: '#E9EAEB',
                    whiteSpace: 'pre-line',
                    minWidth: '400px'
                  }
                },
                arrow: {
                  sx: {
                    color: '#E9EAEB'
                  }
                }
              }}
            >
              <span style={{ height: '18px' }}>
                <InformationIcon color="#8E939B" size={16} />
              </span>
            </Tooltip>
          </Box>

          {_get(errors, ['recipients', 'message']) && (
            <FormHelperText
              error
              component="label"
              style={{
                wordBreak: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {_get(errors, ['recipients', 'message'])}
            </FormHelperText>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ComposeEmailField
            hideRequiredMark
            control={control}
            title={'Add recipients manually'}
            fieldName={'recipients'}
            isLoading={isLoadingEmail}
            Component={renderEmailRecipients}
            rules={{
              required: {
                value: requireRecipients,
                message: 'Please ensure that at least one field is filled out for recipient emails before proceeding'
              },
              validate: (value: string) => {
                if (_isEmpty(value)) {
                  return true
                }
                const regEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                const emails: string[] = value.split(';').map(item => item.trim())
                const invalidEmails = emails.filter(email => !regEx.test(email))
                return (
                  invalidEmails.length === 0 || `Please enter the correct email structure: ${invalidEmails.join(', ')}`
                )
              }
            }}
            labelProps={{
              fontSize: '13px'
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CheckboxWithLabel
            label={
              <Typography variant="subtitle2">
                Additionally, automatically retrieve the recipient list from the available options.
              </Typography>
            }
            checkboxProps={{
              disabled: !enableEditing,
              checked: showAdditionalRecipients,
              onChange: (e: any) => {
                setShowAdditionalRecipients(e.target.checked)
                if (e.target.checked) {
                  setValue('products', [])
                }
              }
            }}
            restProps={{
              sx: {
                width: 'fit-content',
                paddingLeft: 0.5
              }
            }}
          />
        </Grid>

        {showAdditionalRecipients && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <ComposeEmailField
              control={control}
              title={'Add recipients from'}
              titleIcon={
                <Tooltip
                  arrow
                  placement="top-start"
                  title={`You can select a product, and the system will automatically retrieve the emails of all users who have licenses or permissions for that product, populating them into the recipients field. The Admin cannot edit these emails, as the field is read-only. If you want to customize the list, you can download the emails to a CSV file, make updates, and then import them into the "Add recipients manually" field.`}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#1B273C',
                        backgroundColor: '#E9EAEB',
                        whiteSpace: 'pre-line',
                        minWidth: '400px'
                      }
                    },
                    arrow: {
                      sx: {
                        color: '#E9EAEB'
                      }
                    }
                  }}
                >
                  <span style={{ height: '16px' }}>
                    <WarningIcon size={16} />
                  </span>
                </Tooltip>
              }
              fieldName={'products'}
              isLoading={isLoadingEmail}
              isFetching={isLoadingProducts}
              Component={renderEmailProducts}
              labelProps={{
                fontSize: '13px',
                sx: {
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5
                }
              }}
            />

            <ComposeEmailField
              control={control}
              title={''}
              fieldName={'productRecipientsText'}
              isLoading={isLoadingEmail}
              isFetching={isLoadingRecipients}
              Component={renderEmailProductRecipients}
              rules={{
                required: {
                  value: requireRecipients,
                  message: ''
                }
              }}
            />
            {enableCriticalAlerts && renderIsCriticalAlert()}
          </Grid>
        )}
      </Grid>

      <ComposeEmailField
        control={control}
        title={'Content'}
        fieldName={'content'}
        isLoading={isLoadingEmail}
        rules={{
          required: 'Content cannot be blank'
        }}
        Component={renderEmailContent}
      />

      <ComposeEmailField
        control={control}
        title={'Sending Purpose'}
        fieldName={'sendingPurpose'}
        isLoading={isLoadingEmail}
        rules={{
          required: 'Sending purpose cannot be blank'
        }}
        Component={renderEmailPurpose}
      />

      <ComposeEmailField
        control={control}
        title={'Send Time'}
        fieldName={'sendType'}
        isLoading={isLoadingEmail}
        rules={{
          required: 'Send time cannot be blank'
        }}
        Component={renderEmailSendTime}
      />
    </Grid>
  )
}

export default ComposeEmailForm
