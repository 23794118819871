import { Box, OpswatCheckbox, TableAdvanced, TextField, TextGrid, Typography } from '@opswat/react-ui'
import _isEmpty from 'lodash/isEmpty'
import _size from 'lodash/size'
import _union from 'lodash/union'
import GridView from 'myopswat-admin/src/components/Grid/GridView'
import { useEffect, useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { IRoleForm } from './RoleSchema'

interface IProps {
  formRef: UseFormReturn<IRoleForm>
  permissionCodenames: any
  isFetchingPermissionData: boolean
  contentTypePermissions: any
  selectedPermissions: string[]
  setSelectedPermissions: (value: string[]) => void
}

const RoleForm = ({
  formRef,
  permissionCodenames,
  isFetchingPermissionData,
  contentTypePermissions,
  selectedPermissions,
  setSelectedPermissions
}: IProps) => {
  const {
    register,
    formState: { errors }
  } = formRef

  const [selectAllItems, setSelectAllItems] = useState<string[]>([])
  const [isSelectAllCalculation, setIsSelectAllCalculation] = useState<boolean>(false)

  const isPermissionExisted = (mappingName: string, action: string) => {
    const permission = `${action.toLowerCase()}_${mappingName}`
    return permissionCodenames && permissionCodenames.includes(permission)
  }

  const onSelectAll = (mappingName: string, newValue: boolean) => {
    const contentType = contentTypePermissions.filter((item: any) => item.mappingName === mappingName)[0]
    const permissionsOfType = contentType.permissions
      .concat(contentType.otherPermissions)
      .map((perm: any) => perm.codename)

    if (newValue) {
      setSelectedPermissions(_union(selectedPermissions, permissionsOfType))
      setSelectAllItems([...selectAllItems, mappingName])
    } else {
      setSelectedPermissions(selectedPermissions.filter((item: any) => !permissionsOfType.includes(item)))
      setSelectAllItems(selectAllItems.filter((item: any) => item != mappingName))
    }
  }

  const onPermissionChange = (mappingName: string, permission: string, newValue: boolean, action: string | null) => {
    const parsedPermission = action === null ? permission : `${action.toLowerCase()}_${mappingName}`
    let permissions: string[] = []
    if (newValue) {
      permissions = [...selectedPermissions, parsedPermission]
    } else {
      permissions = selectedPermissions.filter((item: string) => item != parsedPermission)
    }
    setSelectedPermissions(permissions)

    const contentType = contentTypePermissions.filter((item: any) => item.mappingName === mappingName)[0]
    const permissionsOfType = contentType.permissions
      .concat(contentType.otherPermissions)
      .map((perm: any) => perm.codename)
    if (permissionsOfType.every((item: string) => permissions.includes(item))) {
      setSelectAllItems([...selectAllItems, contentType.mappingName])
    } else if (selectAllItems.includes(contentType.mappingName)) {
      setSelectAllItems(selectAllItems.filter((item: any) => item != contentType.mappingName))
    }
  }

  useEffect(() => {
    if (contentTypePermissions && !_isEmpty(selectedPermissions) && !isSelectAllCalculation) {
      const selectAllResults: string[] = []
      contentTypePermissions.forEach((contentType: any) => {
        const permissionsOfType = contentType.permissions
          .concat(contentType.otherPermissions)
          .map((perm: any) => perm.codename)
        if (permissionsOfType.every((item: string) => selectedPermissions.includes(item))) {
          selectAllResults.push(contentType.mappingName)
        }
      })
      setSelectAllItems(selectAllResults)
      setIsSelectAllCalculation(true)
    }
  }, [contentTypePermissions, selectedPermissions])

  const columns = useMemo(() => {
    return [
      {
        header: '',
        body: (data: any) => <Typography variant="body2">{data.displayName || '--'}</Typography>,
        style: { minWidth: 250, width: 250 }
      },
      {
        header: '',
        body: (data: any) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 1 }}>
            <Typography sx={{ mr: 2 }}>
              {selectAllItems.includes(data.mappingName) ? 'Deselect All' : 'Select All'}
            </Typography>
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              key={`all_${data.mappingName}_${Date.now()}`}
              checked={selectAllItems.includes(data.mappingName)}
              onChange={(event: any) => {
                onSelectAll(data.mappingName, event.target.checked)
              }}
            />
          </Box>
        ),
        style: { minWidth: 120, width: 120 }
      },
      {
        header: 'View',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'view') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              key={`view_${data.mappingName}_${Date.now()}`}
              checked={selectedPermissions.includes(`view_${data.mappingName}`)}
              onChange={(event: any) => {
                onPermissionChange(data.mappingName, '', event.target.checked, 'view')
              }}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Add',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'add') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              key={`add_${data.mappingName}_${Date.now()}`}
              checked={selectedPermissions.includes(`add_${data.mappingName}`)}
              onChange={(event: any) => {
                onPermissionChange(data.mappingName, '', event.target.checked, 'add')
              }}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Change',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'change') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              key={`change_${data.mappingName}_${Date.now()}`}
              checked={selectedPermissions.includes(`change_${data.mappingName}`)}
              onChange={(event: any) => {
                onPermissionChange(data.mappingName, '', event.target.checked, 'change')
              }}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'Delete',
        body: (data: any) =>
          isPermissionExisted(data.mappingName, 'delete') && (
            <OpswatCheckbox
              labelStyle={{ mx: 0 }}
              sx={{ margin: 'auto' }}
              label=""
              key={`delete_${data.mappingName}_${Date.now()}`}
              checked={selectedPermissions.includes(`delete_${data.mappingName}`)}
              onChange={(event: any) => {
                onPermissionChange(data.mappingName, '', event.target.checked, 'delete')
              }}
            />
          ),
        style: { minWidth: 100, width: 100, textAlign: 'center' }
      },
      {
        header: 'More',
        body: (data: any) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 1 }}>
            {data.otherPermissions.map((item: any, idx: number) => (
              <Box sx={{ display: 'flex', flexWrap: 'nowrap' }} key={idx.toString()}>
                <OpswatCheckbox
                  labelStyle={{ mx: 0, gap: 1 }}
                  sx={{ margin: 'auto' }}
                  label=""
                  key={`${item.codename}_${Date.now()}`}
                  checked={selectedPermissions.includes(item.codename)}
                  onChange={(event: any) => {
                    onPermissionChange(data.mappingName, item.codename, event.target.checked, null)
                  }}
                />
                <Typography sx={{ mr: 2, my: 0.5 }}>{item.name}</Typography>
              </Box>
            ))}
          </Box>
        ),
        style: { textAlign: 'center' }
      }
    ]
  }, [selectedPermissions, selectAllItems])

  return (
    <Box>
      {!isFetchingPermissionData && _size(permissionCodenames) === 0 && (
        <Typography textAlign="center" variant="body2" color="textSecondary">
          There is nothing to display here.
        </Typography>
      )}
      {_size(permissionCodenames) > 0 && (
        <Box>
          <GridView
            label={'Name*'}
            value={
              <TextField
                size="small"
                fullWidth
                error={'name' in errors}
                {...register('name')}
                helperText={errors.name?.message}
              />
            }
          />
          <GridView
            label={'Description'}
            value={
              <TextField
                size="small"
                fullWidth
                rows={6}
                multiline
                error={'description' in errors}
                {...register('description')}
                helperText={errors.description?.message}
              />
            }
          />
          <TextGrid
            label={'Permissions'}
            containerProps={{ alignItems: 'top' }}
            labelColProps={{ md: 2 }}
            value={
              <Box sx={{ maxHeight: '800px', overflowY: 'scroll' }}>
                <TableAdvanced boxMinHeight="60vh" columns={columns} isLoading={false} data={contentTypePermissions} />
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  )
}
export default RoleForm
