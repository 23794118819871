import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import _isEmpty from 'lodash/isEmpty'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CircleBlockIcon, CircleCheckIcon, PendingIcon } from '@opswat/react-icon'
import { ActionButton, Box, Typography, TypographyLineClamp } from '@opswat/react-ui'

import { useAdminMassNotifyDeleteMutation, useLazyAdminMassNotifyListQuery } from 'myopswat-admin/src/api/massEmail'
import { IMassNotificationInputType } from 'myopswat-admin/src/api/massEmail/types'
import useDialog from 'myopswat-admin/src/components/Dialog/DialogHook'
import useSearch from 'myopswat-admin/src/hooks/useSearch'
import { notificationManagementComposeEmailBaseURL } from 'myopswat-admin/src/routes'

import MassNotificationPage from '.'
import { UTC_TIMEZONE_CODE } from './ComposeEmailPage/utils'
import { MassNotificationContext } from './interface'

interface IProps {
  permission: any
}

const MassNotificationProvider: React.FC<IProps> = ({ permission }) => {
  const navigate = useNavigate()
  const dialog = useDialog()
  const { enqueueSnackbar } = useSnackbar()

  const [deleteEmail] = useAdminMassNotifyDeleteMutation()

  const searchData = useSearch<IMassNotificationInputType>({
    searchCallback: useLazyAdminMassNotifyListQuery
  })

  const handleDelete = useCallback(async (emailId?: string) => {
    const confirmed = await dialog.openConfirmation({
      content: `Are you sure you want to delete this email notification?`
    })
    if (confirmed && emailId) {
      await deleteEmail(emailId)
        .unwrap()
        .then(async data => {
          if (data?.success) {
            await enqueueSnackbar('Deleted email notification successfully', {
              variant: 'success'
            })
            searchData.handleSearch()
          } else {
            await enqueueSnackbar(
              'Deleting email notification has failed. Please give the system a moment then try again.',
              {
                variant: 'error'
              }
            )
          }
        })
        .catch(error => {
          console.log('log: error', error)
        })
    }
  }, [])

  const getTitleColumn = useCallback(
    (data: IMassNotificationInputType) => (
      <TypographyLineClamp
        line={1}
        tooltipValue={`${data.deliveryStatus?.toUpperCase() === 'DRAFT' ? '[Draft]' : ''} ${data.title ?? ''}`}
      >
        <Typography variant="body2" component="span" style={{ fontStyle: 'italic' }}>{`${
          data.deliveryStatus?.toUpperCase() === 'DRAFT' ? '[Draft]' : ''
        }`}</Typography>
        <Typography variant="body1" component="span">{` ${data.title ?? ''}`}</Typography>
      </TypographyLineClamp>
    ),
    []
  )

  const getAuthorColumn = useCallback(
    (data: IMassNotificationInputType) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.creator?.email}>
        {data.creator?.email ?? '--'}
      </TypographyLineClamp>
    ),
    []
  )

  const getProductColumn = useCallback((data: IMassNotificationInputType) => {
    const products = data.products?.map((item: any) => item.name).join(', ')
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={products}>
        {data.products && data.products.length > 0 ? products : '--'}
      </TypographyLineClamp>
    )
  }, [])

  const getCriticalAlertColumn = useCallback(
    (data: IMassNotificationInputType) => (
      <TypographyLineClamp line={1} variant="body2">
        {data.isCriticalAlert ? 'Yes' : 'No'}
      </TypographyLineClamp>
    ),
    []
  )

  const getPurposeColumn = useCallback(
    (data: IMassNotificationInputType) => (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={data.sendingPurpose}>
        {_isEmpty(data.sendingPurpose) ? '--' : data.sendingPurpose}
      </TypographyLineClamp>
    ),
    []
  )

  const getStatusColumn = useCallback((data: IMassNotificationInputType) => {
    const status = data.deliveryStatus
    const label = status === 'Draft' ? '--' : status
    let color = '#3D4A68'
    let Icon: any
    switch (status) {
      case 'Pending':
        color = '#ED6706'
        Icon = <PendingIcon color={color} size={18} />
        break
      case 'Partially Delivered':
        color = '#313C4E'
        Icon = <PendingIcon color={color} size={18} />
        break
      case 'Delivered':
        color = '#008A00'
        Icon = <CircleCheckIcon color={color} size={20} />
        break
      case 'Failed':
        color = '#D00300'
        Icon = <CircleBlockIcon color={color} size={18} />
        break
    }
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {status !== 'Draft' && Icon}
        <TypographyLineClamp
          variant="body2"
          line={1}
          fontWeight={500}
          tooltipValue={status}
          sx={{
            color
          }}
        >
          {label}
        </TypographyLineClamp>
      </Box>
    )
  }, [])

  const getSentTimeColumn = useCallback((data: IMassNotificationInputType) => {
    const dateFormat = `${DATE_FORMATS.DATE_TIME} aa`
    const dateValue = data.sendAt ? `${formatDatetime(data.sendAt, dateFormat, UTC_TIMEZONE_CODE)} UTC` : '--'
    return (
      <TypographyLineClamp line={1} variant="body2" tooltipValue={dateValue}>
        {dateValue}
      </TypographyLineClamp>
    )
  }, [])

  const getActionColumn = useCallback((data: IMassNotificationInputType) => {
    const status = data.deliveryStatus?.toUpperCase() ?? ''
    const items: any[] = []
    if (['DRAFT', 'PENDING'].includes(status)) {
      if (permission['delete_massmail']) {
        items.unshift({
          label: 'Delete',
          onClick: () => handleDelete(data.id)
        })
      }
      if (permission['change_massmail']) {
        items.unshift({
          label: 'Edit',
          onClick: () => {
            navigate({
              pathname: `${notificationManagementComposeEmailBaseURL}/${data.id}`,
              search: createSearchParams({ mode: 'edit' }).toString()
            })
          }
        })
      }
    }
    if (!['DRAFT'].includes(status)) {
      if (permission['view_massmail']) {
        items.unshift({
          label: 'View',
          onClick: () => {
            navigate({
              pathname: `${notificationManagementComposeEmailBaseURL}/${data.id}`,
              search: createSearchParams({ mode: 'view' }).toString()
            })
          }
        })
      }
    }
    if (['PARTLY_DELIVERED'].includes(status)) {
      if (permission['resend_massmail']) {
        items.unshift({
          label: 'Resend to Failed List',
          onClick: () => {
            console.log('Resend')
          }
        })
      }
    }
    return _isEmpty(items) ? (
      <></>
    ) : (
      <ActionButton
        items={items}
        paperStyles={{
          minWidth: '150px'
        }}
      />
    )
  }, [])

  const columns = useMemo(() => {
    return [
      {
        header: 'Title',
        body: getTitleColumn,
        style: { minWidth: '12vw', width: '12vw' }
      },
      {
        header: 'Created By',
        body: getAuthorColumn,
        style: { minWidth: '10vw', width: '10vw' }
      },
      {
        header: 'Product Name',
        body: getProductColumn,
        style: { minWidth: '12vw', width: '12vw' }
      },
      {
        header: 'Purpose',
        body: getPurposeColumn,
        style: { minWidth: '8vw', width: '8vw' }
      },
      {
        header: 'Sent Time',
        body: getSentTimeColumn,
        style: { minWidth: '12vw', width: '12vw' }
      },
      {
        header: (
          <TypographyLineClamp line={1} tooltipValue="Only send to Critical Alerts list">
            Only send to Critical Alerts list
          </TypographyLineClamp>
        ),
        body: getCriticalAlertColumn,
        style: { minWidth: '15vw', width: '15vw' }
      },
      {
        header: 'Status',
        body: getStatusColumn,
        style: { minWidth: '10vw', width: '10vw' }
      },
      {
        header: '',
        body: getActionColumn,
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [])

  const contextValue = useMemo(() => ({ columns, ...searchData }), [columns, searchData])

  return (
    <MassNotificationContext.Provider value={contextValue}>
      <MassNotificationPage />
    </MassNotificationContext.Provider>
  )
}
export default MassNotificationProvider
