import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../../store'

type MyAccountState = {
  editMode: '' | 'info'
  submitMode: '' | 'info'
  isDirtyField: boolean
  isLoadingSubmit: boolean
}

const initialState: MyAccountState = {
  editMode: '',
  submitMode: '',
  isDirtyField: false,
  isLoadingSubmit: false
}

const myAccount = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    saveEditMode: (state, action: PayloadAction<'' | 'info'>) => {
      state.editMode = action.payload
    },
    saveSubmitMode: (state, action: PayloadAction<'' | 'info'>) => {
      state.submitMode = action.payload
    },
    saveIsDirtyField: (state, action: PayloadAction<boolean>) => {
      state.isDirtyField = action.payload
    },
    saveIsLoadingSubmit: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSubmit = action.payload
    }
  }
})

export const { saveEditMode, saveSubmitMode, saveIsDirtyField, saveIsLoadingSubmit } = myAccount.actions

export default myAccount.reducer

export const selectEditMode = (state: RootState) => state.myAccount.editMode
export const selectSubmitMode = (state: RootState) => state.myAccount.submitMode
export const selectIsDirtyField = (state: RootState) => state.myAccount.isDirtyField
export const selectIsLoadingSubmit = (state: RootState) => state.myAccount.isLoadingSubmit
