import React, { FC, useEffect, useState } from 'react'

import _get from 'lodash/get'

import { Box, Button, Drawer, Typography, TypographyLink, useTheme } from '@opswat/react-ui'
import { getLocalStorage, setLocalStorage } from '@opswat/react-core'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { DIALOGS } from '@myopswat/common'

const CookieSettingDrawer: FC<unknown> = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [isCookieSetting, setIsCookieSetting] = useState<boolean>(false)

  useEffect(() => {
    if (
      getLocalStorage('cn') !== 'all' &&
      getLocalStorage('cn') !== 'necessary' &&
      !_get(dialogType, DIALOGS.COOKIE_SETTINGS, false)
    ) {
      setIsCookieSetting(true)
    } else {
      setIsCookieSetting(false)
    }
  }, [dialogType])

  return (
    <Drawer variant="persistent" anchor="bottom" open={isCookieSetting}>
      <Box
        p={2}
        sx={{
          backgroundColor: '#3a3a3a'
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          variant="outlined"
          color="info"
          sx={{
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            mr: { xs: 2, md: 10 }
          }}
          onClick={() => {
            dispatch(
              toggleDialogs({
                [DIALOGS.COOKIE_SETTINGS]: true
              })
            )

            setIsCookieSetting(false)
          }}
        >
          Cookies Details
        </Button>
        <Box>
          <Typography
            align="center"
            variant="subtitle2"
            sx={{
              color: theme.palette.common.white
            }}
          >
            To improve the user experience this website stores cookies on your computer.
          </Typography>
          <Typography
            align="center"
            variant="subtitle2"
            sx={{
              color: theme.palette.common.white
            }}
          >
            By continuing you accept our cookie policy. For additional information please read the&nbsp;
            <TypographyLink
              variant="inherit"
              color="inherit"
              href={`${process.env.REACT_APP_OPSWAT_URL}/legal/cookies-policy`}
              target="_blank"
            >
              Cookie Policy
            </TypographyLink>
            .
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          sx={{
            ml: { xs: 2, md: 10 }
          }}
          onClick={() => {
            setLocalStorage('cn', 'all')
            setIsCookieSetting(false)
          }}
        >
          Accept All
        </Button>
      </Box>
    </Drawer>
  )
}

export default CookieSettingDrawer
