import { DIALOGS } from '@myopswat/common'
import { Box, ButtonLoading, Chip, DialogAdvanced, FormControl, Grid, TextField, Typography } from '@opswat/react-ui'
import _get from 'lodash/get'
import { useAdminFalseSubmissionResendEmailMutation } from 'myopswat-admin/src/api/analyzer/falseSubmission'
import { selectDialogs, toggleDialogs } from 'myopswat-admin/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-admin/src/store'
import { useSnackbar } from 'notistack'
import { ChangeEvent, FC, useMemo, useState } from 'react'

const DialogFalseDetectionResend: FC<unknown> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)

  const [values, setValues] = useState<string[]>([])
  const [currentValue, setCurrentValue] = useState('')

  const [adminFalseSubmissionResend, { isLoading: isResending }] = useAdminFalseSubmissionResendEmailMutation()

  const fileDescription = useMemo(() => {
    if (_get(dialogType, `${DIALOGS.FALSE_DETECTION_RESEND}_DATA`)) {
      const data: any = _get(dialogType, `${DIALOGS.FALSE_DETECTION_RESEND}_DATA`)
      return `#${data?.id.toString().padStart(8, '0')} - ${data?.filename}`
    }
    return []
  }, [dialogType])

  const handleKeyUp = (e: any) => {
    // check whether pressed key is whitespace
    if (e.keyCode == 32) {
      setValues((oldState: string[]) => [...oldState, e.target.value])
      setCurrentValue('')
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }

  const handleDelete = (item: any, index: number) => {
    const temp = [...values]
    temp.splice(index, 1)
    setValues(temp)
  }

  const handleClose = () => {
    dispatch(
      toggleDialogs({
        [DIALOGS.FALSE_DETECTION_RESEND]: false,
        [`${DIALOGS.FALSE_DETECTION_RESEND}_DATA`]: ''
      })
    )
    setCurrentValue('')
    setValues([])
  }

  const handleSubmit = async () => {
    const submissionId = _get(_get(dialogType, `${DIALOGS.FALSE_DETECTION_RESEND}_DATA`), 'id', null)
    if (submissionId) {
      const emails = currentValue ? values.concat([currentValue]) : values
      adminFalseSubmissionResend({
        id: submissionId,
        recipientEmails: emails
      })
        .unwrap()
        .then(async (response: any) => {
          if (response.success) {
            enqueueSnackbar('Resend email successfully', { variant: 'success' })
            handleClose()
          } else {
            if (_get(response, 'errors.0.message')) {
              enqueueSnackbar(_get(response, 'errors.0.message', 'Resend email failed'), {
                variant: 'error'
              })
            }
          }
        })
        .catch(() => {
          enqueueSnackbar('Resend email failed', { variant: 'error' })
        })
    }
  }

  return (
    <DialogAdvanced
      title={`Resend email for ${fileDescription}`}
      open={_get(dialogType, DIALOGS.FALSE_DETECTION_RESEND, false)}
      onClose={() => handleClose()}
      content={
        <Box>
          <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
            Recipient emails:
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <div className={'container'} style={{ marginBottom: 10 }}>
              {values.map((item, index) => (
                <Chip size="small" sx={{ mr: 1, my: 0.5 }} onDelete={() => handleDelete(item, index)} label={item} />
              ))}
            </div>
            <TextField value={currentValue} onChange={handleChange} onKeyDown={handleKeyUp} />
          </FormControl>
        </Box>
      }
      actions={
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'text',
                color: 'inherit',
                onClick: () => handleClose()
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={false}
            >
              Cancel
            </ButtonLoading>
          </Grid>
          <Grid item xs="auto">
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                onClick: () => handleSubmit(),
                fullWidth: true
              }}
              propsLoading={{ color: 'inherit' }}
              isLoading={isResending}
            >
              Resend
            </ButtonLoading>
          </Grid>
        </Grid>
      }
      dialogProps={{
        maxWidth: 'sm'
      }}
    />
  )
}

export default DialogFalseDetectionResend
