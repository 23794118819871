import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { dashboardUserPageURL } from 'myopswat-admin/src/routes'
import { useEffect } from 'react'

const DashboardHomePage: FC<unknown> = () => {
  const navigate = useNavigate()
  useEffect(() => navigate(dashboardUserPageURL), [])
  return <></>
}

export default DashboardHomePage
