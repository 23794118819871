export const BeautifyJSON = (value: object) => {
  return JSON.stringify(value, null, 2)
}

export const convertValueToString = (value: unknown) => {
  if (Array.isArray(value) || (typeof value === 'object' && value !== null)) {
    return BeautifyJSON(value)
  }
  return String(value)
}
