export const USER_FORM_LABEL_MAP = {
  email: 'Email',
  userTypes: 'Type',
  sfdcUserType: 'SFDC User Type',
  sfdcCountry: 'SFDC Country Restriction',
  sfdcContactId: 'SFDC ContactID',
  sfdcAccountId: 'SFDC AccountID',
  firstName: 'First Name',
  lastName: 'Last Name',
  title: 'Title',
  companyName: 'Company',
  phoneNumber: 'Phone Number',
  timezone: 'Time Zone',
  note: 'Note',
  customerSupportId: 'Customer Support Id',
  streetAddress1: 'Address1',
  streetAddress2: 'Address2',
  city: 'City',
  country: 'Country',
  state: 'State',
  postalCode: 'Postal Code',
  updatedReason: 'Updated Reason',
  showKioskSecureImage: 'Is Kiosk Secure Image',
  showEnginePackage: 'Can Access V4 Definition Updates',
  isManageSubOrganization: 'Manage Sub organizations',
  isOemCustomer: 'OEM Customer',
  isChannelPartner: 'Channel Partner'
}

export const USER_TYPE_VALUES = {
  oemCustomer: 'OP',
  channelPartner: 'CP'
}

export enum BASE_FILTERS {
  CES = 'CES',
  KSI = 'KSI',
  DKSI = 'DKSI'
}

export const CUSTOMER_MANAGEMENT_FILTER = [BASE_FILTERS.CES, BASE_FILTERS.KSI, BASE_FILTERS.DKSI]
export const ORG_MANAGEMENT_FILTER = [BASE_FILTERS.CES, BASE_FILTERS.KSI]

export const CUSTOMER_MANAGEMENT_FILTER_LABELS = {
  [BASE_FILTERS.CES]: 'MD Email Security',
  [BASE_FILTERS.KSI]: 'Kiosk Secure Image',
  [BASE_FILTERS.DKSI]: 'Downloaded Kiosk Secure Image'
}